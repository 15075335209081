define('backoffice/pods/components/transactions-dialog/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var reasons = [{ id: "M:MD", name: "Merchant decision" }, { id: "M:A", name: "Authentication charge" }, { id: "T:T", name: "Test" }];

  exports.default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.reasons = reasons;
    },

    tagName: '',
    refundReasonsList: Ember.computed('refundReasons', function () {
      var reasonsArray = this.get('refundReasons').toArray();

      var reasonsMap = reasonsArray.map(function (item) {
        return { id: item.get('id'), name: item.get('name') };
      });
      reasonsMap.push({ id: 'other', name: 'Input other reason...' });

      return reasonsMap;
    })
  });
});