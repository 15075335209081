define('backoffice/routes/admin/offers/view', ['exports', 'backoffice/mixins/admin-route-mixin', 'ember-simple-auth/mixins/authenticated-route-mixin', 'backoffice/mixins/errors-handler'], function (exports, _adminRouteMixin, _authenticatedRouteMixin, _errorsHandler) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_adminRouteMixin.default, _authenticatedRouteMixin.default, _errorsHandler.default, {
    init: function init() {
      this._super.apply(this, arguments);
      this.title = this.get('intl').t('offers.viewOffer');
    },
    model: function model(params) {
      return Ember.RSVP.hash({
        offers: this.get('store').findAll('offer'),
        newOffer: this.get('store').findRecord('offer', params.offer_id),
        offerTypes: this.get('store').findAll('offerType'),
        currencies: this.get('store').findAll('currency')
      });
    },
    closeOfferAction: function closeOfferAction(offerTypeId) {
      this.transitionTo('admin.offers.index', { queryParams: { offerTypeId: offerTypeId } });
    },

    actions: {
      cancel: function cancel(offerTypeId) {
        if (offerTypeId) {
          this.closeOfferAction(offerTypeId);
        }
      }
    }
  });
});