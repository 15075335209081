define('backoffice/pods/components/table-collapse-row/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    isDetailsShown: false,
    actions: {
      toggleDetails: function toggleDetails(param) {
        if (param.collapsed) {
          this.set('columnsCount', param.columnsCount);
          this.set('isDetailsShown', true);
        } else {
          this.set('isDetailsShown', false);
        }
      }
    }
  });
});