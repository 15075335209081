define('backoffice/mixins/new-offer-set-tooltips', ['exports', 'backoffice/constants/offers'], function (exports, _offers) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var OFFER_MEMBERSHIP_TYPEID = _offers.default.OFFER_MEMBERSHIP_TYPEID,
      OFFER_TRIAL_TYPEID = _offers.default.OFFER_TRIAL_TYPEID,
      OFFER_ONESHOT_TYPEID = _offers.default.OFFER_ONESHOT_TYPEID,
      OFFER_UPSELL_TYPEID = _offers.default.OFFER_UPSELL_TYPEID;


  var offerTooltip = '<div>Select this type of offer to create member accesses with automatic rebilling.</div>';
  var offerOneShotTooltip = '<div>These offers are to be combined with the memberships or one shots. If you create\n a package that includes upsells offers, these prices will be displayed in our upsell form and your users will be able\n  to buy these upsells by providing only the 6 last digits of their credit card number.</div>\n  <div class="m-t-xs">Links to the upsell form will be provided to you once your web site has been approved.</div>';
  var offerUpsellTooltip = '<div>Select this type of offer to charge the user a one-time fee. No automatic charge will\n be applied and there is no time consideration on our side.</div><div class="m-t-xs">You can either grant an\n  access for a certain period of time or grant credits to the user but this has to be managed by your members management system.</div>';

  exports.default = Ember.Mixin.create({
    offerTooltip: offerTooltip,
    offerOneShotTooltip: offerOneShotTooltip,
    offerUpsellTooltip: offerUpsellTooltip,
    setTooltip: function setTooltip(typeId) {
      var text = void 0;

      switch (typeId) {
        case OFFER_MEMBERSHIP_TYPEID:
        case OFFER_TRIAL_TYPEID:
          {
            text = this.get('offerTooltip');
            break;
          }
        case OFFER_ONESHOT_TYPEID:
          {
            text = this.get('offerOneShotTooltip');
            break;
          }
        case OFFER_UPSELL_TYPEID:
          {
            text = this.get('offerUpsellTooltip');
            break;
          }
        default:
          {
            text = null;
          }
      }

      return text;
    }
  });
});