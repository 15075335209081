define('backoffice/pods/components/side-bar/component', ['exports', 'backoffice/constants/permissions'], function (exports, _permissions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var DASHBOARD_MERCHANT_PERFORMANCE_KEY = _permissions.default.DASHBOARD_MERCHANT_PERFORMANCE_KEY,
      DASHBOARD_COUNTRY_PERFORMANCE_KEY = _permissions.default.DASHBOARD_COUNTRY_PERFORMANCE_KEY,
      DASHBOARD_TRANSACTION_PERFORMANCE_KEY = _permissions.default.DASHBOARD_TRANSACTION_PERFORMANCE_KEY,
      TRANSACTIONS_VIEW_KEY = _permissions.default.TRANSACTIONS_VIEW_KEY,
      PAYOUTS_VIEW_KEY = _permissions.default.PAYOUTS_VIEW_KEY,
      MEMBERSHIPS_VIEW_KEY = _permissions.default.MEMBERSHIPS_VIEW_KEY,
      WEBSITES_VIEW_KEY = _permissions.default.WEBSITES_VIEW_KEY,
      SUPPORT_ALL_KEY = _permissions.default.SUPPORT_ALL_KEY,
      PERMISSIONS_VIEW_KEY = _permissions.default.PERMISSIONS_VIEW_KEY,
      FINANCE_VIEW_KEY = _permissions.default.FINANCE_VIEW_KEY,
      BTV_VIEW_KEY = _permissions.default.BTV_VIEW_KEY,
      BTV_REPORTS_VIEW_KEY = _permissions.default.BTV_REPORTS_VIEW_KEY,
      API_DOCS_VIEW_KEY = _permissions.default.API_DOCS_VIEW_KEY,
      CHB_VIEW_KEY = _permissions.default.CHB_VIEW_KEY,
      CHB_REPORTS_VIEW_KEY = _permissions.default.CHB_REPORTS_VIEW_KEY,
      IP_RESTRICTIONS_VIEW_KEY = _permissions.default.IP_RESTRICTIONS_VIEW_KEY,
      CALLBACK_CONFIG_KEY = _permissions.default.CALLBACK_CONFIG_KEY,
      TWO_FACTOR_AUTH_VIEW_KEY = _permissions.default.TWO_FACTOR_AUTH_VIEW_KEY;


  var dashboardPermissions = [DASHBOARD_MERCHANT_PERFORMANCE_KEY, DASHBOARD_COUNTRY_PERFORMANCE_KEY, DASHBOARD_TRANSACTION_PERFORMANCE_KEY];

  var settingsPermissions = [CALLBACK_CONFIG_KEY, IP_RESTRICTIONS_VIEW_KEY, TWO_FACTOR_AUTH_VIEW_KEY];

  exports.default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.dashboardPermissions = dashboardPermissions;
      this.settingsPermissions = settingsPermissions;
    },

    tagName: 'nav',
    classNames: ['navbar-default', 'navbar-static-side'],
    attributeBindings: ['role'],
    transactionsPermissions: TRANSACTIONS_VIEW_KEY,
    btvView: BTV_VIEW_KEY,
    twoFactorAuthSettings: TWO_FACTOR_AUTH_VIEW_KEY,
    btvReportsView: BTV_REPORTS_VIEW_KEY,
    chargebacksView: CHB_VIEW_KEY,
    chargebacksReportsView: CHB_REPORTS_VIEW_KEY,
    payoutsPermissions: PAYOUTS_VIEW_KEY,
    membershipsPermissions: MEMBERSHIPS_VIEW_KEY,
    websitesPermissions: WEBSITES_VIEW_KEY,
    supportPermissions: SUPPORT_ALL_KEY,
    rolesPermissions: PERMISSIONS_VIEW_KEY,
    ipRestrictionsPermissions: IP_RESTRICTIONS_VIEW_KEY,
    financePermissions: FINANCE_VIEW_KEY,
    callbackSettings: CALLBACK_CONFIG_KEY,
    apiDocs: API_DOCS_VIEW_KEY,
    actions: {
      toggleMenu: function toggleMenu(event) {
        event.preventDefault();
        var element = Ember.$(event.target);
        element.closest('li').toggleClass('active');
        element.closest('li').find('.nav-second-level').toggleClass('in');
      }
    }
  });
});