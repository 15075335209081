define('backoffice/routes/index', ['exports', 'backoffice/mixins/admin-route-mixin', 'backoffice/config/environment'], function (exports, _adminRouteMixin, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Route.extend(_adminRouteMixin.default, {
    beforeModel: function beforeModel() {
      var appointment = this.get('settingsStorage').get('beforeInvalidationSucceeded');

      if (appointment) {
        if (/\?/.test(appointment) && appointment !== 'login') {
          var url = void 0,
              params = void 0,
              queryObject = void 0;

          var _appointment$split = appointment.split('?');

          var _appointment$split2 = _slicedToArray(_appointment$split, 2);

          url = _appointment$split2[0];
          params = _appointment$split2[1];


          try {
            queryObject = JSON.parse('{"' + decodeURI(params).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');
          } catch (error) {
            Ember.Log('Parse error on index route: ' + error);
          }

          this.transitionTo(url, { queryParams: queryObject });
        } else {
          try {
            this.replaceWith(appointment);
          } catch (e) {
            this.replaceWith(_environment.default.APP.defaultRoute);
          }
        }
      } else {
        this.transitionTo(_environment.default.APP.defaultRoute);
      }
    }
  });
});