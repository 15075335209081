define('backoffice/pods/components/2fa-profile-form/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    isLoading: false,
    isResetDialogShown: false,
    isRecoveryCodesDialogShown: false,
    recoveryCodes: null,
    init: function init() {
      this._super.apply(this, arguments);
      this.set('recoveryCodes', []);
    },

    actions: {
      closeResetDialog: function closeResetDialog() {
        this.set('isResetDialogShown', false);
      },
      confirmReset2FA: function confirmReset2FA() {
        var callback = this.get('onProfile2faStatusChanged');
        if (typeof callback === "function") {
          callback(false);
        }
        this.set('isResetDialogShown', false);
      },
      showResetDialog: function showResetDialog() {
        this.set('isResetDialogShown', true);
      },
      register2FAConfirmed: function register2FAConfirmed(codes) {
        this.set('recoveryCodes', codes);
        this.set('isRecoveryCodesDialogShown', true);
        var callback = this.get('onProfile2faStatusChanged');
        if (typeof callback === "function") {
          Ember.run(function () {
            return callback(true);
          });
        }
      },
      closeRecoveryCodesDialog: function closeRecoveryCodesDialog() {
        this.set('isRecoveryCodesDialogShown', false);
        this.set('recoveryCodes', []);
      }
    }
  });
});