define('backoffice/mixins/check-domain-mixin', ['exports', 'backoffice/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    currentDomain: window.location.hostname,
    isCGDomain: Ember.computed('currentDomain', function () {
      return (_environment.default['cg-domains'] || []).indexOf(this.currentDomain) !== -1;
    }),
    isIdeaBankDomain: Ember.computed('currentDomain', function () {
      return (_environment.default['ab-domains'] || []).indexOf(this.currentDomain) !== -1;
    }),
    isWLDomain: Ember.computed('currentDomain', function () {
      return (_environment.default['wl-domains'] || []).indexOf(this.currentDomain) !== -1;
    })
  });
});