define('backoffice/models/permission', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    name: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    key: (0, _attr.default)('string'),
    parentId: (0, _attr.default)('string'),
    resourceId: (0, _attr.default)('string')
  });
});