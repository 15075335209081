define('backoffice/pods/components/table-cell-counter/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'th',
    attributeBindings: ['style'],
    index: null,
    data: null,
    row: null,
    meta: null,
    collapsed: null,
    realIndex: Ember.computed('index', 'meta', function () {
      var index = parseInt(this.get('index')),
          number = parseInt(this.get('meta.number')),
          size = parseInt(this.get('meta.size'));

      return index + number * size + 1;
    }),
    click: function click() {
      if (this.get('isTruncated')) {
        this.toggleProperty('collapsed');
        if (this.get('toggleDetails')) {
          this.get('toggleDetails')({ collapsed: this.get('collapsed') });
        }
      }
    }
  });
});