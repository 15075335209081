define('backoffice/pods/components/content-footer/component', ['exports', 'backoffice/constants/general'], function (exports, _general) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'footer',
    copyrightText: _general.COPYRIGHT_TEXT
  });
});