define('backoffice/pods/components/file-uploading/component', ['exports', 'ember-uploader', 'backoffice/constants/payouts', 'backoffice/constants/requests'], function (exports, _emberUploader, _payouts, _requests) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var PAYOUT_UPLOAD_NOTIFICATION_TIME = _payouts.default.PAYOUT_UPLOAD_NOTIFICATION_TIME;
  var FAILED_RESPONSE_STATUS = _requests.default.FAILED_RESPONSE_STATUS;
  exports.default = _emberUploader.default.FileField.extend({
    router: Ember.inject.service('router'),
    notify: Ember.inject.service('notify'),
    session: Ember.inject.service('session'),
    filesDidChange: function filesDidChange(files) {
      var _this = this;

      var uploader = _emberUploader.default.Uploader.create({
        url: this.get('url')
      });
      if (!Ember.isEmpty(files)) {
        var notify = this.get('notify');

        uploader.upload(files[0]).then(function () {
          _this.get('action')();
        }, function (error) {
          if (parseInt(error.status) === FAILED_RESPONSE_STATUS) {
            _this.get('session').invalidate();
          } else {
            _this.$().val(null);
            notify.error(JSON.parse(error.responseText).errors.msg, {
              closeAfter: PAYOUT_UPLOAD_NOTIFICATION_TIME
            });
          }
        });
      }
    }
  });
});