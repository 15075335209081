define('backoffice/controllers/admin/websites/new', ['exports', 'backoffice/controllers/admin/websites/edit'], function (exports, _edit) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _edit.default.extend({
    queryParams: ['copiedId'],
    copiedId: null
  });
});