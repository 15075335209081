define('backoffice/pods/components/offers-tab/component', ['exports', 'backoffice/constants/permissions', 'backoffice/constants/offers'], function (exports, _permissions, _offers) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var WEBSITES_VIEW_KEY = _permissions.default.WEBSITES_VIEW_KEY,
      WEBSITES_EDIT_KEY = _permissions.default.WEBSITES_EDIT_KEY;
  var OFFER_MEMBERSHIP_TYPEID = _offers.default.OFFER_MEMBERSHIP_TYPEID,
      OFFER_TRIAL_TYPEID = _offers.default.OFFER_TRIAL_TYPEID,
      OFFER_ONESHOT_TYPEID = _offers.default.OFFER_ONESHOT_TYPEID,
      OFFER_UPSELL_TYPEID = _offers.default.OFFER_UPSELL_TYPEID;
  exports.default = Ember.Component.extend({
    intl: Ember.inject.service(),
    currencies: null,
    permissionsManager: Ember.inject.service(),
    hasPermissionNewBtn: Ember.computed(function () {
      return this.get('permissionsManager').hasPermission([WEBSITES_VIEW_KEY, WEBSITES_EDIT_KEY]);
    }),
    isActionColumnShown: Ember.computed(function () {
      return this.get('permissionsManager').hasPermission([WEBSITES_VIEW_KEY, WEBSITES_EDIT_KEY]);
    }),
    didRender: function didRender() {
      this._super.apply(this, arguments);
      switch (parseInt(this.get('offerTypeId'), 10)) {
        case OFFER_MEMBERSHIP_TYPEID:
        case OFFER_TRIAL_TYPEID:
          {
            Ember.$('a[href="#tab_1"]').tab('show');
            break;
          }
        case OFFER_ONESHOT_TYPEID:
          {
            Ember.$('a[href="#tab_3"]').tab('show');
            break;
          }
        case OFFER_UPSELL_TYPEID:
          {
            Ember.$('a[href="#tab_5"]').tab('show');
            break;
          }
        default:
          {
            Ember.$('a[href="#tab_1"]').tab('show');
          }
      }
    },

    columns: Ember.computed('currencies', 'offersPrices', 'isActionColumnShown', function () {
      var array = [{
        title: this.get('intl').t('offers.fields.offerId'),
        component: 'table-cell-text',
        property: 'id',
        cls: 'text-center'
      }, {
        title: this.get('intl').t('offers.fields.description'),
        component: 'table-cell-text',
        property: 'name',
        cls: 'text-left'
      }, {
        title: this.get('intl').t('offers.fields.type'),
        component: 'table-cell-text',
        property: 'type.description',
        cls: 'text-center'
      }, {
        title: this.get('intl').t('offers.fields.duration'),
        component: 'table-cell-text',
        property: 'duration',
        cls: 'nowrap-row text-center'
      }, {
        title: this.get('intl').t('offers.fields.rebillsOverOffer'),
        component: 'table-cell-offer-rebill',
        property: 'rebill',
        cls: 'text-center'
      }, {
        title: this.get('intl').t('offers.fields.translation'),
        component: 'table-cell-offer-translations',
        property: 'translations',
        cls: 'text-center'
      }, {
        title: this.get('intl').t('offers.fields.packagesCounter'),
        component: 'table-cell-tooltipstered-packages-counter',
        property: 'packagesAttached',
        cls: 'text-center'
      }, {
        title: this.get('intl').t('offers.fields.status'),
        component: 'table-cell-offer-status',
        property: 'status',
        cls: 'text-center'
      }, {
        title: this.get('intl').t('common.actions'),
        component: 'table-cell-offer-actions',
        property: 'options',
        cls: 'text-center'
      }];

      this.addCurrenciesSettings(array);
      this.checkPermission(array);

      return array;
    }),
    columnsOneShot: Ember.computed('currencies', 'offersPrices', 'isActionColumnShown', function () {
      var array = [{
        title: this.get('intl').t('offers.fields.offerId'),
        component: 'table-cell-text',
        property: 'id',
        cls: 'text-center'
      }, {
        title: this.get('intl').t('offers.fields.description'),
        component: 'table-cell-text',
        property: 'name',
        cls: 'text-left'
      }, {
        title: this.get('intl').t('offers.fields.translation'),
        component: 'table-cell-offer-translations',
        property: 'translations',
        cls: 'text-center'
      }, {
        title: this.get('intl').t('offers.fields.packagesCounter'),
        component: 'table-cell-tooltipstered-packages-counter',
        property: 'packagesAttached',
        cls: 'text-center'
      }, {
        title: this.get('intl').t('offers.fields.status'),
        component: 'table-cell-offer-status',
        property: 'status',
        cls: 'text-center'
      }, {
        title: this.get('intl').t('common.actions'),
        component: 'table-cell-offer-actions',
        property: 'options',
        cls: 'text-center'
      }];

      this.addCurrenciesSettings(array);
      this.checkPermission(array);

      return array;
    }),
    columnsUpsell: Ember.computed('currencies', 'offersPrices', 'isActionColumnShown', function () {
      var array = [{
        title: this.get('intl').t('offers.fields.offerId'),
        component: 'table-cell-text',
        property: 'id',
        cls: 'text-center'
      }, {
        title: this.get('intl').t('offers.fields.description'),
        component: 'table-cell-text',
        property: 'name',
        cls: 'text-left'
      }, {
        title: this.get('intl').t('offers.fields.translation'),
        component: 'table-cell-offer-translations',
        property: 'translations',
        cls: 'text-center'
      }, {
        title: this.get('intl').t('offers.fields.packagesCounter'),
        component: 'table-cell-tooltipstered-packages-counter',
        property: 'packagesAttached',
        cls: 'text-center'
      }, {
        title: this.get('intl').t('offers.fields.status'),
        component: 'table-cell-offer-status',
        property: 'status',
        cls: 'text-center'
      }, {
        title: this.get('intl').t('common.actions'),
        component: 'table-cell-offer-actions',
        property: 'options',
        cls: 'text-center'
      }];

      this.addCurrenciesSettings(array);
      this.checkPermission(array);

      return array;
    }),
    addCurrenciesSettings: function addCurrenciesSettings(array) {
      var _this = this;

      var currencies = this.get('currencies');

      if (currencies) {
        currencies.forEach(function (currency) {
          array.pushObject({
            title: _this.get('intl').t('offers.fields.price') + ' ' + currency.get('iso'),
            component: 'table-cell-text',
            property: 'prices',
            iso: currency.get('iso'),
            cls: 'text-right'
          });
        });
      }
    },
    checkPermission: function checkPermission(array) {
      var hasNotPermission = false,
          index = void 0;
      if (!this.get('isActionColumnShown')) {
        array.forEach(function (item, i) {
          if (item.title === 'Actions') {
            index = i;
            hasNotPermission = true;
          }
        });
      }

      if (hasNotPermission) {
        array.splice(index, 1);
      }
    },

    offersMembership: Ember.computed.filter('offers', function (offer) {
      return parseInt(offer.get('type.id')) === OFFER_MEMBERSHIP_TYPEID || parseInt(offer.get('type.id')) === OFFER_TRIAL_TYPEID;
    }),
    offersOneShot: Ember.computed.filter('offers', function (offer) {
      return parseInt(offer.get('type.id')) === OFFER_ONESHOT_TYPEID;
    }),
    offersUpsell: Ember.computed.filter('offers', function (offer) {
      return parseInt(offer.get('type.id')) === OFFER_UPSELL_TYPEID;
    })
  });
});