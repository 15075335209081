define('backoffice/pods/components/table-row-packages/component', ['exports', 'backoffice/pods/components/table-row/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    checkedItem: Ember.computed('checkedItems', function () {
      return this.get('checkedItems') && this.get('checkedItems').findBy('id', this.get('data.id'));
    }),
    isCheckboxDisabled: Ember.computed('data.offersAttached', function () {
      return !parseInt(this.get('data.offersAttached'));
    }),
    isDefaultDisabled: Ember.computed('checkedItem.isSelected', function () {
      return !this.get('checkedItem.isSelected') || this.get('isCheckboxDisabled');
    }),
    actions: {
      toggleDetailsTable: function toggleDetailsTable(pack) {
        this.get('toggleDetailsTable')(pack);
      },
      updateList: function updateList(item) {
        this.get('updateList')(item);
      },
      select: function select(item) {
        this.get('onSelect')(item);
      }
    }
  });
});