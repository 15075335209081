define('backoffice/pods/components/status-performance-chart/component', ['exports', 'numeral', 'moment'], function (exports, _numeral, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    authorizedData: Ember.computed('data', function () {
      return this.setDataArray('unpaid');
    }),
    capturedData: Ember.computed('data', function () {
      return this.setDataArray('paid');
    }),
    cgDeclinesData: Ember.computed('data', function () {
      return this.setDataArray('cgError');
    }),
    cgVoidedData: Ember.computed('data', function () {
      return this.setDataArray('cancelled');
    }),
    bankDeclinesData: Ember.computed('data', function () {
      return this.setDataArray('acquirerError');
    }),
    refundsData: Ember.computed('data', function () {
      return this.setDataArray('refunded');
    }),
    chbRepresData: Ember.computed('data', function () {
      return this.setDataArray('represented');
    }),
    chargeBackData: Ember.computed('data', function () {
      return this.setDataArray('chargedBack');
    }),
    splineData: Ember.computed('data', function () {
      return this.setDataArray('summary');
    }),
    categories: Ember.computed('data', function () {
      if (this.get('data')) {
        return this.get('data').map(function (item) {
          return new Date(item.get('month'));
        });
      } else {
        return [];
      }
    }),
    setDataArray: function setDataArray(dataType) {
      if (this.get('data')) {
        return this.get('data').map(function (item) {
          var val = item.get(dataType);
          return val === 0 ? null : val;
        });
      } else {
        return [];
      }
    },

    chartOptions: Ember.computed('data', function () {
      return {
        chart: {
          type: 'bar',
          height: 400,
          events: {
            redraw: function redraw() {
              Ember.$.each(this.series, function (i, serie) {
                Ember.$.each(serie.data, function (j, data) {
                  if (data.yBottom - data.plotY < 15) {
                    data.dataLabel.hide();
                  }
                });
              });
            },
            load: function load() {
              Ember.$.each(this.series, function (i, serie) {
                Ember.$.each(serie.data, function (j, data) {
                  if (data.yBottom - data.plotY < 15) {
                    data.dataLabel.hide();
                  }
                });
              });
            }
          }
        },
        legend: {
          reversed: true,
          align: 'right',
          verticalAlign: 'top',
          symbolRadius: 1,
          title: {
            style: {
              fontWeight: 'normal'
            }
          }
        },
        title: {
          text: null
        },
        loading: {
          labelStyle: {
            color: '#000000',
            fontWeight: 'bold'
          }
        },
        tooltip: {
          formatter: function formatter() {
            if (this.y === 0) {
              return false;
            } else {
              var value = (0, _numeral.default)(this.y).format('0,0'),
                  date = (0, _moment.default)(this.x).format("MMM'YY");

              return '<span style="font-size: 12px; font-weight: bold;">' + this.series.name + '.</span><br>' + date + ' : ' + value;
            }
          }
        },
        xAxis: {
          title: {
            text: 'Period',
            style: {
              fontWeight: 'bold'
            }
          },
          labels: {
            type: 'datetime',
            format: '{value:%b\'%y}',
            enabled: true
          },
          categories: this.get('categories')
        },
        yAxis: {
          title: {
            text: 'Transactions',
            style: {
              fontWeight: 'bold'
            },
            y: 15
          },
          tickInterval: 500,
          labels: {
            enabled: true
          }
        },
        plotOptions: {
          series: {
            stacking: 'normal',
            groupPadding: 0
            //pointWidth: 23
          }
        }
      };
    }),
    series: Ember.computed('data', function () {
      return [{
        type: 'spline',
        name: 'Total',
        dashStyle: 'ShortDash',
        data: this.get('splineData'),
        marker: {
          lineColor: '#5290E9',
          fillColor: '#5290E9'
        },
        dataLabels: {
          enabled: true,
          allowOverlap: true,
          align: 'center',
          color: '#000000',
          style: {
            color: 'contrast',
            fontSize: '11px',
            fontWeight: 'normal',
            textOutline: '0px 0px contrast'
          },
          formatter: function formatter() {
            return this.y === 0 ? '' : (0, _numeral.default)(this.y).format('0,0');
          },

          x: 15,
          y: -3
        }
      }, {
        name: 'CHB Represented',
        data: this.get('chbRepresData'),
        visible: false,
        color: '#CEE237',
        dataLabels: {
          enabled: true,
          allowOverlap: true,
          align: 'center',
          color: '#000000',
          style: {
            color: 'contrast',
            fontSize: '11px',
            fontWeight: 'normal',
            textOutline: '0px 0px contrast'
          },
          formatter: function formatter() {
            return this.y === 0 ? '' : (0, _numeral.default)(this.y).format('0,0');
          }
        }
      }, {
        name: 'ChargeBack',
        data: this.get('chargeBackData'),
        color: '#965994',
        dataLabels: {
          enabled: true,
          allowOverlap: true,
          align: 'center',
          color: '#000000',
          style: {
            color: 'contrast',
            fontSize: '11px',
            fontWeight: 'normal',
            textOutline: '0px 0px contrast'
          },
          formatter: function formatter() {
            return this.y === 0 ? '' : (0, _numeral.default)(this.y).format('0,0');
          }
        }
      }, {
        name: 'Refunds',
        data: this.get('refundsData'),
        color: '#5290E9',
        dataLabels: {
          enabled: true,
          allowOverlap: true,
          align: 'center',
          color: '#000000',
          style: {
            color: 'contrast',
            fontSize: '11px',
            fontWeight: 'normal',
            textOutline: '0px 0px contrast'
          },
          formatter: function formatter() {
            return this.y === 0 ? '' : (0, _numeral.default)(this.y).format('0,0');
          }
        }
      }, {
        name: 'Bank declines',
        data: this.get('bankDeclinesData'),
        color: '#9A9289',
        dataLabels: {
          enabled: true,
          allowOverlap: true,
          align: 'center',
          color: '#000000',
          style: {
            color: 'contrast',
            fontSize: '11px',
            fontWeight: 'normal',
            textOutline: '0px 0px contrast'
          },
          formatter: function formatter() {
            return this.y === 0 ? '' : (0, _numeral.default)(this.y).format('0,0');
          }
        }
      }, {
        name: 'CG voided',
        data: this.get('cgVoidedData'),
        color: '#E14D57',
        dataLabels: {
          enabled: true,
          allowOverlap: true,
          align: 'center',
          color: '#000000',
          style: {
            color: 'contrast',
            fontSize: '11px',
            fontWeight: 'normal',
            textOutline: '0px 0px contrast'
          },
          formatter: function formatter() {
            return this.y === 0 ? '' : (0, _numeral.default)(this.y).format('0,0');
          }
        }
      }, {
        name: 'CG declines',
        data: this.get('cgDeclinesData'),
        color: '#EC932F',
        dataLabels: {
          enabled: true,
          allowOverlap: true,
          align: 'center',
          color: '#000000',
          style: {
            color: 'contrast',
            fontSize: '11px',
            fontWeight: 'normal',
            textOutline: '0px 0px contrast'
          },
          formatter: function formatter() {
            return this.y === 0 ? '' : (0, _numeral.default)(this.y).format('0,0');
          }
        }
      }, {
        name: 'Captured',
        data: this.get('capturedData'),
        color: '#71B37C',
        dataLabels: {
          enabled: true,
          allowOverlap: true,
          align: 'center',
          color: '#000000',
          style: {
            color: 'contrast',
            fontSize: '11px',
            fontWeight: 'normal',
            textOutline: '0px 0px contrast'
          },
          formatter: function formatter() {
            return this.y === 0 ? '' : (0, _numeral.default)(this.y).format('0,0');
          }
        }
      }, {
        name: 'Authorized',
        data: this.get('authorizedData'),
        color: '#CEE237',
        dataLabels: {
          enabled: true,
          allowOverlap: true,
          align: 'center',
          color: '#000000',
          style: {
            color: 'contrast',
            fontSize: '11px',
            fontWeight: 'normal',
            textOutline: '0px 0px contrast'
          },
          formatter: function formatter() {
            return this.y === 0 ? '' : (0, _numeral.default)(this.y).format('0,0');
          }
        }
      }];
    })
  });
});