define('backoffice/pods/components/offer-settings-tooltip/component', ['exports', 'ember-cli-tooltipster/components/tool-tipster'], function (exports, _toolTipster) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _toolTipster.default.extend({
    triggerEvent: 'click',
    maxWidth: 300,
    contentAsHTML: true,
    side: 'top',
    content: Ember.computed('tooltip', function () {
      return this.get('tooltip');
    })
  });
});