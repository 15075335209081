define('backoffice/routes/admin/offers/new', ['exports', 'backoffice/mixins/admin-route-mixin', 'ember-simple-auth/mixins/authenticated-route-mixin', 'backoffice/mixins/errors-handler', 'backoffice/constants/offers'], function (exports, _adminRouteMixin, _authenticatedRouteMixin, _errorsHandler, _offers) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var OFFER_UPSELL_TYPEID = _offers.default.OFFER_UPSELL_TYPEID;
  exports.default = Ember.Route.extend(_adminRouteMixin.default, _authenticatedRouteMixin.default, _errorsHandler.default, {
    init: function init() {
      this._super.apply(this, arguments);
      this.title = this.get('intl').t('offers.newOffer');
    },

    queryParams: {
      offerTypeId: { refreshModel: true }
    },
    model: function model() {
      return Ember.RSVP.hash({
        offers: this.get('store').findAll('offer'),
        newOffer: this.get('store').createRecord('offer'),
        offerTypes: this.get('store').query('offerType', {}),
        currencies: this.get('store').findAll('currency')
      });
    },
    closeOfferAction: function closeOfferAction(id) {
      this.transitionTo('admin.offers.index', { queryParams: { offerTypeId: id } });
    },
    clearSavedOffer: function clearSavedOffer(offer) {
      var offerFromStore = this.get('store').peekRecord('offer', +offer.get('id'));
      offerFromStore.set('prices', offer.get('prices').filter(function (price) {
        return price.get('id');
      }));
      offerFromStore.set('translations', offer.get('translations').filter(function (translation) {
        return translation.get('id').indexOf('null') === -1;
      }));
    },

    actions: {
      willTransition: function willTransition() {
        this.get('store').unloadAll('packageTitle');
      },
      offerAction: function offerAction(offer) {
        var _this = this;

        var id = offer.get('type.id');
        var upsellSavedOffer = null;
        if (offer.get('isCloneAsUpsell')) {
          offer.save().then(function (savedOffer) {
            var upsellOffer = _this.get('store').createRecord('offer');
            var upsellType = _this.get('store').peekRecord('offerType', OFFER_UPSELL_TYPEID);

            upsellOffer.set('type', upsellType);
            upsellOffer.set('name', savedOffer.get('name'));
            upsellOffer.set('days', savedOffer.get('days'));
            upsellOffer.set('months', savedOffer.get('months'));
            upsellOffer.set('rebill', savedOffer.get('rebill'));
            upsellOffer.set('upsellRelatedToOneshot', savedOffer.get('id'));
            upsellOffer.set('isDynamicPricing', savedOffer.get('isDynamicPricing'));
            upsellOffer.set('prices', savedOffer.get('prices').filter(function (price) {
              return !price.get('id');
            }));

            savedOffer.get('translations').forEach(function (item) {
              upsellOffer.get('translations').pushObject(_this.get('store').peekRecord('offerTranslation', 'null' + item.get('langcode')));
            });

            upsellSavedOffer = upsellOffer;
            _this.clearSavedOffer(savedOffer);

            return upsellOffer.save();
          }).then(function () {
            _this.clearSavedOffer(upsellSavedOffer);
            _this.closeOfferAction(id);
          }).catch(function (error) {
            _this.handleErrors(error);
          });
        } else {
          offer.save().then(function (savedOffer) {
            _this.clearSavedOffer(savedOffer);
            _this.closeOfferAction(id);
          }).catch(function (error) {
            _this.handleErrors(error);
          });
        }
      },
      cancel: function cancel(id) {
        if (id) {
          this.closeOfferAction(id);
        }
      }
    }
  });
});