define('backoffice/pods/components/offers-table/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'table',
    didRender: function didRender() {
      this._super.apply(this, arguments);
      var id = this.$().attr('id'),
          elt = Ember.$('#' + id),
          hoveredRow = elt.find('tr:not(".details-info") td');

      if (hoveredRow.length) {
        hoveredRow.hover(function () {
          var t = parseInt(Ember.$(this).index()) + 1;

          elt.find('thead th:nth-child(' + t + ')').addClass('highlighted');
          elt.find('td:nth-child(' + t + ')').addClass('highlighted');
          Ember.$(this).addClass('active-highlighted');
        }, function () {
          var t = parseInt(Ember.$(this).index()) + 1;

          elt.find('thead th:nth-child(' + t + ')').removeClass('highlighted');
          elt.find('td:nth-child(' + t + ')').removeClass('highlighted');
          Ember.$(this).removeClass('active-highlighted');
        });
      }
    }
  });
});