define('backoffice/routes/admin/support/tickets/new', ['exports', 'backoffice/mixins/admin-route-mixin', 'ember-simple-auth/mixins/authenticated-route-mixin', 'backoffice/config/environment', 'backoffice/constants/requests'], function (exports, _adminRouteMixin, _authenticatedRouteMixin, _environment, _requests) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var CLOSE_NOTIFY_DELAY = _requests.default.CLOSE_NOTIFY_DELAY;
  exports.default = Ember.Route.extend(_adminRouteMixin.default, _authenticatedRouteMixin.default, {
    init: function init() {
      this._super.apply(this, arguments);
      this.title = this.get('intl').t('support.newTicketTitle');
    },

    notify: Ember.inject.service('notify'),
    session: Ember.inject.service('session'),
    beforeModel: function beforeModel() {
      this.controllerFor('admin.support.tickets').set('isNewButtonVisible', false);
    },
    resetController: function resetController(controller) {
      controller.set('subject', null);
      controller.set('category', null);
      controller.set('level', null);
      controller.set('newMessage', null);
      controller.set('files', []);
      controller.set('selectedCategory', null);
      controller.set('selectedLevel', null);
      controller.set('isButtonsDisabled', false);
    },

    actions: {
      setCategory: function setCategory(categoryDescription) {
        var categoryValue = this.controller.getMapValue(this.controller.get('categoriesMap'), categoryDescription);
        this.controller.set('category', categoryValue);
        this.controller.set('selectedCategory', categoryDescription);
      },
      setLevel: function setLevel(levelDescription) {
        var levelValue = this.controller.getMapValue(this.controller.get('severitiesMap'), levelDescription);
        this.controller.set('level', levelValue);
        this.controller.set('selectedLevel', levelDescription);
      },
      createTicket: function createTicket() {
        // validation
        var isErrors = false,
            subject = this.controller.get('subject'),
            category = this.controller.get('category'),
            level = this.controller.get('level') || null,
            message = this.controller.get('newMessage');

        if (!subject || typeof subject === 'string' && !subject.trim()) {
          this.controller.set('isSubjectError', true);
          isErrors = true;
        }
        if (!category) {
          this.controller.set('isCategoryError', true);
          isErrors = true;
        }
        if (this.controller.get('categoriesMap')[category] === this.controller.get('categories')[1] && level === null || level === undefined) {
          this.controller.set('isSeverityLevelError', true);
          isErrors = true;
        }
        if (!message || typeof message === 'string' && !message.trim()) {
          this.controller.set('isContentError', true);
          isErrors = true;
        }

        if (isErrors) {
          return;
        }

        this.controller.set('isButtonsDisabled', true);

        var formData = new FormData();
        formData.append('title', subject);
        formData.append('product', category);
        formData.append('message', message);
        if (level !== null) {
          formData.append('category', level);
        }
        this.controller.get('files').forEach(function (item) {
          formData.append(item.fieldName, item.file);
        });

        var _this = this;

        Ember.$.ajax({
          url: _environment.default.APP.apiHost + '/api/tickets',
          data: formData,
          type: 'POST',
          async: false,
          headers: {
            Authorization: 'Bearer ' + this.get('session').get('data.authenticated.token.access_token')
          },
          mimeType: 'multipart/form-data',
          processData: false,
          contentType: false,
          success: function success() /*successData*/{
            _this.send('onSave');
            _this.transitionTo('admin.support.tickets');
          },
          error: function error(_error) {
            var notify = _this.get('notify');
            notify.error(JSON.parse(_error.responseText).errors.msg, { closeAfter: CLOSE_NOTIFY_DELAY });
            _this.controller.set('isButtonsDisabled', false);
          }
        });
      },
      cancelTicket: function cancelTicket() {
        this.transitionTo('admin.support.tickets');
      },
      fileChanged: function fileChanged(event) {
        var counter = this.controller.get('fileCounter');
        this.controller.get('files').addObject({ fieldName: 'file-' + counter, file: event.target.files[0] });
        this.controller.set('fileCounter', counter + 1);
      },
      removeFile: function removeFile(file, event) {
        event.preventDefault();
        var removedFile = this.controller.get('files').findBy('fieldName', file.fieldName);
        this.controller.get('files').removeObject(removedFile);
      },
      willTransition: function willTransition() {
        this.controllerFor('admin.support.tickets').set('isNewButtonVisible', true);
      },
      resetError: function resetError(errorParam) {
        this.controller.set(errorParam, false);
      }
    }
  });
});