define('backoffice/pods/components/trx-refund/component', ['exports', 'ember-intl'], function (exports, _emberIntl) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var otherReasonValue = 'other';

  exports.default = Ember.Component.extend({
    intl: Ember.inject.service(),
    reason: null,
    maxRefundAmount: null,
    errorRefundAmountMessage: 'Error',
    selectCancelTooltip: (0, _emberIntl.t)('transactions.selectCancelTooltip'),
    cancelVisible: Ember.computed(function () {
      return !!this.get('selectedTransaction.cancelItem');
    }),
    init: function init() {
      this._super.apply(this, arguments);
      //this.get('intl').setLocale('en-us');
      var maxRefundAmount = (this.get('selectedTransaction.amount') - (this.get('selectedTransaction.refundedAmount') ? this.get('selectedTransaction.refundedAmount') : 0)) / 100;
      this.set('maxRefundAmount', maxRefundAmount);
      this.set('refundAmount', maxRefundAmount);
    },
    willDestroyElement: function willDestroyElement() {
      this.set('maxRefundAmount', null);
      this.set('reason', null);
      this.set('customReason', null);
      this.set('refundAmount', null);
    },

    charged: Ember.computed(function () {
      return this.get('intl').formatNumber(this.get('selectedTransaction.amount') / 100, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
      });
    }),
    refunded: Ember.computed(function () {
      var refundedAmount = this.get('selectedTransaction.refundedAmount') ? this.get('selectedTransaction.refundedAmount') : 0;

      return this.get('intl').formatNumber(refundedAmount / 100, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
      });
    }),
    otherReasonChosen: Ember.computed('reason.id', function () {
      return this.get('reason.id') === otherReasonValue;
    }),
    isErrorRefundAmount: Ember.computed('refundAmount', function () {
      var refundAmount = this.get('refundAmount'),
          hasError = false;

      if (refundAmount) {
        if (/^\d+(\.\d+)?$/.test(refundAmount)) {
          if (parseFloat(refundAmount) === 0) {
            this.set('errorRefundAmountMessage', 'More than 0.00 ' + this.get('selectedTransaction.currency'));
            hasError = true;
          } else if (refundAmount > this.get('maxRefundAmount')) {
            this.set('errorRefundAmountMessage', 'Not more than ' + this.get('maxRefundAmount') + ' ' + this.get('selectedTransaction.currency'));
            hasError = true;
          }
        } else {
          this.set('errorRefundAmountMessage', 'Numbers only');
          hasError = true;
        }
      }

      return hasError;
    }),
    actions: {
      confirm: function confirm() {
        var reason = void 0;
        if (this.get('reason.id') === otherReasonValue) {
          reason = this.get('customReason');
        } else {
          reason = this.get('reason.id');
        }

        var params = {
          transactionId: this.get('selectedTransaction.transactionId'),
          reason: reason,
          amount: Math.round(parseFloat(this.get('refundAmount')) * 100)
        };

        if (this.get('selectedTransaction.cancelItem.isSelected')) {
          params.subscriptionId = this.get('selectedTransaction.cancelItem.id');
        }

        this.get('refund')(params);
        this.set('reason', null);
        this.set('customReason', null);
      },
      cancelOperation: function cancelOperation() {
        this.set('reason', null);
        this.set('customReason', null);
        this.set('refundAmount', 0);
        this.get('closeDialog')();
      },
      select: function select(item) {
        item.toggleProperty('isSelected');
        this.get('updateList')(item);
      }
    }
  });
});