define('backoffice/pods/components/offer-settings-translations-collapse-btn/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'h4',
    classNames: ['panel-title'],
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      Ember.run.schedule('afterRender', this, function () {
        if (_this.get('newOffer.translations.length') > 0) {
          Ember.$('a[data-toggle="collapse"]').trigger('click');
        }
      });
    },

    actions: {
      collapse: function collapse() {
        this.toggleProperty('collapsed');
      }
    }
  });
});