define('backoffice/pods/components/offer-settings-name-input/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['row'],
    disabled: false,
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.set('tooltip', null);
      this.set('title', null);
      this.set('placeholder', null);
    },

    actions: {
      showErrors: function showErrors(param) {
        this.set(param, true);
      }
    }
  });
});