define('backoffice/pods/components/search-members-form/component', ['exports', 'backoffice/constants/requests'], function (exports, _requests) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var CLOSE_NOTIFY_DELAY = _requests.default.CLOSE_NOTIFY_DELAY;


  var offersTypesList = [{ id: 0, name: 'One-Off', isSelected: false }, { id: 1, name: 'Subscription', isSelected: false }];
  var mbrStatuses = [{ id: 0, name: 'Active', isSelected: false }, { id: 1, name: 'Cancelled', isSelected: false }, { id: 2, name: 'Test', isSelected: false }];

  exports.default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.offersTypesList = offersTypesList;
      this.selectedTypes = [];
      this.selectedWebsites = [];
      this.selectedOffers = [];
      this.mbrStatuses = mbrStatuses;
    },

    store: Ember.inject.service('store'),
    notify: Ember.inject.service('notify'),
    model: null,
    // Multiselect default items
    offersList: Ember.computed('model.websites', 'selectedWebsites', function () {
      var _this = this;

      var selectedWebsites = this.get('selectedWebsites'),
          websites = [];

      if (selectedWebsites.length) {
        websites = selectedWebsites.map(function (item) {
          return item.get('id');
        });
      } else {
        var model = this.get('model.websites');
        if (model !== undefined) {
          websites = model.map(function (item) {
            return item.get('id');
          });
        } else {
          return [];
        }
      }
      return this.get('store').query('short-offer', { websiteId: websites.join(',') }).catch(function (error) {
        _this.handleErrors(error);
      });
    }),

    // Multiselect dropdown default parameters
    dropdownStatusOpen: false,
    dropdownWebsiteOpen: false,
    dropdownOfferOpen: false,
    dropdownOferGroupTypesOpen: false,

    handleErrors: function handleErrors(error) {
      var notify = this.get('notify');
      notify.error(error.errors.msg, {
        closeAfter: CLOSE_NOTIFY_DELAY
      });
    },
    createParamsList: function createParamsList(arr) {
      return arr.map(function (el) {
        return el.id;
      }).join();
    },
    addParamToList: function addParamToList(item, list) {
      var paramsList = list.addObject(item);
      return paramsList.join();
    },
    removeParamFromList: function removeParamFromList(item, list) {
      var paramsList = list.removeObject(item);
      return paramsList.join();
    },


    actions: {
      searchMbrs: function searchMbrs() {
        this.get('searchMbrs')();
      },
      resetSearchForm: function resetSearchForm() {
        this.get('resetSearchForm')();
      },
      setDateRange: function setDateRange(start, end) {
        this.get('updateSearchParam')('dateFrom', start);
        this.get('updateSearchParam')('dateTo', end);
      },
      cancelDatePicker: function cancelDatePicker() {},
      addTagFilter: function addTagFilter(filter, item, list) {
        this.get('updateSearchParam')(filter, this.addParamToList(item, list));
      },
      removeTagFilter: function removeTagFilter(filter, item, list) {
        this.get('updateSearchParam')(filter, this.removeParamFromList(item, list));
      },
      changeMultiselectFilter: function changeMultiselectFilter(filter, items) {
        if (filter === 'websiteId') {
          this.set('selectedWebsites', items);
        }

        this.get('updateSearchParam')(filter, this.createParamsList(items));
      },
      changeParam: function changeParam(param, value) {
        this.get('updateSearchParam')(param, value);
      }
    }
  });
});