define('backoffice/pods/components/table-row-roles/component', ['exports', 'backoffice/pods/components/table-row/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    classNameBindings: ['isActiveRow:selected-row'],
    isActiveRow: Ember.computed('activeRow', function () {
      return this.get('activeRow.id') === this.get('data.id');
    }),
    sharedModel: null,
    actions: {
      toggleDetailsTable: function toggleDetailsTable(data) {
        this.get('toggleDetailsTable')(data);
      }
    }
  });
});