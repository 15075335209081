define('backoffice/pods/components/change-user-status-dialog/component', ['exports', 'ember-intl'], function (exports, _emberIntl) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    isDialogShown: false,
    confirmBtnName: (0, _emberIntl.t)('common.yes'),
    cancelBtnName: (0, _emberIntl.t)('common.no'),
    actionType: null,
    init: function init() {
      this._super.apply(this, arguments);
      this.set('actionType', this.get('selectedUser.enabled') ? 'Disable' : 'Enable');
    },

    actionTypeDescription: Ember.computed(function () {
      return this.get('selectedUser.enabled') ? 'disable' : 'enable';
    }),
    accessDescription: Ember.computed(function () {
      return this.get('selectedUser.enabled') ? 'disabled' : 'enabled';
    }),
    actions: {
      confirm: function confirm() {
        this.get('confirm')();
      },
      cancelOperation: function cancelOperation() {
        this.get('reset')();
      }
    }
  });
});