define('backoffice/pods/components/table-cell-tx-actions/component', ['exports', 'backoffice/constants/transactions'], function (exports, _transactions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var ORIGINAL_TRANSACTION_TYPE_REBILL = _transactions.default.ORIGINAL_TRANSACTION_TYPE_REBILL,
      ORIGINAL_TRANSACTION_TYPE_UPSELL = _transactions.default.ORIGINAL_TRANSACTION_TYPE_UPSELL,
      ORIGINAL_TRANSACTION_TYPE_SALE = _transactions.default.ORIGINAL_TRANSACTION_TYPE_SALE,
      ORIGINAL_TRANSACTION_TYPE_ALTPAYMENT = _transactions.default.ORIGINAL_TRANSACTION_TYPE_ALTPAYMENT;
  exports.default = Ember.Component.extend({
    tagName: 'td',
    index: null,
    data: null,
    row: null,
    operationType: null,
    hasActions: Ember.computed('row.{originalTransactionType,successful,hasBeenCancelled,hasBeenRefunded,hasBeenChargedback,refundedAmount}', function () {
      var successful = !!this.get('row.successful'),
          hasBeenCancelled = !!this.get('row.hasBeenCancelled'),
          hasBeenPaid = !!this.get('row.hasBeenPaid'),
          hasBeenChargedback = !!this.get('row.hasBeenChargedback'),
          hasBeenRefunded = this.get('row.hasBeenRefunded'),
          orderId = this.get('row.orderId'),
          refundedAmount = this.get('row.refundedAmount') ? parseFloat(this.get('row.refundedAmount')) : 0,
          amount = this.get('row.amount') ? parseFloat(this.get('row.amount')) : 0,
          originalTransactionType = this.get('row.originalTransactionType').toLowerCase();

      if (originalTransactionType === ORIGINAL_TRANSACTION_TYPE_REBILL || originalTransactionType === ORIGINAL_TRANSACTION_TYPE_UPSELL || originalTransactionType === ORIGINAL_TRANSACTION_TYPE_SALE || ORIGINAL_TRANSACTION_TYPE_ALTPAYMENT) {

        if (!successful || hasBeenChargedback || hasBeenCancelled) {
          return false;
        }

        if (orderId) {
          if (hasBeenPaid && !hasBeenRefunded) {
            this.set('operationType', 'Refund');
            return true;
          }
          if (hasBeenPaid && hasBeenRefunded && refundedAmount < amount) {
            this.set('operationType', 'Refund');
            return true;
          }
        }
      }

      return false;
    }),
    hasResendChback: Ember.computed('row.{originalTransactionType}', function () {
      var originalTransactionType = this.get('row.originalTransactionType').toLowerCase();

      return originalTransactionType !== 'chargeback';
    })
  });
});