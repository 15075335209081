define('backoffice/routes/docs', ['exports', 'backoffice/mixins/admin-route-mixin', 'backoffice/constants/permissions'], function (exports, _adminRouteMixin, _permissions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_adminRouteMixin.default, {
    session: Ember.inject.service('session'),
    permissionsManager: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      var routeName = transition.targetName;
      var hasPermissionForRoute = _permissions.ACCESSIBLE_PERMISSION_FOR_ROUTE[routeName];

      if (hasPermissionForRoute !== undefined && this.get('session.isAuthenticated') && !this.get('permissionsManager').hasPermission(hasPermissionForRoute, true)) {
        this.transitionTo('admin.no-access');
      }
    },
    model: function model() {
      return { user: this.get('accountStorage').get('account') };
    }
  });
});