define('backoffice/controllers/login', ['exports', 'backoffice/config/environment', 'backoffice/constants/auth'], function (exports, _environment, _auth) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var LOCALES = [{ locale: "en-us", name: "EN" }, { locale: "es-es", name: "ES" }, { locale: "pt-br", name: "PT" }];

  exports.default = Ember.Controller.extend({
    queryParams: ['username', 'pass', 'lang'],
    isEnabledTranslations: _environment.default.APP.enableTranslations,
    identification: null,
    password: null,
    errorMessage: null,
    session: Ember.inject.service('session'),
    username: null,
    pass: null,
    intl: Ember.inject.service('intl'),
    switchLanguage: function switchLanguage(lang) {
      if (this.get('isEnabledTranslations') && lang) {
        var locale = LOCALES.find(function (i) {
          return i.name.toLowerCase() === lang;
        }) || LOCALES[0];
        var settingsStorage = this.get('settingsStorage');
        this.get('intl').setLocale(locale.locale);
        settingsStorage.set('user-locale', locale.locale);
      }
    },
    authenticate: function authenticate() {
      var _this = this;

      Ember.$('#warning-sign').addClass('hidden');
      Ember.$('#spinner').toggleClass('hidden');

      var _arguments = Array.prototype.slice.call(arguments),
          identification = _arguments[0],
          password = _arguments[1],
          setupNew = _arguments[2],
          token = _arguments[3];

      this.get('session').authenticate('authenticator:oauth2', identification, password, token).then(function () {
        Ember.$('#spinner').toggleClass('hidden');
        Ember.$('#success-sign').removeClass('hidden');

        if (setupNew) {
          _this.transitionToRoute('new-password');
          return;
        }

        if (token) {
          _this.transitionToRoute('admin.dashboard');
          return;
        }
      }).catch(function (reason) {
        Ember.$('#spinner').toggleClass('hidden');
        Ember.$('#warning-sign').removeClass('hidden');

        var errorText = void 0;
        switch (reason.status) {
          case _auth.default.AUTH_2FA_STATUS_FLAG:
            var _ref = reason || {},
                account = _ref.account;

            if (account && account.is2faConfigured === false) {
              return _this.transitionToRoute('2fa.configuration');
            }

            return _this.transitionToRoute('2fa.verification');
          case 0:
            errorText = reason.statusText ? reason.statusText : _this.get('intl').t('common.errors.serverNotAvailable');
            break;
          case 400:
            errorText = reason.responseJSON.error_description ? _this.get('intl').t('common.errors.' + reason.responseJSON.error_description, { default: 'login.badCredentials' }) : _this.get('intl').t('login.badCredentials');
            break;
          default:
            errorText = reason.statusText ? reason.statusText : _this.get('intl').t('common.errors.noErrorMessage');
        }

        _this.set('errorMessage', '' + errorText);
      });
    },

    actions: {
      authenticate: function authenticate() {
        this.authenticate.apply(this, arguments);
      }
    }
  });
});