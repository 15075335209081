define('backoffice/pods/components/offer-modal/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      cancelOperation: function cancelOperation() {
        // this.sendAction('closeDialog', false);
        this.get('closeDialog')(false);
      }
    }
  });
});