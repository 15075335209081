define('backoffice/pods/components/data-table-pagination/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['pagination-wrapper'],
    display: 10,
    page: null,
    first: null,
    last: null,
    total: null,
    isFirst: Ember.computed('first', function () {
      return this.get('first').toString() === 'true';
    }),
    isLast: Ember.computed('last', function () {
      return this.get('last').toString() === 'true';
    }),
    currentPage: Ember.computed('page', function () {
      return parseInt(this.get('page'));
    }),
    totalPages: Ember.computed('total', function () {
      return parseInt(this.get('total'));
    }),
    rangeToDisplay: Ember.computed('page', function () {
      var prev = parseInt(this.get('page')),
          range = [prev],
          next = prev + 1,
          total = parseInt(this.get('total')),
          display = this.get('display');

      prev--;

      while (display > 0) {
        var pushes = 0;
        if (prev >= 0) {
          range.push(prev);
          prev--;
          pushes++;
        }
        if (next < total) {
          range.push(next);
          next++;
          pushes++;
        }
        if (pushes > 0) {
          display -= pushes;
        } else {
          display--;
        }
      }

      return range.sort(function (a, b) {
        return a - b;
      });
    }),
    actions: {
      changePage: function changePage(page) {
        var pageNum = parseInt(page);
        if (pageNum < 0 || pageNum >= this.get('totalPages')) {
          return false;
        }
        this.get('pageChanged')(pageNum);
      }
    }
  });
});