define('backoffice/helpers/and', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.helper(function (params) {
    for (var i = 0, len = params.length; i < len; i++) {
      if (truthConvert(params[i]) === false) {
        return params[i];
      }
    }
    return params[params.length - 1];

    function truthConvert(result) {
      var truthy = result && Ember.get(result, 'isTruthy');
      if (typeof truthy === 'boolean') {
        return truthy;
      }

      if (Ember.isArray(result)) {
        return Ember.get(result, 'length') !== 0;
      } else {
        return !!result;
      }
    }
  });
});