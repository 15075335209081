define('backoffice/helpers/array-element', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.helper(function (params, hash) {
    var config = hash.config,
        value = params[0],
        isArray = false;

    if (typeof Array.isArray === 'undefined') {
      isArray = Object.prototype.toString.call(value) === '[object Array]';
    } else {
      isArray = Array.isArray(value);
    }

    var data = value;
    if (value.hasOwnProperty('_data')) {
      data = value._data;
      isArray = true;
    }

    value = isArray ? data[hash['index']] : data.get(hash['index']);

    if (config.hasOwnProperty('formatter') && typeof config.formatter === 'function') {
      value = config.formatter(value);
    }

    return value;
  });
});