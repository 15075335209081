define('backoffice/pods/components/dashboard-permissons-manager/component', ['exports', 'backoffice/constants/permissions'], function (exports, _permissions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var DASHBOARD_MERCHANT_PERFORMANCE_KEY = _permissions.default.DASHBOARD_MERCHANT_PERFORMANCE_KEY,
      DASHBOARD_COUNTRY_PERFORMANCE_KEY = _permissions.default.DASHBOARD_COUNTRY_PERFORMANCE_KEY,
      DASHBOARD_TRANSACTION_PERFORMANCE_KEY = _permissions.default.DASHBOARD_TRANSACTION_PERFORMANCE_KEY;
  exports.default = Ember.Component.extend({
    tagName: '',
    permissionsManager: Ember.inject.service(),
    hasMerchantPermission: Ember.computed(function () {
      return this.get('permissionsManager').hasPermission(DASHBOARD_MERCHANT_PERFORMANCE_KEY);
    }),
    hasCountryPermission: Ember.computed(function () {
      return this.get('permissionsManager').hasPermission(DASHBOARD_COUNTRY_PERFORMANCE_KEY);
    }),
    hasTransactionsPermission: Ember.computed(function () {
      return this.get('permissionsManager').hasPermission(DASHBOARD_TRANSACTION_PERFORMANCE_KEY);
    })
  });
});