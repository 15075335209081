define('backoffice/pods/components/2fa-reset-form/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    intl: Ember.inject.service(),
    clientSettings: Ember.inject.service('clientSettings'),
    twoFactorAuthService: Ember.inject.service('twoFactorAuthentication'),
    message: false,
    securityCode: '',
    errorMessage: '',
    successMessage: '',
    isLoading: false,
    isCancelButtonShown: true,
    init: function init() {
      this._super.apply(this, arguments);
    },

    actions: {
      confirm: function confirm() {
        var _this = this;

        var account = this.get('clientSettings').getAccount();

        this.set('message', '');
        this.set('isLoading', true);
        this.set('errorMessage', false);
        this.set('successMessage', false);

        this.get('twoFactorAuthService').reset2FA(account.id, this.get('securityCode')).then(function () {
          _this.set('isLoading', false);
          var callback = _this.get('onResetTokenSuccessfullyVerified');
          if (typeof callback === "function") {
            Ember.run(function () {
              return callback();
            });
          }
        }).catch(function (error) {
          var _ref = error || {},
              message = _ref.message;

          _this.set('isLoading', false);
          _this.set('message', message);
          _this.set('errorMessage', true);
        });
      },
      errorHandler: function errorHandler() {},
      setValidationState: function setValidationState() {}
    }
  });
});