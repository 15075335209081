define('backoffice/pods/components/selected-package-dialog/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    intl: Ember.inject.service(),
    formPositions: Ember.computed('model', function () {
      return this.get('model.formPositions');
    }),
    dialogTitle: Ember.computed('selectedPackageId', function () {
      return this.get('intl').t("packages.selectedPackageIdTitle") + ' ' + this.get('selectedPackageId');
    }),
    actions: {
      cancelOperation: function cancelOperation() {
        this.set('dialogTitle', null);
        this.get('deselect')();
      }
    }
  });
});