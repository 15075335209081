define('backoffice/helpers/lte', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.lte = lte;
  function lte(params) {
    return params.reduce(function (a, b) {
      return a <= b;
    });
  }

  exports.default = Ember.Helper.helper(lte);
});