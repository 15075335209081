define('backoffice/models/country-volume-kpi', ['exports', 'ember-data', 'ember-data/attr'], function (exports, _emberData, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    country: (0, _attr.default)('string'),
    iso: (0, _attr.default)('string'),
    success: (0, _attr.default)('number'),
    failed: (0, _attr.default)('number'),
    count: (0, _attr.default)('number'),
    ratio: (0, _attr.default)('number'),
    volume: (0, _attr.default)('number'),
    reference: (0, _attr.default)('number')
  });
});