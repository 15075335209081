define('backoffice/pods/components/table-cell-tooltipstered-websites-counter/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'td',
    index: null,
    data: null,
    row: null,
    collapsed: null,
    list: Ember.computed('row.websitesTitles', function () {
      return this.get('row.websitesTitles').reduce(function (prev, item) {
        return prev + (item.get('id') + ' - ' + item.get('name') + '<br>');
      }, '');
    }),
    click: function click() {
      if (this.get('isTruncated')) {
        this.toggleProperty('collapsed');
        if (this.get('toggleDetails')) {
          this.get('toggleDetails')({ collapsed: this.get('collapsed') });
        }
      }
    }
  });
});