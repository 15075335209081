define('backoffice/pods/components/bread-crumbs/component', ['exports', 'ember-crumbly/components/bread-crumbs'], function (exports, _breadCrumbs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _breadCrumbs.default.extend({
    init: function init() {
      this._super.apply(this, arguments);
    },

    currentLocale: null,
    intl: Ember.inject.service('intl'),
    routeHierarchy: Ember.computed('intl.primaryLocale', 'currentUrl', 'currentRouteName', 'reverse', function () {
      Ember.get(this, 'currentUrl');
      var currentRouteName = Ember.getWithDefault(this, 'currentRouteName', false);

      (false && !(currentRouteName) && Ember.assert('[ember-crumbly] Could not find a current route', currentRouteName));


      var routeNames = currentRouteName.split('.');
      var filteredRouteNames = this._filterIndexAndLoadingRoutes(routeNames);
      var crumbs = this._lookupBreadCrumb(routeNames, filteredRouteNames);

      return Ember.get(this, 'reverse') ? crumbs.reverse() : crumbs;
    }),
    _lookupBreadCrumb: function _lookupBreadCrumb(routeNames, filteredRouteNames) {
      var _this = this;

      var defaultLinkable = Ember.get(this, 'linkable');
      var pathLength = filteredRouteNames.length;
      var breadCrumbs = Ember.A();

      filteredRouteNames.map(function (name, index) {
        var path = _this._guessRoutePath(routeNames, name, index);
        var title = void 0;
        var route = _this._lookupRoute(path);
        var isHead = index === 0;
        var isTail = index === pathLength - 1;

        var crumbLinkable = index === pathLength - 1 ? false : defaultLinkable;

        (false && !(route) && Ember.assert('[ember-crumbly] `route:' + path + '` was not found', route));


        var multipleBreadCrumbs = route.get('breadCrumbs');

        if (multipleBreadCrumbs) {
          multipleBreadCrumbs.forEach(function (breadCrumb) {
            breadCrumbs.pushObject(breadCrumb);
          });
        } else {
          var breadCrumb = Object.assign({}, route.get('breadCrumb'));

          switch (true) {
            case _this.get('intl').exists('breadcrumbs.' + name):
              title = _this.get('intl').t('breadcrumbs.' + name);
              break;
            case Ember.isPresent(breadCrumb.title):
              title = breadCrumb.title;
              break;
            default:
              title = Ember.String.classify(name);
          }

          if (Ember.isPresent(breadCrumb.path)) {
            path = breadCrumb.path;
          }

          Ember.setProperties(breadCrumb, {
            title: title,
            path: path,
            isHead: isHead,
            isTail: isTail,
            linkable: Ember.isPresent(breadCrumb.linkable) ? breadCrumb.linkable : crumbLinkable
          });

          breadCrumbs.pushObject(breadCrumb);
        }
      });

      return Ember.A(breadCrumbs.filter(function (breadCrumb) {
        return breadCrumb !== undefined;
      }));
    }
  });
});