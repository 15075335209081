define('backoffice/pods/components/search-invoice-form-tab/component', ['exports', 'backoffice/constants/reports'], function (exports, _reports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var INVOICE_APPROVED_STATUS = _reports.default.INVOICE_APPROVED_STATUS,
      INVOICE_APPROVED_DESCRIPTION = _reports.default.INVOICE_APPROVED_DESCRIPTION,
      INVOICE_APPROVED_CLEARED_STATUS = _reports.default.INVOICE_APPROVED_CLEARED_STATUS,
      INVOICE_APPROVED_CLEARED_DESCRIPTION = _reports.default.INVOICE_APPROVED_CLEARED_DESCRIPTION,
      INVOICE_DUE_STATUS = _reports.default.INVOICE_DUE_STATUS,
      INVOICE_DUE_DESCRIPTION = _reports.default.INVOICE_DUE_DESCRIPTION,
      INVOICE_SETTLED_STATUS = _reports.default.INVOICE_SETTLED_STATUS,
      INVOICE_SETTLED_DESCRIPTION = _reports.default.INVOICE_SETTLED_DESCRIPTION,
      INVOICE_RETURNED_STATUS = _reports.default.INVOICE_RETURNED_STATUS,
      INVOICE_RETURNED_DESCRIPTION = _reports.default.INVOICE_RETURNED_DESCRIPTION,
      INVOICE_SUSPENDED_STATUS = _reports.default.INVOICE_SUSPENDED_STATUS,
      INVOICE_SUSPENDED_DESCRIPTION = _reports.default.INVOICE_SUSPENDED_DESCRIPTION;


  var statuses = [{ id: INVOICE_APPROVED_STATUS, name: INVOICE_APPROVED_DESCRIPTION, isSelected: false }, { id: INVOICE_APPROVED_CLEARED_STATUS, name: INVOICE_APPROVED_CLEARED_DESCRIPTION, isSelected: false }, { id: INVOICE_DUE_STATUS, name: INVOICE_DUE_DESCRIPTION, isSelected: false }, { id: INVOICE_RETURNED_STATUS, name: INVOICE_RETURNED_DESCRIPTION, isSelected: false }, { id: INVOICE_SETTLED_STATUS, name: INVOICE_SETTLED_DESCRIPTION, isSelected: false }, { id: INVOICE_SUSPENDED_STATUS, name: INVOICE_SUSPENDED_DESCRIPTION, isSelected: false }];

  exports.default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.statuses = statuses;
    },

    model: null,
    currencies: Ember.computed('model.allCurrencies', function () {
      var model = this.get('model.allCurrencies');
      var currencies = model.map(function (item) {
        return { id: item.get('iso'), name: item.get('iso'), isSelected: false };
      });
      return currencies;
    }),
    // // Multiselect dropdown default parameters
    dropdownStatusOpen: false,
    dropdownCurrencyOpen: false,

    createParamsList: function createParamsList(arr) {
      return arr.map(function (el) {
        return el.id;
      }).join();
    },
    addParamToList: function addParamToList(item, list) {
      var paramsList = list.addObject(item);
      return paramsList.join();
    },
    removeParamFromList: function removeParamFromList(item, list) {
      var paramsList = list.removeObject(item);
      return paramsList.join();
    },

    actions: {
      searchInvoice: function searchInvoice() {
        this.get('searchInvoice')();
      },
      resetSearchForm: function resetSearchForm() {
        this.get('resetSearchForm')();
      },
      setDateRange: function setDateRange(start, end) {
        this.get('updateSearchParam')('dateFrom', start);
        this.get('updateSearchParam')('dateTo', end);
      },
      cancelDatePicker: function cancelDatePicker() {},
      changeMultiselectFilter: function changeMultiselectFilter(filter, items) {
        this.get('updateSearchParam')(filter, this.createParamsList(items));
      },
      changeParam: function changeParam(param, value) {
        this.get('updateSearchParam')(param, value);
      },
      addTagFilter: function addTagFilter(filter, item, list) {
        this.get('updateSearchParam')(filter, this.addParamToList(item, list));
      },
      removeTagFilter: function removeTagFilter(filter, item, list) {
        this.get('updateSearchParam')(filter, this.removeParamFromList(item, list));
      }
    }
  });
});