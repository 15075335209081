define('backoffice/pods/components/table-cell-roles/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'td',
    index: null,
    data: null,
    row: null,
    collapsed: false,
    sharedModel: null,
    store: Ember.inject.service('store'),
    roles: Ember.computed(function () {
      return this.get('store').findAll('role');
    }),
    permissionsManager: Ember.inject.service(),
    click: function click() {},

    actions: {
      roleChanged: function roleChanged(roleId) {
        var permissionsManager = this.get('permissionsManager');
        permissionsManager.changeRole(this.get('row'), roleId);
      }
    }
  });
});