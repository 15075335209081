define('backoffice/helpers/range', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.range = range;
  function range(params /*, hash*/) {
    var range = [],
        i = void 0;
    for (i = params[0]; i <= params[1]; ++i) {
      range.push(i);
    }
    return range;
  }

  exports.default = Ember.Helper.helper(range);
});