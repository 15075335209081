define('backoffice/storages/settlements-columns', ['exports', 'ember-local-storage/local/object'], function (exports, _object) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Storage = _object.default.extend();

  Storage.reopenClass({
    initialState: function initialState() {
      // console.log('Storage columns init');

      return {
        settings: [{
          uniqueKey: 'counter',
          isShown: true
        }, {
          uniqueKey: 'date',
          isShown: true
        }, {
          uniqueKey: 'settlementId',
          isShown: true
        }, {
          uniqueKey: 'settlementCurrency',
          isShown: true
        }, {
          uniqueKey: 'currentAmount',
          isShown: true
        }, {
          uniqueKey: 'reserveAmount',
          isShown: true
        }, {
          uniqueKey: 'totalAmount',
          isShown: true
        }, {
          uniqueKey: 'status',
          isShown: true
        }, {
          uniqueKey: 'settlementDate',
          isShown: true
        }]
      };
    }
  });

  exports.default = Storage;
});