define('backoffice/pods/components/reset-user-pass-dialog/component', ['exports', 'ember-intl'], function (exports, _emberIntl) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    isDialogShown: false,
    confirmBtnName: (0, _emberIntl.t)('common.confirm'),
    cancelBtnName: (0, _emberIntl.t)('common.cancel'),
    actions: {
      confirm: function confirm() {
        this.get('confirm')();
      },
      cancelOperation: function cancelOperation() {
        this.get('reset')();
      }
    }
  });
});