define('backoffice/routes/admin/2fa-settings', ['exports', 'backoffice/mixins/admin-route-mixin', 'ember-simple-auth/mixins/authenticated-route-mixin', 'backoffice/mixins/errors-handler'], function (exports, _adminRouteMixin, _authenticatedRouteMixin, _errorsHandler) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_adminRouteMixin.default, _authenticatedRouteMixin.default, _errorsHandler.default, {
    clientSettings: Ember.inject.service(),
    breadCrumb: {
      title: '...',
      path: '2faSettings'
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.set('breadCrumb.title', this.get('intl').t('otp.settings.title'));
    },

    actions: {}
  });
});