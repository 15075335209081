define('backoffice/routes/admin/forecast', ['exports', 'backoffice/mixins/admin-route-mixin', 'ember-simple-auth/mixins/authenticated-route-mixin', 'moment'], function (exports, _adminRouteMixin, _authenticatedRouteMixin, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_adminRouteMixin.default, _authenticatedRouteMixin.default, {
    title: 'Forecast',
    model: function model() {
      return Ember.RSVP.hash({
        forecastSummary: this.store.query('forecast', {
          dateFrom: (0, _moment.default)().format('YYYY-MM-DD'),
          dateTo: (0, _moment.default)().format('YYYY-MM-DD'),
          groupBy: 'website',
          type: 'summary'
        }),
        forecastDetail: this.store.query('forecast', {
          dateFrom: (0, _moment.default)().format('YYYY-MM-DD'),
          dateTo: (0, _moment.default)().format('YYYY-MM-DD'),
          groupBy: 'website',
          type: 'detail'
        })
      });
    }
  });
});