define('backoffice/pods/components/table-cell-offer-actions/component', ['exports', 'backoffice/constants/permissions', 'backoffice/constants/offers'], function (exports, _permissions, _offers) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var WEBSITES_EDIT_KEY = _permissions.default.WEBSITES_EDIT_KEY,
      WEBSITES_VIEW_KEY = _permissions.default.WEBSITES_VIEW_KEY;
  var OFFER_INACTIVE_STATUS = _offers.default.OFFER_INACTIVE_STATUS;


  var PACKAGE_TO_EDIT_TOOLTIP = 'ONLY not linked to packages offer can be edited. To edit the offer you need to unlink offer from all packages';

  exports.default = Ember.Component.extend({
    tagName: 'td',
    permissionsManager: Ember.inject.service(),
    tooltipContent: PACKAGE_TO_EDIT_TOOLTIP,
    hasActions: Ember.computed('row', function () {
      return parseInt(this.get('row.status')) === OFFER_INACTIVE_STATUS;
    }),
    hasEditPermission: Ember.computed(function () {
      return this.get('permissionsManager').hasPermission([WEBSITES_VIEW_KEY, WEBSITES_EDIT_KEY]);
    }),
    hasViewPermission: Ember.computed(function () {
      return this.get('permissionsManager').hasPermission([WEBSITES_VIEW_KEY]);
    }),
    actions: {
      updateOffer: function updateOffer(offerId) {
        this.get('updateOffer')(offerId);
      },
      viewOffer: function viewOffer(offerId) {
        this.get('viewOffer')(offerId);
      }
    }
  });
});