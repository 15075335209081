define('backoffice/pods/components/set-user-pass-dialog/component', ['exports', 'ember-cp-validations', 'ember-intl'], function (exports, _emberCpValidations, _emberIntl) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var passFormat = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/g;

  var Validations = (0, _emberCpValidations.buildValidations)({
    oldPass: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      messageKey: "profile.setUserPassDialog.validations.requiredMessage"
    })],
    newPass: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      messageKey: "profile.setUserPassDialog.validations.requiredMessage"
    })],
    newPassRepeat: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      messageKey: "profile.setUserPassDialog.validations.requiredMessage"
    }), (0, _emberCpValidations.validator)('confirmation', {
      on: 'newPass',
      messageKey: "profile.setUserPassDialog.validations.notMatched"
    })]
  });

  exports.default = Ember.Component.extend(Validations, {
    isDialogShown: false,
    confirmBtnName: (0, _emberIntl.t)("common.save"),
    cancelBtnName: (0, _emberIntl.t)("common.cancel"),
    oldPass: null,
    newPass: null,
    newPassRepeat: null,
    isFormDisabled: true,
    isOldPassErrorShown: false,
    isNewPassErrorShown: false,
    isNewPassRepeatErrorShown: false,
    isCorrectPasswordFormat: true,
    incorrectPasswordFormatMessage: null,
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      this.set('oldPass', null);
      this.set('newPass', null);
      this.set('newPassRepeat', null);
      this.set('isOldPassErrorShown', null);
      this.set('isNewPassErrorShown', null);
      this.set('isNewPassRepeatErrorShown', null);
      this.set('isCorrectPasswordFormat', true);
      this.set('incorrectPasswordFormatMessage', null);
    },

    actions: {
      confirm: function confirm() {
        this.get('confirm')({ oldPassword: this.get('oldPass'), newPassword: this.get('newPass') });
      },
      cancelOperation: function cancelOperation() {
        this.get('reset')();
      },
      changePassType: function changePassType(name) {
        var $input = Ember.$('input[name="' + name + '"]');

        if ($input.attr('type') === 'password') {
          $input.attr('type', 'text');
        } else {
          $input.attr('type', 'password');
        }
        $input.next('.fa').toggleClass('fa-eye').toggleClass('fa-eye-slash');
      },
      setValidationState: function setValidationState(inputField) {
        if (inputField === 'NewPass') {
          if (!this.get('newPass')) {
            this.set('isCorrectPasswordFormat', false);
            this.set('isNewPassErrorShown', true);
            this.set('incorrectPasswordFormatMessage', (0, _emberIntl.t)("profile.setUserPassDialog.validations.requiredMessage"));
          } else if (!this.get('newPass').match(passFormat)) {
            this.set('isCorrectPasswordFormat', false);
            this.set('isNewPassErrorShown', true);
            this.set('incorrectPasswordFormatMessage', (0, _emberIntl.t)("profile.setUserPassDialog.validations.incorrectPasswordFormatMessage"));
          } else {
            this.set('isCorrectPasswordFormat', true);
            this.set('isNewPassErrorShown', false);
            this.set('incorrectPasswordFormatMessage', null);
          }
        }

        var isFormValid = !!(this.get('validations.attrs.oldPass.isValid') && this.get('isCorrectPasswordFormat') && this.get('validations.attrs.newPassRepeat.isValid'));
        this.set('isFormDisabled', !isFormValid);
      },
      errorHandler: function errorHandler(inputField) {
        this.set('is' + inputField + 'ErrorShown', !this.get('validations.attrs.new' + inputField + '.isValid'));
      }
    }
  });
});