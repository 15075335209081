define('backoffice/routes/admin/packages/new', ['exports', 'backoffice/mixins/admin-route-mixin', 'ember-simple-auth/mixins/authenticated-route-mixin', 'backoffice/mixins/errors-handler', 'backoffice/mixins/reset-scroll-position'], function (exports, _adminRouteMixin, _authenticatedRouteMixin, _errorsHandler, _resetScrollPosition) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_adminRouteMixin.default, _authenticatedRouteMixin.default, _errorsHandler.default, _resetScrollPosition.default, {
    init: function init() {
      this._super.apply(this, arguments);
      this.title = this.get('intl').t('packages.newPackage');
    },
    model: function model() {
      var _this = this;

      var packagePromise = new Ember.RSVP.Promise(function (resolve /*, reject*/) {
        resolve(_this.store.createRecord('package'));
      });

      return Ember.RSVP.hash({
        'package': packagePromise,
        offersList: this.store.query('offer', {})
      });
    },

    actions: {
      cancel: function cancel() {
        var model = this.controller.get('model.package'),
            isChanged = model.get('hasDirtyAttributes');
        if (isChanged) {
          model.rollbackAttributes();
        }
        this.transitionTo('admin.packages.index');
      },
      save: function save(pack) {
        var _this2 = this;

        pack.set('creationDate', null);
        pack.save().then(function () {
          return _this2.transitionTo('admin.packages.index');
        }).catch(function (error) {
          _this2.handleErrors(error);
        });
      },
      willTransition: function willTransition() /*transition*/{
        var model = this.controller.get('model.package');
        if (model.get('hasDirtyAttributes')) {
          model.rollbackAttributes();
        }
      }
    }
  });
});