define('backoffice/models/role', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'ember-data-copyable'], function (exports, _model, _attr, _relationships, _emberDataCopyable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend(_emberDataCopyable.default, {
    changeTracker: { trackHasMany: true, auto: true, except: ['accounts'] },
    name: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    isAssignable: (0, _attr.default)('boolean'),
    isEditable: (0, _attr.default)('boolean'),
    isDefault: (0, _attr.default)('boolean'),
    permissions: (0, _relationships.hasMany)('permission'),
    accounts: (0, _relationships.hasMany)('account')
  });
});