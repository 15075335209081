define('backoffice/helpers/eq2', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.eq2 = eq2;
  function eq2(params) {
    return params.reduce(function (a, b) {
      return a == b;
    });
  } /* jshint ignore:start */
  exports.default = Ember.Helper.helper(eq2);
});