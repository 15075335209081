define('backoffice/pods/components/subscriptions-table-wrapper/component', ['exports', 'backoffice/constants/permissions'], function (exports, _permissions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var MEMBERSHIPS_CANCEL_KEY = _permissions.default.MEMBERSHIPS_CANCEL_KEY,
      MEMBERSHIPS_VIEW_KEY = _permissions.default.MEMBERSHIPS_VIEW_KEY;


  var rowSettings = {
    component: 'table-collapse-row'
  };
  var columns = [{
    uniqueKey: 'id',
    component: 'table-cell-text',
    title: 'ID',
    cls: 'subscriptionid-column id-width text-center',
    key: 'id',
    isAllowHide: false,
    isTruncated: true
  }, {
    uniqueKey: 'txid',
    component: 'table-cell-subscription-txid',
    title: 'Tx.ID',
    cls: 'transactionid-column id-width text-center',
    key: 'initialTransactionId',
    isAllowHide: false,
    isTruncated: true
  }, {
    uniqueKey: 'status',
    component: 'table-cell-text',
    title: 'Status',
    key: 'status',
    cls: 'status-column icon-cell text-center',
    type: 'icon-cell',
    isAllowHide: true,
    isTruncated: true
  }, {
    uniqueKey: 'startDate',
    component: 'table-cell-date-time',
    title: 'Join Date',
    key: 'startDate',
    cls: 'startdate-column date-width nowrap-row text-center',
    isAllowHide: true,
    isTruncated: true
  }, {
    uniqueKey: 'reason',
    component: 'table-cell-text',
    title: 'Reason',
    key: 'reason',
    cls: 'reason-column collapse-width nowrap-row',
    isAllowHide: true,
    isTruncated: true
  }, {
    uniqueKey: 'rebillAmount',
    component: 'table-cell-member-nextrebill-rel',
    title: 'Rebill Amount',
    key: 'amount',
    cls: 'amount-column amount-width nowrap-row text-right',
    isAllowHide: true,
    isTruncated: true
  }, {
    uniqueKey: 'rebillCurrency',
    component: 'table-cell-member-nextrebill-rel',
    title: 'Rebill Currency',
    key: 'currency',
    cls: 'currency-column currency-width nowrap-row text-center',
    isAllowHide: true,
    isTruncated: true
  }, {
    uniqueKey: 'wid',
    component: 'table-cell-subscription-wid',
    title: 'WID',
    cls: 'wid-column wid-width text-center',
    key: 'websiteId',
    isAllowHide: true,
    isTruncated: true
  }, {
    uniqueKey: 'offerId',
    component: 'table-cell-subscription-offerid',
    title: 'OffID',
    cls: 'offid-column offid-width text-center',
    key: 'offerId',
    isAllowHide: true,
    isTruncated: true
  }, {
    uniqueKey: 'userName',
    component: 'table-cell-text',
    title: 'Username',
    key: 'username',
    cls: 'username-column username-width nowrap-row',
    isAllowHide: true,
    isTruncated: true
  }, {
    uniqueKey: 'email',
    component: 'table-cell-text',
    title: 'Email',
    key: 'email',
    cls: 'email-column email-width',
    isAllowHide: true,
    isTruncated: true
  }, {
    uniqueKey: 'previousRebillDate',
    component: 'table-cell-date-time',
    title: 'Previous Rebill Date',
    key: 'lastRebillDate',
    cls: 'prevrebilldate-column date-width nowrap-row text-center',
    isAllowHide: true,
    isTruncated: true
  }, {
    uniqueKey: 'nextRebillDate',
    component: 'table-cell-date-time',
    title: 'Next Rebill Date',
    key: 'nextRebillDate',
    cls: 'nextrebilldate-column date-width nowrap-row text-center',
    isAllowHide: true,
    isTruncated: true
  }, {
    uniqueKey: 'endSubscriptionDate',
    component: 'table-cell-date-time',
    title: 'Subscription End Date',
    key: 'endSubscriptionDate',
    cls: 'enddate-column date-width nowrap-row text-center',
    isAllowHide: true,
    isTruncated: true
  }, {
    uniqueKey: 'cancellationDate',
    component: 'table-cell-date-time',
    title: 'Cancellation Date',
    key: 'cancelDate',
    cls: 'canceldate-column date-width nowrap-row text-center',
    isAllowHide: true,
    isTruncated: true
  }, {
    uniqueKey: 'action',
    component: 'table-cell-subscription-actions',
    title: 'Action',
    key: 'id',
    cls: 'action-column text-center',
    isAllowHide: false,
    isTruncated: false
  }];

  exports.default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.rowSettings = rowSettings;
      this.columns = columns;
    },

    tagName: '',
    session: Ember.inject.service('session'),
    permissionsManager: Ember.inject.service(),
    dateFrom: null,
    dateTo: null,
    status: null,
    websiteId: null,
    offer: null,
    email: null,
    userName: null,
    page: null,
    emailParam: null,
    usernameParam: null,
    size: null,
    isDialogShown: false,
    isActionColumnShown: Ember.computed(function () {
      return this.get('permissionsManager').hasPermission([MEMBERSHIPS_VIEW_KEY, MEMBERSHIPS_CANCEL_KEY]);
    }),
    tableId: 'members-table',
    filter: null,
    actions: {
      pageChanged: function pageChanged(page) {
        this.get('pageChanged')(page);
        return true;
      },
      chooseEntriesOnPage: function chooseEntriesOnPage(item) {
        this.set('chosenEntriesOnPage', item);
        this.get('chooseEntriesOnPage')(item);
      }
    }
  });
});