define('backoffice/pods/components/table-cell-tx-attributes/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'td',
    index: null,
    data: null,
    row: null,
    isThreeD: Ember.computed('row.secure3DProcessed', function () {
      return this.get('row.secure3DProcessed');
    }),
    hasStatus: Ember.computed('data', function () {
      return this.get('row.hasBeenRefunded') || this.get('row.hasBeenChargedback') || this.get('row.hasBeenCancelled');
    }),
    paymentType: Ember.computed('row', function () {
      var paymentTypes = {
        void: { iconText: '', tooltip: '', iconClassName: '' },
        chargeback: { iconText: 'CB', tooltip: 'Chargeback', iconClassName: 'chargeback-icon' },
        refund: { iconText: 'RF', tooltip: 'Refund', iconClassName: 'refund-icon' }
      };

      if (this.get('row.originalTransactionType').toLowerCase() === 'octpt' && this.get('row.hasBeenCancelled')) {
        return paymentTypes.void;
      } else if (this.get('row.transactionType').toLowerCase() === 'sale3dinit') {
        return paymentTypes.void;
      } else if (this.get('row.transactionType').toLowerCase() === 'upsell3dinit') {
        return paymentTypes.void;
      } else {
        if (this.get('row.originalTransactionType').toLowerCase() !== 'refund' && this.get('row.hasBeenRefunded')) {
          return paymentTypes.refund;
        }
        if (this.get('row.hasBeenChargedback')) {
          return paymentTypes.chargeback;
        }
        return paymentTypes.void;
      }
    }),
    click: function click() {
      if (this.get('isTruncated')) {
        this.toggleProperty('collapsed');
        if (this.get('toggleDetails')) {
          this.get('toggleDetails')({ collapsed: this.get('collapsed') });
        }
      }
    }
  });
});