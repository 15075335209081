define('backoffice/controllers/admin/reports/settlements', ['exports', 'moment', 'backoffice/constants/permissions'], function (exports, _moment, _permissions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  var PERMISSIONS_VIEW_KEY = _permissions.default.PERMISSIONS_VIEW_KEY;
  exports.default = Ember.Controller.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.searchParams = {
        dateFrom: _moment.default.utc().startOf('month').format('YYYY-MM-DDTHH:mm:ss'),
        dateTo: (0, _moment.default)().format('YYYY-MM-DDTHH:mm:ss'),
        status: null,
        currency: null,
        invoiceNumbers: null,
        page: null,
        size: null
      };
    },

    searchCtrl: Ember.inject.controller('admin.reports.settlements.search'),
    store: Ember.inject.service(),
    permissionsManager: Ember.inject.service(),
    hasPermissions: Ember.computed(function () {
      return this.get('permissionsManager').hasPermission(PERMISSIONS_VIEW_KEY);
    }),
    updateQueryParams: function updateQueryParams() {
      var _this = this;

      var searchParams = this.get('searchParams'),
          page = void 0;

      Object.keys(searchParams).forEach(function (prop) {
        if (prop === 'page') {
          page = searchParams[prop];
          return;
        }

        if (prop === 'dateFrom' || prop === 'dateTo') {
          if (_this.get('searchCtrl.' + prop) && _this.get('searchCtrl.' + prop) !== searchParams[prop]) {
            _this.set('isNewSearch', true);
          }

          _this.get('searchCtrl').set(prop, searchParams[prop]);
          return;
        }

        if (_this.get('searchCtrl.' + prop) === undefined || _this.get('searchCtrl.' + prop) !== searchParams[prop]) {
          _this.get('searchCtrl').set(prop, searchParams[prop]);
          _this.set('isNewSearch', true);
        }
      });

      this.get('searchCtrl').set('page', this.get('isNewSearch') ? null : page);
    },
    getQueryObject: function getQueryObject() {
      var searchParams = this.get('searchParams'),
          resultParams = void 0;

      resultParams = Object.keys(searchParams).reduce(function (result, prop) {
        var currentKeys = void 0;
        if (searchParams[prop] == null) {
          currentKeys = {};
        } else {
          currentKeys = _defineProperty({}, prop, searchParams[prop]);
        }

        return Ember.merge(result, currentKeys);
      }, {});

      return resultParams;
    },
    updateSearchParam: function updateSearchParam(param, value) {
      var searchparam = 'searchParams.' + param;
      this.set(searchparam, value === '' ? null : value);
    },

    actions: {
      pageChanged: function pageChanged(page) {
        this.updateSearchParam('page', page);
        this.updateQueryParams();
        this.replaceRoute('admin.reports.settlements.search');
        return true;
      },
      updateSearchParam: function updateSearchParam(param, value) {
        this.updateSearchParam(param, value);
      },
      searchSettlement: function searchSettlement() {
        // Create an object only with keys from model that have values (skip undefined or null values)
        if (Ember.getOwner(this).lookup('controller:application').currentPath !== 'admin.reports.settlements.index') {
          this.updateQueryParams();

          if (this.get('isNewSearch')) {
            this.replaceRoute('admin.reports.settlements.search');
          } else {
            this.send('refreshPage');
          }
        } else {
          this.replaceRoute('admin.reports.settlements.search', { queryParams: this.getQueryObject() });
        }

        this.set('isNewSearch', false);
      },
      resetSearchForm: function resetSearchForm() {
        for (var key in this.get('searchParams')) {
          if (this.get('searchParams').hasOwnProperty(key) && this.get('searchParams.' + key) !== null) {
            if (key === 'dateFrom') {
              this.set('searchParams.dateFrom', _moment.default.utc().startOf('month').format('YYYY-MM-DDTHH:mm:ss'));
            } else if (key === 'dateTo') {
              this.set('searchParams.dateTo', (0, _moment.default)().format('YYYY-MM-DDTHH:mm:ss'));
            } else {
              this.set('searchParams.' + key, null);
            }
          }
        }
        this.set('isNewSearch', false);
      }
    }
  });
});