define('backoffice/models/website-member', ['exports', 'ember-data/model', 'ember-data'], function (exports, _model, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr;
  exports.default = _model.default.extend({
    status: attr('string'),
    amount: attr('from-cent'), //rebillAmount
    cancelDate: attr('string'), // cancellationDate
    joinDate: attr('string'), //subscriptionDate
    currency: attr('string'), //rebillCurrency
    email: attr('string'),
    offerId: attr('number'),
    offerTypeName: attr('string'),
    username: attr('string'),
    websiteId: attr('number'),
    nextRebillDate: attr('string'),
    reason: attr('string'),
    prevRebillDate: attr('string'),
    endSubscriptionDate: attr('string'),
    isTest: attr('boolean'),
    numberId: Ember.computed('id', function () {
      return +this.get('id');
    })
  });
});