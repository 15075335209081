define('backoffice/pods/components/set-password-form/component', ['exports', 'backoffice/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    intl: Ember.inject.service(),
    session: Ember.inject.service('session'),
    serverTokenEndpoint: _environment.default.APP.apiHost + '/api/security/password',
    password: null,
    confirmPassword: null,
    message: false,
    errorMessage: '',
    successMessage: '',
    isLoading: false,
    token: null,
    validate: function validate() {
      var password = this.get('password'),
          confirmPassword = this.get('confirmPassword'),
          passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,}$/g,
          isValid = true;

      if (!passwordPattern.test(password)) {
        this.set('message', this.get('intl').t('password.errors.validationPattern'));
        this.set('errorMessage', true);
        this.set('successMessage', false);
        isValid = false;
      } else if (!password || !confirmPassword || password !== confirmPassword) {
        this.set('message', this.get('intl').t('password.errors.validationConfirm'));
        this.set('errorMessage', true);
        this.set('successMessage', false);
        isValid = false;
      }

      return isValid;
    },

    actions: {
      setPassword: function setPassword() {
        var _this = this;

        var isValid = this.validate();

        if (!isValid) {
          return;
        }

        var serverTokenEndpoint = this.get('serverTokenEndpoint');

        this.set('isLoading', true);
        Ember.$.ajax({
          url: serverTokenEndpoint,
          headers: {
            Authorization: 'Bearer ' + this.get('token')
          },
          data: JSON.stringify({
            password: this.get('password')
          }),
          type: 'POST',
          contentType: "application/json; charset=utf-8",
          dataType: 'json'
        }).then(function () /*response*/{
          _this.set('message', _this.get('intl').t('password.resetMessage'));
          _this.set('errorMessage', false);
          _this.set('successMessage', false);
          _this.set('isLoading', false);
          _this.get('router').transitionTo('login');
        }, function (error) {
          var errorText = void 0;

          switch (error.status) {
            case 0:
              errorText = error.statusText ? error.statusText : _this.get('intl').t('common.errors.serverNotAvailable');
              break;
            default:
              errorText = _this.get('intl').t('common.errors.' + error.statusText, { default: 'common.errors.noErrorMessage' });
          }
          _this.set('message', errorText);
          _this.set('successMessage', false);
          _this.set('errorMessage', true);
          _this.set('isLoading', false);
        });
      }
    }
  });
});