define('backoffice/models/account', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    name: (0, _attr.default)('string'),
    login: (0, _attr.default)('string'),
    surname: (0, _attr.default)('string'),
    email: (0, _attr.default)('string'),
    businessname: (0, _attr.default)('string'),
    accountType: (0, _attr.default)('number'),
    role: (0, _relationships.belongsTo)('role'),
    timeZone: (0, _attr.default)('string'),
    enabled: (0, _attr.default)('boolean', { defaultValue: true }),
    is2faConfigured: (0, _attr.default)('boolean', { defaultValue: false }),
    is2faEnabled: (0, _attr.default)('boolean', { defaultValue: false }),
    lastLogin: (0, _attr.default)('string'),
    oldPassword: (0, _attr.default)('string'),
    newPassword: (0, _attr.default)('string')
  });
});