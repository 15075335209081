define('backoffice/helpers/concat_old', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.concat = concat;
  function concat(params) {
    var temp = '';
    for (var i = 0; i < params.length; i++) {
      temp += params[i];
    }
    return temp;
  }

  exports.default = Ember.Helper.helper(concat);
});