define('backoffice/routes/admin/transactions/loading', ['exports', 'backoffice/mixins/admin-route-mixin'], function (exports, _adminRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_adminRouteMixin.default, {
    init: function init() {
      this._super.apply(this, arguments);
      this.title = this.get('intl').t('common.loading');
    }
  });
});