define('backoffice/routes/docs/reference', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'backoffice/mixins/admin-route-mixin'], function (exports, _authenticatedRouteMixin, _adminRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_adminRouteMixin.default, _authenticatedRouteMixin.default, {
    session: Ember.inject.service('session'),
    model: function model() {
      return {
        token: this.get('session').get('data.authenticated.token.access_token')
      };
    }
  });
});