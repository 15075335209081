define('backoffice/pods/components/support-tickets-list/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tickets: [],
    subjectFilter: '',
    classNames: ['ibox-content', 'animated', 'fadeInRight'],
    init: function init() {
      this._super();
      this.set('subjectFilter', this.get('initialSubjectFilter'));
    },

    actions: {
      pageChanged: function pageChanged(page) {
        this.get('pageChanged')(page);
      },
      filterByStatus: function filterByStatus(item) {
        this.get('filterByStatus')(item);
      },
      filterBySubject: function filterBySubject(_, event) {
        var execute = false;
        if (event) {
          var keycode = event.keyCode ? event.keyCode : event.which;
          if (keycode === 13) {
            execute = true;
          }
        } else {
          execute = true;
        }

        if (execute) {
          var subjectFilterValue = this.get('subjectFilter');
          this.get('filterBySubject')(subjectFilterValue || "");
        }
      }
    }
  });
});