define('backoffice/controllers/2fa/verification', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    actions: {
      onTokenGetsExpired: function onTokenGetsExpired() {
        this.transitionToRoute('login');
      },
      onTokenSuccessfullyVerified: function onTokenSuccessfullyVerified(data) {
        var _this = this;

        return this.get('session').authenticate('authenticator:token', data).then(function () {
          return _this.transitionToRoute('admin.transactions');
        });
      }
    }
  });
});