define('backoffice/storages/invoices-columns', ['exports', 'ember-local-storage/local/object'], function (exports, _object) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Storage = _object.default.extend();

  Storage.reopenClass({
    initialState: function initialState() {
      // console.log('Storage columns init');

      return {
        settings: [{
          uniqueKey: 'counter',
          isShown: true
        }, {
          uniqueKey: 'billingPeriod',
          isShown: true
        }, {
          uniqueKey: 'date',
          isShown: true
        }, {
          uniqueKey: 'documentTypeTitle',
          isShown: true
        }, {
          uniqueKey: 'externalId',
          isShown: true
        }, {
          uniqueKey: 'settlementCurrency',
          isShown: true
        }, {
          uniqueKey: 'totalNetTurnover',
          isShown: true
        }, {
          uniqueKey: 'totalServiceFees',
          isShown: true
        }, {
          uniqueKey: 'reserveAmount',
          isShown: true
        }, {
          uniqueKey: 'payoutAmount',
          isShown: true
        }, {
          uniqueKey: 'status',
          isShown: true
        }, {
          uniqueKey: 'flag',
          isShown: true
        }, {
          uniqueKey: 'settlementDate',
          isShown: true
        }, {
          uniqueKey: 'settlementNoteId',
          isShown: true
        }, {
          uniqueKey: 'extRefId',
          isShown: true
        }]
      };
    }
  });

  exports.default = Storage;
});