define('backoffice/pods/components/search-settlement-form-tab/component', ['exports', 'backoffice/constants/reports'], function (exports, _reports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var SETTLEMENT_APPROVED_STATUS = _reports.default.SETTLEMENT_APPROVED_STATUS,
      SETTLEMENT_APPROVED_DESCRIPTION = _reports.default.SETTLEMENT_APPROVED_DESCRIPTION,
      SETTLEMENT_PAID_STATUS = _reports.default.SETTLEMENT_PAID_STATUS,
      SETTLEMENT_PAID_DESCRIPTION = _reports.default.SETTLEMENT_PAID_DESCRIPTION,
      SETTLEMENT_SETTLED_STATUS = _reports.default.SETTLEMENT_SETTLED_STATUS,
      SETTLEMENT_SETTLED_DESCRIPTION = _reports.default.SETTLEMENT_SETTLED_DESCRIPTION,
      SETTLEMENT_REJECTED_STATUS = _reports.default.SETTLEMENT_REJECTED_STATUS,
      SETTLEMENT_REJECTED_DESCRIPTION = _reports.default.SETTLEMENT_REJECTED_DESCRIPTION,
      SETTLEMENT_RETURNED_STATUS = _reports.default.SETTLEMENT_RETURNED_STATUS,
      SETTLEMENT_RETURNED_DESCRIPTION = _reports.default.SETTLEMENT_RETURNED_DESCRIPTION;


  var statuses = [{ id: SETTLEMENT_APPROVED_STATUS, name: SETTLEMENT_APPROVED_DESCRIPTION, isSelected: false }, { id: SETTLEMENT_PAID_STATUS, name: SETTLEMENT_PAID_DESCRIPTION, isSelected: false }, { id: SETTLEMENT_SETTLED_STATUS, name: SETTLEMENT_SETTLED_DESCRIPTION, isSelected: false }, { id: SETTLEMENT_REJECTED_STATUS, name: SETTLEMENT_REJECTED_DESCRIPTION, isSelected: false }, { id: SETTLEMENT_RETURNED_STATUS, name: SETTLEMENT_RETURNED_DESCRIPTION, isSelected: false }];

  exports.default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.statuses = statuses;
    },

    model: null,
    currencies: Ember.computed('model.allCurrencies', function () {
      var model = this.get('model.allCurrencies');
      var currencies = model.map(function (item) {
        return { id: item.get('iso'), name: item.get('iso'), isSelected: false };
      });
      return currencies;
    }),
    // // Multiselect dropdown default parameters
    dropdownStatusOpen: false,
    dropdownCurrencyOpen: false,
    isGeneralTabActive: true,
    isInvoiceTabActive: false,

    createParamsList: function createParamsList(arr) {
      return arr.map(function (el) {
        return el.id;
      }).join();
    },
    addParamToList: function addParamToList(item, list) {
      var paramsList = list.addObject(item);
      return paramsList.join();
    },
    removeParamFromList: function removeParamFromList(item, list) {
      var paramsList = list.removeObject(item);
      return paramsList.join();
    },

    actions: {
      searchSettlement: function searchSettlement() {
        this.get('searchSettlement')();
      },
      resetSearchForm: function resetSearchForm() {
        this.get('resetSearchForm')();
      },
      setDateRange: function setDateRange(start, end) {
        this.get('updateSearchParam')('dateFrom', start);
        this.get('updateSearchParam')('dateTo', end);
      },
      cancelDatePicker: function cancelDatePicker() {},
      changeMultiselectFilter: function changeMultiselectFilter(filter, items) {
        this.get('updateSearchParam')(filter, this.createParamsList(items));
      },
      changeParam: function changeParam(param, value) {
        this.get('updateSearchParam')(param, value);
      },
      addTagFilter: function addTagFilter(filter, item, list) {
        this.get('updateSearchParam')(filter, this.addParamToList(item, list));
      },
      removeTagFilter: function removeTagFilter(filter, item, list) {
        this.get('updateSearchParam')(filter, this.removeParamFromList(item, list));
      },
      selectTab: function selectTab(tabName) {
        this.set('isGeneralTabActive', tabName === 'general');
        this.set('isInvoiceTabActive', tabName === 'invoice');
      }
    }
  });
});