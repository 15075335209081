define('backoffice/constants/offers', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    OFFER_MEMBERSHIP_TYPEID: 1,
    OFFER_TRIAL_TYPEID: 2,
    OFFER_ONESHOT_TYPEID: 3,
    OFFER_UPSELL_TYPEID: 5,
    OFFER_INACTIVE_STATUS: 0,
    OFFER_ACTIVE_STATUS: 1,
    PATH_TO_FLAGS: 'assets/images/flags/'
  };
});