define('backoffice/pods/components/error-page/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    session: Ember.inject.service('session'),
    code: null,
    message: null,
    isAuthenticated: Ember.computed(function () {
      return this.get('session.isAuthenticated');
    })
  });
});