define('backoffice/pods/components/table-cell-full-amount/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    intl: Ember.inject.service(),
    tagName: 'td',
    index: null,
    data: null,
    row: null,
    collapsed: null,
    init: function init() {
      this._super.apply(this, arguments);
      //this.get('intl').setLocale('en-us');
    },

    formattedSum: Ember.computed('intl.locale', 'data', function () {
      return this.get('intl').formatNumber(this.get('data'), {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
    }),
    currencySign: Ember.computed('currencyProperty', function () {
      var sign = '';
      switch (this.get('row.' + this.get('currencyProperty'))) {
        case 'EUR':
          sign = '€';
          break;

        case 'USD':
          sign = '$';
          break;

        case 'GBP':
          sign = '£';
          break;
      }
      return sign;
    }),
    click: function click() {
      if (this.get('isTruncated')) {
        this.toggleProperty('collapsed');
        if (this.get('toggleDetails')) {
          this.get('toggleDetails')({ collapsed: this.get('collapsed') });
        }
      }
    }
  });
});