define('backoffice/models/ticket', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      Model = _emberData.default.Model,
      hasMany = _emberData.default.hasMany;
  exports.default = Model.extend({
    title: attr('string'),
    description: attr('string'),
    author: attr('string'),
    status: attr('number'),
    attachments: hasMany('attachment', { async: false }),
    category: attr('string'),
    closed: attr('boolean'),
    comments: attr('number'),
    content: attr('string'),
    product: attr('string'),
    created: attr('string'),
    read: attr('boolean'),
    messages: hasMany('message', { async: false }),
    modified: attr('string'),
    textStatus: attr('string'),
    fullTitle: Ember.computed('id', 'title', function () {
      return '# ' + this.get('id') + ' - ' + this.get('title');
    })
  });
});