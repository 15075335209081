define('backoffice/pods/components/table-cell-date-time/component', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'td',
    index: null,
    data: null,
    row: null,
    collapsed: null,
    date: Ember.computed('data', function () {
      if (!this.get('data')) {
        return null;
      }

      if (this.get('data') === 'never') {
        return 'never';
      }

      var tz = (0, _moment.default)(this.get('data')).parseZone().utcOffset();
      return (0, _moment.default)(this.get('data')).utc().utcOffset(tz).format('YYYY-MM-DD HH:mm:ss Z');
    }),
    click: function click() {
      if (this.get('isTruncated')) {
        this.toggleProperty('collapsed');
        if (this.get('toggleDetails')) {
          this.get('toggleDetails')({ collapsed: this.get('collapsed') });
        }
      }
    }
  });
});