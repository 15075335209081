define('backoffice/routes/admin/no-access', ['exports', 'backoffice/mixins/admin-route-mixin', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _adminRouteMixin, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_adminRouteMixin.default, _authenticatedRouteMixin.default, {
    init: function init() {
      this._super.apply(this, arguments);
    }
  });
});