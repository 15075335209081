define('backoffice/helpers/gte', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.gte = gte;
  function gte(params) {
    return params.reduce(function (a, b) {
      return a >= b;
    });
  }

  exports.default = Ember.Helper.helper(gte);
});