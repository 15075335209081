define('backoffice/routes/admin/websites/index', ['exports', 'backoffice/mixins/admin-route-mixin', 'ember-simple-auth/mixins/authenticated-route-mixin', 'backoffice/mixins/reset-scroll-position'], function (exports, _adminRouteMixin, _authenticatedRouteMixin, _resetScrollPosition) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_adminRouteMixin.default, _authenticatedRouteMixin.default, _resetScrollPosition.default, {
    init: function init() {
      this._super.apply(this, arguments);
      this.title = this.get('intl').t('websites.title');
    },
    model: function model() {
      return this.store.query('website', {});
    },

    actions: {
      create: function create() {
        this.transitionTo('admin.websites.new');
      },
      edit: function edit(id) {
        if (!id) {
          return;
        }

        this.transitionTo('admin.websites.edit', id);
      },
      copy: function copy(id) {
        if (!id) {
          return;
        }

        this.transitionTo('admin.websites.new', { queryParams: { copiedId: id } });
      }
    }
  });
});