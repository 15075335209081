define('backoffice/controllers/admin/payouts', ['exports', 'backoffice/config/environment', 'backoffice/constants/requests', 'backoffice/constants/permissions'], function (exports, _environment, _requests, _permissions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var SUCCESS_RESPONSE_STATUS = _requests.default.SUCCESS_RESPONSE_STATUS;
  var PAYOUTS_PAY_KEY = _permissions.default.PAYOUTS_PAY_KEY,
      PAYOUTS_VIEW_KEY = _permissions.default.PAYOUTS_VIEW_KEY,
      PAYOUTS_GET_BALANCE_KEY = _permissions.default.PAYOUTS_GET_BALANCE_KEY;
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service(),
    permissionsManager: Ember.inject.service(),
    intl: Ember.inject.service(),
    queryParams: ['page', 'size'], routeHierarchy: Ember.computed('currentUrl', 'currentRouteName', 'reverse', {
      get: function (_get) {
        function get() {
          return _get.apply(this, arguments);
        }

        get.toString = function () {
          return _get.toString();
        };

        return get;
      }(function () {
        get(this, 'currentUrl');
        var currentRouteName = getWithDefault(this, 'currentRouteName', false);

        assert('[ember-crumbly] Could not find a current route', currentRouteName);

        var routeNames = currentRouteName.split('.');
        var filteredRouteNames = this._filterIndexAndLoadingRoutes(routeNames);
        var crumbs = this._lookupBreadCrumb(routeNames, filteredRouteNames);

        return get(this, 'reverse') ? crumbs.reverse() : crumbs;
      })
    }).readOnly(),
    payoutBalances: null,
    balances: Ember.computed('intl.locale', 'payoutBalances', function () {
      var _this = this;

      var payoutBalances = this.get('payoutBalances');
      var array = [];

      if (payoutBalances) {
        Object.keys(payoutBalances).forEach(function (key) {
          var formattedBalance = void 0;
          if (payoutBalances[key].currency != null) {
            console.log("payoutBalances[key]: " + JSON.stringify(payoutBalances[key]));
            formattedBalance = _this.get('intl').formatNumber(parseFloat((payoutBalances[key].balance || "0.0").replace(',', '.')).toFixed(2) || 0, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            });
          } else {
            formattedBalance = payoutBalances[key].balance;
          }

          var balanceList = [];
          if (payoutBalances[key].balanceList && payoutBalances[key].balanceList.length) {
            balanceList = payoutBalances[key].balanceList.map(function (balanceFromList) {
              var formattedAmount = _this.get('intl').formatNumber(balanceFromList.amount || 0, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              });
              return Object.assign({}, balanceFromList, { amount: formattedAmount });
            });
          }

          var currentBalance = Object.assign({}, payoutBalances[key], {
            id: key,
            balance: formattedBalance,
            balanceList: balanceList
          });
          array.push(currentBalance);
        });
        return array.sort(function (a, b) {
          return a.id.localeCompare(b.id);
        });
      } else {
        return [];
      }
    }),
    balancesDate: Ember.computed('payoutBalances', function () {
      var payoutBalances = this.get('payoutBalances');
      var dates = [];
      if (payoutBalances) {
        Object.keys(payoutBalances).forEach(function (key) {
          var dateTime = payoutBalances[key].dateTime;
          if (dateTime) {
            dates.push(dateTime);
          }
        });
      }

      return dates.length > 0 ? dates[dates.length - 1] : '';
    }),
    hasPermissionViewBalance: Ember.computed(function () {
      return this.get('permissionsManager').hasPermission([PAYOUTS_GET_BALANCE_KEY]);
    }),
    page: 0,
    size: 10,
    isCancelDialogShown: false,
    isSendDialogShown: false,
    actionWindowId: 'payout-action',
    activePayout: null,
    pollingModel: null,
    pollingHandler: null,
    uploadUrl: Ember.computed('', function () {
      return _environment.default.APP.apiHost + '/api/payout/upload?access_token=' + this.get('session').get('data.authenticated.token.access_token');
    }),
    isUploadBtnShown: Ember.computed(function () {
      return this.get('permissionsManager').hasPermission([PAYOUTS_VIEW_KEY, PAYOUTS_PAY_KEY]);
    }),
    actions: {
      pageChanged: function pageChanged(page) {
        this.replaceRoute({ queryParams: { page: page } });
        return true;
      },
      ping: function ping(url) {
        var self = this;
        Ember.$.ajax({
          url: _environment.default.APP.apiHost + '/api/ping',
          data: {},
          async: false,
          headers: {
            Authorization: 'Bearer ' + this.get('session').get('data.authenticated.token.access_token')
          },
          type: 'GET',
          contentType: 'application/x-www-form-urlencoded',
          dataType: 'json',
          complete: function complete(data) {
            if (data.status === SUCCESS_RESPONSE_STATUS) {
              window.open(url);
            } else {
              self.get('session').invalidate();
            }
          }
        });
        return false;
      }
    }
  });
});