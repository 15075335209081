define('backoffice/pods/components/offer-settings-prices-board/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    classNames: ['row', 'prices'],
    columnsAtRow: 3,
    disabled: false,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.get('currencies').forEach(function (item) {
        _this.get('newOffer.prices').forEach(function (price) {
          if (item.get('iso') === price.get('iso')) {
            item.set('price', (parseFloat(price.get('price'), 10) * 0.01).toFixed(2));
          }
        });
      });
    },

    actions: {
      setPrice: function setPrice(currency, newPrice) {
        var offerRecord = this.get('newOffer.id') ? this.get('store').peekRecord('offer', this.get('newOffer.id')) : null;
        var isPresent = false;

        if (offerRecord) {
          offerRecord.get('prices').forEach(function (price) {
            if (price.get('iso') === currency.get('iso')) {
              price.set('price', newPrice * 100);
              isPresent = true;
            }
          });

          if (!isPresent) {
            offerRecord.get('prices').pushObject(this.get('store').createRecord('price', {
              name: currency.get('name'),
              iso: currency.get('iso'),
              idOffer: this.get('newOffer.idOffer') ? this.get('newOffer.idOffer') : 0,
              idCurrency: currency.get('idCurrency'),
              price: newPrice * 100
            }));
          }
        } else {
          this.get('newOffer.prices').forEach(function (price) {
            if (price.get('iso') === currency.get('iso')) {
              price.set('price', newPrice * 100);
              isPresent = true;
            }
          });

          if (!isPresent) {
            this.get('newOffer.prices').pushObject(this.get('store').createRecord('price', {
              name: currency.get('name'),
              iso: currency.get('iso'),
              idOffer: this.get('newOffer.idOffer') ? this.get('newOffer.idOffer') : 0,
              idCurrency: currency.get('idCurrency'),
              price: newPrice * 100
            }));
          }
        }
      }
    }
  });
});