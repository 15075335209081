define('backoffice/pods/components/offer-settings/component', ['exports', 'backoffice/constants/offers'], function (exports, _offers) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var OFFER_MEMBERSHIP_TYPEID = _offers.default.OFFER_MEMBERSHIP_TYPEID,
      OFFER_TRIAL_TYPEID = _offers.default.OFFER_TRIAL_TYPEID,
      OFFER_ONESHOT_TYPEID = _offers.default.OFFER_ONESHOT_TYPEID,
      OFFER_UPSELL_TYPEID = _offers.default.OFFER_UPSELL_TYPEID;
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    isCloneAsUpsellFlag: false,
    isModalShown: false,
    isNameErrorShown: false,
    isTypeErrorShown: false,
    isRebillErrorShown: false,
    isTranslationErrorShown: false,
    isSaveBtnBlocked: false,
    isCalendarErrorShown: false,
    isCalendarCountErrorShown: false,
    isDurationValid: false,
    validatePrice: false,
    buttonText: '',
    preSelectOfferType: null,
    init: function init() {
      this._super.apply(this, arguments);
      this.set('buttonText', this.get('newOffer.type.id') ? this.get('intl').t('common.update') : this.get('intl').t('common.create'));
      if (this.get('newOffer.type')) {
        this.set('currentOfferType', this.get('newOffer.type'));
      }
    },
    checkPrices: function checkPrices(offer) {
      if (this.get('currencies.length') > 0) {
        return offer.get('prices') ? offer.get('prices.length') === this.get('currencies.length') : false;
      } else {
        return true;
      }
    },

    actions: {
      save: function save() {
        var offer = this.get('newOffer');
        var proxyType = this.get('currentOfferType');
        if (proxyType) {
          offer.set('type', proxyType);
        }

        var isFormValid = false;
        var isPricesSet = this.checkPrices(offer);

        this.set('isSaveBtnBlocked', true);

        if (offer.get('type.id') && isPricesSet) {
          switch (parseInt(offer.get('type.id'), 10)) {
            case OFFER_MEMBERSHIP_TYPEID:
              {
                if (offer.get('validations.attrs.name.isValid') && this.get('isDurationValid') && !Ember.$('p.has-error').length) {
                  isFormValid = true;
                } else {
                  this.set('isNameErrorShown', true);
                  this.set('isCalendarErrorShown', true);
                  this.set('isCalendarCountErrorShown', true);
                  this.toggleProperty('validatePrice');

                  isFormValid = false;
                }
                break;
              }
            case OFFER_TRIAL_TYPEID:
              {
                if (offer.get('validations.attrs.name.isValid') && offer.get('validations.attrs.rebill.isValid') && this.get('isDurationValid') && !Ember.$('p.has-error').length) {
                  isFormValid = true;
                } else {
                  this.set('isNameErrorShown', true);
                  this.set('isCalendarErrorShown', true);
                  this.set('isCalendarCountErrorShown', true);
                  this.set('isRebillErrorShown', true);
                  this.toggleProperty('validatePrice');

                  isFormValid = false;
                }
                break;
              }
            case OFFER_ONESHOT_TYPEID:
              {
                if (offer.get('validations.attrs.name.isValid') && !Ember.$('p.has-error').length) {
                  isFormValid = true;
                } else {
                  this.set('isNameErrorShown', true);
                  this.toggleProperty('validatePrice');

                  isFormValid = false;
                }
                break;
              }
            case OFFER_UPSELL_TYPEID:
              {
                if (offer.get('validations.attrs.name.isValid') && !Ember.$('p.has-error').length) {
                  isFormValid = true;
                } else {
                  this.set('isNameErrorShown', true);
                  this.toggleProperty('validatePrice');

                  isFormValid = false;
                }
                break;
              }
          }
        } else {
          this.set('isTypeErrorShown', true);
          this.set('isNameErrorShown', true);
          this.set('isCalendarErrorShown', true);
          this.set('isCalendarCountErrorShown', true);
          this.set('isRebillErrorShown', true);
          this.toggleProperty('validatePrice');
          isFormValid = false;
        }

        if (isFormValid) {
          if (this.get('isCloneAsUpsellFlag')) {
            offer.set('isCloneAsUpsell', true);
          }
          this.get('offerAction')(offer);
        } else {
          this.set('isSaveBtnBlocked', false);
        }
      },
      closeDialog: function closeDialog(state) {
        this.set('isModalShown', state);
      },
      setOfferType: function setOfferType(type) {
        this.set('currentOfferType', type);
      }
    }
  });
});