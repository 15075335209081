define('backoffice/helpers/average-type', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.averageType = averageType;
  function averageType(param) {
    var value = void 0,
        obj = param[0];

    if (obj.rowType === 'average') {
      if (obj.total.type === 'average' || obj.total.type === 'sumWithPersent') {
        value = obj.total.average;
      } else if (obj.total.type === 'sum') {
        value = obj.total.sum;
      } else if (obj.total.type === 'title') {
        value = obj.total.title;
      } else {
        console.info('Strange total type: [' + obj.total.type + ']');
      }
    } else {
      value = obj.value;
    }

    return value;
  }

  exports.default = Ember.Helper.helper(averageType);
});