define('backoffice/pods/components/package-settings/component', ['exports', 'backoffice/constants/offers'], function (exports, _offers) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var OFFER_MEMBERSHIP_TYPEID = _offers.default.OFFER_MEMBERSHIP_TYPEID,
      OFFER_TRIAL_TYPEID = _offers.default.OFFER_TRIAL_TYPEID,
      OFFER_ONESHOT_TYPEID = _offers.default.OFFER_ONESHOT_TYPEID,
      OFFER_UPSELL_TYPEID = _offers.default.OFFER_UPSELL_TYPEID;
  exports.default = Ember.Component.extend({
    store: Ember.inject.service('store'),
    item: null,
    model: null,
    offersList: null,
    isNameErrorShown: false,
    isOffersErrorShown: false,
    isSaveBtnBlocked: false,
    init: function init() {
      this._super.apply(this, arguments);
      this.set('formPositions', this.get('item.formPositions'));
    },

    checkedItems: Ember.computed('item.offers', 'offersList', function () {
      var _this = this;

      var list = this.get('offersList').map(function (offer) {
        var isSelected = false;

        if (_this.get('item.offers.length')) {
          _this.get('item.offers').forEach(function (itemInPackage) {
            if (offer.get('id') === itemInPackage.get('id')) {
              isSelected = true;
            }
          });
        }

        var newItem = Ember.Object.create();
        newItem.set('id', offer.get('id'));
        newItem.set('name', offer.get('name'));
        newItem.set('isSelected', isSelected);

        return newItem;
      });

      return list;
    }),
    offersTrialList: Ember.computed.filter('offersList', function (offer /*, index, array*/) {
      return parseInt(offer.get('type.id')) === OFFER_MEMBERSHIP_TYPEID || parseInt(offer.get('type.id')) === OFFER_TRIAL_TYPEID;
    }),
    offersOneShotList: Ember.computed.filter('offersList', function (offer /*, index, array*/) {
      return parseInt(offer.get('type.id')) === OFFER_ONESHOT_TYPEID;
    }),
    offersUpsellList: Ember.computed.filter('offersList', function (offer /*, index, array*/) {
      return parseInt(offer.get('type.id')) === OFFER_UPSELL_TYPEID;
    }),
    actions: {
      select: function select(item) {
        this.set('item.defaultPackageId', item);
      },
      updateOffersList: function updateOffersList(item) {
        // if user checks the checkbox
        if (item.get('isSelected')) {
          // append offer to list
          var el = this.get('offersList').findBy('id', item.get('id'));
          if (!el) {
            return;
          }
          this.get('item.offers').pushObject(el);

          // update formPositions list
          var id = this.get('item.id') + '_' + el.get('id');
          var formPosition = this.get('store').peekRecord('form-position', id) || this.get('store').createRecord('form-position', {
            id: id,
            offerId: el.get('id'),
            packageId: this.get('item.id')
          });

          formPosition.set('position', this.get('item.formPositions.length') + 1);
          this.get('item.formPositions').pushObject(formPosition);
        } else {
          // if user unchecks the checkbox
          var offerEl = this.get('item.offers').findBy('id', item.get('id')),
              _el = this.get('item.formPositions').findBy('offerId', item.get('id'));

          if (!offerEl || !_el) {
            return;
          }

          var removedOfferPosition = _el.get('position');
          this.get('item.formPositions').forEach(function (item) {
            if (item.get('position') > removedOfferPosition) {
              item.set('position', item.get('position') - 1);
            }
          });

          // remove from offers list
          this.get('item.offers').removeObject(offerEl);

          // remove from formPositions list
          this.get('item.formPositions').removeObject(_el);
        }
      },
      showErrors: function showErrors(param) {
        this.set(param, true);
      },
      save: function save(pack) {
        if (!pack.get('validations.isValid') || !this.get('item.offers.length')) {
          this.set('isNameErrorShown', true);
          this.set('isOffersErrorShown', true);
          return;
        }

        this.set('isSaveBtnBlocked', true);
        this.get('save')(pack);
      }
    }
  });
});