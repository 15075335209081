define('backoffice/pods/components/i-checkbox/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'label',
    attributeBindings: ['id', 'tabindex'],
    withoutLabel: false,
    isDisabled: null,
    actions: {
      select: function select(item) {
        if (!this.get('isDisabled')) {
          var onSelectFn = this.get('onSelect');
          if (onSelectFn && typeof onSelectFn === 'function') {
            onSelectFn(item);
          }
        }
      }
    }
  });
});