define('backoffice/helpers/date-formatter', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.dateFormatter = dateFormatter;
  function dateFormatter(params) {
    var dateString = params[0],
        dateFormat = params[1];

    if (!dateString) {
      return '';
    }

    if (!(0, _moment.default)(dateString, _moment.default.ISO_8601).isValid()) {
      return "Invalid date format";
    }

    return (0, _moment.default)(dateString).format(dateFormat);
  }

  exports.default = Ember.Helper.helper(dateFormatter);
});