define('backoffice/pods/components/kpi-summary-table/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    tagName: '',
    currentMonth: Ember.computed('merchantSummary', function () {
      if (!this.get('merchantSummary')) {
        return this.get('store').createRecord('kpi');
      } else {
        return this.get('merchantSummary').objectAt(0);
      }
    }),
    prevMonth: Ember.computed('merchantSummary', function () {
      if (!this.get('merchantSummary')) {
        return this.get('store').createRecord('kpi');
      } else {
        return this.get('merchantSummary').objectAt(1);
      }
    }),
    prevMonth2: Ember.computed('merchantSummary', function () {
      if (!this.get('merchantSummary')) {
        return this.get('store').createRecord('kpi');
      } else {
        return this.get('merchantSummary').objectAt(2);
      }
    }),
    prevMonth3: Ember.computed('merchantSummary', function () {
      if (!this.get('merchantSummary')) {
        return this.get('store').createRecord('kpi');
      } else {
        return this.get('merchantSummary').objectAt(3);
      }
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      Ember.run.scheduleOnce('afterRender', this, function () {
        _this.notifyPropertyChange('currentMonth');
      });
    },

    setColors: Ember.observer('currentMonth', function () {
      var summaryObj = void 0;

      if (!this.get('merchantSummary')) {
        summaryObj = this.get('store').createRecord('kpi');
      } else {
        summaryObj = this.get('merchantSummary').objectAt(0);
      }

      this.indicateCapturedTransactionsPercent({
        elem: Ember.$('#captured-transactions-percent'),
        value: parseFloat(summaryObj.get('successRatio')) / 100
      });

      this.indicateCapturedAmountPercent({
        elem: Ember.$('#captured-amount-percent'),
        value: parseFloat(summaryObj.get('amountProgress')) / 100
      });

      this.indicateSuccesRatio({
        elem: Ember.$('#success-ratio'),
        value: summaryObj.get('successRatio'),
        prev: this.get('currentMonth.successRatio')
      });

      this.indicateVisaCHBRatio({
        elem: Ember.$('#visaCHBRatio'),
        ratio: summaryObj.get('visaCHBRatio'),
        prevRatio: this.get('currentMonth.visaCHBRatio'),
        chb: summaryObj.get('visaCHB')
      });

      this.indicateMastercardCHBRatio({
        elem: Ember.$('#mastercardCHBRatio'),
        ratio: summaryObj.get('mcCHBRatio'),
        prevRatio: this.get('currentMonth.mcCHBRatio'),
        chb: summaryObj.get('mcCHB')
      });
    }),
    indicateCapturedTransactionsPercent: function indicateCapturedTransactionsPercent(obj) {
      if (obj.value >= 0.9 && obj.value < 1) {
        obj.elem.css('color', '#f66a77');
      } else if (obj.value < 1) {
        obj.elem.css('color', '#e14d57');
      } else {
        obj.elem.css('color', '#000000');
      }
    },
    indicateCapturedAmountPercent: function indicateCapturedAmountPercent(obj) {
      if (obj.value < 1) {
        obj.elem.css('color', '#e14d57');
      } else {
        obj.elem.css('color', '#000000');
      }
    },
    indicateVisaCHBRatio: function indicateVisaCHBRatio(obj) {
      if (obj.ratio >= 0.007 && obj.chb >= 70) {
        obj.elem.css('color', '#f66a77');
      } else if (obj.ratio >= 0.01 && obj.chb >= 100) {
        obj.elem.css('color', '#e14d57');
      } else if (obj.ratio < obj.prevRatio) {
        obj.elem.css('color', '#71b37c');
      } else {
        obj.elem.css('color', '#000000');
      }
    },
    indicateMastercardCHBRatio: function indicateMastercardCHBRatio(obj) {
      if (obj.ratio < obj.prevRatio) {
        obj.elem.css('color', '#71b37c');
      } else if (obj.ratio >= 0.0105 && obj.chb >= 70) {
        obj.elem.css('color', '#b66a77');
      } else if (obj.ratio >= 0.015 && obj.chb >= 100) {
        obj.elem.css('color', '#e14d57');
      } else {
        obj.elem.css('color', '#000000');
      }
    },
    indicateSuccesRatio: function indicateSuccesRatio(obj) {
      if (obj.val < obj.prev) {
        obj.elem.css('color', '#e14d57');
      } else {
        obj.elem.css('color', '#000000');
      }
    }
  });
});