define('backoffice/models/customer-ip-constraint', ['exports', 'ember-data', 'ember-data/attr'], function (exports, _emberData, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    ip: (0, _attr.default)('string'),
    context: (0, _attr.default)('string'),
    creationDate: (0, _attr.default)('string')
  });
});