define('backoffice/pods/components/table-row-offers/component', ['exports', 'backoffice/pods/components/table-row/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    formPositions: null,
    checkedItem: Ember.computed('checkedItems', function () {
      return this.get('checkedItems') && this.get('checkedItems').findBy('id', this.get('data.id'));
    }),
    actions: {
      updateList: function updateList(item) {
        this.get('updateList')(item);
      },
      select: function select(item) {
        this.get('onSelect')(item);
      }
    }
  });
});