define('backoffice/pods/components/offer-settings-price-input/component', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    price: [(0, _emberCpValidations.validator)('number', {
      allowString: true,
      messageKey: "offers.validations.price.number"
    }), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      gte: 1,
      messageKey: "offers.validations.price.value"
    })]
  });

  exports.default = Ember.Component.extend(Validations, {
    classNames: ['col-sm-4', 'col-xs-4', 'col-md-4', 'col-lg-4'],
    price: null,
    isPriceErrorShown: false,
    disabled: false,
    init: function init() {
      this._super.apply(this, arguments);
      this.set('price', this.get('currency.price'));
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.set('currency.price', null);
    },

    validateObserver: Ember.observer('validatePrice', function () {
      if (!this.get('validations.attrs.price.isValid')) {
        this.set('isPriceErrorShown', true);
      }
    }),
    format: function format(value) {
      var formatNumber = (+value.replace(',', '.')).toFixed(2);

      if (isNaN(formatNumber)) {
        formatNumber = value;
      }
      return formatNumber;
    },

    actions: {
      setPrice: function setPrice(param, currency, newPrice) {
        var formattedPrice = this.format(newPrice);
        this.set(param, true);
        this.set('price', formattedPrice);
        this.get('getPrice')(currency, formattedPrice);
      }
    }
  });
});