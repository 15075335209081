define('backoffice/pods/components/offer-settings-view/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    isCloneAsUpsellFlag: false,
    isDurationValid: false,
    validatePrice: false,
    preSelectOfferType: null,
    disabled: true,
    init: function init() {
      this._super.apply(this, arguments);
      if (this.get('newOffer.type')) {
        this.set('currentOfferType', this.get('newOffer.type'));
      }
    },

    actions: {
      setOfferType: function setOfferType(type) {
        this.set('currentOfferType', type);
      }
    }
  });
});