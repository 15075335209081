define('backoffice/pods/components/locale-switcher/component', ['exports', 'backoffice/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var LOCALES = [{ locale: "en-us", name: "EN" }, { locale: "es-es", name: "ES" }, { locale: "pt-br", name: "PT" }];

  exports.default = Ember.Component.extend({
    intl: Ember.inject.service('intl'),
    isEnabled: _environment.default.APP.enableTranslations,
    localesList: LOCALES,
    activeLocale: Ember.computed('activeLocale', function () {
      var settingsStorage = this.get('settingsStorage');
      return settingsStorage.get('user-locale');
    }),
    actions: {
      changeLocale: function changeLocale(locale) {
        var settingsStorage = this.get('settingsStorage');
        this.get('intl').setLocale(locale);
        this.set('activeLocale', locale);
        settingsStorage.set('user-locale', locale);
      }
    }
  });
});