define('backoffice/pods/components/merchant-performance/component', ['exports', 'backoffice/mixins/errors-handler', 'backoffice/constants/dashboard'], function (exports, _errorsHandler, _dashboard) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var MERCHANT_PERFORMANCE_TABLE_CONFIG = _dashboard.default.MERCHANT_PERFORMANCE_TABLE_CONFIG,
      PERIOD_CHANGE_TEXTS = _dashboard.default.PERIOD_CHANGE_TEXTS;


  var rowSettings = {
    component: 'table-row',
    shareModel: false
  };

  exports.default = Ember.Component.extend(_errorsHandler.default, {
    init: function init() {
      this._super.apply(this, arguments);

      this.rowSettings = rowSettings;
    },

    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    dashboardService: Ember.inject.service('dashboard'),
    clientSettings: Ember.inject.service(),
    lastUpdate: Ember.computed('model.lastUpdate', 'dataInProgress', function () {
      var result = this.get('model.lastUpdate');
      var dataInProgress = this.get('dataInProgress');
      return !dataInProgress && result ? result.lastUpdate : null;
    }),
    timeZone: Ember.computed('data', function () {
      var data = this.get('data');
      var firstObject = data.objectAt(0);
      return firstObject && firstObject.get('timeZone') ? firstObject.get('timeZone').slice(0, -3) : null;
    }),
    dataInProgress: Ember.computed('data', function () {
      var data = this.get('data');
      return data.meta && data.meta.status === "processing";
    }),
    columnsSettings: Ember.computed('currency', function () {
      return MERCHANT_PERFORMANCE_TABLE_CONFIG(this.get('currency') || "EUR");
    }),
    periods: PERIOD_CHANGE_TEXTS,
    model: null,
    companies: Ember.computed('model.processingCompanies', function () {
      var result = [{ id: null, name: "ALL-EUR" }];
      var model = this.get('model.processingCompanies');
      model.map(function (item) {
        result.push({
          id: item.id,
          name: 'local-' + item.get('currency.iso')
        });
      });
      return result;
    }),
    selectedCompany: Ember.computed('companyId', function () {
      var _this = this;

      return this.get('companies').find(function (item) {
        return item.id === _this.get('companyId');
      });
    }),
    formattedData: Ember.computed('data', function () {
      var data = this.get('data'),
          formatPercent = this.get('dashboardService').formatPercent,
          formatDate = this.get('dashboardService').formatDate,
          round = this.get('dashboardService').round;

      var formattedData = [];

      for (var i = 0; i < 3; i++) {
        var row = data.objectAt(i);

        formattedData.push(Ember.Object.extend({}).create({
          'period': formatDate(row.get('period'), this.get('period')),
          'successfulTransactions': this.get('intl').formatNumber(row.get('successfulTransactions')),
          'successfulTrxAmount': row.get('successfulTrxAmount'),
          'successRatio': formatPercent(round(parseFloat(row.get('successRatio')) * 100)),
          'visaChargebackRatio': formatPercent(round(parseFloat(row.get('visaChargebackRatio')) * 100)),
          'mcChargebackRatio': formatPercent(round(parseFloat(row.get('mcChargebackRatio')) * 100))
        }));
      }

      return formattedData;
    }),

    selectedPeriod: Ember.computed('period', function () {
      switch (this.get('period')) {
        case 'day':
          {
            return '14 Days';
          }
        case 'week':
          {
            return '14 Weeks';
          }
        case 'month':
          {
            return '14 Months';
          }
        default:
          {
            return '14 Days';
          }
      }
    }),
    actions: {
      onPeriodSelect: function onPeriodSelect(period) {
        this.set('period', this._changePeriod(period));
        this.get('filterChange')();
      },
      onCompanySelect: function onCompanySelect(company) {
        this.set('companyId', company.id);
        this.get('filterChange')();
      }
    },
    _changePeriod: function _changePeriod(period) {
      switch (period) {
        case '14 Days':
          {
            return 'day';
          }
        case '14 Weeks':
          {
            return 'week';
          }
        case '14 Months':
          {
            return 'month';
          }
        default:
          {
            return 'day';
          }
      }
    }
  });
});