define('backoffice/models/offer-translation', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    idOffer: (0, _emberCpValidations.validator)('presence', true),
    langcode: (0, _emberCpValidations.validator)('presence', true),
    translation: [(0, _emberCpValidations.validator)('length', {
      min: 6,
      max: 30,
      messageKey: 'offers.validations.translationText.length'
    })]
  });

  exports.default = _emberData.default.Model.extend(Validations, {
    idOffer: _emberData.default.attr('number'),
    langcode: _emberData.default.attr('string'),
    translation: _emberData.default.attr('string')
  });
});