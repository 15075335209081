define('backoffice/storages/mbrs-viewer-columns', ['exports', 'ember-local-storage/local/object'], function (exports, _object) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Storage = _object.default.extend();

  Storage.reopenClass({
    initialState: function initialState() {
      // console.log('Storage columns init');

      return {
        settings: [{
          uniqueKey: 'counter',
          isShown: true
        }, {
          uniqueKey: 'id',
          isShown: true
        }, {
          uniqueKey: 'status',
          isShown: true
        }, {
          uniqueKey: 'userName',
          isShown: true
        }, {
          uniqueKey: 'email',
          isShown: true
        }, {
          uniqueKey: 'customerWebsiteId',
          isShown: true
        }, {
          uniqueKey: 'offerId',
          isShown: true
        }, {
          uniqueKey: 'subscriptionDate',
          isShown: true
        }, {
          uniqueKey: 'unsubscribeDate',
          isShown: true
        }, {
          uniqueKey: 'unsubscribeReason',
          isShown: true
        }, {
          uniqueKey: 'memberFor',
          isShown: true
        }, {
          uniqueKey: 'action',
          isShown: true
        }, {
          uniqueKey: 'nextRebill',
          isShown: true
        }, {
          uniqueKey: 'extendMembership',
          isShown: true
        }]
      };
    }
  });

  exports.default = Storage;
});