define('backoffice/pods/components/date-range-picker/component', ['exports', 'moment', 'ember-intl'], function (exports, _moment, _emberIntl) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.RANGE_KEY_2_YEARS = exports.RANGE_KEY_PREVIOUS_YEAR = exports.RANGE_KEY_CURRENT_YEAR = exports.RANGE_KEY_PREVIOUS_QUARTER = exports.RANGE_KEY_CURRENT_QUARTER = exports.RANGE_KEY_PREVIOUS_MONTH = exports.RANGE_KEY_CURRENT_MONTH = exports.RANGE_KEY_LAST_30_DAYS = exports.RANGE_KEY_LAST_7_DAYS = exports.RANGE_KEY_YESTERDAY = exports.RANGE_KEY_TODAY = undefined;

  var _RANGE_MAPPER;

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  var RANGE_KEY_TODAY = exports.RANGE_KEY_TODAY = 'today';
  var RANGE_KEY_YESTERDAY = exports.RANGE_KEY_YESTERDAY = 'yesterday';
  var RANGE_KEY_LAST_7_DAYS = exports.RANGE_KEY_LAST_7_DAYS = 'last7days';
  var RANGE_KEY_LAST_30_DAYS = exports.RANGE_KEY_LAST_30_DAYS = 'last30days';
  var RANGE_KEY_CURRENT_MONTH = exports.RANGE_KEY_CURRENT_MONTH = 'currentMonth';
  var RANGE_KEY_PREVIOUS_MONTH = exports.RANGE_KEY_PREVIOUS_MONTH = 'previousMonth';
  var RANGE_KEY_CURRENT_QUARTER = exports.RANGE_KEY_CURRENT_QUARTER = 'currentQuarter';
  var RANGE_KEY_PREVIOUS_QUARTER = exports.RANGE_KEY_PREVIOUS_QUARTER = 'previousQuarter';
  var RANGE_KEY_CURRENT_YEAR = exports.RANGE_KEY_CURRENT_YEAR = 'currentYear';
  var RANGE_KEY_PREVIOUS_YEAR = exports.RANGE_KEY_PREVIOUS_YEAR = 'previousYear';
  var RANGE_KEY_2_YEARS = exports.RANGE_KEY_2_YEARS = 'twoYearsAgo';

  var RANGE_MAPPER = (_RANGE_MAPPER = {}, _defineProperty(_RANGE_MAPPER, RANGE_KEY_TODAY, [(0, _moment.default)().startOf('day'), (0, _moment.default)()]), _defineProperty(_RANGE_MAPPER, RANGE_KEY_YESTERDAY, [(0, _moment.default)().subtract(1, 'days').startOf('day'), (0, _moment.default)().subtract(1, 'days').endOf('day')]), _defineProperty(_RANGE_MAPPER, RANGE_KEY_LAST_7_DAYS, [(0, _moment.default)().subtract(6, 'days').startOf('day'), (0, _moment.default)().endOf('day')]), _defineProperty(_RANGE_MAPPER, RANGE_KEY_LAST_30_DAYS, [(0, _moment.default)().subtract(29, 'days').startOf('day'), (0, _moment.default)().endOf('day')]), _defineProperty(_RANGE_MAPPER, RANGE_KEY_CURRENT_MONTH, [(0, _moment.default)().startOf('month').startOf('day'), (0, _moment.default)().endOf("day")]), _defineProperty(_RANGE_MAPPER, RANGE_KEY_PREVIOUS_MONTH, [(0, _moment.default)().subtract(1, 'month').startOf('month').startOf('day'), (0, _moment.default)().subtract(1, 'month').endOf('month').endOf('day')]), _defineProperty(_RANGE_MAPPER, RANGE_KEY_CURRENT_QUARTER, [(0, _moment.default)().quarter((0, _moment.default)().quarter()).startOf('quarter'), (0, _moment.default)().endOf('day')]), _defineProperty(_RANGE_MAPPER, RANGE_KEY_PREVIOUS_QUARTER, [(0, _moment.default)().subtract(1, 'Q').startOf('quarter').startOf('day'), (0, _moment.default)().subtract(1, 'Q').endOf('quarter').startOf('day')]), _defineProperty(_RANGE_MAPPER, RANGE_KEY_CURRENT_YEAR, [(0, _moment.default)().startOf('year'), (0, _moment.default)().endOf("day")]), _defineProperty(_RANGE_MAPPER, RANGE_KEY_PREVIOUS_YEAR, [(0, _moment.default)().year((0, _moment.default)().year() - 1).startOf('year'), (0, _moment.default)().year((0, _moment.default)().year() - 1).endOf('year')]), _defineProperty(_RANGE_MAPPER, RANGE_KEY_2_YEARS, [(0, _moment.default)().subtract(2, 'year').startOf('year'), (0, _moment.default)().subtract(2, 'year').endOf('year')]), _RANGE_MAPPER);

  exports.default = Ember.Component.extend({
    intl: Ember.inject.service(),
    buttonClasses: ['btn'],
    ranges: Ember.computed('intl.primaryLocale', function () {
      var _ref;

      return _ref = {}, _defineProperty(_ref, this.get('intl').t('datepicker.ranges.' + RANGE_KEY_TODAY), RANGE_MAPPER[RANGE_KEY_TODAY]), _defineProperty(_ref, this.get('intl').t('datepicker.ranges.' + RANGE_KEY_YESTERDAY), RANGE_MAPPER[RANGE_KEY_YESTERDAY]), _defineProperty(_ref, this.get('intl').t('datepicker.ranges.' + RANGE_KEY_LAST_7_DAYS), RANGE_MAPPER[RANGE_KEY_LAST_7_DAYS]), _defineProperty(_ref, this.get('intl').t('datepicker.ranges.' + RANGE_KEY_LAST_30_DAYS), RANGE_MAPPER[RANGE_KEY_LAST_30_DAYS]), _defineProperty(_ref, this.get('intl').t('datepicker.ranges.' + RANGE_KEY_CURRENT_MONTH), RANGE_MAPPER[RANGE_KEY_CURRENT_MONTH]), _defineProperty(_ref, this.get('intl').t('datepicker.ranges.' + RANGE_KEY_PREVIOUS_MONTH), RANGE_MAPPER[RANGE_KEY_PREVIOUS_MONTH]), _ref;
    }),
    classNames: ['form-group'],
    styleLable: '',
    attributeBindings: ['start', 'end', 'serverFormat'],
    start: undefined,
    end: undefined,
    minDate: undefined,
    maxDate: (0, _moment.default)().endOf('day'),
    timePicker: false,
    timePicker24Hour: false,
    timePickerSeconds: false,
    timePickerIncrement: undefined,
    showWeekNumbers: false,
    showDropdowns: false,
    linkedCalendars: false,
    datelimit: false,
    parentEl: 'body',
    format: 'MMM D, YYYY',
    serverFormat: 'YYYY-MM-DD',
    rangeText: Ember.computed('start', 'end', function () {
      var format = this.get('format');
      var serverFormat = this.get('serverFormat');
      var start = this.get('start');
      var end = this.get('end');
      if (!Ember.isEmpty(start) && !Ember.isEmpty(end)) {
        return (0, _moment.default)(start, serverFormat).format(format) + this.get('separator') + (0, _moment.default)(end, serverFormat).format(format);
      }
      return '';
    }),
    opens: null,
    drops: null,
    separator: ' - ',
    singleDatePicker: false,
    placeholder: null,
    applyClass: null,
    cancelClass: null,
    rangesParam: null,
    getRanges: function getRanges() {
      var _this = this;

      if (!this.get('rangesParam')) {
        return this.get('ranges');
      } else {
        var ranges = this.get('rangesParam').split(','),
            result = {};
        ranges.forEach(function (range) {
          var value = RANGE_MAPPER[range];
          if (value !== undefined) {
            result[_this.get('intl').t('datepicker.ranges.' + range)] = value;
          }
        });
        return result;
      }
    },

    daysOfWeek: Ember.computed('intl.primaryLocale', function () {
      return this.get('intl').t('datepicker.daysOfWeek').split(',');
    }), //moment.weekdaysMin(),
    monthNames: Ember.computed('intl.primaryLocale', function () {
      return this.get('intl').t('datepicker.monthNames').split(',');
    }), //moment.monthShorts()
    removeDropdownOnDestroy: false,
    cancelLabel: (0, _emberIntl.t)('datepicker.cancelLabel'),
    applyLabel: (0, _emberIntl.t)('datepicker.applyLabel'),
    customRangeLabel: (0, _emberIntl.t)('datepicker.customRangeLabel'),
    fromLabel: (0, _emberIntl.t)('datepicker.fromLabel'),
    toLabel: (0, _emberIntl.t)('datepicker.toLabel'),
    hideAction: null,
    applyAction: null,
    cancelAction: null,
    autoUpdateInput: true,
    autoApply: false,
    alwaysShowCalendars: false,
    firstDay: (0, _emberIntl.t)('datepicker.firstDay'),
    comments: null,

    // Init the dropdown when the component is added to the DOM
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.setupPicker();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);
      this.setupPicker();
    },
    getOptions: function getOptions() {
      var momentStartDate = this.get('start') ? (0, _moment.default)(this.get('start')) : (0, _moment.default)().subtract(1, 'hours');
      var momentEndDate = this.get('end') ? (0, _moment.default)(this.get('end')) : (0, _moment.default)();
      var startDate = momentStartDate.isValid() ? momentStartDate : undefined;
      var endDate = momentEndDate.isValid() ? momentEndDate : undefined;
      var momentMinDate = (0, _moment.default)(this.get('minDate'), this.get('serverFormat'));
      var momentMaxDate = (0, _moment.default)(this.get('maxDate'), this.get('serverFormat'));
      var minDate = momentMinDate.isValid() ? momentMinDate : undefined;
      var maxDate = momentMaxDate.isValid() ? momentMaxDate : undefined;

      var options = {
        alwaysShowCalendars: this.get('alwaysShowCalendars'),
        autoUpdateInput: this.get('autoUpdateInput'),
        autoApply: this.get('autoApply'),
        locale: {
          applyLabel: this.get('applyLabel'),
          cancelLabel: this.get('cancelLabel'),
          customRangeLabel: this.get('customRangeLabel'),
          fromLabel: this.get('fromLabel'),
          toLabel: this.get('toLabel'),
          format: this.get('format'),
          firstDay: this.get('firstDay'),
          daysOfWeek: this.get('daysOfWeek'),
          monthNames: this.get('monthNames')
        },
        startDate: startDate,
        endDate: endDate,
        minDate: minDate,
        maxDate: maxDate,
        timePicker: this.get('timePicker'),
        buttonClasses: this.get('buttonClasses'),
        applyClass: this.get('applyClass'),
        cancelClass: this.get('cancelClass'),
        separator: this.get('separator'),
        singleDatePicker: this.get('singleDatePicker'),
        drops: this.get('drops'),
        opens: this.get('opens'),
        timePicker24Hour: this.get('timePicker24Hour'),
        timePickerSeconds: this.get('timePickerSeconds'),
        timePickerIncrement: this.get('timePickerIncrement'),
        showWeekNumbers: this.get('showWeekNumbers'),
        showDropdowns: this.get('showDropdowns'),
        linkedCalendars: this.get('linkedCalendars'),
        dateLimit: this.get('dateLimit') && JSON.parse(this.get('dateLimit')),
        parentEl: this.get('parentEl')
      };

      if (!this.get('singleDatePicker')) {
        options.ranges = this.getRanges();
      }

      return options;
    },
    setupPicker: function setupPicker() {
      Ember.run.cancel(this._setupTimer);
      this._setupTimer = Ember.run.scheduleOnce('afterRender', this, this._setupPicker);
    },
    _setupPicker: function _setupPicker() {
      this.$('.daterangepicker-input').daterangepicker(this.getOptions());
      if (this.get('comments')) {
        var comment = '<div class="daterangepicker-comments"><span>' + this.get('comments') + '</span></div>';
        Ember.$('.daterangepicker').append(Ember.$(comment));
      }
      this.attachPickerEvents();
    },
    attachPickerEvents: function attachPickerEvents() {
      var _this2 = this;

      this.$('.daterangepicker-input').on('apply.daterangepicker', function (ev, picker) {
        var start = picker.startDate.format(_this2.get('serverFormat'));
        var end = picker.endDate.format(_this2.get('serverFormat'));

        var applyAction = _this2.get('applyAction');

        if (applyAction) {
          (false && !(typeof applyAction === 'function') && Ember.assert('applyAction for date-range-picker must be a function', typeof applyAction === 'function'));

          applyAction(start, end);
        } else {
          _this2.setProperties({
            start: start, end: end
          });
        }
      });

      this.$('.daterangepicker-input').on('hide.daterangepicker', function (ev, picker) {
        var start = picker.startDate.format(_this2.get('serverFormat'));
        var end = picker.endDate.format(_this2.get('serverFormat'));
        var hideAction = _this2.get('hideAction');

        if (hideAction) {
          (false && !(typeof hideAction === 'function') && Ember.assert('hideAction for date-range-picker must be a function', typeof hideAction === 'function'));

          hideAction(start, end);
        } else {
          _this2.setProperties({
            start: start, end: end
          });
        }
      });

      this.$('.daterangepicker-input').on('cancel.daterangepicker', function () {
        var cancelAction = _this2.get('cancelAction');

        if (cancelAction) {
          (false && !(typeof cancelAction === 'function') && Ember.assert('cancelAction for date-range-picker must be a function', typeof cancelAction === 'function'));

          cancelAction();
        } else {
          _this2.setProperties({
            start: _this2.get('start'),
            end: _this2.get('end')
          });
        }
      });
    },


    // Remove the hidden dropdown when this component is destroyed
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      Ember.run.cancel(this._setupTimer);

      if (this.get('removeDropdownOnDestroy')) {
        Ember.$('.daterangepicker').remove();
      }
    }
  });
});