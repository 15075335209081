define('backoffice/pods/components/dashboard-country-chart/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    dashboardService: Ember.inject.service('dashboard'),
    intl: Ember.inject.service(),
    ptdHitsData: Ember.computed('data', function () {
      return this._getData('hitsCurrent', this.get('data'));
    }),
    p1ptdHitsData: Ember.computed('data', function () {
      return this._getData('hitsPrev', this.get('data'));
    }),
    successPtdData: Ember.computed('data', function () {
      var _this = this;

      return this._getData('hitsCurrentRatio', this.get('data')).map(function (value) {
        return _this.get('dashboardService').round(parseFloat(value) * 100);
      });
    }),
    successPtd1Data: Ember.computed('data', function () {
      var _this2 = this;

      return this._getData('hitsPrevRatio', this.get('data')).map(function (value) {
        return _this2.get('dashboardService').round(parseFloat(value) * 100);
      });
    }),
    categories: Ember.computed('data', function () {
      if (this.get('data')) {
        return this.get('data').map(function (item) {
          return item.get('nameCountry');
        });
      } else {
        return [];
      }
    }),
    _getData: function _getData(dataType) {
      var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];

      var filtered = data.filter(function (item) {
        return item.get('nameCountry') !== 'Total';
      });
      return filtered.map(function (item) {
        var val = item.get(dataType);
        return val === 0 ? 0 : val;
      });
    },

    chartOptions: Ember.computed('data', function () {
      return {
        chart: {
          type: 'column',
          height: 300
        },
        legend: {
          align: 'right',
          verticalAlign: 'top',
          title: {
            style: {
              fontWeight: 'normal'
            }
          }
        },
        title: {
          text: null
        },
        loading: {
          labelStyle: {
            color: '#000000',
            fontWeight: 'bold'
          }
        },
        xAxis: {
          categories: this.get('categories')
        },
        yAxis: [{
          title: null,
          labels: {
            enabled: false
          }
        }, {
          title: null,
          labels: {
            enabled: false
          }
        }]
      };
    }),
    series: Ember.computed('data', function () {
      var that = this;
      return [{
        name: 'PTD Hits',
        data: this.get('ptdHitsData'),
        color: '#71b37c',
        yAxis: 0,
        dataLabels: {
          enabled: false,
          allowOverlap: true,
          align: 'center',
          color: '#000000',
          style: {
            fontSize: '11px',
            fontWeight: 'bold',
            boxShadow: '0px 0px 15px 10px rgba(255,255,255,1)'
          }
        },
        tooltip: {
          headerFormat: '<span style="font-size: 12px; font-weight: bold;">{series.name}.</span>',
          pointFormatter: function pointFormatter() {
            return '<br>' + this.category + ': ' + that.get('intl').formatNumber(this.y);
          }
        }
      }, {
        name: 'P-1 PTD Hits',
        data: this.get('p1ptdHitsData'),
        color: '#ec9230',
        yAxis: 0,
        dataLabels: {
          enabled: false,
          allowOverlap: true,
          align: 'center',
          color: '#000000',
          style: {
            fontSize: '11px',
            fontWeight: 'bold',
            boxShadow: '0px 0px 15px 10px rgba(255,255,255,1)'
          }
        },
        tooltip: {
          headerFormat: '<span style="font-size: 12px; font-weight: bold;">{series.name}.</span>',
          pointFormatter: function pointFormatter() {
            return '<br>' + this.category + ': ' + that.get('intl').formatNumber(this.y);
          }
        }
      }, {
        name: 'Success % PTD',
        type: 'spline',
        data: this.get('successPtdData'),
        color: '#c5f3d2',
        yAxis: 1,
        dataLabels: {
          enabled: true,
          allowOverlap: true,
          align: 'center',
          color: '#000000',
          style: {
            fontSize: '11px',
            fontWeight: 'bold',
            boxShadow: '0px 0px 15px 10px rgba(255,255,255,1)'
          },
          formatter: function formatter() {
            return this.y === 0 ? '' : that.get('dashboardService').round(this.y) + '%';
          }
        },
        tooltip: {
          headerFormat: '<span style="font-size: 12px; font-weight: bold;">{series.name}.</span>',
          pointFormatter: function pointFormatter() {
            return '<br>' + this.category + ': ' + that.get('intl').formatNumber(this.y) + '%';
          }
        }
      }, {
        name: 'Success % PTD-1',
        type: 'spline',
        data: this.get('successPtd1Data'),
        color: '#ffcf68',
        dashStyle: 'Dot',
        yAxis: 1,
        dataLabels: {
          enabled: false,
          allowOverlap: true,
          align: 'center',
          color: '#000000',
          style: {
            fontSize: '11px',
            fontWeight: 'bold',
            boxShadow: '0px 0px 15px 10px rgba(255,255,255,1)'
          },
          formatter: function formatter() {
            return this.y === 0 ? '' : that.get('dashboardService').round(this.y) + '%';
          }
        },
        tooltip: {
          headerFormat: '<span style="font-size: 12px; font-weight: bold;">{series.name}.</span>',
          pointFormatter: function pointFormatter() {
            return '<br>' + this.category + ': ' + that.get('intl').formatNumber(this.y) + '%';
          }
        }
      }];
    })
  });
});