define('backoffice/models/forecast', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    date: (0, _attr.default)('string'),
    entityId: (0, _attr.default)('string'),
    entityName: (0, _attr.default)('string'),
    settlementCurrency: (0, _attr.default)('string'),
    payoutCurrency: (0, _attr.default)('string'),
    grossAmount: (0, _attr.default)('number'),
    chargebacksAmount: (0, _attr.default)('number'),
    refundsAmount: (0, _attr.default)('number'),
    sentPayoutsAmount: (0, _attr.default)('number'),
    netAmount: (0, _attr.default)('number'),
    processingFeesAmount: (0, _attr.default)('number'),
    chbFeesAmount: (0, _attr.default)('number'),
    refundFeesAmount: (0, _attr.default)('number'),
    payoutsFeesAmount: (0, _attr.default)('number'),
    reserves: (0, _attr.default)('number'),
    reservesRls: (0, _attr.default)('number'),
    estimatedTotalAmount: (0, _attr.default)('number')
  });
});