define('backoffice/pods/components/dashboard-trx-proc-chart/component', ['exports', 'numeral', 'moment'], function (exports, _numeral, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    dashboardService: Ember.inject.service('dashboard'),
    successfulSalesData: Ember.computed('data', function () {
      return this._getData('successfulSales', this.get('data'));
    }),
    successfulRebillsData: Ember.computed('data', function () {
      var _this = this;

      return this._getData('successfulRebills', this.get('data')).map(function (value) {
        return _this.get('dashboardService').round(value);
      });
    }),
    categories: Ember.computed('data', function () {
      if (this.get('data')) {
        return this.get('data').map(function (item) {
          var period = item.get('period');
          var formattedPeriod = typeof period === 'string' ? period.replace(/-/g, "/") : period;
          return new Date(formattedPeriod);
        });
      } else {
        return [];
      }
    }),
    _getData: function _getData(dataType) {
      var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];

      var filtered = data.filter(function (item) {
        return item.get('intervalName') !== 'Total';
      });
      return filtered.map(function (item) {
        var val = item.get(dataType);
        return val === 0 ? 0 : val;
      });
    },

    chartOptions: Ember.computed('data', function () {
      var that = this;
      return {
        chart: {
          type: 'column',
          height: 300
        },
        legend: {
          reversed: true,
          align: 'right',
          verticalAlign: 'top',
          title: {
            style: {
              fontWeight: 'normal'
            }
          }
        },
        title: {
          text: null
        },
        loading: {
          labelStyle: {
            color: '#000000',
            fontWeight: 'bold'
          }
        },
        tooltip: {
          formatter: function formatter() {
            if (this.y === 0) {
              return false;
            } else {
              var value = (0, _numeral.default)(this.y).format('0,0'),
                  date = (0, _moment.default)(this.x).format("MMM'YY");

              return '<span style="font-size: 12px; font-weight: bold;">' + this.series.name + '.</span><br>' + date + ' : ' + value;
            }
          }
        },
        xAxis: {
          labels: {
            type: 'datetime',
            formatter: function formatter() {
              return this.x === 0 ? '' : that.get('dashboardService').formatDate(this.value, that.get('period'));
            }
          },
          categories: this.get('categories')
        },
        yAxis: {
          title: null,
          labels: {
            enabled: false
          }
        },
        plotOptions: {
          column: {
            stacking: 'normal',
            dataLabels: {
              enabled: true
            }
          }
        }
      };
    }),
    series: Ember.computed('data', function () {
      var that = this;
      return [{
        name: 'Recurrings',
        data: this.get('successfulRebillsData'),
        color: '#fa8d00',
        dataLabels: {
          enabled: true,
          allowOverlap: true,
          align: 'center',
          color: '#000000',
          style: {
            fontSize: '11px',
            fontWeight: 'bold',
            boxShadow: '0px 0px 15px 10px rgba(255,255,255,1)'
          },
          formatter: function formatter() {
            return this.y === 0 ? '' : '' + that.get('dashboardService').round(this.y);
          }
        }
      }, {
        name: 'Sales',
        data: this.get('successfulSalesData'),
        color: '#5ab676',
        dataLabels: {
          enabled: true,
          allowOverlap: true,
          align: 'center',
          color: '#000000',
          style: {
            fontSize: '11px',
            fontWeight: 'bold',
            boxShadow: '0px 0px 15px 10px rgba(255,255,255,1)'
          },
          formatter: function formatter() {
            return this.y === 0 ? '' : '' + that.get('dashboardService').round(this.y);
          }
        }
      }];
    })
  });
});