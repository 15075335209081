define('backoffice/mixins/errors-handler', ['exports', 'backoffice/constants/requests'], function (exports, _requests) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var CLOSE_NOTIFY_DELAY = _requests.default.CLOSE_NOTIFY_DELAY;
  exports.default = Ember.Mixin.create({
    notify: Ember.inject.service('notify'),
    handleErrors: function handleErrors(error) {
      var notify = this.get('notify');
      var errorMsg = null;
      if (error.isAdapterError) {
        if (!error.errors.msg && error.errors.length > 0 && error.errors[0].detail) {
          errorMsg = error.errors[0].detail;
        } else if (error.errors.msg) {
          errorMsg = error.errors.msg;
        } else if (error.message) {
          errorMsg = error.message;
        }
      }
      notify.error(errorMsg, {
        closeAfter: CLOSE_NOTIFY_DELAY
      });
    }
  });
});