define('backoffice/pods/components/dashboard-wrapper/component', ['exports', 'backoffice/mixins/errors-handler', 'backoffice/constants/dashboard'], function (exports, _errorsHandler, _dashboard) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var BASE_PERIOD = _dashboard.default.BASE_PERIOD,
      BASE_TOP_COUNTRY_COUNT = _dashboard.default.BASE_TOP_COUNTRY_COUNT;
  exports.default = Ember.Component.extend(_errorsHandler.default, {
    store: Ember.inject.service(),
    tagName: '',
    isLoading: false,
    isCountriesLoading: false,
    topCountries: BASE_TOP_COUNTRY_COUNT,
    period: BASE_PERIOD,
    companyId: null,
    actions: {
      filterChange: function filterChange() {
        var _this = this;

        var store = this.get('store'),
            period = this.get('period'),
            company = this.get('companyId'),
            countries = this.get('topCountries');

        var companyObj = this.get('model.processingCompanies').find(function (item) {
          return item.id === _this.get('companyId');
        });
        this.set('currency', companyObj && companyObj.currency && companyObj.currency.iso || "EUR");

        this.set('isLoading', true);
        Ember.RSVP.hash({
          merchantPerformances: store.query('merchant-performance', { period: period, processingCompany: company }),
          countryPerformances: store.query('country-performance', { period: period, processingCompany: company, topCountriesCount: countries })
        }).then(function (data) {
          _this.set('model.merchantPerformances', data.merchantPerformances);
          _this.set('model.countryPerformances', data.countryPerformances);
          _this.set('isLoading', false);
        }, function (error) {
          _this.handleErrors(error);
          _this.set('isLoading', false);
        });
      },
      changeCountriesCount: function changeCountriesCount() {
        var _this2 = this;

        var store = this.get('store'),
            period = this.get('period'),
            company = this.get('companyId'),
            countries = this.get('topCountries');

        this.set('isCountriesLoading', true);
        Ember.RSVP.hash({
          countryPerformances: store.query('country-performance', { period: period, processingCompany: company, topCountriesCount: countries })
        }).then(function (data) {
          _this2.set('model.countryPerformances', data.countryPerformances);
          _this2.set('isCountriesLoading', false);
        }, function (error) {
          _this2.handleErrors(error);
          _this2.set('isCountriesLoading', false);
        });
      }
    }
  });
});