define("backoffice/constants/auth", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    AUTH_2FA_STATUS_FLAG: -1,

    AUTH_2FA_PLACEMENT_MBO: "mbo"
  };
});