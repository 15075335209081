define('backoffice/routes/admin/packages/edit', ['exports', 'backoffice/mixins/admin-route-mixin', 'ember-simple-auth/mixins/authenticated-route-mixin', 'backoffice/mixins/errors-handler', 'backoffice/mixins/reset-scroll-position'], function (exports, _adminRouteMixin, _authenticatedRouteMixin, _errorsHandler, _resetScrollPosition) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_adminRouteMixin.default, _authenticatedRouteMixin.default, _errorsHandler.default, _resetScrollPosition.default, {
    init: function init() {
      this._super.apply(this, arguments);
      this.title = this.get('intl').t('packages.editPackage');
    },
    model: function model(params) {
      return Ember.RSVP.hash({
        'package': this.store.findRecord('package', params.package_id),
        offersList: this.store.query('offer', {})
      });
    },
    afterModel: function afterModel(model /*,transition*/) {
      model.package.startTrack();
      model.package.formPositions.forEach(function (el) {
        el.startTrack();
      });
    },

    actions: {
      cancel: function cancel() {
        this.transitionTo('admin.packages.index');
      },
      save: function save(pack) {
        var _this = this;

        pack.save().then(function () {
          return _this.transitionTo('admin.packages.index');
        }).catch(function (error) {
          _this.handleErrors(error);
        });
      },
      willTransition: function willTransition() /*transition*/{
        var model = this.controller.get('model.package'),
            isChanged = model.get('isDirty') || model.get('hasDirtyRelations');
        this.transitionTo('admin.packages.index');
        if (isChanged) {
          model.rollback();
          model.formPositions.forEach(function (el) {
            el.rollback();
          });
        }
      }
    }
  });
});