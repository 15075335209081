define('backoffice/controllers/admin/support/tickets/new', ['exports', 'backoffice/constants/support'], function (exports, _support) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var CATEGORIES_LIST = _support.default.CATEGORIES_LIST,
      CATEGORIES_MAP = _support.default.CATEGORIES_MAP,
      SEVERITY_LIST = _support.default.SEVERITY_LIST,
      SEVERITIES_MAP = _support.default.SEVERITIES_MAP,
      CRITICAL_HINT = _support.default.CRITICAL_HINT,
      ISSUE_HINT = _support.default.ISSUE_HINT,
      IMPROVEMENT_HINT = _support.default.IMPROVEMENT_HINT;
  exports.default = Ember.Controller.extend({
    files: [],
    criticalHint: CRITICAL_HINT,
    issueHint: ISSUE_HINT,
    improvementHint: IMPROVEMENT_HINT,
    categories: CATEGORIES_LIST,
    severityLevels: SEVERITY_LIST,
    categoriesMap: CATEGORIES_MAP,
    severitiesMap: SEVERITIES_MAP,
    selectedCategory: null,
    selectedLevel: null,
    fileCounter: 0,
    isSubjectError: false,
    isCategoryError: false,
    isContentError: false,
    isSeverityLevelError: false,
    isButtonsDisabled: false,
    showChooseLevel: Ember.computed('selectedCategory', function () {
      return this.get('selectedCategory') === this.get('categories')[1];
    }),
    getMapValue: function getMapValue(map, description) {
      for (var key in map) {
        if (map.hasOwnProperty(key)) {
          if (map[key] === description) {
            return key;
          }
        }
      }

      return null;
    },

    tooltip: Ember.computed('selectedLevel', function () {
      var text = void 0;

      switch (this.get('selectedLevel')) {
        case this.get('severityLevels')[0]:
          {
            text = this.get('criticalHint');
            break;
          }
        case this.get('severityLevels')[1]:
          {
            text = this.get('issueHint');
            break;
          }
        case this.get('severityLevels')[2]:
          {
            text = this.get('improvementHint');
            break;
          }
        default:
          {
            text = '';
          }
      }
      return text;
    })
  });
});