define('backoffice/pods/components/table-cell-tx-status/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'td',
    index: null,
    data: null,
    row: null,
    collapsed: false,
    status: Ember.computed('data', 'row', function () {
      var businessStatus = this.get('row.businessStatus');
      var status = '';

      if (businessStatus) {
        if (businessStatus === "PENDING") {
          status = 'Pending';
        } else if (businessStatus === "SUCCESS") {
          status = 'Successful';
        } else {
          status = 'Failed';
        }
      }

      return status;
    }),
    click: function click() {
      if (this.get('isTruncated')) {
        this.toggleProperty('collapsed');
        if (this.get('toggleDetails')) {
          this.get('toggleDetails')({ collapsed: this.get('collapsed') });
        }
      }
    }
  });
});