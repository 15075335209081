define('backoffice/pods/components/table-row-transactions/component', ['exports', 'backoffice/pods/components/table-row/component', 'backoffice/constants/transactions'], function (exports, _component, _transactions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var ORIGINAL_TRANSACTION_TYPE_REFUND = _transactions.default.ORIGINAL_TRANSACTION_TYPE_REFUND,
      ORIGINAL_TRANSACTION_TYPE_OCTPT = _transactions.default.ORIGINAL_TRANSACTION_TYPE_OCTPT,
      ORIGINAL_TRANSACTION_TYPE_CHARGEBACK = _transactions.default.ORIGINAL_TRANSACTION_TYPE_CHARGEBACK,
      ORIGINAL_TRANSACTION_TYPE_UPSELL = _transactions.default.ORIGINAL_TRANSACTION_TYPE_UPSELL,
      ORIGINAL_TRANSACTION_TYPE_SALE = _transactions.default.ORIGINAL_TRANSACTION_TYPE_SALE,
      ORIGINAL_TRANSACTION_TYPE_REBILL = _transactions.default.ORIGINAL_TRANSACTION_TYPE_REBILL,
      ORIGINAL_TRANSACTION_TYPE_ALTPAYMENT = _transactions.default.ORIGINAL_TRANSACTION_TYPE_ALTPAYMENT;
  exports.default = _component.default.extend({
    classNameBindings: ['isUpsell:upsell-row', 'isSale:sale-row', 'isRebill:rebill-row', 'isChargeback:chargeback-row', 'isOctPt:octpt-row', 'isRefund:refund-row', 'isError:error-payment-row', 'isActive:active'],
    isUpsell: Ember.computed('row', function () {
      return this.get('data.originalTransactionType') && this.get('data.originalTransactionType').toLowerCase() === ORIGINAL_TRANSACTION_TYPE_UPSELL;
    }),
    isSale: Ember.computed('row', function () {
      return this.get('data.originalTransactionType') && (this.get('data.originalTransactionType').toLowerCase() === ORIGINAL_TRANSACTION_TYPE_SALE || this.get('data.originalTransactionType').toLowerCase() === ORIGINAL_TRANSACTION_TYPE_ALTPAYMENT);
    }),
    isRebill: Ember.computed('row', function () {
      return this.get('data.originalTransactionType') && this.get('data.originalTransactionType').toLowerCase() === ORIGINAL_TRANSACTION_TYPE_REBILL;
    }),
    isChargeback: Ember.computed('row', function () {
      return this.get('data.originalTransactionType') && this.get('data.originalTransactionType').toLowerCase() === ORIGINAL_TRANSACTION_TYPE_CHARGEBACK;
    }),
    isRefund: Ember.computed('row', function () {
      return this.get('data.originalTransactionType') && this.get('data.originalTransactionType').toLowerCase() === ORIGINAL_TRANSACTION_TYPE_REFUND;
    }),
    isOctPt: Ember.computed('row', function () {
      return this.get('data.originalTransactionType') && this.get('data.originalTransactionType').toLowerCase() === ORIGINAL_TRANSACTION_TYPE_OCTPT;
    }),
    isError: Ember.computed('row', function () {
      if (this.get('data.successful') && this.get('data.transactionType').toLowerCase() === 'refundinit') {
        return this.get('data.hasBeenCancelled') && !this.get('data.hasBeenRefunded');
      }
      if (this.get('data.successful') && (this.get('data.transactionType').toLowerCase() === 'octinit' || this.get('data.transactionType').toLowerCase() === 'sale3dinit' || this.get('data.transactionType').toLowerCase() === 'upsell3dinit' || this.get('data.transactionType').toLowerCase() === 'paymentinit')) {
        return this.get('data.hasBeenCancelled') && !this.get('data.hasBeenPaid');
      } else {
        return this.get('data.errorCode');
      }
    })
  });
});