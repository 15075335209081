define('backoffice/pods/components/table-cell-package-actions/component', ['exports', 'backoffice/constants/permissions'], function (exports, _permissions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var WEBSITES_EDIT_KEY = _permissions.default.WEBSITES_EDIT_KEY,
      WEBSITES_VIEW_KEY = _permissions.default.WEBSITES_VIEW_KEY;
  exports.default = Ember.Component.extend({
    tagName: 'td',
    permissionsManager: Ember.inject.service(),
    hasActions: Ember.computed('row.websitesAttached', function () {
      return this.get('row.websitesAttached') === 0;
    }),
    index: null,
    data: null,
    row: null,
    hasPermission: Ember.computed(function () {
      return this.get('permissionsManager').hasPermission([WEBSITES_VIEW_KEY, WEBSITES_EDIT_KEY]);
    })
  });
});