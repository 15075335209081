define('backoffice/pods/components/dashboard-merchant-chart/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    dashboardService: Ember.inject.service('dashboard'),
    intl: Ember.inject.service(),
    paidAmountData: Ember.computed('data', function () {
      return this._getData('successfulTrxAmount', this.get('data'));
    }),
    successRatioData: Ember.computed('data', function () {
      var _this = this;

      return this._getData('successRatio', this.get('data')).map(function (value) {
        return _this.get('dashboardService').round(value * 100);
      });
    }),
    avgTicketData: Ember.computed('data', function () {
      var _this2 = this;

      return this._getData('averageTicketAmount', this.get('data')).map(function (value) {
        return _this2.get('dashboardService').round(value);
      });
    }),
    categories: Ember.computed('data', function () {
      if (this.get('data')) {
        return this.get('data').map(function (item) {
          var period = item.get('period');
          var formattedPeriod = typeof period === 'string' ? period.replace(/-/g, "/") : period;
          return new Date(formattedPeriod);
        });
      } else {
        return [];
      }
    }),
    _getData: function _getData(dataType) {
      var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];

      var filtered = data.filter(function (item) {
        return item.get('intervalName') !== 'Total';
      });
      return filtered.map(function (item) {
        return item.get(dataType);
      });
    },

    chartOptions: Ember.computed('data', function () {
      var that = this;
      return {
        chart: {
          height: 300 /*,
                      zoomType: 'xy'*/
        },
        legend: {
          align: 'right',
          verticalAlign: 'top',
          title: {
            style: {
              fontWeight: 'normal'
            }
          }
        },
        title: {
          text: null
        },
        loading: {
          labelStyle: {
            color: '#000000',
            fontWeight: 'bold'
          }
        },
        xAxis: {
          labels: {
            type: 'datetime',
            formatter: function formatter() {
              return this.x === 0 ? '' : that.get('dashboardService').formatDate(this.value, that.get('period'));
            }
          },
          categories: this.get('categories')
        },
        yAxis: [{
          title: null,
          labels: {
            enabled: false
          }
        }, {
          title: null,
          labels: {
            enabled: false
          }
        }],
        plotOptions: {
          series: {
            stacking: 'normal',
            groupPadding: 0
          },
          area: {
            stacking: 'percent'
          }
        }
      };
    }),
    series: Ember.computed('data', function () {
      var that = this;
      return [{
        name: 'Paid ' + (this.get('currency') || "EUR"),
        type: 'column',
        data: this.get('paidAmountData'),
        color: '#ec9230',
        yAxis: 0,
        legendIndex: 1,
        dataLabels: {
          enabled: false,
          allowOverlap: true,
          align: 'center',
          color: '#000000',
          style: {
            fontSize: '11px',
            fontWeight: 'bold',
            boxShadow: '0px 0px 15px 10px rgba(255,255,255,1)'
          }
        },
        tooltip: {
          headerFormat: '<span style="font-size: 12px; font-weight: bold;">{series.name}.</span>',
          pointFormatter: function pointFormatter() {
            return '<br>' + that.get('dashboardService').formatDate(this.category, that.get('period')) + ': ' + that.get('intl').formatNumber(this.y);
          }
        }
      }, {
        name: 'AVG Ticket',
        type: 'area',
        dashStyle: 'Dot',
        data: this.get('avgTicketData'),
        color: '#c478c2',
        visible: false,
        yAxis: 0,
        legendIndex: 3,
        fillColor: {
          linearGradient: [0, 0, 0, 200],
          stops: [[0, '#c478c2'], [1, Highcharts.Color('#c478c2').setOpacity(0).get('rgba')]]
        },
        dataLabels: {
          enabled: true,
          allowOverlap: true,
          align: 'center',
          color: '#000000',
          style: {
            fontSize: '11px',
            fontWeight: 'bold',
            boxShadow: '0px 0px 15px 10px rgba(255,255,255,1)'
          },
          formatter: function formatter() {
            return this.y === 0 ? '' : '' + that.get('dashboardService').round(this.y);
          }
        },
        tooltip: {
          headerFormat: '<span style="font-size: 12px; font-weight: bold;">{series.name}.</span>',
          pointFormatter: function pointFormatter() {
            return '<br>' + that.get('dashboardService').formatDate(this.category, that.get('period')) + ': ' + that.get('intl').formatNumber(this.y);
          }
        }
      }, {
        name: 'Success Ratio',
        type: 'spline',
        data: this.get('successRatioData'),
        color: '#99dba7',
        yAxis: 1,
        legendIndex: 2,
        dataLabels: {
          enabled: true,
          allowOverlap: true,
          align: 'center',
          color: '#000000',
          style: {
            fontSize: '11px',
            fontWeight: 'bold',
            boxShadow: '0px 0px 15px 10px rgba(255,255,255,1)'
          },
          formatter: function formatter() {
            return this.y === 0 ? '' : that.get('dashboardService').round(this.y) + '%';
          }
        },
        tooltip: {
          headerFormat: '<span style="font-size: 12px; font-weight: bold;">{series.name}.</span>',
          pointFormatter: function pointFormatter() {
            return '<br>' + that.get('dashboardService').formatDate(this.category, that.get('period')) + ': ' + this.y + '%';
          }
        }
      }];
    })
  });
});