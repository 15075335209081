define('backoffice/pods/components/table-cell-tx-type/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var paymentTypes = {
    upsell: { iconText: 'UP', tooltip: 'Upsell', iconClassName: 'upsell-icon' },
    sale: { iconText: 'S', tooltip: 'Sale', iconClassName: 'sale-icon' },
    sale3dinit: { iconText: 'S', tooltip: 'Sale pending', iconClassName: 'sale-pending-icon' },
    upsell3dinit: { iconText: 'UP', tooltip: 'Upsell pending', iconClassName: 'upsell-pending-icon' },
    rebill: { iconText: 'REB', tooltip: 'Rebill', iconClassName: 'rebill-icon' },
    chargeback: { iconText: 'CB', tooltip: 'Chargeback', iconClassName: 'chargeback-icon' },
    refund: { iconText: 'RF', tooltip: 'Refund', iconClassName: 'refund-icon' },
    refundinit: { iconText: 'RF', tooltip: 'Refund pending', iconClassName: 'refund-pending-icon' },
    octpt: { iconText: 'OCT', tooltip: 'Original Credit', iconClassName: 'octpt-icon' },
    octinit: { iconText: 'OCT', tooltip: 'Original Credit pending', iconClassName: 'octpt-pending-icon' },
    threedsecureenrollment: { iconText: '3D', tooltip: '3D Enrollment', iconClassName: 'threed-icon' }
  };

  var orderTypes = {
    payment: {
      getPaymentType: function getPaymentType() {
        return null;
      }
    },
    subscription: {
      getPaymentType: function getPaymentType() {
        return paymentTypes.sale;
      }
    },
    recurringPayment: {
      getPaymentType: function getPaymentType() {
        return paymentTypes.upsell;
      }
    },
    subscriptionRecurringPayment: {
      getPaymentType: function getPaymentType(_ref) {
        var otType = _ref.otType,
            gwOrderType = _ref.gwOrderType;

        if (otType === 'refund') {
          return paymentTypes.refund;
        } else if (gwOrderType === 'subscriptionRecurringPayment') {
          return paymentTypes.rebill;
        }
        return paymentTypes.upsell;
      }
    },
    payout: {
      getPaymentType: function getPaymentType() {
        return paymentTypes.octpt;
      }
    },
    refund: {
      getPaymentType: function getPaymentType() {
        return paymentTypes.refund;
      }
    },
    chargeback: {
      getPaymentType: function getPaymentType() {
        return paymentTypes.chargeback;
      }
    }
  };

  var trxTypes = {
    octinit: {
      getPaymentType: function getPaymentType(_ref2) {
        var businessStatus = _ref2.businessStatus;

        if (businessStatus === 'PENDING') {
          return paymentTypes.octinit;
        } else {
          return paymentTypes.octpt;
        }
      }
    },
    sale3dinit: {
      getPaymentType: function getPaymentType(_ref3) {
        var businessStatus = _ref3.businessStatus;

        if (businessStatus === 'PENDING') {
          return paymentTypes.sale3dinit;
        } else {
          return paymentTypes.sale;
        }
      }
    },
    paymentinit: {
      getPaymentType: function getPaymentType(_ref4) {
        var businessStatus = _ref4.businessStatus;

        if (businessStatus === 'PENDING') {
          return paymentTypes.sale3dinit;
        } else {
          return paymentTypes.sale;
        }
      }
    },
    upsell3dinit: {
      getPaymentType: function getPaymentType(_ref5) {
        var businessStatus = _ref5.businessStatus;

        if (businessStatus === 'PENDING') {
          return paymentTypes.upsell3dinit;
        } else {
          return paymentTypes.upsell;
        }
      }
    },
    refundinit: {
      getPaymentType: function getPaymentType(_ref6) {
        var businessStatus = _ref6.businessStatus;

        if (businessStatus === 'PENDING') {
          return paymentTypes.refundinit;
        } else {
          return paymentTypes.refund;
        }
      }
    }
  };

  exports.default = Ember.Component.extend({
    tagName: 'td',
    index: null,
    data: null,
    row: null,
    paymentType: Ember.computed('data', 'row', function () {
      var successful = this.get('row.successful');
      var gwOrderType = this.get('row.orderType');
      var tType = this.get('row.transactionType').toLowerCase();
      var otType = this.get('row.originalTransactionType').toLowerCase();
      var isCancelled = this.get('row.hasBeenCancelled');
      var isPaid = this.get('row.hasBeenPaid');
      var isRefund = this.get('row.hasBeenRefunded');
      var businessStatus = this.get('row.businessStatus');

      var props = { isPaid: isPaid, isCancelled: isCancelled, otType: otType, isRefund: isRefund, gwOrderType: gwOrderType, businessStatus: businessStatus };
      var paymentType = void 0;

      if (gwOrderType) {
        var order = orderTypes[gwOrderType];
        paymentType = order && order.getPaymentType(props);
      }

      if (!paymentType && successful) {
        var trx = trxTypes[tType];
        paymentType = trx && trx.getPaymentType(props);
      }

      if (!paymentType) {
        paymentType = paymentTypes[this.get('data').toLowerCase()];
      }

      return paymentType;
    }),
    click: function click() {
      if (this.get('isTruncated')) {
        this.toggleProperty('collapsed');
        if (this.get('toggleDetails')) {
          this.get('toggleDetails')({ collapsed: this.get('collapsed') });
        }
      }
    }
  });
});