define('backoffice/controllers/admin/packages/edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var rowSettingsOffers = {
    component: 'table-row-offers'
  };

  var EditPackageController = Ember.Controller.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.rowSettingsOffers = rowSettingsOffers;
    },

    columnsSettingsTrialOffers: Ember.computed('intl.primaryLocale', function () {
      return [{
        uniqueKey: 'isOfferChecked',
        component: 'table-cell-checkbox',
        title: this.get('intl').t('offers.fields.linkPackage'),
        key: 'id',
        cls: 'text-center',
        isTruncated: false
      }, {
        uniqueKey: 'formPosition',
        component: 'table-cell-form-position',
        title: this.get('intl').t('offers.fields.formPosition'),
        key: 'formPosition',
        cls: 'offer-position-cell text-center',
        isTruncated: true
      }, {
        uniqueKey: 'id',
        component: 'table-cell-text',
        title: this.get('intl').t('offers.fields.offId'),
        key: 'id',
        cls: 'text-center',
        isTruncated: true
      }, {
        uniqueKey: 'description',
        component: 'table-cell-text',
        title: this.get('intl').t('offers.fields.description'),
        key: 'name',
        cls: '',
        isTruncated: true
      }, {
        uniqueKey: 'duration',
        component: 'table-cell-text',
        title: this.get('intl').t('offers.fields.duration'),
        key: 'duration',
        cls: '',
        isTruncated: true
      }, {
        uniqueKey: 'rebillsOverOffer',
        component: 'table-cell-offer-rebill',
        title: this.get('intl').t('offers.fields.rebillsOverOffer'),
        key: 'rebill',
        cls: 'text-center',
        isTruncated: true
      }, {
        uniqueKey: 'packagesCounter',
        component: 'table-cell-tooltipstered-packages-counter',
        title: this.get('intl').t('offers.fields.packagesCounter'),
        key: 'packagesAttached',
        cls: 'text-center',
        isTruncated: true
      }, {
        uniqueKey: 'amountEuro',
        component: 'table-cell-eur-amount',
        title: this.get('intl').t('offers.fields.amountEuro'),
        key: 'prices',
        cls: 'text-right',
        isTruncated: true
      }, {
        uniqueKey: 'amountUSD',
        component: 'table-cell-usd-amount',
        title: this.get('intl').t('offers.fields.amountUSD'),
        key: 'prices',
        cls: 'text-right',
        isTruncated: true
      }];
    }),
    columnsSettingsOneShotOffers: Ember.computed('intl.primaryLocale', function () {
      return [{
        uniqueKey: 'isOfferChecked',
        component: 'table-cell-checkbox',
        title: this.get('intl').t('offers.fields.linkPackage'),
        key: 'id',
        cls: 'text-center',
        isTruncated: false
      }, {
        uniqueKey: 'formPosition',
        component: 'table-cell-form-position',
        title: this.get('intl').t('offers.fields.formPosition'),
        key: 'formPosition',
        cls: 'offer-position-cell text-center',
        isTruncated: true
      }, {
        uniqueKey: 'id',
        component: 'table-cell-text',
        title: this.get('intl').t('offers.fields.offId'),
        key: 'id',
        cls: 'text-center',
        isTruncated: true
      }, {
        uniqueKey: 'description',
        component: 'table-cell-text',
        title: this.get('intl').t('offers.fields.description'),
        key: 'name',
        cls: '',
        isTruncated: true
      }, {
        uniqueKey: 'packagesCounter',
        component: 'table-cell-tooltipstered-packages-counter',
        title: this.get('intl').t('offers.fields.packagesCounter'),
        key: 'packagesAttached',
        cls: 'text-center',
        isTruncated: true
      }, {
        uniqueKey: 'amountEuro',
        component: 'table-cell-eur-amount',
        title: this.get('intl').t('offers.fields.amountEuro'),
        key: 'prices',
        cls: 'text-right',
        isTruncated: true
      }, {
        uniqueKey: 'amountUSD',
        component: 'table-cell-usd-amount',
        title: this.get('intl').t('offers.fields.amountUSD'),
        key: 'prices',
        cls: 'text-right',
        isTruncated: true
      }];
    }),
    columnsSettingsUpsellOffers: Ember.computed('intl.primaryLocale', function () {
      return [{
        uniqueKey: 'isOfferChecked',
        component: 'table-cell-checkbox',
        title: this.get('intl').t('offers.fields.linkPackage'),
        key: 'id',
        cls: 'text-center',
        isTruncated: false
      }, {
        uniqueKey: 'formPosition',
        component: 'table-cell-form-position',
        title: this.get('intl').t('offers.fields.formPosition'),
        key: 'formPosition',
        cls: 'offer-position-cell text-center',
        isTruncated: true
      }, {
        uniqueKey: 'id',
        component: 'table-cell-text',
        title: this.get('intl').t('offers.fields.offId'),
        key: 'id',
        cls: 'text-center',
        isTruncated: true
      }, {
        uniqueKey: 'description',
        component: 'table-cell-text',
        title: this.get('intl').t('offers.fields.description'),
        key: 'name',
        cls: '',
        isTruncated: true
      }, {
        uniqueKey: 'packagesCounter',
        component: 'table-cell-tooltipstered-packages-counter',
        title: this.get('intl').t('offers.fields.packagesCounter'),
        key: 'packagesAttached',
        cls: 'text-center',
        isTruncated: true
      }, {
        uniqueKey: 'amountEuro',
        component: 'table-cell-eur-amount',
        title: this.get('intl').t('offers.fields.amountEuro'),
        key: 'prices',
        cls: 'text-right',
        isTruncated: true
      }, {
        uniqueKey: 'amountUSD',
        component: 'table-cell-usd-amount',
        title: this.get('intl').t('offers.fields.amountUSD'),
        key: 'prices',
        cls: 'text-right',
        isTruncated: true
      }];
    }),
    intl: Ember.inject.service(),
    tableIdOffers: 'package-offers-table',
    tableIdTrialPackages: 'package-trial-offers-table',
    tableIdOneShotPackages: 'package-oneshot-offers-table',
    tableIdUpsellPackages: 'package-upsell-offers-table',
    filter: null,
    actions: {}
  });

  exports.default = EditPackageController;
});