define('backoffice/initializers/component-router-injector', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {
    application.inject('component', 'router', 'router:main');
  }

  exports.default = {
    name: 'component-router-injector',
    initialize: initialize
  };
});