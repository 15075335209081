define('backoffice/mixins/sorting-data', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    sortBy: null,
    sortDefinition: Ember.computed('sortBy', function () {
      return ['' + this.get('sortBy')];
    }),
    addSortedStyle: function addSortedStyle(obj) {
      var _this = this;

      var id = obj.get('elementId');

      Ember.$('#' + id).parent().children().each(function (index, value) {
        if (Ember.$(value).hasClass('sort')) {
          Ember.$(value).removeClass('sort');
        }
      });
      Ember.$('#' + id).addClass(function () {
        if (!Ember.$(_this).hasClass('sort')) {
          return 'sort';
        }
      });
    },

    actions: {
      sort: function sort(obj) {
        var newDirection = void 0;

        switch (obj.get('direction')) {
          case 'none':
            {
              newDirection = 'asc';
              this.addSortedStyle(obj);
              this.set('isSorted', true);
              break;
            }
          case 'asc':
            {
              newDirection = 'desc';
              this.addSortedStyle(obj);
              this.set('isSorted', true);
              break;
            }
          case 'desc':
            {
              newDirection = false;
              Ember.$('#' + obj.get('elementId')).parent().children().each(function (index, value) {
                if (Ember.$(value).hasClass('sort')) {
                  Ember.$(value).removeClass('sort');
                }
              });
              this.set('isSorted', false);
              break;
            }
          default:
            {
              newDirection = false;
            }
        }

        if (newDirection) {
          this.set('sortBy', obj.modelProp + (newDirection ? ':' + newDirection : ''));
        } else {
          this.set('sortBy', null);
        }
        obj.set('direction', newDirection ? newDirection : 'none');
      }
    }
  });
});