define('backoffice/router', ['exports', 'backoffice/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('signup');
    this.route('login');
    this.route('admin', function () {
      this.route('no-access');
      this.route('error', { path: '/*path' });
      this.route('dashboard');
      this.route('transactions', function () {
        this.route('search');
      });
      this.route('websites', function () {
        this.route('new');
        this.route('edit', { path: '/:website_id' });
      });
      this.route('packages', function () {
        this.route('new');
        this.route('edit', { path: '/:package_id' });
      });
      this.route('offers', function () {
        this.route('new');
        this.route('edit', { path: '/:offer_id' });
        this.route('view', { path: '/view/:offer_id' });
      });
      this.route('payouts');
      this.route('support', function () {
        this.route('tickets', function () {
          this.route('edit', { path: '/:ticket_id' });
          this.route('new');
        });
      });
      this.route('roles');
      this.route('forecast', function () {
        this.route('search');
      });
      this.route('reports', function () {
        this.route('invoices', function () {
          this.route('search');
        });
        this.route('settlements', function () {
          this.route('search');
        });
      });
      this.route('profile');
      this.route('ipRestrictions');
      this.route('subscriptions');
      this.route('chargebacks');
      this.route('businessTransactions');
      this.route('generatedReports');
      this.route('callbackSettings');
      this.route('2faSettings', { path: '2fa/settings' });
    });
    this.route('docs', function () {
      this.route('reference');
    });
    this.route('2fa', function () {
      this.route('configuration');
      this.route('recovery');
      this.route('verification');
      this.route('reset');
    });
    this.route('loading');
    this.route('error', { path: '/*path' });
    this.route('new-password');
    this.route('reset-password');
    this.route('health');
  });

  exports.default = Router;
});