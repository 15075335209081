define('backoffice/routes/admin/payouts', ['exports', 'backoffice/mixins/admin-route-mixin', 'ember-simple-auth/mixins/authenticated-route-mixin', 'backoffice/mixins/errors-handler', 'backoffice/config/environment', 'backoffice/constants/payouts', 'backoffice/constants/requests', 'backoffice/constants/permissions'], function (exports, _adminRouteMixin, _authenticatedRouteMixin, _errorsHandler, _environment, _payouts, _requests, _permissions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var PAYOUT_POLLING_TIMEOUT = _payouts.default.PAYOUT_POLLING_TIMEOUT,
      STATUS_PAYOUT_PENDING = _payouts.default.STATUS_PAYOUT_PENDING,
      STATUS_PAYOUT_IN_PROGRESS = _payouts.default.STATUS_PAYOUT_IN_PROGRESS;
  var CLOSE_NOTIFY_DELAY = _requests.default.CLOSE_NOTIFY_DELAY;
  var PAYOUTS_GET_BALANCE_KEY = _permissions.default.PAYOUTS_GET_BALANCE_KEY;
  exports.default = Ember.Route.extend(_adminRouteMixin.default, _authenticatedRouteMixin.default, _errorsHandler.default, {
    title: 'Payouts',
    session: Ember.inject.service('session'),
    notify: Ember.inject.service('notify'),
    permissionsManager: Ember.inject.service(),
    queryParams: {
      page: { refreshModel: true },
      action: { refreshModel: true }
    },
    model: function model(params) {
      return Ember.RSVP.hash({
        payoutDocument: this.store.query('payoutDocument', params)
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      this.pollingAction();
      this.getBalance();
    },
    pollingAction: function pollingAction() {
      var _this2 = this;

      var pollingHandler = Ember.run.later(this, function () {
        if (_this2.controller.get('model.payoutDocument.length')) {
          _this2.controller.get('model.payoutDocument').forEach(function (pollingModel) {
            var documentStatusId = parseInt(pollingModel.get('statusId'));
            if (documentStatusId === STATUS_PAYOUT_PENDING || documentStatusId === STATUS_PAYOUT_IN_PROGRESS) {
              _this2.store.findRecord('payoutDocument', pollingModel.get('id'));
            }
          });
          _this2.pollingAction();
        }
      }, PAYOUT_POLLING_TIMEOUT);

      this.controller.set('pollingHandler', pollingHandler);
    },
    getBalance: function getBalance() {
      if (!this.get('permissionsManager').hasPermission([PAYOUTS_GET_BALANCE_KEY])) {
        return;
      }

      var _this = this;

      Ember.$.ajax({
        url: _environment.default.APP.apiHost + '/api/payout/balance',
        type: 'GET',
        async: false,
        headers: {
          Authorization: 'Bearer ' + this.get('session').get('data.authenticated.token.access_token')
        },
        contentType: false,
        success: function success(successData) {
          _this.controller.set('payoutBalances', successData.payoutBalances);
        },
        error: function error(_error) {
          var notify = _this.get('notify');
          notify.error(JSON.parse(_error.responseText).errors.msg, { closeAfter: CLOSE_NOTIFY_DELAY });
        }
      });
    },
    deactivate: function deactivate() {
      Ember.run.cancel(this.controller.get('pollingHandler'));
    },
    sendPayoutDocument: function sendPayoutDocument(payoutDocument) {
      var _this3 = this;

      var doc = this.store.peekRecord('payoutDocument', payoutDocument.id);
      doc.set('command', 'send');
      doc.save().catch(function (error) {
        _this3.handleErrors(error);
      });
    },
    cancelPayoutDocument: function cancelPayoutDocument(payoutDocument) {
      var _this4 = this;

      var doc = this.store.peekRecord('payoutDocument', payoutDocument.id);
      doc.set('command', 'cancel');
      doc.save().catch(function (error) {
        _this4.handleErrors(error);
      });
    },

    actions: {
      uploadSuccess: function uploadSuccess() {
        this.refresh();
        this.transitionTo('admin.payouts', { queryParams: { page: 0 } });
      },
      resetDialog: function resetDialog() {
        this.controller.set('activePayout', null);
        this.controller.set('isCancelDialogShown', false);
        this.controller.set('isSendDialogShown', false);
      },
      confirmCancel: function confirmCancel(payoutDocument) {
        this.controller.set('activePayout', payoutDocument);
        this.controller.set('isCancelDialogShown', true);
      },
      confirmSend: function confirmSend(payoutDocument) {
        this.controller.set('activePayout', payoutDocument);
        this.controller.set('isSendDialogShown', true);
      },
      allowCancel: function allowCancel() {
        this.controller.set('isCancelDialogShown', false);
        this.cancelPayoutDocument(this.controller.get('activePayout'));
      },
      allowSend: function allowSend() {
        this.controller.set('isSendDialogShown', false);
        this.sendPayoutDocument(this.controller.get('activePayout'));
      },
      getBalance: function getBalance() {
        this.getBalance();
      }
    }
  });
});