define('backoffice/routes/admin/offers/index', ['exports', 'backoffice/mixins/admin-route-mixin', 'ember-simple-auth/mixins/authenticated-route-mixin', 'backoffice/constants/offers'], function (exports, _adminRouteMixin, _authenticatedRouteMixin, _offers) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var OFFER_MEMBERSHIP_TYPEID = _offers.default.OFFER_MEMBERSHIP_TYPEID;
  exports.default = Ember.Route.extend(_adminRouteMixin.default, _authenticatedRouteMixin.default, {
    model: function model() {
      return Ember.RSVP.hash({
        offers: this.store.query('offer', {}),
        currencies: this.store.findAll('currency')
      });
    },

    offerTypeId: OFFER_MEMBERSHIP_TYPEID,
    actions: {
      tabChanged: function tabChanged(tabId) {
        this.set('offerTypeId', tabId.substr(-1));
      },
      updateOffer: function updateOffer(offerId) {
        this.transitionTo('admin.offers.edit', offerId);
      },
      viewOffer: function viewOffer(offerId) {
        this.transitionTo('admin.offers.view', offerId);
      },
      newOffer: function newOffer() {
        this.transitionTo('admin.offers.new', { queryParams: { offerTypeId: Ember.$('.tab-pane.active').attr('id').substr(-1) } });
      }
    }
  });
});