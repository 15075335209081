define('backoffice/helpers/eq', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.eq = eq;
  function eq(params) {
    return params.reduce(function (a, b) {
      return a === b;
    });
  }

  exports.default = Ember.Helper.helper(eq);
});