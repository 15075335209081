define('backoffice/routes/admin/support/tickets/edit', ['exports', 'backoffice/mixins/admin-route-mixin', 'ember-simple-auth/mixins/authenticated-route-mixin', 'backoffice/mixins/errors-handler', 'backoffice/config/environment', 'backoffice/constants/requests'], function (exports, _adminRouteMixin, _authenticatedRouteMixin, _errorsHandler, _environment, _requests) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var CLOSE_NOTIFY_DELAY = _requests.default.CLOSE_NOTIFY_DELAY;
  exports.default = Ember.Route.extend(_adminRouteMixin.default, _authenticatedRouteMixin.default, _errorsHandler.default, {
    notify: Ember.inject.service('notify'),
    session: Ember.inject.service('session'),
    model: function model(params) {
      var _this2 = this;

      return this.store.findRecord('ticket', params.ticket_id).then(function (data) {
        _this2.controllerFor('admin.support.tickets').set('activeTicketId', params.ticket_id);
        return data;
      });
    },
    resetController: function resetController(controller) {
      controller.set('currentPage', null);
      controller.set('pageManuallyChanged', false);
      controller.set('isUnsentDialogShown', false);
    },

    actions: {
      createMessage: function createMessage(ticketId) {
        var message = this.controller.get('newMessage');
        // validation
        if (!message || typeof message === 'string' && !message.trim()) {
          this.controller.set('isContentError', true);
          return;
        }

        this.controller.set('isButtonsDisabled', true);

        var formData = new FormData();
        formData.append('message', message);

        this.controller.get('files').forEach(function (item) {
          formData.append(item.fieldName, item.file);
        });

        var _this = this;

        Ember.$.ajax({
          url: _environment.default.APP.apiHost + '/api/tickets/' + ticketId + '/messages',
          data: formData,
          type: 'POST',
          async: false,
          headers: {
            Authorization: 'Bearer ' + this.get('session').get('data.authenticated.token.access_token')
          },
          mimeType: 'multipart/form-data',
          processData: false,
          contentType: false,
          success: function success() /*successData*/{
            var ctrl = _this.controller;
            ctrl.set('newMessage', null);
            ctrl.set('files', []);
            ctrl.set('isButtonsDisabled', false);
            ctrl.set('currentPage', null);
            ctrl.set('pageManuallyChanged', false);
            _this.refresh();
          },
          error: function error(_error) {
            var notify = _this.get('notify');
            notify.error(JSON.parse(_error.responseText).errors.msg, { closeAfter: CLOSE_NOTIFY_DELAY });
            _this.controller.set('isButtonsDisabled', false);
          }
        });
      },
      cancelTicket: function cancelTicket() {
        this.transitionTo('admin.support.tickets');
      },
      changeTicketStatus: function changeTicketStatus(newStatus, ticket) {
        var _this3 = this;

        ticket.set('status', newStatus);
        this.controller.set('isButtonsDisabled', true);
        this.controller.set('isStatusChangeRequest', true);

        ticket.save().then(function () /*data*/{
          _this3.refresh();

          var ctrl = _this3.controller;
          ctrl.set('isButtonsDisabled', false);
          ctrl.set('currentPage', null);
          ctrl.set('pageManuallyChanged', false);
          ctrl.set('isStatusChangeRequest', false);
        }, function (error) {
          _this3.handleErrors(error);
          _this3.controller.set('isButtonsDisabled', false);
          _this3.controller.set('isStatusChangeRequest', false);
        });
      },
      fileChanged: function fileChanged(event) {
        var counter = this.controller.get('fileCounter');
        this.controller.get('files').addObject({ fieldName: 'file-' + counter, file: event.target.files[0] });
        this.controller.set('fileCounter', counter + 1);
      },
      removeFile: function removeFile(file, event) {
        event.preventDefault();
        var removedFile = this.controller.get('files').findBy('fieldName', file.fieldName);
        this.controller.get('files').removeObject(removedFile);
      },
      resetError: function resetError(errorParam) {
        this.controller.set(errorParam, false);
      },
      willTransition: function willTransition(transition) {
        if (!!(this.controller.get('newMessage') || this.controller.get('files.length')) && !this.controller.get('isStatusChangeRequest')) {
          this.controller.set('isUnsentDialogShown', true);
          this.controller.set('previousTransition', transition);
          transition.abort();
        } else if (!this.controller.get('isStatusChangeRequest')) {
          Ember.$('form#new-message-form').get(0).reset();
        }
      },
      cancelTransition: function cancelTransition() {
        this.controller.set('isUnsentDialogShown', false);
        this.controller.set('previousTransition', null);
      },
      proceedTransition: function proceedTransition() {
        this.controller.set('newMessage', null);
        this.controller.set('files', []);
        this.controller.set('isUnsentDialogShown', false);
        this.controller.get('previousTransition').retry();
      }
    }
  });
});