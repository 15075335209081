define('backoffice/routes/admin/profile', ['exports', 'backoffice/mixins/admin-route-mixin', 'ember-simple-auth/mixins/authenticated-route-mixin', 'backoffice/mixins/errors-handler', 'backoffice/constants/requests'], function (exports, _adminRouteMixin, _authenticatedRouteMixin, _errorsHandler, _requests) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var CLOSE_NOTIFY_DELAY = _requests.default.CLOSE_NOTIFY_DELAY;
  exports.default = Ember.Route.extend(_adminRouteMixin.default, _authenticatedRouteMixin.default, _errorsHandler.default, {
    model: function model() {
      return Ember.RSVP.hash({
        profile: this.store.find('account', this.get('accountStorage').get('account').id),
        timezones: this.store.findAll('timeZone')
      });
    },

    actions: {
      openUserDialog: function openUserDialog() {
        this.controller.set('isDialogShown', true);
      },
      resetDialog: function resetDialog() {
        this.controller.set('isDialogShown', false);
      },
      setProfile2faStatus: function setProfile2faStatus(status) {
        var accountStorage = this.get('accountStorage');
        var account = accountStorage.get('account');

        account.is2faConfigured = status;
        accountStorage.set('account', account);

        this.controller.set('model.profile.is2faEnabled', status);
        this.controller.get('model.profile').save();
      },
      setPass: function setPass(params) {
        var _this = this;

        this.controller.set('model.profile.oldPassword', params.oldPassword);
        this.controller.set('model.profile.newPassword', params.newPassword);
        this.controller.get('model.profile').save().then(function () {
          _this.get('notify').success(_this.get('intl').t('profile.messages.passwordChanged'), { closeAfter: CLOSE_NOTIFY_DELAY });
          _this.controller.set('isDialogShown', false);
        }, function (error) {
          return _this.handleErrors(error);
        });
      },
      tzChanged: function tzChanged(params) {
        this.controller.set('isTzChanged', true);
        this.controller.get('model.profile').set('timeZone', params.target.value);
      },
      saveProfile: function saveProfile() {
        var _this2 = this;

        this.controller.get('model.profile').save().then(function () {
          _this2.get('notify').success("PROFILE Settings saved", { closeAfter: CLOSE_NOTIFY_DELAY });
          _this2.controller.set('isTzChanged', false);
        }, function (error) {
          _this2.handleErrors(error);
        });
      },
      changeTzEnabled: function changeTzEnabled(params) {
        this.controller.set('isTzEnabled', !params.target.checked);
        if (params.target.checked) {
          var offset = -new Date().getTimezoneOffset() / 60;
          var search = '' + (Math.sign(offset) < 0 ? "-" : "") + (Math.abs(offset) < 10 ? "0" : "") + Math.abs(offset) + ':00:00';
          var tz = 'GMT';
          if (search) {
            this.controller.get('model.timezones').forEach(function (item) {
              if (item.offset === search) {
                tz = item.id;
                return false;
              }
            });
          }
          this.controller.get('model.profile').set('timeZone', tz);
          this.controller.set('isTzChanged', true);
        }
      }
    }
  });
});