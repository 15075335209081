define('backoffice/controllers/admin/roles', ['exports', 'backoffice/constants/permissions'], function (exports, _permissions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var PERMISSIONS_VIEW_KEY = _permissions.default.PERMISSIONS_VIEW_KEY,
      TWO_FACTOR_AUTH_VIEW_KEY = _permissions.default.TWO_FACTOR_AUTH_VIEW_KEY,
      TWO_FACTOR_AUTH_MANAGE_KEY = _permissions.default.TWO_FACTOR_AUTH_MANAGE_KEY;


  var rowSettingsUsers = {
    component: 'table-row-users',
    shareModel: true
  };
  var rowSettingsRoles = {
    component: 'table-row-roles',
    shareModel: true
  };

  exports.default = Ember.Controller.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.rowSettingsUsers = rowSettingsUsers;
      this.rowSettingsRoles = rowSettingsRoles;
    },

    columnsSettingsRoles: Ember.computed('intl.primaryLocale', function () {
      return [{
        uniqueKey: 'name',
        component: 'table-cell-text',
        title: this.get('intl').t('roles.fields.role'),
        key: 'name',
        cls: 'ordered-role-width text-center',
        isTruncated: true,
        sortable: true,
        sortedProperty: 'sorting'
      }, {
        uniqueKey: 'usersCounter',
        component: 'table-cell-users-assigned',
        title: this.get('intl').t('roles.fields.usersCounter'),
        key: 'id',
        cls: 'text-center',
        isTruncated: true
      }];
    }),
    columnsSettingsUsers: Ember.computed('intl.primaryLocale', function () {
      return [{
        uniqueKey: 'login',
        component: 'table-cell-text',
        title: this.get('intl').t('users.fields.userId'),
        key: 'login',
        cls: 'ordered-login-width text-center',
        isTruncated: true,
        sortable: true,
        sortedProperty: 'sorting'
      }, {
        uniqueKey: 'email',
        component: 'table-cell-text',
        title: this.get('intl').t('users.fields.email'),
        key: 'email',
        cls: '',
        isTruncated: true,
        sortable: true,
        sortedProperty: 'sorting'
      }, {
        uniqueKey: 'name',
        component: 'table-cell-text',
        title: this.get('intl').t('users.fields.name'),
        key: 'name',
        cls: 'ordered-name-width text-center',
        isTruncated: true,
        sortable: true,
        sortedProperty: 'sorting'
      }, {
        uniqueKey: 'status',
        component: 'table-cell-user-status',
        title: this.get('intl').t('users.fields.status'),
        key: 'enabled',
        cls: 'ordered-status-width text-center',
        isTruncated: true,
        sortable: true,
        sortedProperty: 'sorting'
      }, {
        uniqueKey: 'role',
        component: 'table-cell-role',
        title: this.get('intl').t('users.fields.role'),
        key: 'role',
        cls: 'ordered-role-width text-center',
        isTruncated: true,
        sortable: true,
        sortedProperty: 'sorting',
        sortingProperty: 'role.name'
      }, {
        uniqueKey: 'lastLogin',
        component: 'table-cell-date-time',
        title: this.get('intl').t('users.fields.lastLogin'),
        key: 'lastLogin',
        cls: 'text-center',
        isTruncated: true,
        sortable: true,
        sortedProperty: 'sorting'
      }, {
        uniqueKey: 'is2faEnabled',
        component: 'table-cell-checkbox',
        title: this.get('intl').t('users.fields.twoFactorAuth'),
        key: 'is2faEnabled',
        cls: 'text-center',
        isTruncated: false,
        sortable: true,
        sortedProperty: 'sorting',
        isHidden: !this.get('permissionsManager').hasPermission(TWO_FACTOR_AUTH_VIEW_KEY),
        isDisabled: !this.get('permissionsManager').hasPermission(TWO_FACTOR_AUTH_MANAGE_KEY)
      }, {
        uniqueKey: 'actions',
        component: 'table-cell-user-actions',
        title: this.get('intl').t('common.actions'),
        key: 'id',
        cls: 'text-center',
        isTruncated: true
      }];
    }),
    columnsUsersAtRole: Ember.computed('intl.primaryLocale', function () {
      return [{
        uniqueKey: 'login',
        component: 'table-cell-text',
        title: this.get('intl').t('users.fields.userId'),
        key: 'login',
        cls: 'text-center',
        isTruncated: true
      }, {
        uniqueKey: 'email',
        component: 'table-cell-text',
        title: this.get('intl').t('users.fields.email'),
        key: 'email',
        cls: '',
        isTruncated: true
      }, {
        uniqueKey: 'name',
        component: 'table-cell-text',
        title: this.get('intl').t('users.fields.name'),
        key: 'name',
        cls: 'text-center',
        isTruncated: true
      }, {
        uniqueKey: 'status',
        component: 'table-cell-user-status',
        title: this.get('intl').t('users.fields.status'),
        key: 'status',
        cls: 'text-center',
        isTruncated: true
      }, {
        uniqueKey: 'lastLogin',
        component: 'table-cell-date-time',
        title: this.get('intl').t('users.fields.lastLogin'),
        key: 'lastLogin',
        cls: 'text-center',
        isTruncated: true
      }];
    }),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    permissionsManager: Ember.inject.service(),
    tableIdUsers: 'roles-table',
    tableIdRoles: 'users-table',
    tableIdUsersAtRole: 'users-at-role-table',
    filterUsers: null,
    filterRoles: null,
    filterUsersAtRole: null,
    selectedUser: null,
    activeRoleAtRoles: null,
    isEditMode: false,
    isActionInProgress: false,

    hasPermissions: Ember.computed(function () {
      return this.get('permissionsManager').hasPermission(PERMISSIONS_VIEW_KEY);
    }),
    chosenRole: Ember.computed('selectedUser', function () {
      return this.get('selectedUser') && this.get('selectedUser.role') && {
        id: this.get('selectedUser.role.id'),
        name: this.get('selectedUser.role.name')
      };
    }),
    isActiveRoleEditable: Ember.computed('activeRoleAtRoles', function () {
      return this.get('activeRoleAtRoles.isEditable');
    }),
    filteredAccounts: Ember.computed.filter('model.accounts', function (account) {
      return account.get('id') !== null;
    }).property('model.accounts.@each.id'),
    selectingOperationChecker: false,
    checkedItems: Ember.computed('filteredAccounts', 'selectingOperationChecker', function () {
      return this.filteredAccounts.map(function (item) {
        var isSelected = item.is2faEnabled;

        var newItem = Ember.Object.create();
        newItem.set('id', item.get('id'));
        newItem.set('name', item.get('name'));
        newItem.set('isSelected', isSelected);

        return newItem;
      });
    }),
    assignableRoles: Ember.computed.filter('model.roles', function (role) {
      return role.get('id') !== null && role.get('isAssignable');
    }).property('model.roles.@each.id'),
    filteredRoles: Ember.computed.filter('model.roles', function (role) {
      return role.get('id') !== null;
    }).property('model.roles.@each.id'),
    selectedRole: null,
    operationType: null,
    isDialogShown: false,
    isResetOTPDialogShown: false,
    isNewUserShowDialog: false,
    isNewRoleShowDialog: false,
    isRoleAssignmentDialog: false,
    isRoleChangesDialog: false,
    isChangeOTPStatusDialogShown: false,
    validCreateForm: false,
    uniqueLoginError: null,
    uniqueRoleNameError: null,
    actions: {
      toggleDetailsUsersTable: function toggleDetailsUsersTable(user) {
        this.set('selectedUser', user);
      },
      toggleDetailsRolesTable: function toggleDetailsRolesTable(role) {
        var activeRoleAtRoles = this.get('activeRoleAtRoles');
        var activeRole = this.get('store').peekRecord('role', role.get('id'));

        if (activeRoleAtRoles) {
          this.get('activeRoleAtRoles').rollback();
        }

        this.set('activeRoleAtRoles', activeRole);
        this.get('activeRoleAtRoles').startTrack();
        this.set('isEditMode', false);
      }
    }
  });
});