define('backoffice/pods/components/role-name/component', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var roleNameFormat = /[a-zA-Z0-9\-_]+/i;
  var Validations = (0, _emberCpValidations.buildValidations)({
    newRole: [(0, _emberCpValidations.validator)('length', {
      min: 1,
      max: 20,
      messageKey: "roles.validations.name.length"
    }), (0, _emberCpValidations.validator)('format', {
      regex: roleNameFormat,
      messageKey: "roles.validations.name.format"
    })]
  });

  exports.default = Ember.Component.extend(Validations, {
    newRole: null,
    isEditable: true,
    isRoleErrorShown: false,
    uniqueNameError: null,
    hasUniqueNameError: Ember.computed('uniqueNameError', function () {
      return !!this.get('uniqueNameError');
    }),
    actions: {
      setValidationState: function setValidationState() {
        var isFormValid = this.get('validations.attrs.newRole.isValid');
        this.get('setValidationState')(isFormValid);
        this.get('voidUniqueNameError')();
      },
      errorHandler: function errorHandler(inputField) {
        this.set('is' + inputField + 'ErrorShown', !this.get('validations.attrs.new' + inputField + '.isValid'));
      }
    }
  });
});