define('backoffice/pods/components/search-trx-form/component', ['exports', 'backoffice/mixins/errors-handler', 'backoffice/constants/transactions'], function (exports, _errorsHandler, _transactions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var SOURCE_API_CODE = _transactions.default.SOURCE_API_CODE,
      SOURCE_PAYMENTFORM_CODE = _transactions.default.SOURCE_PAYMENTFORM_CODE,
      SOURCE_API_DESCRIPTION = _transactions.default.SOURCE_API_DESCRIPTION,
      SOURCE_PAYMENTFORM_DESCRIPTION = _transactions.default.SOURCE_PAYMENTFORM_DESCRIPTION;


  var typesList = [{ id: 0, name: 'Sale', isSelected: false }, { id: 1, name: 'Rebill', isSelected: false }, { id: 2, name: 'Upsell', isSelected: false }, { id: 5, name: 'Refund', isSelected: false }, { id: 6, name: 'Chargeback', isSelected: false }, { id: 9, name: 'Enrollment Check (3D)', isSelected: false }, { id: 13, name: 'Original Credits', isSelected: false }, { id: 30, name: 'Alternative Payments', isSelected: false }];
  var sourcesList = [{ id: SOURCE_API_CODE, name: SOURCE_API_DESCRIPTION, isSelected: false }, { id: SOURCE_PAYMENTFORM_CODE, name: SOURCE_PAYMENTFORM_DESCRIPTION, isSelected: false }];
  var providersMapping = { 'TEST': 'TEST', 'LIVE': 'LIVE', 'NA': 'N/A' };
  var providersList = ['TEST', 'LIVE', 'N/A'];

  exports.default = Ember.Component.extend(_errorsHandler.default, {
    init: function init() {
      this._super.apply(this, arguments);

      this.typesList = typesList;
      this.sourcesList = sourcesList;
      this.providersMapping = providersMapping;
      this.providersList = providersList;
      this.selectedWebsites = [];
    },

    store: Ember.inject.service('store'),
    notify: Ember.inject.service('notify'),
    model: null,
    intCountries: Ember.computed('model.countries', function () {
      var model = this.get('model.countries');
      var countries = model.map(function (item) {
        return { id: item.get('iso'), name: item.get('name') };
      });
      return countries;
    }),
    statusesList: Ember.computed('model.statuses', function () {
      var model = this.get('model.statuses');
      var statuses = model.filter(function (item) {
        return parseInt(item.get('id')) !== 3;
      });
      return statuses;
    }),
    offersList: Ember.computed('model.websites', 'selectedWebsites', function () {
      var _this = this;

      var selectedWebsites = this.get('selectedWebsites'),
          websites = [];

      if (this.get('selectedWebsites.length')) {
        websites = selectedWebsites.map(function (item) {
          return item.get('id');
        });
      } else {
        var model = this.get('model.websites');
        if (model) {
          websites = model.map(function (item) {
            return item.get('id');
          });
        } else {
          return [];
        }
      }
      return this.get('store').query('short-offer', { websiteId: websites.join(',') }).catch(function (error) {
        _this.handleErrors(error);
      });
    }),

    // Multiselect dropdown default parameters
    dropdownStatusOpen: false,
    dropdownTypeOpen: false,
    dropdownProviderOpen: false,
    dropdownCountryOpen: false,
    dropdownWebsiteOpen: false,
    dropdownOfferOpen: false,
    dropdownSourceOpen: false,
    dropdownCardBrandOpen: false,

    providerLabel: Ember.computed('searchDefault.provider', function () {
      return this.get('providersMapping')[this.get('searchDefault.provider')];
    }),

    createParamsList: function createParamsList(arr) {
      return arr.map(function (el) {
        return el.id;
      }).join();
    },
    addParamToList: function addParamToList(item, list) {
      var paramsList = list.addObject(item);
      return paramsList.join();
    },
    removeParamFromList: function removeParamFromList(item, list) {
      var paramsList = list.removeObject(item);
      return paramsList.join();
    },

    actions: {
      searchTrx: function searchTrx() {
        this.get('searchTrx')();
      },
      resetSearchForm: function resetSearchForm() {
        this.get('resetSearchForm')();
        Ember.$('#main_tab').trigger('click');
      },
      setDateRange: function setDateRange(start, end) {
        this.get('updateSearchParam')('dateFrom', start);
        this.get('updateSearchParam')('dateTo', end);
      },
      cancelDatePicker: function cancelDatePicker() {},
      addTagFilter: function addTagFilter(filter, item, list) {
        this.get('updateSearchParam')(filter, this.addParamToList(item, list));
      },
      removeTagFilter: function removeTagFilter(filter, item, list) {
        this.get('updateSearchParam')(filter, this.removeParamFromList(item, list));
      },
      changeMultiselectFilter: function changeMultiselectFilter(filter, items) {
        if (filter === 'websiteId') {
          this.set('selectedWebsites', items);
        }
        this.get('updateSearchParam')(filter, this.createParamsList(items));
      },
      changeParam: function changeParam(param, value) {
        this.get('updateSearchParam')(param, value);
      },
      selectProvider: function selectProvider(param) {
        var paramIndex = void 0;
        for (var key in this.get('providersMapping')) {
          if (this.get('providersMapping').hasOwnProperty(key) && this.get('providersMapping')[key] === param) {
            paramIndex = key;
          }
        }

        this.get('updateSearchParam')('provider', paramIndex);
      }
    }
  });
});