define('backoffice/models/offer', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany,
      Model = _emberData.default.Model;

  var Validations = (0, _emberCpValidations.buildValidations)({
    name: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      messageKey: 'offers.validations.required'
    }), (0, _emberCpValidations.validator)('length', {
      min: 6,
      max: 30,
      messageKey: 'offers.validations.name.length'
    })],
    // type: [
    //   validator('presence', {
    //     presence: true,
    //     message: 'Required parameter'
    //   }),
    //   validator('belongs-to')],
    rebill: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      messageKey: 'offers.validations.required'
    })
  });

  exports.default = Model.extend(Validations, {
    name: attr('string'),
    creationDate: attr('string'),
    discountOffer: attr('string'),
    duration: attr('string'),
    months: attr('number'),
    days: attr('number'),
    hours: attr('number'),
    minutes: attr('number'),
    idCustomer: attr('number'),
    laststatusmodif: attr('string'),
    offerVisibilityStatusDid: attr(),
    rebill: attr('number'),
    rebillReminderHours: attr('string'),
    status: attr('number'),
    packagesAttached: attr('number'),
    totalMinutes: attr('number'),
    type: belongsTo('offerType', { async: true }),
    prices: hasMany('price', { async: true }),
    translations: hasMany('offerTranslation'),
    typepermission: attr('string'),
    isDynamicPricing: attr('boolean'),
    hasUpsell: attr('boolean'),
    upsellRelatedToOneshot: attr('number'),
    packagesTitles: hasMany('package-title')
  });
});