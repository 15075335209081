define('backoffice/pods/components/edit-role-dialog/component', ['exports', 'ember-intl'], function (exports, _emberIntl) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    filter: null,
    isRoleChangesDialog: false,
    confirmBtnName: (0, _emberIntl.t)('common.yes'),
    cancelBtnName: (0, _emberIntl.t)('common.no'),
    filteredUsers: Ember.computed.filter('users', function (user) {
      return user.get('role.id') === this.get('filter');
    }).property('users', 'filter'),
    actions: {
      cancelOperation: function cancelOperation() {
        this.get('reset')();
      },
      confirm: function confirm() {
        this.get('confirm')();
      }
    }
  });
});