define('backoffice/pods/components/table-fields-choosing/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    settings: Ember.computed('columns', function () {
      var Item = Ember.Object.extend({}),
          columns = this.get('columns'),
          mapped = [];

      columns.forEach(function (item) {
        if (item.isAllowHide === true) {
          mapped.push(Item.create({
            isSelected: item.isShown,
            name: item.title,
            uniqueKey: item.uniqueKey
          }));
        }
      });

      return mapped;
    }),

    classes: Ember.computed('fieldsClasses', function () {
      var val = this.get('fieldsClasses');

      if (val !== null && val !== undefined && val !== "") {
        return val;
      } else {
        return "col-md-4 col-lg-4 col-xl-4 text-left";
      }
    }),

    actions: {
      select: function select(item) {
        item.toggleProperty('isSelected');
      },
      applySelection: function applySelection() {
        var items = this.get('settings');
        this.get('showSelected')(items);
        // close dialog box
        this.$().parent().prev().trigger('click');
      },
      showAll: function showAll(showParam) {
        this.get('settings').forEach(function (item) {
          item.set('isSelected', showParam);
        });
      }
    }
  });
});