define('backoffice/constants/dashboard', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    BOLD_TEXT: ' font-weight: bold;',
    GREEN_TEXT: ' color: green;',
    RED_TEXT: ' color: red;',
    BLACK_TEXT: ' color: black;',
    BORDER_TOP: 'border-top: 1px solid #ECECEC;',
    AVERAGE_ROW_TYPE: 'average',
    NORMAL_ROW_TYPE: 'normal',
    DATE_CELL_TYPE: 'date',
    NUMBER_CELL_TYPE: 'number',
    AVAILABLE_PERIODS: ['day', 'week', 'month'],
    PERIOD_CHANGE_TEXTS: ['14 Days', '14 Weeks', '14 Months'],
    BASE_PERIOD: 'day',
    AVAILABLE_COUNTRY_COUNTS: [5, 10, 20, 30],
    COUNTRY_COUNT_CHANGE_TEXTS: ['Top 5 Countries', 'Top 10 Countries', 'Top 20 Countries', 'Top 30 Countries'],
    BASE_TOP_COUNTRY_COUNT: 5,
    MERCHANT_PERFORMANCE_TABLE_CONFIG: function MERCHANT_PERFORMANCE_TABLE_CONFIG(currency) {
      return [{
        uniqueKey: 'period',
        component: 'table-cell-text',
        title: 'PERIOD',
        cls: 'text-center dashboard-period-text',
        key: 'period',
        isAllowHide: false,
        withTooltip: false,
        isTruncated: true
      }, {
        uniqueKey: 'successfulTransactions',
        component: 'table-cell-text',
        title: 'Successful Transactions',
        key: 'successfulTransactions',
        cls: 'text-center dashboard-successful-trx-text',
        isAllowHide: false,
        isTruncated: true,
        sortable: false
      }, {
        uniqueKey: 'successfulTrxAmount',
        component: 'table-cell-text',
        title: 'Successful ' + currency,
        key: 'successfulTrxAmount',
        cls: 'text-center dashboard-successful-eur-text',
        isAllowHide: true,
        isTruncated: true,
        sortable: false
      }, {
        uniqueKey: 'successRatio',
        component: 'table-cell-text',
        title: 'Successful Ratio',
        key: 'successRatio',
        cls: 'text-center dashboard-success-ratio-text',
        isAllowHide: true,
        isTruncated: true,
        sortable: false
      }, {
        uniqueKey: 'visaChargebackRatio',
        component: 'table-cell-text',
        title: 'Visa CHB Ratio',
        key: 'visaChargebackRatio',
        cls: 'text-center dashboard-visa-chb-ratio-text',
        isAllowHide: true,
        isTruncated: true,
        sortable: false
      }, {
        uniqueKey: 'mcChargebackRatio',
        component: 'table-cell-text',
        title: 'MC CHB Ratio',
        key: 'mcChargebackRatio',
        cls: 'text-center dashboard-mc-chb-ratio-text',
        isAllowHide: true,
        isTruncated: true,
        sortable: false
      }];
    },
    COUNTRY_TABLE_CONFIG: [{
      uniqueKey: 'nameCountry',
      component: 'table-cell-text',
      title: 'Region Name',
      cls: 'text-center',
      key: 'nameCountry',
      isAllowHide: false,
      withTooltip: false,
      isTruncated: true
    }, {
      uniqueKey: 'codeCountry',
      component: 'table-cell-text',
      title: 'Region ID',
      key: 'codeCountry',
      cls: 'text-center',
      isAllowHide: false,
      isTruncated: true,
      sortable: false
    }, {
      uniqueKey: 'hitsCurrent',
      component: 'table-cell-text',
      title: 'Country Period (CP) Hits',
      key: 'hitsCurrent',
      cls: 'text-center',
      isAllowHide: true,
      isTruncated: true,
      sortable: false
    }, {
      uniqueKey: 'hitsCurrentRatio',
      component: 'table-cell-text',
      title: 'CP Region Hits % versus Total',
      key: 'hitsCurrentRatio',
      cls: 'text-center',
      isAllowHide: true,
      isTruncated: true,
      sortable: false
    }, {
      uniqueKey: 'currentSuccessRatio',
      component: 'table-cell-text',
      title: 'CP Success Ratio',
      key: 'currentSuccessRatio',
      cls: 'text-center',
      isAllowHide: true,
      isTruncated: true,
      sortable: false
    }, {
      uniqueKey: 'hitsPrevRatio',
      component: 'table-cell-text',
      title: 'PP % Tot Vol',
      key: 'hitsPrevRatio',
      cls: 'text-center',
      isAllowHide: true,
      isTruncated: true,
      sortable: false
    }, {
      uniqueKey: 'hitsPrev',
      component: 'table-cell-text',
      title: 'PP PAid',
      key: 'hitsPrev',
      cls: 'text-center',
      isAllowHide: true,
      isTruncated: true,
      sortable: false
    }, {
      uniqueKey: 'previousSuccessRatio',
      component: 'table-cell-text',
      title: 'PP Conv %',
      key: 'previousSuccessRatio',
      cls: 'text-center',
      isAllowHide: true,
      isTruncated: true,
      sortable: false
    }],
    CONVERSION_SUMMARY_TABLE_CONFIG: [{
      uniqueKey: 'intervalNumber',
      component: 'table-cell-text',
      title: 'Interval Number',
      cls: 'text-center display-none',
      key: 'intervalNumber',
      isAllowHide: true,
      withTooltip: false,
      isTruncated: true
    }, {
      uniqueKey: 'period',
      component: 'table-cell-text',
      title: 'Period',
      cls: 'text-center',
      key: 'period',
      isAllowHide: true,
      withTooltip: false,
      isTruncated: true
    }, {
      uniqueKey: 'processedTransactions',
      component: 'table-cell-text',
      title: 'Hits',
      key: 'processedTransactions',
      cls: 'text-center dashboard-successful-trx-text',
      isAllowHide: false,
      isTruncated: true,
      sortable: false
    }, {
      uniqueKey: 'successRatio',
      component: 'table-cell-text',
      title: 'Paid %',
      key: 'successRatio',
      cls: 'text-center dashboard-paid-percent',
      isAllowHide: true,
      isTruncated: true,
      sortable: false
    }, {
      uniqueKey: 'techErrorsRatio',
      component: 'table-cell-text',
      title: 'Tech %',
      key: 'techErrorsRatio',
      cls: 'text-center dashboard-tech-percent',
      isAllowHide: true,
      isTruncated: true,
      sortable: false
    }, {
      uniqueKey: 'riskErrorsRatio',
      component: 'table-cell-text',
      title: 'Risk %',
      key: 'riskErrorsRatio',
      cls: 'text-center dashboard-risk-perecent',
      isAllowHide: true,
      isTruncated: true,
      sortable: false
    }, {
      uniqueKey: 'bankErrorsRatio',
      component: 'table-cell-text',
      title: 'Bank %',
      key: 'bankErrorsRatio',
      cls: 'text-center dashboard-bank-perecent',
      isAllowHide: true,
      isTruncated: true,
      sortable: false
    }],
    TRX_TYPES_SUMMARY_TABLE_CONFIG: [{
      uniqueKey: 'intervalNumber',
      component: 'table-cell-text',
      title: 'Interval Number',
      cls: 'text-center display-none',
      key: 'intervalNumber',
      isAllowHide: true,
      withTooltip: false,
      isTruncated: true
    }, {
      uniqueKey: 'period',
      component: 'table-cell-text',
      title: 'Period',
      cls: 'text-center',
      key: 'period',
      isAllowHide: false,
      withTooltip: false,
      isTruncated: true
    }, {
      uniqueKey: 'successfulSales',
      component: 'table-cell-text',
      title: 'Sales',
      key: 'successfulSales',
      cls: 'text-center dashboard-sales',
      isAllowHide: false,
      isTruncated: true,
      sortable: false
    }, {
      uniqueKey: 'rateSales',
      component: 'table-cell-text',
      title: 'Sales %',
      key: 'rateSales',
      cls: 'text-center dashboard-sales',
      isAllowHide: true,
      isTruncated: true,
      sortable: false
    }, {
      uniqueKey: 'successfulRebills',
      component: 'table-cell-text',
      title: 'Recurrings',
      key: 'successfulRebills',
      cls: 'text-center dashboard-rebills',
      isAllowHide: true,
      isTruncated: true,
      sortable: false
    }, {
      uniqueKey: 'rateRebill',
      component: 'table-cell-text',
      title: 'Recurrings %',
      key: 'rateRebill',
      cls: 'text-center dashboard-rebills',
      isAllowHide: true,
      isTruncated: true,
      sortable: false
    }]
  };
});