define('backoffice/pods/components/generation-dialog/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    type: null,
    isDialogShown: false,
    isDetailsShown: false,
    dialogTitle: Ember.computed('type', function () {
      return 'Do you confirm Automatic ' + this.get('type') + ' Generation?';
    }),
    detailsSwitcher: 'More details',
    detailsFirst: Ember.computed('type', function () {
      var typedString = this.get('type') === 'username' ? 'that usernames are automatically generated. Your' : 'automatic generation of password your';
      return 'You have selected ' + typedString + ' users will be sent an e-mail reminding them of their ' + this.get('type') + 's.';
    }),
    detailsSecond: Ember.computed('type', function () {
      return 'Be aware that if a user makes a mistake or enters a false email address they will not receive the\n     confirmation email with their ' + this.get('type') + '. If a user forgets their ' + this.get('type') + ' they will not be\n      able to access the members\' area of the site.';
    }),
    detailsThird: Ember.computed('type', function () {
      return 'Allowing the user to choose their own ' + this.get('type') + ' results in a higher likelihood that they will not\n    have login issues related to forgotten or lost automatically generated ' + this.get('type') + 's. Furthermore, in the\n    unlikely event of charge disputes the ' + this.get('type') + ' can frequently be used as evidence that they willingly\n    created their subscription. You can select to let the user choose their ' + this.get('type') + ' on our payment form.\n    Alternatively, you can select that you will obtain the ' + this.get('type') + ' on your website and send it to us via the\n    link to the payment form.\n    Do you confirm Automatic ' + this.get('type') + ' Generation?';
    }),
    actions: {
      confirm: function confirm() {
        this.get('confirm')(this.get('type'));
      },
      toggleDetails: function toggleDetails() {
        this.set('isDetailsShown', !this.get('isDetailsShown'));
        var detailsSwitcher = this.get('isDetailsShown') ? 'Less details' : 'More details';
        this.set('detailsSwitcher', detailsSwitcher);
      }
    }
  });
});