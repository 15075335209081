define('backoffice/routes/2fa/verification', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    session: Ember.inject.service('session'),
    queryParams: {
      token: { refreshModel: true }
    },
    activate: function activate() {
      Ember.$('body').addClass('gray-bg fixed-footer');
    },
    deactivate: function deactivate() {
      Ember.$('body').removeClass('gray-bg fixed-footer');
    }
  });
});