define('backoffice/transforms/from-cent', ['exports', 'ember-data', 'numeral'], function (exports, _emberData, _numeral) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      var val = parseFloat(serialized);
      return (0, _numeral.default)(val / 100).format('0.00[00]');
    },
    serialize: function serialize(deserialized) {
      return deserialized * 100;
    }
  });
});