define('backoffice/pods/components/offer-settings-duration/component', ['exports', 'ember-intl', 'backoffice/constants/offers', 'ember-cp-validations'], function (exports, _emberIntl, _offers, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var OFFER_TRIAL_TYPEID = _offers.default.OFFER_TRIAL_TYPEID;


  var Validations = (0, _emberCpValidations.buildValidations)({
    selectedCalendarCount: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      messageKey: "offers.validations.required"
    }),
    selectedCalendar: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      messageKey: "offers.validations.required"
    })
  });

  var calendars = ['day', 'month'];

  exports.default = Ember.Component.extend(Validations, {
    classNames: ['row'],
    title: (0, _emberIntl.t)('offers.fields.duration'),
    calendarPlaceholder: (0, _emberIntl.t)('offers.placeholders.duration'),
    selectedCalendar: null,
    selectedCalendarCount: null,
    isCalendarErrorShown: false,
    isCalendarCountErrorShown: false,
    disabled: false,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.calendars = calendars;

      if (this.get('newOffer.months') > 0) {
        this.set('selectedCalendar', 'month');
        this.set('selectedCalendarCount', this.get('newOffer.months'));
      } else if (this.get('newOffer.days') > 0) {
        this.set('selectedCalendar', 'day');
        this.set('selectedCalendarCount', this.get('newOffer.days'));
      }

      if (this.get('isTrial')) {
        this.set('selectedCalendar', 'day');
      }

      Ember.run.schedule('afterRender', this, function () {
        _this.notifyPropertyChange('selectedCalendar');
        _this.notifyPropertyChange('selectedCalendarCount');
      });
    },

    validationObserver: Ember.observer('selectedCalendar', 'selectedCalendarCount', function () {
      this.set('isDurationValid', this.get('validations.attrs.selectedCalendar.isValid') && this.get('validations.attrs.selectedCalendarCount.isValid'));
    }),
    offerTypeId: Ember.computed('currentOfferType.id', function () {
      return parseInt(this.get('currentOfferType.id') || this.get('preSelectOfferType'));
    }),
    isTrial: Ember.computed('offerTypeId', function () {
      return this.get('offerTypeId') === OFFER_TRIAL_TYPEID;
    }),
    countPlaceholder: Ember.computed('offerTypeId', function () {
      return this.get('offerTypeId') === 2 ? 'Select count of days' : 'Select count of days or months';
    }),
    isTrialObserver: Ember.observer('isTrial', function () {
      if (this.get('isTrial')) {
        this.set('selectedCalendar', 'day');
      }
    }),
    calendarsCount: Ember.computed('selectedCalendar', function () {
      var array = [];
      if (this.get('isTrial')) {
        array = this.get('countDays');
      } else {
        if (this.get('selectedCalendar') === 'day') {
          array = this.get('countDays');
        } else if (this.get('selectedCalendar') === 'month') {
          array = this.get('countMonths');
        } else {
          array = [];
        }
      }

      return array;
    }),
    duration: Ember.observer('selectedCalendarCount', 'selectedCalendar', function () {
      var calendar = this.get('selectedCalendar');

      if (calendar === 'day') {
        this.set('newOffer.days', this.get('selectedCalendarCount'));
        this.set('newOffer.months', 0);
      } else if (calendar === 'month') {
        this.set('newOffer.months', this.get('selectedCalendarCount'));
        this.set('newOffer.days', 0);
      } else {
        this.set('newOffer.months', null);
        this.set('newOffer.days', null);
      }
    }),
    countDays: Ember.computed(function () {
      return this._getNumberArray(30);
    }),
    countMonths: Ember.computed(function () {
      return this._getNumberArray(12);
    }),
    actions: {
      setCalendarCount: function setCalendarCount(count) {
        this.set('selectedCalendarCount', count);
      },
      setCalendar: function setCalendar(period) {
        this.set('selectedCalendar', period);
      },
      showErrors: function showErrors(param) {
        this.set(param, true);
      }
    },
    _getNumberArray: function _getNumberArray(count) {
      var array = [];

      for (var i = 1; i <= count; i++) {
        array.push(i);
      }

      return array;
    }
  });
});