define('backoffice/pods/components/container-ibox/component', ['exports', 'ember-rl-dropdown/mixins/rl-dropdown-component'], function (exports, _rlDropdownComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_rlDropdownComponent.default, {
    classNames: ['ibox'],
    classNameBindings: ['collapsed:border-bottom'],
    collapsed: false,
    dropdownExpanded: false,

    keyUp: function keyUp(event) {
      var ESC_KEY_CODE = 27;
      // close box on ESC key
      if (event.keyCode === ESC_KEY_CODE) {
        this.set('dropdownExpanded', false);
      }
    },


    notCollapsed: Ember.computed('collapsed', function () {
      return !this.get('collapsed');
    }),

    actions: {
      toggleCollapsed: function toggleCollapsed() {
        this.toggleProperty('collapsed');
      },
      toggleSettings: function toggleSettings() {
        this.toggleProperty('dropdownExpanded');
      },
      showSelected: function showSelected(item) {
        this.get('showSelected')(item);
      }
    }
  });
});