define('backoffice/controllers/2fa/reset', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    clientSettings: Ember.inject.service('clientSettings'),
    init: function init() {
      this._super.apply(this, arguments);

      var account = this.get('clientSettings').getAccount();

      if (!account.is2faConfigured) {
        this.transitionToRoute('login');
      }
    },

    actions: {
      onResetTokenSuccessfullyVerified: function onResetTokenSuccessfullyVerified() {
        return this.transitionToRoute('2fa.configuration');
      }
    }
  });
});