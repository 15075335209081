define('backoffice/models/provider', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    transactionUrl: (0, _attr.default)('string'),
    queryUrl: (0, _attr.default)('string'),
    loginUser: (0, _attr.default)('string'),
    loginPassword: (0, _attr.default)('string'),
    invoiceLabel: (0, _attr.default)('number'),
    providerText: (0, _attr.default)('string'),
    transactionClassFilename: (0, _attr.default)('number'),
    responseClassFilename: (0, _attr.default)('string'),
    timeout: (0, _attr.default)('number'),
    test: (0, _attr.default)('boolean'),
    threeDee: (0, _attr.default)('number'),
    ppv: (0, _attr.default)('number'),
    adult: (0, _attr.default)('number'),
    tokenSupport: (0, _attr.default)('number'),
    isDefault: (0, _attr.default)('boolean'),
    providerNameId: (0, _attr.default)('number'),
    sharedTokenId: (0, _attr.default)('string'),
    accountType: (0, _attr.default)('string'),
    mccCode: (0, _attr.default)('string'),
    fpsEnabled: (0, _attr.default)('number'),
    isOctPtEnabled: (0, _attr.default)('number')
  });
});