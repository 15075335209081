define('backoffice/pods/components/dashboard-conv-proc-chart/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    dashboardService: Ember.inject.service('dashboard'),
    intl: Ember.inject.service(),
    processedTransactionsData: Ember.computed('data', function () {
      return this._getData('processedTransactions', this.get('data'));
    }),
    successRatioData: Ember.computed('data', function () {
      var _this = this;

      return this._getData('successRatio', this.get('data')).map(function (value) {
        return _this.get('dashboardService').round(value * 100);
      });
    }),
    techErrorsRatioData: Ember.computed('data', function () {
      var _this2 = this;

      return this._getData('techErrorsRatio', this.get('data')).map(function (value) {
        return _this2.get('dashboardService').round(value * 100);
      });
    }),
    riskErrorsRatioData: Ember.computed('data', function () {
      var _this3 = this;

      return this._getData('riskErrorsRatio', this.get('data')).map(function (value) {
        return _this3.get('dashboardService').round(value * 100);
      });
    }),
    bankErrorsRatioData: Ember.computed('data', function () {
      var _this4 = this;

      return this._getData('bankErrorsRatio', this.get('data')).map(function (value) {
        return _this4.get('dashboardService').round(value * 100);
      });
    }),
    categories: Ember.computed('data', function () {
      if (this.get('data')) {
        return this.get('data').map(function (item) {
          var period = item.get('period');
          var formattedPeriod = typeof period === 'string' ? period.replace(/-/g, "/") : period;
          return new Date(formattedPeriod);
        });
      } else {
        return [];
      }
    }),
    _getData: function _getData(dataType) {
      var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];

      var filtered = data.filter(function (item) {
        return item.get('intervalName') !== 'Total';
      });
      return filtered.map(function (item) {
        var val = item.get(dataType);
        return val === 0 ? 0 : val;
      });
    },

    chartOptions: Ember.computed('data', function () {
      var that = this;
      return {
        chart: {
          height: 300
        },
        legend: {
          align: 'right',
          verticalAlign: 'top',
          title: {
            style: {
              fontWeight: 'normal'
            }
          }
        },
        title: {
          text: null
        },
        loading: {
          labelStyle: {
            color: '#000000',
            fontWeight: 'bold'
          }
        },
        xAxis: {
          labels: {
            type: 'datetime',
            formatter: function formatter() {
              return this.x === 0 ? '' : that.get('dashboardService').formatDate(this.value, that.get('period'));
            }
          },
          categories: this.get('categories')
        },
        yAxis: [{
          title: null,
          labels: {
            enabled: false
          }
        }, {
          title: null,
          labels: {
            enabled: false
          }
        }],
        plotOptions: {
          series: {
            stacking: 'normal',
            groupPadding: 0
          }
        }
      };
    }),
    series: Ember.computed('data', function () {
      var that = this;
      return [{
        name: 'Hits',
        type: 'column',
        data: this.get('processedTransactionsData'),
        color: '#5290e9',
        yAxis: 0,
        dataLabels: {
          enabled: true,
          allowOverlap: true,
          align: 'center',
          color: '#000000',
          style: {
            fontSize: '11px',
            fontWeight: 'bold',
            boxShadow: '0px 0px 15px 10px rgba(255,255,255,1)'
          }
        },
        tooltip: {
          headerFormat: '<span style="font-size: 12px; font-weight: bold;">{series.name}.</span>',
          pointFormatter: function pointFormatter() {
            return '<br>' + that.get('dashboardService').formatDate(this.category, that.get('period')) + ': ' + that.get('intl').formatNumber(this.y);
          }
        }
      }, {
        name: 'Paid Ratio',
        type: 'spline',
        data: this.get('successRatioData'),
        color: '#99dba7',
        visible: false,
        yAxis: 1,
        dashStyle: 'Dot',
        dataLabels: {
          enabled: true,
          allowOverlap: true,
          align: 'center',
          color: '#000000',
          style: {
            fontSize: '11px',
            fontWeight: 'bold',
            boxShadow: '0px 0px 15px 10px rgba(255,255,255,1)'
          },
          formatter: function formatter() {
            return this.y === 0 ? '' : that.get('dashboardService').round(this.y) + '%';
          }
        },
        tooltip: {
          headerFormat: '<span style="font-size: 12px; font-weight: bold;">{series.name}.</span>',
          pointFormatter: function pointFormatter() {
            return '<br>' + that.get('dashboardService').formatDate(this.category, that.get('period')) + ': ' + that.get('intl').formatNumber(this.y) + '%';
          }
        }
      }, {
        name: 'Tech Ratio',
        type: 'spline',
        data: this.get('techErrorsRatioData'),
        color: '#ec932f',
        visible: false,
        yAxis: 1,
        dashStyle: 'Dot',
        dataLabels: {
          enabled: true,
          allowOverlap: true,
          align: 'center',
          color: '#000000',
          style: {
            fontSize: '11px',
            fontWeight: 'bold',
            boxShadow: '0px 0px 15px 10px rgba(255,255,255,1)'
          },
          formatter: function formatter() {
            return this.y === 0 ? '' : that.get('dashboardService').round(this.y) + '%';
          }
        },
        tooltip: {
          headerFormat: '<span style="font-size: 12px; font-weight: bold;">{series.name}.</span>',
          pointFormatter: function pointFormatter() {
            return '<br>' + that.get('dashboardService').formatDate(this.category, that.get('period')) + ': ' + that.get('intl').formatNumber(this.y) + '%';
          }
        }
      }, {
        name: 'Risk Ratio',
        type: 'spline',
        data: this.get('riskErrorsRatioData'),
        color: '#efd140',
        visible: false,
        yAxis: 1,
        dashStyle: 'Dot',
        dataLabels: {
          enabled: true,
          allowOverlap: true,
          align: 'center',
          color: '#000000',
          style: {
            fontSize: '11px',
            fontWeight: 'bold',
            boxShadow: '0px 0px 15px 10px rgba(255,255,255,1)'
          },
          formatter: function formatter() {
            return this.y === 0 ? '' : that.get('dashboardService').round(this.y) + '%';
          }
        },
        tooltip: {
          headerFormat: '<span style="font-size: 12px; font-weight: bold;">{series.name}.</span>',
          pointFormatter: function pointFormatter() {
            return '<br>' + that.get('dashboardService').formatDate(this.category, that.get('period')) + ': ' + that.get('intl').formatNumber(this.y) + '%';
          }
        }
      }, {
        name: 'Bank Ratio',
        type: 'spline',
        data: this.get('bankErrorsRatioData'),
        color: '#e14d57',
        visible: false,
        yAxis: 1,
        dashStyle: 'Dot',
        dataLabels: {
          enabled: true,
          allowOverlap: true,
          align: 'center',
          color: '#000000',
          style: {
            fontSize: '11px',
            fontWeight: 'bold',
            boxShadow: '0px 0px 15px 10px rgba(255,255,255,1)'
          },
          formatter: function formatter() {
            return this.y === 0 ? '' : that.get('dashboardService').round(this.y) + '%';
          }
        },
        tooltip: {
          headerFormat: '<span style="font-size: 12px; font-weight: bold;">{series.name}.</span>',
          pointFormatter: function pointFormatter() {
            return '<br>' + that.get('dashboardService').formatDate(this.category, that.get('period')) + ': ' + that.get('intl').formatNumber(this.y) + '%';
          }
        }
      }];
    })
  });
});