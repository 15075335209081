define('backoffice/constants/subscriptions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    SUBSCRIPTIONS_ON_PAGE_LIST: ['10', '20', '30'],
    DEFAULT_SUBSCRIPTIONS_ON_PAGE: '20',
    DEFAULT_DIALOG_SHOWN: false,
    ACTIVE_SUBSCRIPTION_STATUS: 'Active',
    SUBSCRIPTION_OFFERTYPE: 'Subscription',
    TRIAL_OFFERTYPE: 'Trial'
  };
});