define('backoffice/controllers/admin/support/tickets/edit', ['exports', 'backoffice/config/environment', 'backoffice/constants/support'], function (exports, _environment, _support) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var MESSAGES_ON_PAGE = _support.default.MESSAGES_ON_PAGE;
  exports.default = Ember.Controller.extend({
    files: [],
    session: Ember.inject.service('session'),
    fileCounter: 0,
    isContentError: false,
    isButtonsDisabled: false,
    isStatusChangeRequest: false,
    newMessage: null,
    isUnsentDialogShown: false,
    previousTransition: null,
    baseUrl: Ember.computed(function () {
      return _environment.default.APP.apiHost + '/api/files';
    }),
    accessToken: Ember.computed(function () {
      return this.get('session').get('data.authenticated.token.access_token');
    }),
    currentPage: null,
    pageManuallyChanged: false,
    numberOnPage: MESSAGES_ON_PAGE,
    firstRecord: Ember.computed('pageNumber', function () {
      return this.get('currentPage') * this.get('numberOnPage');
    }),
    lastRecord: Ember.computed('pageNumber', function () {
      return (this.get('currentPage') + 1) * this.get('numberOnPage') - 1;
    }),
    totalPages: Ember.computed('model.messages.length', function () {
      return Math.ceil(this.get('model.messages.length') / this.get('numberOnPage'));
    }),
    pageNumber: Ember.computed('model.messages.length', 'currentPage', function () {
      if (!this.get('currentPage') && !this.get('pageManuallyChanged')) {
        this.set('currentPage', this.get('totalPages') ? this.get('totalPages') - 1 : 0);
        return this.get('totalPages') ? this.get('totalPages') - 1 : 0;
      }

      return this.get('currentPage');
    }),
    messagesRange: Ember.computed('model.messages', 'firstRecord', function () {
      var _this = this;

      return this.get('model.messages').filter(function (message, index /*, array*/) {
        return index >= _this.get('firstRecord') && index <= _this.get('lastRecord');
      });
    }),
    actions: {
      pageChanged: function pageChanged(page) {
        this.set('currentPage', page);
        this.set('pageManuallyChanged', true);
        return true;
      }
    }
  });
});