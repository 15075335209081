define('backoffice/controllers/admin/reports/settlements/search', ['exports', 'moment', 'backoffice/constants/reports'], function (exports, _moment, _reports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var SETTLEMENT_NOTES_ON_PAGE_LIST = _reports.default.SETTLEMENT_NOTES_ON_PAGE_LIST,
      DEFAULT_SETTLEMENT_NOTES_ON_PAGE = _reports.default.DEFAULT_SETTLEMENT_NOTES_ON_PAGE;


  var rowSettings = {
    component: 'table-row-settlements',
    shareModel: false
  };
  var defaultSortedProperty = ['clearingDate:desc'];

  exports.default = Ember.Controller.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.rowSettings = rowSettings;
      this.defaultSortedProperty = defaultSortedProperty;
    },

    columnsSettings: Ember.computed('intl.primaryLocale', function () {
      return [{
        uniqueKey: 'counter',
        component: 'table-cell-counter',
        title: '#',
        cls: 'counter-cell counter-width text-center',
        key: null,
        isAllowHide: false,
        withTooltip: false,
        isTruncated: true
      }, {
        uniqueKey: 'clearingDate',
        component: 'table-cell-date',
        title: this.get('intl').t('reports.fields.clearingDate'),
        key: 'clearingDate',
        cls: 'ordered-settlement-date-width text-center',
        isAllowHide: true,
        isTruncated: true,
        sortable: true,
        sortedProperty: 'sorting'
      }, {
        uniqueKey: 'settlementDate',
        component: 'table-cell-date',
        title: this.get('intl').t('reports.fields.settlementDate'),
        key: 'settlementDate',
        cls: 'ordered-settlement-date-width text-center',
        isAllowHide: true,
        isTruncated: true,
        sortable: true,
        sortedProperty: 'sorting'
      }, {
        uniqueKey: 'date',
        component: 'table-cell-date',
        title: this.get('intl').t('reports.fields.date'),
        key: 'date',
        cls: 'ordered-settlement-date-width text-center',
        isAllowHide: true,
        isTruncated: true,
        sortable: true,
        sortedProperty: 'sorting'
      }, {
        uniqueKey: 'externalId',
        component: 'table-cell-settlement-actions',
        title: this.get('intl').t('reports.fields.settlementId'),
        key: 'externalId',
        cls: 'text-nowrap text-right',
        isAllowHide: true,
        isTruncated: true,
        sortable: false
      }, {
        uniqueKey: 'currency',
        component: 'table-cell-text',
        title: this.get('intl').t('reports.fields.currency'),
        key: 'currency',
        cls: 'ordered-currency-width text-center',
        isAllowHide: true,
        isTruncated: true,
        sortable: true,
        sortedProperty: 'sorting'
      },
      /*{
        uniqueKey: 'currentAmount',
        component: 'table-cell-full-amount',
        title: this.get('intl').t('reports.fields.currentAmount'),
        key: 'currentAmount',
        cls: 'ordered-settlement-amount-width text-right',
        isAllowHide: true,
        isTruncated: true,
        sortable: true,
        sortedProperty: 'sorting',
        currencyProperty: 'currency'
      },
      {
        uniqueKey: 'reserveAmount',
        component: 'table-cell-full-amount',
        title: this.get('intl').t('reports.fields.reserveAmount'),
        key: 'reserveAmount',
        cls: 'ordered-settlement-amount-width text-right',
        isAllowHide: true,
        isTruncated: true,
        sortable: true,
        sortedProperty: 'sorting',
        currencyProperty: 'currency'
      },*/
      {
        uniqueKey: 'totalAmount',
        component: 'table-cell-full-amount',
        title: this.get('intl').t('reports.fields.totalAmount'),
        key: 'totalAmount',
        cls: 'ordered-settlement-amount-width text-right',
        isAllowHide: true,
        isTruncated: true,
        sortable: true,
        sortedProperty: 'sorting',
        currencyProperty: 'currency'
      }, {
        uniqueKey: 'status',
        component: 'table-cell-settlement-status',
        title: this.get('intl').t('reports.fields.status'),
        key: 'status',
        cls: 'ordered-status-width text-center',
        isAllowHide: true,
        isTruncated: true,
        sortable: true,
        sortedProperty: 'sorting'
      }];
    }),
    intl: Ember.inject.service(),
    queryParams: ['dateFrom', 'dateTo', 'status', 'currency', 'page', 'size'],
    dateFrom: _moment.default.utc().startOf('month').format('YYYY-MM-DDTHH:mm:ss'),
    dateTo: (0, _moment.default)().format('YYYY-MM-DDTHH:mm:ss'),
    status: null,
    currency: null,
    page: null,
    size: null,
    tableId: 'table-settlements',
    filter: null,
    selectedSettlement: null,
    entriesOnPage: SETTLEMENT_NOTES_ON_PAGE_LIST,
    defaultEntriesOnPage: DEFAULT_SETTLEMENT_NOTES_ON_PAGE,
    chosenEntriesOnPage: Ember.computed('size', function () {
      return this.get('size') || this.get('defaultEntriesOnPage');
    }),
    columnsStorageSettings: Ember.computed(function () {
      var settingsStorage = this.get('settingsStorage');
      var savedSettings = settingsStorage.get('settlements-columns');
      if (savedSettings) {
        return savedSettings;
      }
      var defaultSettings = this.get('columnsSettings').map(function (item) {
        return {
          uniqueKey: item.uniqueKey,
          isShown: true
        };
      });

      settingsStorage.set('settlements-columns', defaultSettings);
      return defaultSettings;
    }),
    columns: Ember.computed('columnsStorageSettings', 'columnsSettings', function () {
      var columnsStorageSettings = this.get('columnsStorageSettings'),
          columnsSettings = this.get('columnsSettings'),
          settings = void 0;

      settings = columnsSettings.map(function (cSEl) {
        var sameKeyElement = columnsStorageSettings.find(function (cSSEl) {
          return cSSEl.uniqueKey === cSEl.uniqueKey;
        });
        return Ember.merge(cSEl, sameKeyElement);
      });

      return settings;
    }),
    actions: {
      showSelectedColumns: function showSelectedColumns(items) {
        if (!items || !items.length) {
          return;
        }

        var columns = this.get('columns'),
            settings = this.get('columnsStorageSettings');

        if (!settings) {
          return;
        }

        columns.forEach(function (col) {
          items.forEach(function (item) {
            if (item.uniqueKey === col.uniqueKey) {
              var colIndex = settings.map(function (el) {
                return el.uniqueKey;
              }).indexOf(col.uniqueKey),
                  updatedElement = settings.objectAt(colIndex);

              // if element exists at columnsStorageSettings.settings
              if (colIndex >= 0) {
                Ember.set(updatedElement, 'isShown', item.isSelected);
              } else {
                settings.addObject({
                  uniqueKey: col.uniqueKey,
                  isShown: item.isSelected
                });
              }

              Ember.set(col, 'isShown', item.isSelected);
            }
          });
        });

        this.get('columnsStorageSettings').set('settings', settings);
        var settingsStorage = this.get('settingsStorage');
        settingsStorage.set('settlements-columns', settings);
      },
      selectRow: function selectRow(settlement) {
        this.set('selectedSettlement', settlement);
      },
      pageChanged: function pageChanged(page) {
        this.replaceRoute({ queryParams: { page: page } });
        return true;
      },
      chooseEntriesOnPage: function chooseEntriesOnPage(item) {
        this.set('chosenEntriesOnPage', item);
        this.replaceRoute({ queryParams: { size: item, page: null } });
      }
    }
  });
});