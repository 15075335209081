define('backoffice/pods/components/create-role-dialog/component', ['exports', 'ember-intl', 'ember-cp-validations'], function (exports, _emberIntl, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var roleNameFormat = /[a-zA-Z0-9\-_]+/i;
  var Validations = (0, _emberCpValidations.buildValidations)({
    newRole: [(0, _emberCpValidations.validator)('length', {
      min: 1,
      max: 20,
      messageKey: "roles.validations.name.length"
    }), (0, _emberCpValidations.validator)('format', {
      regex: roleNameFormat,
      messageKey: "roles.validations.name.format"
    })]
  });

  exports.default = Ember.Component.extend(Validations, {
    confirmBtnName: (0, _emberIntl.t)("common.save"),
    cancelBtnName: (0, _emberIntl.t)("common.cancel"),
    newRole: null,
    isFormDisabled: true,
    isRoleErrorShown: false,
    uniqueNameError: null,
    willDestroy: function willDestroy() {
      this.set('newRole', null);
    },

    actions: {
      confirm: function confirm() {
        this.get('confirm')({ name: this.get('newRole') });
      },
      confirmNotify: function confirmNotify() {
        this.get('reset')();
      },
      cancelOperation: function cancelOperation() {
        this.get('reset')();
      },
      setValidationState: function setValidationState(isFormValid) {
        this.set('isFormDisabled', !isFormValid);
      },
      errorHandler: function errorHandler(inputField) {
        this.set('is' + inputField + 'ErrorShown', !this.get('validations.attrs.new' + inputField + '.isValid'));
      }
    }
  });
});