define('backoffice/helpers/camel-case', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.camelCase = camelCase;

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  function camelCase(param) {
    if (typeof param[0] !== 'string' || _typeof(param[1]) && param[1].length !== 1) {
      throw new TypeError('camel-case helper error: no valid parameters type');
    }

    var newString = '';
    param[0].split(param[1]).forEach(function (item, index) {
      if (index === 0) {
        newString += item;
      } else {
        newString += item[0].toUpperCase() + item.substr(1);
      }
    });

    return newString;
  }

  exports.default = Ember.Helper.helper(camelCase);
});