define('backoffice/services/dashboard', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    intl: Ember.inject.service(),
    formatDate: function formatDate(date, period) {
      switch (period) {
        case 'day':
          {
            return date ? (0, _moment.default)(date).format("DD/MM/YY") : '';
          }
        case 'week':
          {
            return date ? (0, _moment.default)(date).format("w'DD/YY") : '';
          }
        case 'month':
          {
            return date ? (0, _moment.default)(date).format("MMM'YY") : '';
          }
        default:
          {
            return date ? (0, _moment.default)(date).format("DD/MM/YY") : '';
          }
      }
    },
    round: function round(number) {
      return +number.toFixed(2);
    },
    formatPercent: function formatPercent(value) {
      return value + '%';
    },
    formatCurrency: function formatCurrency(value, intlService) {
      return '' + intlService.formatNumber(value);
    }
  });
});