define('backoffice/pods/components/table-cell-source/component', ['exports', 'backoffice/constants/transactions'], function (exports, _transactions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var SOURCE_API_CODE = _transactions.default.SOURCE_API_CODE,
      SOURCE_PAYMENTFORM_CODE = _transactions.default.SOURCE_PAYMENTFORM_CODE,
      SOURCE_API_DESCRIPTION = _transactions.default.SOURCE_API_DESCRIPTION,
      SOURCE_PAYMENTFORM_DESCRIPTION = _transactions.default.SOURCE_PAYMENTFORM_DESCRIPTION;
  exports.default = Ember.Component.extend({
    tagName: 'td',
    index: null,
    data: null,
    payMethod: Ember.computed('data', function () {
      if (this.get('data') === SOURCE_API_CODE) {
        return SOURCE_API_DESCRIPTION;
      }

      if (this.get('data') === SOURCE_PAYMENTFORM_CODE) {
        return SOURCE_PAYMENTFORM_DESCRIPTION;
      }

      return null;
    }),
    click: function click() {
      if (this.get('isTruncated')) {
        this.toggleProperty('collapsed');
        if (this.get('toggleDetails')) {
          this.get('toggleDetails')({ collapsed: this.get('collapsed') });
        }
      }
    }
  });
});