define('backoffice/pods/components/menu-toggle-button/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'a',
    classNames: ['sidebar-toggle'],
    attributeBindings: ['href', 'role', 'dataToggle'],
    href: '#',
    role: 'button',
    dataToggle: 'offcanvas'
  });
});