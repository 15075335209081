define('backoffice/pods/components/forecast-wrapper/component', ['exports', 'backoffice/mixins/errors-handler'], function (exports, _errorsHandler) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var groupByVariants = ['Website', 'Settlement Currency', 'Acquirer'];

  exports.default = Ember.Component.extend(_errorsHandler.default, {
    init: function init() {
      this._super.apply(this, arguments);

      this.groupByVariants = groupByVariants;
    },

    columnsSettingsSummary: Ember.computed('intl.primaryLocale', function () {
      return [{
        uniqueKey: 'entity',
        component: 'table-cell-text',
        title: this.get('intl').t('forecast.fields.entity'),
        cls: 'nowrap-row text-center',
        key: 'entity',
        isAllowHide: false,
        withTooltip: false,
        isTruncated: true,
        sortable: true,
        sortedProperty: 'sorting'
      }, {
        uniqueKey: 'grossAmount',
        component: 'table-cell-full-amount',
        title: this.get('intl').t('forecast.fields.grossAmount'),
        key: 'grossAmount',
        cls: 'ordered-gross-width text-right',
        type: 'icon-cell',
        isAllowHide: false,
        withTooltip: false,
        isTruncated: true,
        sortable: true,
        sortedProperty: 'sorting'
      }, {
        uniqueKey: 'chargebacksAmount',
        component: 'table-cell-full-amount',
        title: this.get('intl').t('forecast.fields.chargebacksAmount'),
        cls: 'ordered-chargebacks-width text-right',
        key: 'chargebacksAmount',
        isAllowHide: true,
        withTooltip: false,
        isTruncated: true,
        sortable: true,
        sortedProperty: 'sorting'
      }, {
        uniqueKey: 'refundsAmount',
        component: 'table-cell-full-amount',
        title: this.get('intl').t('forecast.fields.refundsAmount'),
        key: 'refundsAmount',
        cls: 'ordered-refunds-width text-right',
        isAllowHide: true,
        withTooltip: false,
        isTruncated: true,
        sortable: true,
        sortedProperty: 'sorting'
      }, {
        uniqueKey: 'sentPayoutsAmount',
        component: 'table-cell-full-amount',
        title: this.get('intl').t('forecast.fields.sentPayoutsAmount'),
        key: 'sentPayoutsAmount',
        cls: 'ordered-sent-payouts-width text-right',
        isAllowHide: true,
        withTooltip: false,
        isTruncated: true,
        sortable: true,
        sortedProperty: 'sorting'
      }, {
        uniqueKey: 'netAmount',
        component: 'table-cell-full-amount',
        title: this.get('intl').t('forecast.fields.netAmount'),
        key: 'netAmount',
        cls: 'ordered-net-width text-right',
        isAllowHide: true,
        withTooltip: false,
        isTruncated: true,
        sortable: true,
        sortedProperty: 'sorting'
      }, {
        uniqueKey: 'processingFeesAmount',
        component: 'table-cell-full-amount',
        title: this.get('intl').t('forecast.fields.processingFeesAmount'),
        cls: 'ordered-processing-fees-width text-right',
        key: 'processingFeesAmount',
        isAllowHide: true,
        withTooltip: false,
        isTruncated: true,
        sortable: true,
        sortedProperty: 'sorting'
      }, {
        uniqueKey: 'chbFeesAmount',
        component: 'table-cell-full-amount',
        title: this.get('intl').t('forecast.fields.chbFeesAmount'),
        cls: 'ordered-chb-fees-width text-right',
        key: 'chbFeesAmount',
        isAllowHide: true,
        withTooltip: false,
        isTruncated: true,
        sortable: true,
        sortedProperty: 'sorting'
      }, {
        uniqueKey: 'refundFeesAmount',
        component: 'table-cell-full-amount',
        title: this.get('intl').t('forecast.fields.refundFeesAmount'),
        cls: 'ordered-refund-fees-width text-right',
        key: 'refundFeesAmount',
        isAllowHide: true,
        withTooltip: false,
        isTruncated: true,
        sortable: true,
        sortedProperty: 'sorting'
      }, {
        uniqueKey: 'payoutsFeesAmount',
        component: 'table-cell-full-amount',
        title: this.get('intl').t('forecast.fields.payoutsFeesAmount'),
        cls: 'ordered-payout-fees-width text-right',
        key: 'payoutsFeesAmount',
        isAllowHide: true,
        withTooltip: false,
        isTruncated: true,
        sortable: true,
        sortedProperty: 'sorting'
      }, {
        uniqueKey: 'reserves',
        component: 'table-cell-full-amount',
        title: this.get('intl').t('forecast.fields.reserves'),
        cls: 'ordered-kept-reserves-width text-right',
        key: 'reserves',
        isAllowHide: true,
        withTooltip: false,
        isTruncated: true,
        sortable: true,
        sortedProperty: 'sorting'
      }, {
        uniqueKey: 'reservesRls',
        component: 'table-cell-full-amount',
        title: this.get('intl').t('forecast.fields.reservesRls'),
        cls: 'ordered-released-reserves-width text-right',
        key: 'reservesRls',
        isAllowHide: true,
        withTooltip: false,
        isTruncated: true,
        sortable: true,
        sortedProperty: 'sorting'
      }, {
        uniqueKey: 'estimatedTotalAmount',
        component: 'table-cell-full-amount',
        title: this.get('intl').t('forecast.fields.estimatedTotalAmount'),
        cls: 'ordered-est-total-width text-right',
        key: 'estimatedTotalAmount',
        isAllowHide: true,
        withTooltip: false,
        isTruncated: true,
        sortable: true,
        sortedProperty: 'sorting'
      }];
    }),
    columnsSettingsDetail: Ember.computed('intl.primaryLocale', function () {
      return [{
        uniqueKey: 'date',
        component: 'table-cell-date',
        title: this.get('intl').t('forecast.fields.date'),
        cls: 'text-center',
        key: 'date',
        isAllowHide: false,
        withTooltip: false,
        isTruncated: true,
        sortable: true,
        sortedProperty: 'sorting'
      }, {
        uniqueKey: 'entity',
        component: 'table-cell-text',
        title: this.get('intl').t('forecast.fields.entity'),
        cls: 'nowrap-row text-center',
        key: 'entity',
        isAllowHide: false,
        withTooltip: false,
        isTruncated: true,
        sortable: true,
        sortedProperty: 'sorting'
      }, {
        uniqueKey: 'grossAmount',
        component: 'table-cell-full-amount',
        title: this.get('intl').t('forecast.fields.grossAmount'),
        key: 'grossAmount',
        cls: 'ordered-gross-width text-right',
        type: 'icon-cell',
        isAllowHide: true,
        withTooltip: false,
        isTruncated: true,
        sortable: true,
        sortedProperty: 'sorting'
      }, {
        uniqueKey: 'chargebacksAmount',
        component: 'table-cell-full-amount',
        title: this.get('intl').t('forecast.fields.chargebacksAmount'),
        cls: 'ordered-chargebacks-width text-right',
        key: 'chargebacksAmount',
        isAllowHide: true,
        withTooltip: false,
        isTruncated: true,
        sortable: true,
        sortedProperty: 'sorting'
      }, {
        uniqueKey: 'refundsAmount',
        component: 'table-cell-full-amount',
        title: this.get('intl').t('forecast.fields.refundsAmount'),
        key: 'refundsAmount',
        cls: 'ordered-refunds-width text-right',
        isAllowHide: true,
        withTooltip: false,
        isTruncated: true,
        sortable: true,
        sortedProperty: 'sorting'
      }, {
        uniqueKey: 'sentPayoutsAmount',
        component: 'table-cell-full-amount',
        title: this.get('intl').t('forecast.fields.sentPayoutsAmount'),
        key: 'sentPayoutsAmount',
        cls: 'ordered-sent-payouts-width text-right',
        isAllowHide: true,
        withTooltip: false,
        isTruncated: true,
        sortable: true,
        sortedProperty: 'sorting'
      }, {
        uniqueKey: 'netAmount',
        component: 'table-cell-full-amount',
        title: this.get('intl').t('forecast.fields.netAmount'),
        key: 'netAmount',
        cls: 'ordered-net-width text-right',
        isAllowHide: true,
        withTooltip: false,
        isTruncated: true,
        sortable: true,
        sortedProperty: 'sorting'
      }, {
        uniqueKey: 'processingFeesAmount',
        component: 'table-cell-full-amount',
        title: this.get('intl').t('forecast.fields.processingFeesAmount'),
        cls: 'ordered-processing-fees-width text-right',
        key: 'processingFeesAmount',
        isAllowHide: true,
        withTooltip: false,
        isTruncated: true,
        sortable: true,
        sortedProperty: 'sorting'
      }, {
        uniqueKey: 'chbFeesAmount',
        component: 'table-cell-full-amount',
        title: this.get('intl').t('forecast.fields.chbFeesAmount'),
        cls: 'ordered-chb-fees-width text-right',
        key: 'chbFeesAmount',
        isAllowHide: true,
        withTooltip: false,
        isTruncated: true,
        sortable: true,
        sortedProperty: 'sorting'
      }, {
        uniqueKey: 'refundFeesAmount',
        component: 'table-cell-full-amount',
        title: this.get('intl').t('forecast.fields.refundFeesAmount'),
        cls: 'ordered-refund-fees-width text-right',
        key: 'refundFeesAmount',
        isAllowHide: true,
        withTooltip: false,
        isTruncated: true,
        sortable: true,
        sortedProperty: 'sorting'
      }, {
        uniqueKey: 'payoutsFeesAmount',
        component: 'table-cell-full-amount',
        title: this.get('intl').t('forecast.fields.payoutsFeesAmount'),
        cls: 'ordered-payout-fees-width text-right',
        key: 'payoutsFeesAmount',
        isAllowHide: true,
        withTooltip: false,
        isTruncated: true,
        sortable: true,
        sortedProperty: 'sorting'
      }, {
        uniqueKey: 'reserves',
        component: 'table-cell-full-amount',
        title: this.get('intl').t('forecast.fields.reserves'),
        cls: 'ordered-kept-reserves-width text-right',
        key: 'reserves',
        isAllowHide: true,
        withTooltip: false,
        isTruncated: true,
        sortable: true,
        sortedProperty: 'sorting'
      }, {
        uniqueKey: 'reservesRls',
        component: 'table-cell-full-amount',
        title: this.get('intl').t('forecast.fields.reservesRls'),
        cls: 'ordered-released-reserves-width text-right',
        key: 'reservesRls',
        isAllowHide: true,
        withTooltip: false,
        isTruncated: true,
        sortable: true,
        sortedProperty: 'sorting'
      }, {
        uniqueKey: 'estimatedTotalAmount',
        component: 'table-cell-full-amount',
        title: this.get('intl').t('forecast.fields.estimatedTotalAmount'),
        cls: 'ordered-est-total-width text-right',
        key: 'estimatedTotalAmount',
        isAllowHide: true,
        withTooltip: false,
        isTruncated: true,
        sortable: true,
        sortedProperty: 'sorting'
      }];
    }),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    permissionsManager: Ember.inject.service(),
    tagName: '',
    isLoading: false,
    summaryData: Ember.computed('model.[]', function () {
      var model = this.get('model');
      var result = void 0;

      if (model !== null && model !== undefined) {
        result = model.forecastSummary.map(function (item) {
          return {
            entity: item.get('entityName') + ' ' + item.get('settlementCurrency'),
            grossAmount: item.get('grossAmount'),
            chargebacksAmount: item.get('chargebacksAmount'),
            refundsAmount: item.get('refundsAmount'),
            sentPayoutsAmount: item.get('sentPayoutsAmount'),
            netAmount: item.get('netAmount'),
            processingFeesAmount: item.get('processingFeesAmount'),
            chbFeesAmount: item.get('chbFeesAmount'),
            refundFeesAmount: item.get('refundFeesAmount'),
            payoutsFeesAmount: item.get('payoutsFeesAmount'),
            reserves: item.get('reserves'),
            reservesRls: item.get('reservesRls'),
            estimatedTotalAmount: item.get('estimatedTotalAmount')
          };
        });
      } else {
        result = [];
      }

      return result;
    }),
    detailData: Ember.computed('model.[]', function () {
      var model = this.get('model');
      var result = void 0;

      if (model !== null && model !== undefined) {
        result = model.forecastDetail.map(function (item) {
          return {
            date: item.get('date'),
            entity: item.get('entityName') + ' ' + item.get('settlementCurrency'),
            grossAmount: item.get('grossAmount'),
            chargebacksAmount: item.get('chargebacksAmount'),
            refundsAmount: item.get('refundsAmount'),
            sentPayoutsAmount: item.get('sentPayoutsAmount'),
            netAmount: item.get('netAmount'),
            processingFeesAmount: item.get('processingFeesAmount'),
            chbFeesAmount: item.get('chbFeesAmount'),
            refundFeesAmount: item.get('refundFeesAmount'),
            payoutsFeesAmount: item.get('payoutsFeesAmount'),
            reserves: item.get('reserves'),
            reservesRls: item.get('reservesRls'),
            estimatedTotalAmount: item.get('estimatedTotalAmount')
          };
        });
      } else {
        result = [];
      }

      return result;
    }),
    columnsSummary: Ember.computed('columnsSummaryStorageSettings', 'columnsSettingsSummary', function () {
      var columnsStorageSettings = void 0,
          columnsSettings = void 0,
          settings = void 0;
      columnsSettings = this.get('columnsSettingsSummary');
      columnsStorageSettings = this.get('columnsSummaryStorageSettings');

      settings = columnsSettings.map(function (cSEl) {
        var sameKeyElement = columnsStorageSettings.find(function (cSSEl) {
          return cSSEl.uniqueKey === cSEl.uniqueKey;
        });
        return Ember.merge(cSEl, sameKeyElement);
      });

      return settings;
    }),
    columnsDetail: Ember.computed('columnsDetailStorageSettings', 'columnsSettingsDetail', function () {
      var columnsStorageSettings = void 0,
          columnsSettings = void 0,
          settings = void 0;
      columnsSettings = this.get('columnsSettingsDetail');
      columnsStorageSettings = this.get('columnsDetailStorageSettings');

      settings = columnsSettings.map(function (cSEl) {
        var sameKeyElement = columnsStorageSettings.find(function (cSSEl) {
          return cSSEl.uniqueKey === cSEl.uniqueKey;
        });
        return Ember.merge(cSEl, sameKeyElement);
      });

      return settings;
    }),
    columnsSummaryStorageSettings: Ember.computed(function () {
      var settingsStorage = this.get('settingsStorage');
      var savedSettings = settingsStorage.get('forecast-summary-viewer-columns');

      if (savedSettings) {
        return savedSettings;
      }
      var defaultSettings = this.get('columnsSettingsSummary').map(function (item) {
        return {
          uniqueKey: item.uniqueKey,
          isShown: true
        };
      });

      settingsStorage.set('forecast-summary-viewer-columns', defaultSettings);
      return defaultSettings;
    }),
    columnsDetailStorageSettings: Ember.computed(function () {
      var settingsStorage = this.get('settingsStorage');
      var savedSettings = settingsStorage.get('forecast-detail-viewer-columns');

      if (savedSettings) {
        return savedSettings;
      }
      var defaultSettings = this.get('columnsSettingsDetail').map(function (item) {
        return {
          uniqueKey: item.uniqueKey,
          isShown: true
        };
      });

      settingsStorage.set('forecast-detail-viewer-columns', defaultSettings);
      return defaultSettings;
    }),
    actions: {
      searchAction: function searchAction(param) {
        var _this = this;

        var store = this.get('store');
        var summaryParams = Object.assign({ type: 'summary' }, param);
        var detailParams = Object.assign({ type: 'detail' }, param);

        this.set('isLoading', true);
        Ember.RSVP.hash({
          forecastSummary: store.query('forecast', summaryParams),
          forecastDetail: store.query('forecast', detailParams)
        }).then(function (data) {
          _this.set('model', data);
          _this.set('isLoading', false);
        }, function (error) {
          _this.handleErrors(error);
          _this.set('isLoading', false);
        });
      },
      showSelectedColumns: function showSelectedColumns(param) {
        var items = param.items,
            type = param.type;


        if (!items || !items.length) {
          return;
        }

        var columns = type === 'summary' ? this.get('columnsSummary') : this.get('columnsDetail'),
            settings = type === 'summary' ? this.get('columnsSummaryStorageSettings') : this.get('columnsDetailStorageSettings');

        if (!settings) {
          return;
        }

        columns.forEach(function (col) {
          items.forEach(function (item) {
            if (item.uniqueKey === col.uniqueKey) {
              var colIndex = settings.map(function (el) {
                return el.uniqueKey;
              }).indexOf(col.uniqueKey),
                  updatedElement = settings.objectAt(colIndex);

              // if element exists at columnsStorageSettings.settings
              if (colIndex >= 0) {
                Ember.set(updatedElement, 'isShown', item.isSelected);
              } else {
                settings.addObject({
                  uniqueKey: col.uniqueKey,
                  isShown: item.isSelected
                });
              }

              Ember.set(col, 'isShown', item.isSelected);
            }
          });
        });
        var settingsStorage = this.get('settingsStorage');
        if (type === 'summary') {
          this.get('columnsSummaryStorageSettings').set('settings', settings);
          settingsStorage.set('forecast-summary-viewer-columns', settings);
        } else {
          this.get('columnsDetailStorageSettings').set('settings', settings);
          settingsStorage.set('forecast-detail-viewer-columns', settings);
        }
      }
    }
  });
});