define('backoffice/models/country-performance', ['exports', 'ember-data', 'ember-data/attr'], function (exports, _emberData, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    reportTypeCode: (0, _attr.default)("string"),
    customer: (0, _attr.default)("number"),
    codeCountry: (0, _attr.default)("string"),
    nameCountry: (0, _attr.default)("string"),
    countryRank: (0, _attr.default)("number"),
    hitsCurrent: (0, _attr.default)("number"),
    hitsCurrentRatio: (0, _attr.default)("number"),
    currentSuccessRatio: (0, _attr.default)("number"),
    hitsPrev: (0, _attr.default)("number"),
    hitsPrevRatio: (0, _attr.default)("number"),
    previousSuccessRatio: (0, _attr.default)("number"),
    timeZone: (0, _attr.default)("string")
  });
});