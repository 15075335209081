define('backoffice/pods/components/table-cell-checkbox/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    row: null,
    data: null,
    index: null,
    tagName: 'td',
    collapsed: null,
    checkedItems: null,
    checkedItem: Ember.computed('row', 'checkedItems', function () {
      return Array.isArray(this.get('checkedItems')) && this.get('checkedItems').findBy('id', this.get('row.id'));
    }),
    disabled: Ember.computed('isCheckboxDisabled', 'isDisabled', function () {
      return this.isDisabled || this.isCheckboxDisabled;
    }),
    isDisabled: null,
    isCheckboxDisabled: null,
    click: function click() {},

    actions: {
      select: function select(item) {
        item.toggleProperty('isSelected');

        var onSelectFn = this.get('onSelect');
        if (typeof onSelectFn === "function") {
          onSelectFn(item, 'cell-checkbox');
        }

        var updateListFn = this.get('updateList');
        if (typeof updateListFn === "function") {
          updateListFn(item);
        }
      }
    }
  });
});