define('backoffice/pods/components/dropdown-button/component', ['exports', 'ember-rl-dropdown/mixins/rl-dropdown-component'], function (exports, _rlDropdownComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_rlDropdownComponent.default, {
    classNames: ['rl-dropdown-toggle'],
    textHeader: null,
    dropdownExpanded: false,
    keyUp: function keyUp(event) {
      // close box on ESC key
      if (event.keyCode === 27) {
        this.set('dropdownExpanded', false);
      }
    },

    actions: {
      toggleSettings: function toggleSettings() {
        this.toggleProperty('dropdownExpanded');
      }
    }
  });
});