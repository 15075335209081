define('backoffice/pods/components/table-cell-member-actions/component', ['exports', 'backoffice/constants/permissions'], function (exports, _permissions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var MEMBERSHIPS_CANCEL_KEY = _permissions.default.MEMBERSHIPS_CANCEL_KEY;
  exports.default = Ember.Component.extend({
    tagName: 'td',
    permissionsManager: Ember.inject.service(),
    hasActions: Ember.computed('row', function () {
      return this.get('row.status') === 'Active' && this.get('permissionsManager').hasPermission(MEMBERSHIPS_CANCEL_KEY);
    })
  });
});