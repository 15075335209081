define('backoffice/pods/components/offer-settings-translations-flags/component', ['exports', 'backoffice/constants/offers'], function (exports, _offers) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var PATH_TO_FLAGS = _offers.default.PATH_TO_FLAGS;
  exports.default = Ember.Component.extend({
    classNames: ['col-sm-3', 'col-xs-3', 'col-md-3', 'col-lg-3', 'offer-translations-langs'],
    path: PATH_TO_FLAGS,
    actions: {
      setFlag: function setFlag(lang) {
        this.set('lang', lang);
      }
    }
  });
});