define('backoffice/helpers/round-number', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.roundNumber = roundNumber;
  function roundNumber(params /*, hash*/) {
    var number = params[0],
        accuracy = params[1];

    return Math.round(number * 10 * accuracy / (10 * accuracy));
  }

  exports.default = Ember.Helper.helper(roundNumber);
});