define('backoffice/models/payout-document', ['exports', 'ember-data/model', 'ember-data/attr', 'backoffice/config/environment'], function (exports, _model, _attr, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    datetime: (0, _attr.default)('date'),
    filename: (0, _attr.default)('string'),
    linesProcessed: (0, _attr.default)('string'),
    linesOrder: (0, _attr.default)('string'),
    amountProcessed: (0, _attr.default)('string'),
    amountOrder: (0, _attr.default)('string'),
    statusName: (0, _attr.default)('string'),
    statusId: (0, _attr.default)('number'),
    reportUrl: Ember.computed('statusName', 'statusId', function () {
      return _environment.default.APP.apiHost + '/api/payoutReport?access_token=' + this.get('session').get('data.authenticated.token.access_token') + '&documentId=' + this.get('id');
    }),
    session: Ember.inject.service('session'),
    command: (0, _attr.default)('string')
  });
});