define('backoffice/controllers/admin/reports/invoices/search', ['exports', 'moment', 'backoffice/constants/reports'], function (exports, _moment, _reports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var INVOICES_ON_PAGE_LIST = _reports.default.INVOICES_ON_PAGE_LIST,
      DEFAULT_INVOICES_ON_PAGE = _reports.default.DEFAULT_INVOICES_ON_PAGE;

  var rowSettings = {
    component: 'table-row-invoices',
    shareModel: false
  };
  var defaultSortedProperty = ['periodTo:desc'];

  exports.default = Ember.Controller.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.rowSettings = rowSettings;
      this.defaultSortedProperty = defaultSortedProperty;
    },

    columnsSettings: Ember.computed('intl.primaryLocale', function () {
      return [{
        uniqueKey: 'counter',
        component: 'table-cell-counter',
        title: '#',
        cls: 'counter-cell counter-width text-center',
        key: null,
        isAllowHide: false,
        withTooltip: false,
        isTruncated: true
      }, {
        uniqueKey: 'billingPeriod',
        component: 'table-cell-period',
        title: this.get('intl').t('reports.fields.billingPeriod'),
        key: 'id',
        cls: 'ordered-period-width text-center',
        isAllowHide: true,
        isTruncated: true,
        sortable: true,
        sortedProperty: 'sorting',
        sortingProperty: 'periodTo'
      }, {
        uniqueKey: 'date',
        component: 'table-cell-date',
        title: this.get('intl').t('reports.fields.date'),
        key: 'date',
        cls: 'ordered-date-width text-center',
        isAllowHide: true,
        isTruncated: true,
        sortable: true,
        sortedProperty: 'sorting'
      }, {
        uniqueKey: 'documentTypeTitle',
        component: 'table-cell-text',
        title: this.get('intl').t('reports.fields.type'),
        key: 'documentTypeTitle',
        cls: 'ordered-invoice-type-width text-center',
        isAllowHide: true,
        isTruncated: true,
        sortable: true,
        sortedProperty: 'sorting'
      }, {
        uniqueKey: 'accountId',
        component: 'table-cell-text',
        title: this.get('intl').t('reports.fields.invoiceNumber'),
        key: 'accountId',
        cls: 'text-nowrap text-right',
        isAllowHide: true,
        isTruncated: true,
        sortable: false
      }, {
        uniqueKey: 'externalId',
        component: 'table-cell-invoice-actions',
        title: this.get('intl').t('reports.fields.statementId'),
        key: 'externalId',
        cls: 'text-nowrap text-right',
        isAllowHide: true,
        isTruncated: true,
        sortable: false
      }, {
        uniqueKey: 'settlementCurrency',
        component: 'table-cell-text',
        title: this.get('intl').t('reports.fields.currency'),
        key: 'settlementCurrency',
        cls: 'ordered-currency-width text-center',
        isAllowHide: true,
        isTruncated: true,
        sortable: true,
        sortedProperty: 'sorting'
      }, {
        uniqueKey: 'totalNetTurnover',
        component: 'table-cell-full-amount',
        title: this.get('intl').t('reports.fields.totalNetTurnover'),
        key: 'totalNetTurnover',
        cls: 'ordered-invoice-amount-width text-right',
        isAllowHide: true,
        isTruncated: true,
        sortable: true,
        sortedProperty: 'sorting',
        currencyProperty: 'settlementCurrency'
      }, {
        uniqueKey: 'totalServiceFees',
        component: 'table-cell-full-amount',
        title: this.get('intl').t('reports.fields.totalFees'),
        key: 'totalServiceFees',
        cls: 'ordered-invoice-amount-width text-right',
        isAllowHide: true,
        isTruncated: true,
        sortable: true,
        sortedProperty: 'sorting',
        currencyProperty: 'settlementCurrency'
      }, {
        uniqueKey: 'reserveAmount',
        component: 'table-cell-full-amount',
        title: this.get('intl').t('reports.fields.reserveAmount'),
        key: 'reserveAmount',
        cls: 'ordered-invoice-amount-width text-right',
        isAllowHide: true,
        isTruncated: true,
        sortable: true,
        sortedProperty: 'sorting',
        currencyProperty: 'settlementCurrency'
      }, {
        uniqueKey: 'payoutAmount',
        component: 'table-cell-full-amount',
        title: this.get('intl').t('reports.fields.payoutAmount'),
        key: 'payoutAmount',
        cls: 'ordered-invoice-amount-width text-right',
        isAllowHide: true,
        isTruncated: true,
        sortable: true,
        sortedProperty: 'sorting',
        currencyProperty: 'settlementCurrency'
      }, {
        uniqueKey: 'status',
        component: 'table-cell-invoice-status',
        title: this.get('intl').t('reports.fields.status'),
        key: 'status',
        cls: 'ordered-invoice-status-width text-center',
        isAllowHide: true,
        isTruncated: true,
        sortable: true,
        sortedProperty: 'sorting'
      }, {
        uniqueKey: 'flag',
        component: 'table-cell-invoice-credited',
        title: this.get('intl').t('reports.fields.note'),
        key: 'id',
        cls: 'flag-width text-center',
        isAllowHide: true,
        isTruncated: true,
        sortable: false
      }, {
        uniqueKey: 'settlementDate',
        component: 'table-cell-date',
        title: this.get('intl').t('reports.fields.settlementDate'),
        key: 'settlementDate',
        cls: 'ordered-settlement-width text-center',
        isAllowHide: true,
        isTruncated: true,
        sortable: true,
        sortedProperty: 'sorting'
      }, {
        uniqueKey: 'settlementNoteId',
        component: 'table-cell-text',
        title: this.get('intl').t('reports.fields.settlementNoteId'),
        key: 'settlementNoteId',
        cls: 'text-nowrap text-center',
        isAllowHide: true,
        isTruncated: true,
        sortable: false
      }, {
        uniqueKey: 'extRefId',
        component: 'table-cell-text',
        title: this.get('intl').t('reports.fields.refId'),
        key: 'extRefId',
        cls: 'text-nowrap text-center',
        isAllowHide: true,
        isTruncated: true,
        sortable: false
      }];
    }),
    intl: Ember.inject.service(),
    queryParams: ['dateFrom', 'dateTo', 'status', 'currency', 'page', 'size'],
    dateFrom: _moment.default.utc().startOf('month').format('YYYY-MM-DDTHH:mm:ss'),
    dateTo: (0, _moment.default)().format('YYYY-MM-DDTHH:mm:ss'),
    status: null,
    currency: null,
    page: null,
    size: null,
    tableId: 'table-invoices',
    filter: null,
    selectedInvoice: null,
    entriesOnPage: INVOICES_ON_PAGE_LIST,
    defaultEntriesOnPage: DEFAULT_INVOICES_ON_PAGE,
    chosenEntriesOnPage: Ember.computed('size', function () {
      return this.get('size') || this.get('defaultEntriesOnPage');
    }),
    columnsStorageSettings: Ember.computed(function () {
      var settingsStorage = this.get('settingsStorage');
      var savedSettings = settingsStorage.get('invoices-columns');
      if (savedSettings) {
        return savedSettings;
      }
      var defaultSettings = this.get('columnsSettings').map(function (item) {
        return {
          uniqueKey: item.uniqueKey,
          isShown: true
        };
      });

      settingsStorage.set('invoices-columns', defaultSettings);
      return defaultSettings;
    }),
    columns: Ember.computed('columnsStorageSettings', 'columnsSettings', function () {
      var columnsStorageSettings = this.get('columnsStorageSettings'),
          columnsSettings = this.get('columnsSettings'),
          settings = void 0;

      settings = columnsSettings.map(function (cSEl) {
        var sameKeyElement = columnsStorageSettings.find(function (cSSEl) {
          return cSSEl.uniqueKey === cSEl.uniqueKey;
        });
        return Ember.merge(cSEl, sameKeyElement);
      });

      return settings;
    }),
    actions: {
      showSelectedColumns: function showSelectedColumns(items) {
        if (!items || !items.length) {
          return;
        }

        var columns = this.get('columns'),
            settings = this.get('columnsStorageSettings');

        if (!settings) {
          return;
        }

        columns.forEach(function (col) {
          items.forEach(function (item) {
            if (item.uniqueKey === col.uniqueKey) {
              var colIndex = settings.map(function (el) {
                return el.uniqueKey;
              }).indexOf(col.uniqueKey),
                  updatedElement = settings.objectAt(colIndex);

              // if element exists at columnsStorageSettings.settings
              if (colIndex >= 0) {
                Ember.set(updatedElement, 'isShown', item.isSelected);
              } else {
                settings.addObject({
                  uniqueKey: col.uniqueKey,
                  isShown: item.isSelected
                });
              }

              Ember.set(col, 'isShown', item.isSelected);
            }
          });
        });

        this.get('columnsStorageSettings').set('settings', settings);
        var settingsStorage = this.get('settingsStorage');
        settingsStorage.set('invoices-columns', settings);
      },
      selectRow: function selectRow(invoice) {
        this.set('selectedInvoice', invoice);
      },
      pageChanged: function pageChanged(page) {
        this.replaceRoute({ queryParams: { page: page } });
        return true;
      },
      chooseEntriesOnPage: function chooseEntriesOnPage(item) {
        this.set('chosenEntriesOnPage', item);
        this.replaceRoute({ queryParams: { size: item, page: null } });
      }
    }
  });
});