define('backoffice/routes/admin/transactions/search', ['exports', 'backoffice/mixins/admin-route-mixin', 'ember-simple-auth/mixins/authenticated-route-mixin', 'backoffice/mixins/errors-handler', 'backoffice/config/environment', 'moment', 'backoffice/constants/requests', 'backoffice/constants/subscriptions'], function (exports, _adminRouteMixin, _authenticatedRouteMixin, _errorsHandler, _environment, _moment, _requests, _subscriptions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var CLOSE_NOTIFY_DELAY = _requests.default.CLOSE_NOTIFY_DELAY;
  var ACTIVE_SUBSCRIPTION_STATUS = _subscriptions.default.ACTIVE_SUBSCRIPTION_STATUS;
  exports.default = Ember.Route.extend(_adminRouteMixin.default, _authenticatedRouteMixin.default, _errorsHandler.default, {
    notify: Ember.inject.service('notify'),
    store: Ember.inject.service(),
    togglesInit: Ember.inject.service('toggles-init'),
    init: function init() {
      this._super.apply(this, arguments);
      this.title = this.get('intl').t('transactions.breadcrumb');
    },

    queryParams: {
      dateFrom: { refreshModel: true },
      dateTo: { refreshModel: true },
      status: { refreshModel: true },
      transactionType: { refreshModel: true },
      isoCountry: { refreshModel: true },
      transactionId: { refreshModel: true },
      referredId: { refreshModel: true },
      provider: { refreshModel: true },
      ip: { refreshModel: true },
      websiteId: { refreshModel: true },
      offer: { refreshModel: true },
      source: { refreshModel: true },
      ccNumber: { refreshModel: true },
      ccLastSixDigits: { refreshModel: true },
      cardHolder: { refreshModel: true },
      cardBrand: { refreshModel: true },
      email: { refreshModel: true },
      userName: { refreshModel: true },
      password: { refreshModel: true },
      usernameType: { refreshModel: true },
      page: { refreshModel: true },
      ipParam: { refreshModel: true },
      cardNumberParam: { refreshModel: true },
      cardHolderParam: { refreshModel: true },
      emailParam: { refreshModel: true },
      usernameParam: { refreshModel: true },
      passwordParam: { refreshModel: true },
      rrn: { refreshModel: true },
      size: { refreshModel: true },
      payoutId: { refreshModel: true }
    },
    model: function model(params) {
      var populatedParams = {};

      for (var key in params) {
        if (params.hasOwnProperty(key) && params[key] != null && params[key] !== '') {
          if (key === 'dateFrom') {
            populatedParams.dateFrom = params.dateFrom || this.controllerFor('admin.transactions').get('searchParams.dateFrom');
          } else if (key === 'dateTo') {
            populatedParams.dateTo = params.dateTo || this.controllerFor('admin.transactions').get('searchParams.dateTo');
          } else {
            populatedParams[key] = params[key];
          }
        }
      }

      this.controllerFor('admin.transactions').set('searchParams', this.paramsFor('admin.transactions.search'));

      this.get('togglesInit').initToggles(this.controllerFor('admin.transactions'));

      return Ember.RSVP.hash({
        transactions: this.store.query('transaction', populatedParams),
        refundReasons: this.store.findAll('refund-reason')
      });
    },
    resetController: function resetController(controller) {
      var queryParams = controller.get('queryParams');

      queryParams.forEach(function (param) {
        if (param === 'dateFrom') {
          controller.set('dateFrom', _moment.default.utc().startOf('month').format('YYYY-MM-DDTHH:mm:ss'));
        } else if (param === 'dateTo') {
          controller.set('dateTo', (0, _moment.default)().endOf('day').format('YYYY-MM-DDTHH:mm:ss'));
        } else {
          controller.set(param, null);
        }
      });
    },
    changeTransaction: function changeTransaction(transactionId, property, value) {
      var record = this.store.peekRecord('transaction', transactionId);

      if (record !== null) {
        record.set(property, value);
      }
    },

    actions: {
      error: function error(_error, transition) {
        var _this = this;

        this.handleErrors(_error);
        transition.catch(function () {
          _this.transitionTo('admin.transactions');
        });
      },
      columnLinkClicked: function columnLinkClicked(column, index, data /*, row*/) {
        if (column.key === 'id' || column.key === 'referedTransaction') {
          this.transitionTo({ queryParams: { transactionId: data, page: null } });
          Ember.$('a[href="#tab_2"]').click();
        }
      },
      openDialog: function openDialog(transactionDetails) {
        var _this2 = this;

        if (!this.controller.get('isDialogShown')) {
          this.controller.set('isDialogShown', true);
          this.controller.set('loadingState', true);
        }

        if (!this.controller.get('selectedTransaction')) {
          this.controller.set('selectedTransaction', {
            transactionId: transactionDetails.get('id'),
            hasBeenPaid: transactionDetails.get('hasBeenPaid'),
            currency: transactionDetails.get('currency'),
            hasBeenCancelled: transactionDetails.get('hasBeenCancelled'),
            amount: transactionDetails.get('amount'),
            refundedAmount: transactionDetails.get('refundedAmount')
          });
        }

        this.store.query('subscription', { transactionIDs: transactionDetails.get('id') }).then(function (response) {
          if (response.get('length') && response.objectAt(0).get('status') === ACTIVE_SUBSCRIPTION_STATUS) {
            var cancelItem = Ember.Object.create();
            cancelItem.set('id', response.objectAt(0).get('id'));
            cancelItem.set('name', 'Cancel also associated subscription');
            cancelItem.set('isSelected', true);
            _this2.controller.set('selectedTransaction.cancelItem', cancelItem);
            _this2.controller.set('selectedTransaction.status', response.objectAt(0).get('status'));
          }

          _this2.controller.set('loadingState', false);
        }, function (error) {
          _this2.handleErrors(error);
        });
      },
      openResendCallbackDialog: function openResendCallbackDialog(transactionDetails) {
        if (!this.controller.get('isResendCallbackDialogShown')) {
          this.controller.set('isResendCallbackDialogShown', true);
        }
        if (!this.controller.get('selectedTransaction')) {
          this.controller.set('selectedTransaction', {
            transactionId: transactionDetails.get('id')
          });
        }
      },
      closeDialog: function closeDialog() {
        if (this.controller.get('isDialogShown')) {
          this.controller.set('isDialogShown', false);
        }

        if (this.controller.get('isResendCallbackDialogShown')) {
          this.controller.set('isResendCallbackDialogShown', false);
        }

        if (this.controller.get('selectedTransaction')) {
          this.controller.set('selectedTransaction', null);
        }

        this.controller.set('loadingState', false);
      },
      refund: function refund(params) {
        var _this3 = this;

        Ember.$.ajax({
          url: _environment.default.APP.apiHost + '/api/transactions/refund',
          headers: {
            Authorization: 'Bearer ' + this.get('session').get('data.authenticated.token.access_token')
          },
          data: {
            transactionId: params.transactionId,
            reason: params.reason,
            amount: params.amount
          },
          type: 'GET',
          dataType: 'json'
        }).then(function (response) {
          if (response.success) {
            _this3.get('notify').success(_this3.get('intl').t('transactions.refundResultSuccess', { id: response.id, message: response.message }), { closeAfter: CLOSE_NOTIFY_DELAY });
            _this3.changeTransaction(params.transactionId, 'hasBeenRefunded', true);

            var record = _this3.store.peekRecord('transaction', params.transactionId);
            if (record !== null) {
              var tempAmount = record.get('refundedAmount') ? parseFloat(record.get('refundedAmount')) + params.amount : params.amount;
              record.set('refundedAmount', tempAmount);
            }

            if (params.subscriptionId) {
              Ember.$.ajax({
                url: _environment.default.APP.apiHost + '/api/subscriptions/cancel',
                headers: {
                  Authorization: 'Bearer ' + _this3.get('session').get('data.authenticated.token.access_token')
                },
                data: {
                  id: params.subscriptionId,
                  reason: params.reason
                },
                type: 'GET',
                dataType: 'json'
              }).then(function (response) {
                if (response.success) {
                  _this3.get('notify').success(_this3.get('intl').t('subscriptions.cancelResultSuccess', { id: response.id }), {
                    closeAfter: CLOSE_NOTIFY_DELAY
                  });
                } else {
                  _this3.get('notify').alert(_this3.get('intl').t('subscriptions.cancelResult', { id: response.id, message: response.message, reason: response.reason }), {
                    closeAfter: CLOSE_NOTIFY_DELAY
                  });
                }
              }, function (error) {
                _this3.handleErrors(error);
              });
            }
          } else {
            _this3.get('notify').alert(_this3.get('intl').t('trsansactions.refundResult', { id: response.id, message: response.message, reason: response.reason }), { closeAfter: CLOSE_NOTIFY_DELAY });
          }
        }, function (error) {
          _this3.get('notify').error(JSON.parse(error.responseText).errors.msg, { closeAfter: CLOSE_NOTIFY_DELAY });
        });

        this.send('closeDialog');
      },
      cancelTrx: function cancelTrx(transactionId, reason) {
        var _this4 = this;

        Ember.$.ajax({
          url: _environment.default.APP.apiHost + '/api/transactions/cancel',
          headers: {
            Authorization: 'Bearer ' + this.get('session').get('data.authenticated.token.access_token')
          },
          data: {
            transactionId: transactionId,
            reason: reason.id
          },
          type: 'GET',
          dataType: 'json'
        }).then(function (response) {
          if (response.success) {
            _this4.get('notify').success(_this4.get('intl').t('transactions.cancelResultSuccess', { id: response.id }), { closeAfter: CLOSE_NOTIFY_DELAY });
            _this4.changeTransaction(transactionId, 'hasBeenCancelled', true);
          } else {
            _this4.get('notify').alert(_this4.get('intl').t('transactions.cancelResult', { id: response.id, message: response.message, reason: response.reason }), { closeAfter: CLOSE_NOTIFY_DELAY });
          }
        }, function (error) {
          _this4.get('notify').error(JSON.parse(error.responseText).errors.msg, { closeAfter: CLOSE_NOTIFY_DELAY });
        });

        this.send('closeDialog');
      },
      resendCallback: function resendCallback(transactionId) {
        var _this5 = this;

        Ember.$.ajax({
          url: _environment.default.APP.apiHost + '/api/transactions/resendCallback?transactionId=' + transactionId,
          headers: {
            Authorization: 'Bearer ' + this.get('session').get('data.authenticated.token.access_token')
          },
          type: 'GET'
        }).then(function (response) {
          if (response.success) {
            _this5.get('notify').success(_this5.get('intl').t('transactions.resendCallbackResultSuccess', { id: transactionId }), { closeAfter: CLOSE_NOTIFY_DELAY });
          } else {
            _this5.get('notify').alert(_this5.get('intl').t('transactions.resendCallbackResult', { id: transactionId, reason: response.reason }), { closeAfter: CLOSE_NOTIFY_DELAY });
          }
        }, function (error) {
          _this5.get('notify').error(JSON.parse(error.responseText).errors.msg, { closeAfter: CLOSE_NOTIFY_DELAY });
        });

        this.send('closeDialog');
      },
      updateCancelItem: function updateCancelItem(item) {
        this.controller.set('selectedTransaction.cancelItem.isSelected', item.get('isSelected'));
      }
    }
  });
});