define('backoffice/models/transaction', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    transactionType: (0, _attr.default)('string'),
    originalTransactionType: (0, _attr.default)('string'),
    status: (0, _attr.default)('string'),
    reason: (0, _attr.default)('string'),
    amount: (0, _attr.default)('number'),
    currency: (0, _attr.default)('string'),
    referedTransaction: (0, _attr.default)('number'),
    ip: (0, _attr.default)('string'),
    customerId: (0, _attr.default)('number'),
    customerWebsiteId: (0, _attr.default)('number'),
    offerId: (0, _attr.default)('number'),
    optionalParam1: (0, _attr.default)('string'),
    optionalParam2: (0, _attr.default)('string'),
    optionalParam3: (0, _attr.default)('string'),
    wlServiceUrl: (0, _attr.default)('string'),
    wlServiceName: (0, _attr.default)('string'),
    isTest: (0, _attr.default)('string'),
    providerId: (0, _attr.default)('number'),
    providerReference: (0, _attr.default)('string'),
    providerAuthCode: (0, _attr.default)('string'),
    panMasked: (0, _attr.default)('string'),
    flowId: (0, _attr.default)('string'),
    binCountry: (0, _attr.default)('string'),
    cardHolder: (0, _attr.default)('string'),
    userName: (0, _attr.default)('string'),
    userPassword: (0, _attr.default)('string'),
    email: (0, _attr.default)('string'),
    city: (0, _attr.default)('string'),
    country: (0, _attr.default)('string'),
    street: (0, _attr.default)('string'),
    zip: (0, _attr.default)('number'),
    refundedAmount: (0, _attr.default)('number'),
    state: (0, _attr.default)('string'),
    errorCode: (0, _attr.default)('string'),
    errorText: (0, _attr.default)('string'),
    hasBeenPaid: (0, _attr.default)('boolean'),
    hasBeenRefunded: (0, _attr.default)('boolean'),
    hasBeenCancelled: (0, _attr.default)('boolean'),
    hasBeenChargedback: (0, _attr.default)('boolean'),
    apiSource: (0, _attr.default)('string'),
    date: (0, _attr.default)('string'),
    ipCountryIso: (0, _attr.default)('string'),
    successful: (0, _attr.default)('boolean'),
    secure3DProcessed: (0, _attr.default)('boolean'),
    ccBrand: (0, _attr.default)('string'),
    numberId: Ember.computed('id', function () {
      return +this.get('id');
    }),
    paymentId: (0, _attr.default)('string'),
    refundId: (0, _attr.default)('string'),
    payoutId: (0, _attr.default)('string'),
    externalId: (0, _attr.default)('string'),
    orderId: (0, _attr.default)('string'),
    orderType: (0, _attr.default)('string'),
    rrn: (0, _attr.default)('string'),
    op1: (0, _attr.default)('string'),
    op2: (0, _attr.default)('string'),
    op3: (0, _attr.default)('string'),
    paymentMethod: (0, _attr.default)('string'),
    businessStatus: (0, _attr.default)('string')
  });
});