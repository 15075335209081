define('backoffice/pods/components/website-settings/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var callbackTypes = ['XML', 'String'];
  var callbackMethods = ['GET', 'POST'];
  var callbackTypesMapping = { '0': 'String', '1': 'XML' };

  exports.default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.callbackTypes = callbackTypes;
      this.callbackMethods = callbackMethods;
      this.callbackTypesMapping = callbackTypesMapping;
      this.sortDefinition = ['id:desc'];
    },

    item: null,
    packagesList: null,
    selectedPackage: null,
    isNameErrorShown: false,
    isPackagesErrorShown: false,
    isDefaultPackageErrorShown: false,
    isPublicUrlErrorShown: false,
    isMemberUrlErrorShown: false,
    isAlternateBillingErrorShown: false,
    isCallbackUrlErrorShown: false,
    isGenerateUserErrorShown: false,
    isCallbackMethodErrorShown: false,
    isGeneratePasswordErrorShown: false,
    isSaveBtnBlocked: false,

    packagesSortedList: Ember.computed.sort('packagesList', 'sortDefinition'),
    selectedPackageOffers: Ember.computed('selectedPackage', function () {
      return !!this.get('selectedPackage.offers.length');
    }),
    checkedItems: Ember.computed('item.packages', 'packagesList', function () {
      var _this = this;

      var list = this.get('packagesList').map(function (item) {
        var isSelected = false;

        if (_this.get('item.packages.length')) {
          _this.get('item.packages').forEach(function (itemInWebsite) {
            if (item.get('id') === itemInWebsite.get('id')) {
              isSelected = true;
            }
          });
        }

        var newItem = Ember.Object.create();
        newItem.set('id', item.get('id'));
        newItem.set('name', item.get('name'));
        newItem.set('isSelected', isSelected);

        return newItem;
      });

      return list;
    }),
    groupValue: Ember.computed('item.defaultPackageId', function () {
      return this.get('item.defaultPackageId');
    }),
    callbackType: Ember.computed('item.callbackType', function () {
      for (var key in this.get('callbackTypesMapping')) {
        if (this.get('callbackTypesMapping').hasOwnProperty(key) && this.get('item.callbackType') === key) {
          return this.get('callbackTypesMapping')[key];
        }
      }

      return 'XML';
    }),
    callbackMethod: Ember.computed('item.callbackMethod', function () {
      return this.get('item.callbackMethod');
    }),
    showError: function showError(param) {
      this.set(param, true);
    },

    actions: {
      selectCallbackMethod: function selectCallbackMethod(param) {
        this.set('item.callbackMethod', param);
      },
      toggleDetailsTable: function toggleDetailsTable(pack) {
        this.get('toggleDetailsTable')(pack);
      },
      select: function select(item) {
        this.set('item.defaultPackageId', item);
      },
      updatePackagesList: function updatePackagesList(item) {
        if (item.get('isSelected')) {
          var el = this.get('packagesList').findBy('id', item.get('id'));
          if (!el) {
            return;
          }
          this.get('item.packages').pushObject(el);
        } else {
          var _el = this.get('item.packages').findBy('id', item.get('id'));

          if (!_el) {
            return;
          }

          if (item.get('id') === this.get('item.defaultPackageId')) {
            this.set('item.defaultPackageId', null);
          }
          this.get('item.packages').removeObject(_el);
        }
      },
      showErrors: function showErrors(param) {
        this.showError(param);
      },
      save: function save(website) {
        if (!website.get('validations.isValid')) {
          this.set('isNameErrorShown', true);
          this.set('isPackagesErrorShown', true);
          this.set('isDefaultPackageErrorShown', true);
          this.set('isPublicUrlErrorShown', true);
          this.set('isMemberUrlErrorShown', true);
          this.set('isAlternateBillingErrorShown', true);
          this.set('isCallbackUrlErrorShown', true);
          this.set('isCallbackMethodErrorShown', true);
          return;
        }

        this.set('isSaveBtnBlocked', true);
        this.get('save')(website);
      },
      deselect: function deselect() {
        this.get('deselect')();
      }
    }
  });
});