define('backoffice/pods/components/table-cell-member-offertype/component', ['exports', 'ember-cli-tooltipster/components/tool-tipster'], function (exports, _toolTipster) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _toolTipster.default.extend({
    tagName: 'td',
    index: null,
    data: null,
    row: null,
    collapsed: null
  });
});