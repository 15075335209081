define('backoffice/mixins/widget-route-mixin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    intl: Ember.inject.service('intl'),
    session: Ember.inject.service('session'),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.get('intl').on('localeChanged', function () {
        if (_this.widgetReady) {
          window.location.reload();
        }
      });
    },

    widgetReady: false,
    actions: {
      makeWidgetReady: function makeWidgetReady() {
        this.widgetReady = true;
      }
    },
    beforeModel: function beforeModel(transition) {
      this._super(transition);
      if (!this.get('session.isAuthenticated')) {
        return;
      }

      var widgetName = this.get('widgetName');
      if (!this.widgetReady) {
        Ember.run.scheduleOnce('afterRender', this, function () {
          $.getScript('/widgets/api/loader.js?placement=mbo&widget=' + widgetName).done(function () {
            window.document.dispatchEvent(new Event("DOMContentLoaded", {
              bubbles: true,
              cancelable: true
            }));
          });
        });
      }
    }
  });
});