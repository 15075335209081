define('backoffice/controllers/2fa/configuration', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    showRecoveryCodes: false,
    recoveryCodes: [],
    actions: {
      on2FARegistrationSuccess: function on2FARegistrationSuccess(codes) {
        this.transitionToRoute('2fa.recovery' /*, {queryParams: {codes}}*/).then(function (route) {
          route.controller.set('recoveryCodes', codes);
        });
      },
      onQRCodeGenerationError: function onQRCodeGenerationError(error) {
        if (error.status === 400) {
          this.transitionToRoute('login');
        }
      }
    }
  });
});