define('backoffice/pods/components/chb-ratio-chart/component', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    intl: Ember.inject.service(),
    data: Ember.computed('merchantSummary', function () {
      var data = [],
          cardType = this.get('cardType');

      if (this.get('merchantSummary')) {
        this.get('merchantSummary').forEach(function (item) {
          if (cardType === 'VISA') {
            data.push(parseFloat(item.get('visaCHBRatio') || 0));
          } else if (cardType === 'Mastercard') {
            data.push(parseFloat(item.get('mcCHBRatio') || 0));
          }
        });
      }

      return data;
    }),
    categories: Ember.computed('merchantSummary', function () {
      var categories = [];

      if (this.get('merchantSummary')) {
        this.get('merchantSummary').forEach(function (item) {
          categories.push((0, _moment.default)(item.get('month'), 'YYYY-MM-DD').format("MMM'YY"));
        });
      }

      return categories;
    }),
    chartOptions: Ember.computed('merchantSummary', function () {
      return {
        chart: {
          type: 'column',
          height: 150
        },
        legend: {
          align: 'right',
          verticalAlign: 'top',
          symbolHeight: 10,
          symbolWidth: 12,
          symbolRadius: 0,
          title: {
            style: {
              fontWeight: 'normal'
            }
          }
        },
        title: {
          text: null,
          align: 'right',
          style: {
            color: '#4A4A4A',
            fontSize: '13px',
            fontWeight: 'normal',
            fill: '#4A4A4A'
          },
          useHTML: true
        },
        loading: {
          labelStyle: {
            color: '#000000',
            fontWeight: 'bold'
          }
        },
        xAxis: {
          type: 'datetime',
          categories: this.get('categories')
        },
        yAxis: {
          labels: {
            enabled: false
          },
          title: {
            text: null
          }
        }
      };
    }),
    series: Ember.computed('merchantSummary', function () {
      return [{
        name: 'CHB Ratio (' + this.get('cardType') + ')',
        color: '#ff9f7f',
        data: this.get('data'),
        dataLabels: {
          enabled: true,
          formatter: function formatter() {
            return this.point.y + '%';
          },

          align: 'center',
          color: '#000000',
          style: {
            color: 'contrast',
            fontSize: '11px',
            fontWeight: 'normal',
            textOutline: '0px 0px contrast'
          },
          y: -20
        },
        tooltip: {
          headerFormat: '<span style="font-size: 12px; font-weight: bold;">{series.name}.</span><br/>',
          pointFormatter: function pointFormatter() {
            return this.category + ': ' + this.get('intl').formatNumber(this.y) + '%';
          }
        }
      }];
    })
  });
});