define('backoffice/models/customer-ip-restriction', ['exports', 'ember-data', 'ember-data/attr'], function (exports, _emberData, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    customerId: (0, _attr.default)('number'),
    ipRestriction: (0, _attr.default)('boolean'),
    ipWhitelist: (0, _attr.default)()
  });
});