define('backoffice/pods/components/has-any-access/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    permissionsManager: Ember.inject.service(),
    hasPermission: Ember.computed('permission', function () {
      return this.get('permissionsManager').hasPermission(this.get('permission'), true);
    })
  });
});