define('backoffice/routes/application', ['exports', 'ember-simple-auth/mixins/application-route-mixin', 'backoffice/mixins/admin-route-mixin', 'backoffice/config/environment', 'backoffice/constants/storage'], function (exports, _applicationRouteMixin, _adminRouteMixin, _environment, _storage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var SETTINGS_STORAGE_VERSION = _storage.default.SETTINGS_STORAGE_VERSION;
  exports.default = Ember.Route.extend(_applicationRouteMixin.default, _adminRouteMixin.default, {
    intl: Ember.inject.service('intl'),
    beforeModel: function beforeModel() {
      var settingsStorage = this.get('settingsStorage');
      var settingsVersion = this.get('settingsVersion');
      var savedVersion = settingsVersion.get('settings-version');

      if (savedVersion !== SETTINGS_STORAGE_VERSION) {
        settingsStorage.set('trx-viewer-columns', null);
        settingsStorage.set('forecast-summary-viewer-columns', null);
        settingsStorage.set('forecast-detail-viewer-columns', null);
        settingsStorage.set('mbrs-viewer-columns', null);
        settingsStorage.set('invoices-columns', null);
        settingsStorage.set('settlements-columns', null);
        settingsVersion.set('settings-version', SETTINGS_STORAGE_VERSION);
      }

      if (_environment.default.APP.enableTranslations) {
        if (!settingsStorage.get('user-locale')) {
          settingsStorage.set('user-locale', 'en-us');
        }
      } else {
        settingsStorage.set('user-locale', 'en-us');
      }
      this.get('intl').setLocale(settingsStorage.get('user-locale'));
    },

    actions: {
      error: function error() /*error, transition*/{
        return true;
      }
    }
  });
});