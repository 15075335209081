define('backoffice/serializers/package', ['exports', 'backoffice/serializers/application', 'ember-data'], function (exports, _application, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      websitesTitles: { embedded: 'always' },
      offers: { embedded: 'always' },
      formPositions: { embedded: 'always' }
    }
  });
});