define('backoffice/routes/signup', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    intl: Ember.inject.service('intl'),
    session: Ember.inject.service('session'),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.get('intl').on('localeChanged', function () {
        if (_this.widgetReady) {
          window.location.reload();
        }
      });
    },

    widgetReady: false,
    actions: {
      makeWidgetReady: function makeWidgetReady() {
        this.widgetReady = true;
      }
    },
    activate: function activate() {
      Ember.$('body').addClass('gray-bg fixed-footer');
    },
    deactivate: function deactivate() {
      Ember.$('body').removeClass('gray-bg fixed-footer');
    },
    beforeModel: function beforeModel(transition) {
      this._super(transition);
      if (this.get('session').get('isAuthenticated')) {
        this.transitionTo('admin.dashboard');
        return;
      }
    }
  });
});