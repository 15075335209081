define('backoffice/pods/components/offers-table-row/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'tr',
    configuredCells: Ember.computed('dataCells', 'columns', function () {
      var rowData = this.get('dataCells'),
          configCells = this.get('columns') || [];

      return configCells.map(function (obj) {
        var temp = {};

        for (var name in obj) {
          if (obj.hasOwnProperty(name)) {
            if (name === 'property') {
              if (obj.property === 'translations') {
                temp.value = [];
                temp.property = 'translations';

                rowData.get('translations').forEach(function (item) {
                  temp.value.pushObject({
                    langcode: item.get('langcode'),
                    translation: item.get('translation')
                  });
                });
              } else if (obj.property === 'prices') {
                rowData.get('prices').forEach(function (price) {
                  if (price.get('iso') === obj.iso) {
                    temp.value = (parseFloat(price.get('price')) * 0.01).toFixed(2);
                    temp.property = obj.property;
                    temp.iso = price.get('iso');
                  }
                });
              } else if (obj.property === 'options') {
                temp.value = rowData.get('packagesAttached');
                temp.property = obj.property;
                temp.offerId = rowData.get('id');
              } else {
                temp.value = rowData.get(obj[name]);
                temp.property = obj.property;
              }
            } else {
              temp[name] = obj[name];
            }
          }
        }

        return temp;
      });
    })
  });
});