define('backoffice/pods/components/table-cell-invoice-status/component', ['exports', 'backoffice/constants/reports'], function (exports, _reports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var INVOICE_APPROVED_STATUS = _reports.default.INVOICE_APPROVED_STATUS,
      INVOICE_APPROVED_DESCRIPTION = _reports.default.INVOICE_APPROVED_DESCRIPTION,
      INVOICE_APPROVED_CLEARED_STATUS = _reports.default.INVOICE_APPROVED_CLEARED_STATUS,
      INVOICE_APPROVED_CLEARED_DESCRIPTION = _reports.default.INVOICE_APPROVED_CLEARED_DESCRIPTION,
      INVOICE_DUE_STATUS = _reports.default.INVOICE_DUE_STATUS,
      INVOICE_DUE_DESCRIPTION = _reports.default.INVOICE_DUE_DESCRIPTION,
      INVOICE_SETTLED_STATUS = _reports.default.INVOICE_SETTLED_STATUS,
      INVOICE_SETTLED_DESCRIPTION = _reports.default.INVOICE_SETTLED_DESCRIPTION,
      INVOICE_SUSPENDED_STATUS = _reports.default.INVOICE_SUSPENDED_STATUS,
      INVOICE_SUSPENDED_DESCRIPTION = _reports.default.INVOICE_SUSPENDED_DESCRIPTION,
      INVOICE_RETURNED_STATUS = _reports.default.INVOICE_RETURNED_STATUS,
      INVOICE_RETURNED_DESCRIPTION = _reports.default.INVOICE_RETURNED_DESCRIPTION;
  exports.default = Ember.Component.extend({
    tagName: 'td',
    index: null,
    data: null,
    row: null,
    collapsed: null,
    status: Ember.computed('data', function () {
      var status = '';

      switch (this.get('data')) {
        case INVOICE_APPROVED_STATUS:
          status = INVOICE_APPROVED_DESCRIPTION;
          break;
        case INVOICE_APPROVED_CLEARED_STATUS:
          status = INVOICE_APPROVED_CLEARED_DESCRIPTION;
          break;
        case INVOICE_DUE_STATUS:
          status = INVOICE_DUE_DESCRIPTION;
          break;
        case INVOICE_SETTLED_STATUS:
          status = INVOICE_SETTLED_DESCRIPTION;
          break;
        case INVOICE_SUSPENDED_STATUS:
          status = INVOICE_SUSPENDED_DESCRIPTION;
          break;
        case INVOICE_RETURNED_STATUS:
          status = INVOICE_RETURNED_DESCRIPTION;
          break;
      }
      return status;
    })
  });
});