define('backoffice/pods/components/2fa-configuration-form/component', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var tokenFormat = /\d{6}/;
  var Validations = (0, _emberCpValidations.buildValidations)({
    token: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      messageKey: "login.otp.validations.token.requiredMessage"
    }), (0, _emberCpValidations.validator)('length', {
      min: 6,
      max: 6,
      messageKey: "login.otp.validations.token.length"
    }), (0, _emberCpValidations.validator)('format', {
      regex: tokenFormat,
      messageKey: "login.otp.validations.token.format"
    })]
  });

  exports.default = Ember.Component.extend(Validations, {
    intl: Ember.inject.service(),
    clientSettings: Ember.inject.service('clientSettings'),
    twoFactorAuthService: Ember.inject.service('twoFactorAuthentication'),
    isTokenErrorShown: false,
    isCancelButtonShown: true,
    isManualConfigurationActivated: false,
    token: '',
    message: false,
    profile: null,
    tokenQRCode: '',
    tokenSecret: '',
    errorMessage: '',
    successMessage: '',
    isLoading: false,
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      this.set('token', '');
    },
    init: function init() {
      this._super.apply(this, arguments);

      var that = this;

      if (!that.get('profile')) {
        that.set('profile', that.get('clientSettings').getAccount());
      }

      var account = that.get('profile');

      that.set('isLoading', true);
      that.get('twoFactorAuthService').generateQRCode(account.id).then(function (_ref) {
        var qrCodeURL = _ref.qrCodeURL,
            secret = _ref.secret;

        that.set('isLoading', false);
        that.set('tokenQRCode', qrCodeURL);
        that.set('tokenSecret', secret);
      }).catch(function (error) {
        var _ref2 = error || {},
            original = _ref2.original;

        that.set('isLoading', false);
        that.get('onQRCodeGenerationError')(original);
      });
    },

    actions: {
      setValidationState: function setValidationState() {
        var isFormValid = this.get('validations.attrs.token.isValid');
        this.set('isTokenErrorShown', !!isFormValid);
      },
      errorHandler: function errorHandler() {
        this.set('isTokenErrorShown', !this.get('validations.attrs.token.isValid'));
      },
      toggleOTPConfigurationOption: function toggleOTPConfigurationOption(event) {
        if (event) {
          event.preventDefault();
        }
        this.toggleProperty('isManualConfigurationActivated');
      },
      configureOTP: function configureOTP() {
        var _this = this;

        var account = this.get('profile') || {};

        this.set('isLoading', true);

        this.get('twoFactorAuthService').register2FA(account.id, this.get('token')).then(function (_ref3) {
          var restoreCodes = _ref3.restoreCodes;

          _this.set('isLoading', false);
          _this.set('message', _this.get('intl').t('otp.twoFactorAuthSetSuccessfully'));
          _this.set('errorMessage', false);
          _this.set('successMessage', true);

          var callback = _this.get('on2FARegistrationSuccess');
          if (callback && typeof callback === "function") {
            Ember.run(function () {
              return callback(restoreCodes || []);
            });
          }
        }).catch(function (error) {
          var _ref4 = error || {},
              message = _ref4.message;

          _this.set('token', '');
          _this.set('isLoading', false);
          _this.set('message', message);
          _this.set('errorMessage', true);
        });
      }
    }
  });
});