define("backoffice/constants/requests", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    SUCCESS_RESPONSE_STATUS: 200,
    FAILED_RESPONSE_STATUS: 401,
    CLOSE_NOTIFY_DELAY: 8000
  };
});