define('backoffice/pods/components/login-form/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    identification: null,
    password: null,
    errorMessage: null,
    actions: {
      authenticate: function authenticate() {
        this.get('authenticate')(this.get('identification'), this.get('password'));
      }
    }
  });
});