define('backoffice/models/processing-company', ['exports', 'ember-data', 'ember-data/attr'], function (exports, _emberData, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    legalName: (0, _attr.default)('string'),
    currency: (0, _attr.default)()
    // "currency.iso": string
  });
});