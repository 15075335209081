define('backoffice/controllers/2fa/recovery', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    recoveryCodes: null,
    init: function init() {
      this._super.apply(this, arguments);
      var codes = this.get('recoveryCodes');
      if (!Array.isArray(codes) || !codes.length) {
        this.transitionToRoute('login');
      }
    },

    actions: {
      onRecoveryFormSubmit: function onRecoveryFormSubmit() {
        var accountStorage = this.get('accountStorage');
        var account = accountStorage.get('account');

        account.is2faConfigured = true;
        accountStorage.set('account', account);

        this.transitionToRoute('2fa.verification');
      },
      onCopyCodeToClipboard: function onCopyCodeToClipboard() {}
    }
  });
});