define('backoffice/models/merchant-performance', ['exports', 'ember-data', 'ember-data/attr'], function (exports, _emberData, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    customer: (0, _attr.default)("number"),
    reportTypeCode: (0, _attr.default)("string"),
    intervalNumber: (0, _attr.default)("number"),
    intervalName: (0, _attr.default)("string"),
    period: (0, _attr.default)("string"),
    timeZone: (0, _attr.default)("string"),
    processedTransactions: (0, _attr.default)("number"),
    successfulTransactions: (0, _attr.default)("number"),
    successRatio: (0, _attr.default)("number"),
    successfulSales: (0, _attr.default)("number"),
    successfulRebills: (0, _attr.default)("number"),
    rateSales: (0, _attr.default)("number"),
    rateRebill: (0, _attr.default)("number"),
    successfulTrxAmount: (0, _attr.default)("number"),
    averageTicketAmount: (0, _attr.default)("number"),
    chargebacks: (0, _attr.default)("number"),
    visaChargebacks: (0, _attr.default)("number"),
    mcChargebacks: (0, _attr.default)("number"),
    visaChargebackRatio: (0, _attr.default)("number"),
    mcChargebackRatio: (0, _attr.default)("number"),
    totalChargebackRatio: (0, _attr.default)("number"),
    refunds: (0, _attr.default)("number"),
    visaRefunds: (0, _attr.default)("number"),
    mcRefunds: (0, _attr.default)("number"),
    visaRefundsRatio: (0, _attr.default)("number"),
    mcRefundsRatio: (0, _attr.default)("number"),
    totalRefundsRatio: (0, _attr.default)("number"),
    techErrors: (0, _attr.default)("number"),
    riskErrors: (0, _attr.default)("number"),
    bankErrors: (0, _attr.default)("number"),
    techErrorsRatio: (0, _attr.default)("number"),
    riskErrorsRatio: (0, _attr.default)("number"),
    bankErrorsRatio: (0, _attr.default)("number")
  });
});