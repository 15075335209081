define('backoffice/pods/components/table-row-users/component', ['exports', 'backoffice/pods/components/table-row/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    classNameBindings: ['isActiveRow:selected-row'],
    isActiveRow: Ember.computed('activeRow', function () {
      return this.get('activeRow.id') === this.get('data.id');
    }),
    activeColumns: Ember.computed('columns', function () {
      return (this.columns || []).filter(function (column) {
        return !column.isHidden;
      });
    }),
    sharedModel: null,
    actions: {
      updateList: function updateList(item) {
        var updateListFn = this.get('updateList');
        if (typeof updateListFn === "function") {
          updateListFn(item);
        }
      },
      select: function select() {
        var onSelectFn = this.get('onSelect');
        if (typeof onSelectFn === "function") {
          onSelectFn.apply(undefined, arguments);
        }
      },
      toggleDetailsTable: function toggleDetailsTable(data) {
        var component = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';

        var ignoredComponents = ['table-cell-checkbox', 'table-cell-user-actions'];

        if (ignoredComponents.includes(component)) {
          return;
        }

        this.get('toggleDetailsTable')(data);
      }
    }
  });
});