define('backoffice/pods/components/data-table/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    sortProperties: [],
    tagName: 'table',
    model: null,
    rowType: null,
    activeColumns: Ember.computed('columns', function () {
      return Array.isArray(this.columns) && this.columns.filter(function (column) {
        return !column.isHidden;
      }) || [];
    }),
    filter: null,
    isDetailsShown: false,
    columnsCount: null,
    checkedItems: null,
    formPositions: null,
    groupValue: null,
    sharedModel: null,
    activeRow: null,
    sorted: Ember.computed.sort('filtered', 'sortProperties').property('filtered.@each', 'sortProperties'),
    filtered: Ember.computed.filter('model', function (item) {
      var filter = this.get('filter');
      if (!filter) {
        return true;
      }

      var matched = false;
      item.eachAttribute(function (name) {
        if (String(item.get(name)).toLowerCase().indexOf(filter.toLowerCase()) > -1) {
          matched = true;
        }
      });

      return matched;
    }).property('model.@each', 'filter'),
    didRender: function didRender() {
      this._super.apply(this, arguments);
      var id = this.$().attr('id'),
          elt = Ember.$('#' + id),
          hoveredRow = elt.find('tr:not(".details-info") td');

      if (hoveredRow.length) {
        hoveredRow.hover(function () {
          var t = parseInt(Ember.$(this).index()) + 1;
          elt.find('thead th:nth-child(' + t + ')').addClass('highlighted');
          elt.find('td:nth-child(' + t + ')').addClass('highlighted');
          Ember.$(this).addClass('active-highlighted');
        }, function () {
          var t = parseInt(Ember.$(this).index()) + 1;
          elt.find('thead th:nth-child(' + t + ')').removeClass('highlighted');
          elt.find('td:nth-child(' + t + ')').removeClass('highlighted');
          Ember.$(this).removeClass('active-highlighted');
        });
      }
    },

    actions: {
      headerClicked: function headerClicked(header) {
        if (!header.sortable) {
          return;
        }

        this.get('columns').forEach(function (item) {
          if (item !== header) {
            Ember.set(item, 'sortedProperty', item.sortable ? 'sorting' : null);
          }
        });

        var currentSort = this.get('sortProperties'),
            sort = header.sortingProperty || header.key;

        if (currentSort[0] === sort) {
          sort += ':desc';
        }

        this.set('sortProperties', [sort]);

        Ember.set(header, 'sortedProperty', Ember.get(header, 'sortedProperty') === 'sorting_desc' ? 'sorting_asc' : 'sorting_desc');
      },
      toggleDetailsTable: function toggleDetailsTable(pack) {
        if (this.get('toggleDetailsTable')) {
          this.get('toggleDetailsTable')(pack);
        }
      },
      updateList: function updateList() {
        var updateListFn = this.get('updateList');
        if (typeof updateListFn === "function") {
          updateListFn.apply(undefined, arguments);
        }
      },
      select: function select() {
        var onSelectFn = this.get('onSelect');
        if (typeof onSelectFn === "function") {
          onSelectFn.apply(undefined, arguments);
        }
      }
    }
  });
});