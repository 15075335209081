define('backoffice/pods/components/table-header-cell/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    column: null,
    sortedClass: Ember.computed('column.sortedProperty', function () {
      return this.get('column.sortedProperty');
    }),
    tagName: 'th',
    actions: {
      headerClicked: function headerClicked(col) {
        this.get('headerClicked')(col);
      }
    }
  });
});