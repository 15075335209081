define('backoffice/pods/components/2fa-verification-form/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    intl: Ember.inject.service(),
    identification: null,
    clientSettings: Ember.inject.service('clientSettings'),
    twoFactorAuthService: Ember.inject.service('twoFactorAuthentication'),
    message: false,
    errorMessage: '',
    successMessage: '',
    isLoading: false,
    actions: {
      verifyOTP: function verifyOTP() {
        var _this = this;

        this.set('isLoading', true);
        var account = this.get('clientSettings').getAccount();

        this.get('twoFactorAuthService').verify2FA(account.id, this.get('identification')).then(function (response) {
          return Ember.run(function () {
            _this.set('isLoading', false);
            var tokenData = _this.get('clientSettings').setCookieAndStoreData(response);
            _this.get('onTokenSuccessfullyVerified')(tokenData);
          });
        }).catch(function (error) {
          return Ember.run(function () {
            var _ref = error || {},
                message = _ref.message;

            _this.set('isLoading', false);
            _this.set('message', message);
            _this.set('successMessage', false);
            _this.set('errorMessage', true);
            _this.set('identification', '');
          });
        });
      },
      errorHandler: function errorHandler() {},
      setValidationState: function setValidationState() {}
    }
  });
});