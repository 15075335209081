define('backoffice/initializers/crumbly', ['exports', 'ember-crumbly/initializers/crumbly'], function (exports, _crumbly) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _crumbly.default;
    }
  });
  Object.defineProperty(exports, 'initialize', {
    enumerable: true,
    get: function () {
      return _crumbly.initialize;
    }
  });
});