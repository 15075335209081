define('backoffice/controllers/admin/transactions', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  var paramsMap = {
    ip: 'ipParam',
    ccNumber: 'cardNumberParam',
    cardHolder: 'cardHolderParam',
    email: 'emailParam',
    userName: 'usernameParam',
    password: 'passwordParam',
    currency: 'currency',
    op1: 'op1',
    op2: 'op2',
    op3: 'op3'
  };
  var switchParams = {
    ipParam: { name: 'ip-param', isChecked: false },
    cardNumberParam: { name: 'card-number-param', isChecked: true },
    cardHolderParam: { name: 'card-holder-param', isChecked: false },
    emailParam: { name: 'email-param', isChecked: true },
    usernameParam: { name: 'username-param', isChecked: true },
    passwordParam: { name: 'pwd-param', isChecked: false }
  };
  var toggles = ['ipParam', 'cardNumberParam', 'cardHolderParam', 'emailParam', 'usernameParam', 'passwordParam'];

  exports.default = Ember.Controller.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.searchParams = {
        dateFrom: _moment.default.utc().startOf('month').format('YYYY-MM-DDTHH:mm:ss'),
        dateTo: (0, _moment.default)().endOf('day').format('YYYY-MM-DDTHH:mm:ss'),
        status: null,
        transactionType: null,
        country: null,
        transactionId: null,
        provider: null,
        referredId: null,
        ip: null,
        websiteId: null,
        offer: null,
        source: null,
        ccNumber: null,
        ccLastSixDigits: null,
        cardHolder: null,
        cardBrand: null,
        email: null,
        userName: null,
        password: null,
        usernameType: null,
        page: null,
        ipParam: null,
        cardNumberParam: null,
        cardHolderParam: null,
        emailParam: null,
        usernameParam: null,
        passwordParam: null,
        size: null,
        op1: null,
        op2: null,
        op3: null,
        currency: null
      };
      this.paramsMap = paramsMap;
      this.switchParams = switchParams;
      this.toggles = toggles;
    },

    searchCtrl: Ember.inject.controller('admin.transactions.search'),
    togglesInit: Ember.inject.service('toggles-init'),
    isNewSearch: false,
    isMappingKey: function isMappingKey(prop) {
      var paramsMap = this.get('paramsMap');
      for (var key in paramsMap) {
        if (paramsMap.hasOwnProperty(key) && paramsMap[key] === prop) {
          return true;
        }
      }
      return false;
    },
    updateQueryParams: function updateQueryParams() {
      var _this = this;

      var paramsMap = this.get('paramsMap'),
          searchParams = this.get('searchParams'),
          page = void 0;

      Object.keys(searchParams).forEach(function (prop) {
        if (prop === 'page') {
          page = searchParams[prop];
          return;
        }

        if (prop === 'dateFrom' || prop === 'dateTo') {
          if (_this.get('searchCtrl.' + prop) && _this.get('searchCtrl.' + prop) !== searchParams[prop]) {
            _this.set('isNewSearch', true);
          }

          _this.get('searchCtrl').set(prop, searchParams[prop]);
          return;
        }

        if (!_this.isMappingKey(prop) && (_this.get('searchCtrl.' + prop) === undefined || _this.get('searchCtrl.' + prop) !== searchParams[prop])) {
          _this.get('searchCtrl').set(prop, searchParams[prop]);
          _this.set('isNewSearch', true);
        }

        if (prop === 'currency' && searchParams[prop] !== null) {
          _this.set('searchParams.' + prop, searchParams['currency'].toUpperCase());
        }

        if (prop in paramsMap) {
          if (searchParams[prop] !== null) {
            if (_this.get('searchCtrl.' + paramsMap[prop]) !== searchParams[paramsMap[prop]] + '') {
              _this.set('isNewSearch', true);
            }

            _this.get('searchCtrl').set(paramsMap[prop], searchParams[paramsMap[prop]]);
          } else {
            _this.get('searchCtrl').set(paramsMap[prop], null);
          }
        }
      });

      this.get('searchCtrl').set('page', this.get('isNewSearch') ? null : page);
    },
    getQueryObject: function getQueryObject() {
      var _this2 = this;

      var paramsMap = this.get('paramsMap'),
          searchParams = this.get('searchParams'),
          resultParams = void 0;

      resultParams = Object.keys(searchParams).reduce(function (result, prop) {
        var currentKeys = {};
        if (searchParams[prop] == null) {
          currentKeys = {};
        } else {
          if (!_this2.isMappingKey(prop)) {
            currentKeys = _defineProperty({}, prop, searchParams[prop]);
          }

          if (prop in paramsMap) {
            currentKeys[paramsMap[prop]] = searchParams[paramsMap[prop]];
          }
        }

        return Ember.merge(result, currentKeys);
      }, {});

      return resultParams;
    },
    updateSearchParam: function updateSearchParam(param, value) {
      var searchparam = 'searchParams.' + param;
      this.set(searchparam, value === '' ? null : value);
    },


    actions: {
      pageChanged: function pageChanged(page) {
        this.updateSearchParam('page', page);
        this.updateQueryParams();
        this.replaceRoute('admin.transactions.search');
        return true;
      },
      updateSearchParam: function updateSearchParam(param, value) {
        this.updateSearchParam(param, value);
      },
      searchTrx: function searchTrx() {
        // Create an object only with keys from model that have values (skip undefined or null values)
        if (Ember.getOwner(this).lookup('controller:application').currentPath !== 'admin.transactions.index') {
          this.updateQueryParams();

          if (this.get('isNewSearch')) {
            this.replaceRoute('admin.transactions.search');
          }
          this.send('refreshPage');
        } else {
          this.replaceRoute('admin.transactions.search', { queryParams: this.getQueryObject() });
        }

        this.set('isNewSearch', false);
      },
      resetSearchForm: function resetSearchForm() {
        for (var key in this.get('searchParams')) {
          if (this.get('searchParams').hasOwnProperty(key) && this.get('searchParams.' + key) !== null) {
            if (key === 'dateFrom') {
              this.set('searchParams.dateFrom', _moment.default.utc().startOf('month').format('YYYY-MM-DDTHH:mm:ss'));
            } else if (key === 'dateTo') {
              this.set('searchParams.dateTo', (0, _moment.default)().endOf('day').format('YYYY-MM-DDTHH:mm:ss'));
            } else {
              this.set('searchParams.' + key, null);
            }
          }
        }
        this.get('togglesInit').initToggles(this);
        this.set('isNewSearch', false);
      }
    }
  });
});