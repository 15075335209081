define('backoffice/pods/components/table-cell-offer-status/component', ['exports', 'backoffice/constants/offers'], function (exports, _offers) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var OFFER_INACTIVE_STATUS = _offers.default.OFFER_INACTIVE_STATUS,
      OFFER_ACTIVE_STATUS = _offers.default.OFFER_ACTIVE_STATUS;
  exports.default = Ember.Component.extend({
    tagName: 'td',
    index: null,
    data: null,
    row: null,
    collapsed: null,
    status: Ember.computed('data', function () {
      var status = void 0,
          statusNum = parseInt(this.get('data'));

      switch (statusNum) {
        case OFFER_INACTIVE_STATUS:
          status = 'Inactive';
          break;
        case OFFER_ACTIVE_STATUS:
          status = 'Active';
          break;
      }
      return status;
    })
  });
});