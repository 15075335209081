define('backoffice/pods/components/offer-settings-select-type/component', ['exports', 'backoffice/mixins/new-offer-set-tooltips', 'backoffice/constants/offers'], function (exports, _newOfferSetTooltips, _offers) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var OFFER_MEMBERSHIP_TYPEID = _offers.default.OFFER_MEMBERSHIP_TYPEID,
      OFFER_TRIAL_TYPEID = _offers.default.OFFER_TRIAL_TYPEID,
      OFFER_UPSELL_TYPEID = _offers.default.OFFER_UPSELL_TYPEID;
  exports.default = Ember.Component.extend(_newOfferSetTooltips.default, {
    store: Ember.inject.service(),
    classNames: ['row', 'offer-type'],
    selectedOfferType: null,
    selectedOfferId: null,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var preSelectOfferId = parseInt(this.get('selectedOfferId') || OFFER_MEMBERSHIP_TYPEID);

      if (this.get('newOffer.isNew')) {
        this.get('store').peekAll('offerType').forEach(function (type) {
          var id = parseInt(type.get('id'));

          if (id === preSelectOfferId) {
            if (id === OFFER_UPSELL_TYPEID) {
              _this.set('selectedOfferType', type.get('description').substr(-6).replace(/^(.)/g, function (letter) {
                return letter.toUpperCase();
              }));
            } else {
              _this.set('selectedOfferType', type.get('description'));
            }

            if (!_this.get('selectedOfferId')) {
              _this.set('selectedOfferId', id);
            }

            _this.set('tooltip', _this.setTooltip(id));
            _this.set('newOffer.type', type);
            _this.get('setOfferType')(type);
          }
        });
      } else {
        // never will executed
        var id = parseInt(this.get('newOffer.type.id'));

        this.set('selectedOfferType', this.get('newOffer.type.description'));

        if (!this.get('selectedOfferId')) {
          this.set('selectedOfferId', id);
        }

        this.set('tooltip', this.setTooltip(id));
      }
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);
      Ember.run.scheduleOnce('afterRender', this, 'isAllowCreateTrial');
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.set('selectedOfferType', null);
      this.set('selectedOfferId', null);
      this.set('tooltip', null);
      this.set('title', null);
      this.set('placeholder', null);
    },
    isAllowCreateTrial: function isAllowCreateTrial() {
      var _this2 = this;

      var isRebillEmpty = this.get('isRebillEmpty');

      if (isRebillEmpty && parseInt(this.get('selectedOfferId')) === OFFER_TRIAL_TYPEID) {
        this.set('isModalShown', true);

        this.get('store').peekAll('offerType').forEach(function (type) {
          var id = parseInt(type.get('id'));

          if (id === OFFER_MEMBERSHIP_TYPEID) {
            _this2.set('tooltip', _this2.setTooltip(id));
            _this2.set('newOffer.type', type);

            _this2.set('selectedOfferId', id);
            _this2.set('selectedOfferType', type.get('description'));
          }
        });
      }
    },

    titlesOfferTypes: Ember.computed('offerTypes', function () {
      var array = [];

      this.get('offerTypes').forEach(function (type) {
        var description = void 0;

        if (parseInt(type.get('id')) === OFFER_UPSELL_TYPEID) {
          description = type.get('description').substr(-6);
          description = description[0].toUpperCase() + description.substr(1);
          array.push('' + description);
        } else {
          description = type.get('description');
          array.push('' + description);
        }
      });

      return array;
    }),
    actions: {
      setType: function setType(description) {
        var _this3 = this;

        var upsellText = description === 'Upsell' ? 'One shot - upsell' : description;

        this.get('store').peekAll('offerType').forEach(function (type) {
          var id = parseInt(type.get('id'));

          if (type.get('description') === upsellText) {
            if (_this3.get('isRebillEmpty') && id === OFFER_TRIAL_TYPEID) {
              _this3.set('isModalShown', true);
            } else {
              _this3.set('selectedOfferType', description);
              _this3.set('selectedOfferId', id);
              _this3.set('tooltip', _this3.setTooltip(id));
              _this3.get('setOfferType')(type);
            }
          }
        });
      },
      showErrors: function showErrors(param) {
        this.set(param, true);
      }
    }
  });
});