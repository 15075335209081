define('backoffice/models/subscription', ['exports', 'ember-data/model', 'ember-data'], function (exports, _model, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr;
  exports.default = _model.default.extend({
    initialTransactionId: attr('number'),
    status: attr('string'),
    amount: attr('from-cent'), //rebillAmount
    chargedAmount: attr('from-cent'), //chargedAmount
    cancelDate: attr('string'), // cancellationDate
    startDate: attr('string'), //subscriptionDate
    currency: attr('string'), //rebillCurrency
    email: attr('string'),
    offerId: attr('number'),
    offerTypeName: attr('string'),
    username: attr('string'),
    websiteId: attr('number'),
    nextRebillDate: attr('string'),
    reason: attr('string'),
    lastRebillDate: attr('string'),
    stopDate: attr('string'),
    endSubscriptionDate: attr('string'),
    isTest: attr('boolean'),
    numberId: Ember.computed('id', function () {
      return +this.get('id');
    })
  });
});