define('backoffice/routes/admin/websites/edit', ['exports', 'backoffice/mixins/admin-route-mixin', 'ember-simple-auth/mixins/authenticated-route-mixin', 'backoffice/mixins/errors-handler', 'backoffice/mixins/reset-scroll-position'], function (exports, _adminRouteMixin, _authenticatedRouteMixin, _errorsHandler, _resetScrollPosition) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_adminRouteMixin.default, _authenticatedRouteMixin.default, _errorsHandler.default, _resetScrollPosition.default, {
    init: function init() {
      this._super.apply(this, arguments);
      this.title = this.get('intl').t('websites.editWebsite');
    },
    beforeModel: function beforeModel() {
      this.store.unloadAll('package');
    },
    model: function model(params) {
      return Ember.RSVP.hash({
        website: this.store.findRecord('website', params.website_id),
        packagesList: this.store.query('package', {})
      });
    },

    actions: {
      cancel: function cancel() {
        var model = this.controller.get('model.website'),
            isChanged = model.get('hasDirtyAttributes');
        if (isChanged) {
          model.rollbackAttributes();
        }
        this.transitionTo('admin.websites.index');
      },
      save: function save(website) {
        var _this = this;

        website.save().then(function () {
          return _this.transitionTo('admin.websites.index');
        }).catch(function (error) {
          _this.handleErrors(error);
        });
      },
      toggleDetailsTable: function toggleDetailsTable(pack) {
        var selectedPackage = this.store.peekRecord('package', pack.get('id'));
        this.controller.set('selectedPackageId', pack.get('id'));
        this.controller.set('selectedPackage', selectedPackage);
      },
      deselect: function deselect() {
        this.controller.set('selectedPackageId', null);
        this.controller.set('selectedPackage', null);
      },
      willTransition: function willTransition() /*transition*/{
        // this close modal for selected Package
        this.controller.set('selectedPackage', null);
      }
    }
  });
});