define('backoffice/constants/members', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    MEMBERS_ON_PAGE_LIST: ['10', '20', '30'],
    DEFAULT_MEMBERS_ON_PAGE: '20',
    DEFAULT_DIALOG_SHOWN: false,
    ACTIVE_MEMBERSHIP_STATUS: 'Active',
    MEMBERSHIP_OFFERTYPE: 'Membership',
    TRIAL_OFFERTYPE: 'Trial'
  };
});