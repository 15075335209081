define('backoffice/pods/components/dashboard-data-table/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'table',
    classNameBindings: ['isLoading'],
    statusPerformanceCountRow: Ember.computed('data', function () {
      return this.get('data.length');
    }),
    sumRow: Ember.computed('data', function () {
      var allRow = this.get('data'),
          configCells = this.get('configCells'),
          sum = {},
          modelFields = {};

      configCells.forEach(function (item) {
        modelFields[item.modelPropertyName] = {
          type: item.type,
          value: item.type === 'string' ? '' : 0
        };
        sum[item.modelPropertyName] = modelFields[item.modelPropertyName].value;
      });

      if (allRow) {
        allRow.forEach(function (row) {
          for (var propName in modelFields) {
            if (modelFields.hasOwnProperty(propName) && row.get(propName) && modelFields[propName].type !== 'string') {
              sum[propName] += parseFloat(row.get(propName));
            }
          }
        });
      }

      return Ember.ArrayProxy.create(sum);
    }),
    actions: {
      sort: function sort(obj) {
        this.get('sort')(obj);
      }
    }
  });
});