define('backoffice/transforms/offer-type', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      return serialized === 'Membership' ? 'Subscription' : serialized;
    },
    serialize: function serialize(deserialized) {
      return deserialized === 'Subscription' ? 'Membership' : deserialized;
    }
  });
});