define('backoffice/helpers/format-number', ['exports', 'numeral'], function (exports, _numeral) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatNumber = formatNumber;
  function formatNumber(params) {
    var formatedNumber = (0, _numeral.default)(params[0]).format(params[1]);

    return formatedNumber;
  }

  exports.default = Ember.Helper.helper(formatNumber);
});