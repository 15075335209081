define('backoffice/authenticators/oauth2', ['exports', 'ember-simple-auth/authenticators/oauth2-password-grant', 'backoffice/config/environment', 'backoffice/constants/auth'], function (exports, _oauth2PasswordGrant, _environment, _auth) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function clearCookies() {
    var cookies = document.cookie.split(';');
    for (var i in cookies) {
      document.cookie = cookies[i] + "; expires=" + new Date(0).toUTCString();
    }
  }

  exports.default = _oauth2PasswordGrant.default.extend({
    serverTokenEndpoint: _environment.default.APP.apiHost + '/oauth/token',
    serverAccessTokenEndpoint: _environment.default.APP.apiHost + '/api/security/access-token',
    session: Ember.inject.service(),
    clientSettings: Ember.inject.service('clientSettings'),
    restore: function restore(data) {
      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (!Ember.isEmpty(data.token)) {
          resolve(data);
        } else {
          reject();
        }
      });
    },
    authenticate: function authenticate(identification, password, token) {
      var _this = this;

      var that = this;
      var queryParams = void 0;

      if (token) {
        queryParams = {
          url: this.get('serverAccessTokenEndpoint'),
          headers: {
            Authorization: 'Bearer ' + token
          },
          type: 'POST',
          contentType: 'application/x-www-form-urlencoded',
          dataType: 'json'
        };
      } else {
        queryParams = {
          url: this.get('serverTokenEndpoint'),
          data: {
            grant_type: 'password',
            client_id: 'oauth',
            client_secret: 'admin',
            username: '' + identification,
            password: '' + password
          },
          type: 'POST',
          contentType: 'application/x-www-form-urlencoded',
          dataType: 'json'
        };
      }

      return new Ember.RSVP.Promise(function (resolve, reject) {
        Ember.$.ajax(queryParams).then(function (response) {
          Ember.run(function () {
            var _ref = response || {},
                account = _ref.account,
                access_token = _ref.access_token;

            var accountStorage = that.get('accountStorage');
            accountStorage.set('account', account);

            if (!access_token && account && account.is2faEnabled === true) {
              return reject({ status: _auth.default.AUTH_2FA_STATUS_FLAG, account: account });
            }

            resolve({
              token: _this.get('clientSettings').setCookieAndStoreData(response)
            });
          });
        }, function (jqXHR) {
          Ember.run(function () {
            reject(jqXHR);
          });
        });
      });
    },
    invalidate: function invalidate() {
      clearCookies();
      return Ember.RSVP.resolve({});
    },
    sessionDataInvalidated: function sessionDataInvalidated() {
      this.invalidate();
      this.transitionTo('login');
    }
  });
});