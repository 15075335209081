define('backoffice/helpers/len', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.len = len;
  function len(params /*, hash*/) {
    return Object.keys(params).length;
  }

  exports.default = Ember.Helper.helper(len);
});