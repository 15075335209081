define('backoffice/routes/admin/support/tickets', ['exports', 'backoffice/mixins/admin-route-mixin', 'ember-simple-auth/mixins/authenticated-route-mixin', 'backoffice/constants/support'], function (exports, _adminRouteMixin, _authenticatedRouteMixin, _support) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var FILTERS_LIST = _support.default.FILTERS_LIST;
  exports.default = Ember.Route.extend(_adminRouteMixin.default, _authenticatedRouteMixin.default, {
    queryParams: {
      page: { refreshModel: true },
      size: { refreshModel: true },
      filter: { refreshModel: true },
      subject: { refreshModel: true }
    },
    model: function model(params) {
      var populatedParams = {};

      for (var key in params) {
        if (params.hasOwnProperty(key) && params[key] != null && params[key] !== '') {
          populatedParams[key] = params[key];
        }
      }

      if (FILTERS_LIST.indexOf(params.filter) > -1) {
        this.controllerFor('admin.support.tickets').set('statusFilter', params.filter);
      }

      this.controllerFor('admin.support.tickets').set('subjectFilter', params.subject);
      populatedParams.subject = params.subject;

      return this.store.query('ticket', populatedParams);
    },

    actions: {
      newRequest: function newRequest() {
        this.controller.set('activeTicketId', null);
        this.transitionTo('admin.support.tickets.new');
      },
      onSave: function onSave() {
        this.refresh();
      }
    }
  });
});