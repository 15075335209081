define('backoffice/pods/components/detailed-role-dialog/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    filter: null,
    filteredUsers: Ember.computed.filter('users', function (user) {
      return user.get('role.id') === this.get('filter');
    }).property('users', 'filter'),
    actions: {
      cancelOperation: function cancelOperation() {
        this.get('reset')();
      }
    }
  });
});