define("backoffice/services/client-settings", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function createCookie(name, value, days) {
    var expires = void 0;
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toGMTString();
    } else {
      expires = "";
    }
    document.cookie = name + "=" + value + expires + "; path=/";
  }

  exports.default = Ember.Service.extend({
    getAccount: function getAccount() {
      return this.get('accountStorage').get('account');
    },
    getCustomer: function getCustomer() {
      return this.get('accountStorage').get('customer');
    },
    setCookieAndStoreData: function setCookieAndStoreData(response) {
      var _ref = response || {},
          scope = _ref.scope,
          account = _ref.account,
          customer = _ref.customer,
          expires_in = _ref.expires_in,
          token_type = _ref.token_type,
          access_token = _ref.access_token,
          refresh_token = _ref.refresh_token,
          _ref$permissions = _ref.permissions,
          incomePermissions = _ref$permissions === undefined ? [] : _ref$permissions;

      var accountStorage = this.get('accountStorage');
      var permissions = Array.isArray(incomePermissions) && incomePermissions.map(function (item) {
        return item.key;
      }) || [];

      accountStorage.set('account', account);
      accountStorage.set('permissions', permissions);
      accountStorage.set('customer', customer);
      createCookie('access_token', access_token, 1);

      return {
        scope: scope,
        expires_in: expires_in,
        token_type: token_type,
        access_token: access_token,
        refresh_token: refresh_token
      };
    }
  });
});