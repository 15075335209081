define('backoffice/pods/components/2fa-reset-user-otp-dialog/component', ['exports', 'ember-intl', 'backoffice/constants/requests'], function (exports, _emberIntl, _requests) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var CLOSE_NOTIFY_DELAY = _requests.default.CLOSE_NOTIFY_DELAY;
  exports.default = Ember.Component.extend({
    intl: Ember.inject.service(),
    notify: Ember.inject.service('notify'),
    isDialogShown: false,
    isLoading: false,
    hasError: false,
    cancelBtnName: (0, _emberIntl.t)('common.cancel'),
    confirmBtnName: (0, _emberIntl.t)('common.confirm'),
    twoFactorAuthService: Ember.inject.service('twoFactorAuthentication'),
    actions: {
      confirm: function confirm() {
        var _this = this;

        var account = this.get('selectedUser') || {};

        this.get('twoFactorAuthService').hardReset2FA(account.id).then(function () {
          _this.set('isLoading', false);
          _this.get('notify').success(_this.get('intl').t('users.reset2FASettings.successNotify', { user: account.name }), { closeAfter: CLOSE_NOTIFY_DELAY });

          var confirmFn = _this.get('confirm');
          if (confirmFn && typeof confirmFn === "function") {
            confirmFn();
          }
        }).catch(function (error) {
          var message = error.message;

          _this.set('isLoading', false);
          _this.get('notify').error(message, { closeAfter: CLOSE_NOTIFY_DELAY });
        });
      },
      cancelOperation: function cancelOperation() {
        this.get('reset')();
      }
    }
  });
});