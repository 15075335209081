define('backoffice/pods/components/bs-tagsinput/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = Ember.Component.extend({
    tagName: 'input',
    classNames: ['tagsinput'],
    attributeBindings: ['id', 'name', 'placeholder'],
    content: null,
    itemText: null,
    source: null,
    tagClass: '',
    pasteHandler: false,
    withSpaces: true,

    init: function init() {
      this._super.apply(this, arguments);

      Ember.run.scheduleOnce('afterRender', this, function () {
        var _this = this;

        var id = '#' + this.get('id'),
            elt = Ember.$(id),
            options = { freeInput: true },
            content = this.setContentType(this.get('content')),
            pasteHandler = this.get('pasteHandler');

        if (this.get('tagClass')) {
          options.tagClass = this.get('tagClass');
        }

        elt.tagsinput(options);
        elt.tagsinput('input').attr('autocomplete', 'off');

        if (content && Array.isArray(content)) {
          content.forEach(function (item) {
            var itemString = item.toString();

            if (_this.get('itemText') === null || _this.get('itemText') === undefined) {
              if ((typeof item === 'undefined' ? 'undefined' : _typeof(item)) !== 'object') {
                elt.tagsinput('add', itemString);
              }
            } else {
              elt.tagsinput('add', item.get(_this.get('itemText')));
            }
          });
        }

        if (!pasteHandler) {
          var newInput = elt.tagsinput('input');
          newInput.on('paste', function (event) {
            event.preventDefault();
            var text = JSON.stringify((event.originalEvent || event).clipboardData.getData('text/plain')).slice(1, -1);
            elt.tagsinput('add', text);
            newInput.val('');
          });
          this.set('pasteHandler', true);
        }

        elt.on('beforeItemAdd', function (event) {
          if (!_this.get('withSpaces') && event.item.match(/\s+/g)) {
            var item = _this._removeSpaces(event.item);
            event.cancel = true;
            elt.tagsinput('add', item);
          }
        });

        elt.on('itemAdded', function (event) {
          var item = event.item.trim();

          if (_this.get('addAction')) {
            // this.sendAction('addAction', item, content);
            _this.get('addAction')(item, content);
          } else {
            if (content && !content.includes(item)) {
              content.addObject(item);
            }
          }
        });

        elt.on('itemRemoved', function (event) {
          var item = event.item;

          if (_this.get('removeAction')) {
            // this.sendAction('removeAction', item, content);
            _this.get('removeAction')(item, content);
          } else {
            content.removeObject(item);
          }
        });
      });
    },
    setContentType: function setContentType(_content) {
      var array = [];

      if (Array.isArray(_content) && _content.length) {
        array = _content.map(function (el) {
          return el;
        });
      } else if (typeof _content === 'string') {
        array = _content.split(',');
      } else {
        array = [];
      }

      return array;
    },
    _removeSpaces: function _removeSpaces(item) {
      return item.replace(/\s+/g, '');
    },
    _tagsManagement: function _tagsManagement(content, tags, element) {
      Ember.run.scheduleOnce('sync', this, function () {
        var _this2 = this;

        content.forEach(function (item) {
          var isItemFound = false;

          var itemString = item;
          if ((typeof item === 'undefined' ? 'undefined' : _typeof(item)) === 'object' && _this2.get('itemText')) {
            itemString = item.get(_this2.get('itemText'));
          }

          tags.forEach(function (tag) {
            if (itemString === tag) {
              isItemFound = true;
            }
          });

          if (!isItemFound) {
            element.tagsinput('add', itemString, true);
          }
        });

        tags.forEach(function (tag) {
          var isItemFound = false;

          content.forEach(function (item) {
            var itemString = item;
            if ((typeof itemString === 'undefined' ? 'undefined' : _typeof(itemString)) === 'object' && _this2.get('itemText')) {
              itemString = item.get(_this2.get('itemText'));
            }

            if (itemString === tag) {
              isItemFound = true;
            }
          });

          if (!isItemFound) {
            element.tagsinput('remove', tag);
            element.tagsinput('refresh');
          }
        });
      });
    },

    contentChange: Ember.observer('content.[]', function () {
      var id = '#' + this.get('id'),
          element = Ember.$(id),
          tagInput = element.tagsinput();

      element.tagsinput('input').attr('autocomplete', 'off');

      if (!tagInput) {
        return;
      }

      var tags = element.tagsinput()[0].itemsArray.map(function (el) {
        return el;
      }) || [];
      var content = this.setContentType(this.get('content'));

      this._tagsManagement(content, tags, element);
    }),
    willDestroyElement: function willDestroyElement() {
      var id = '#' + this.get('id'),
          elt = Ember.$(id);

      elt.tagsinput('destroy');
    }
  });
});