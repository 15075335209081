define('backoffice/pods/components/item-selector/component', ['exports', 'backoffice/mixins/errors-handler', 'ember-rl-dropdown/mixins/rl-dropdown-component', 'backoffice/constants/multi-select'], function (exports, _errorsHandler, _rlDropdownComponent, _multiSelect) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var TAB_KEY = _multiSelect.default.TAB_KEY,
      ESCAPE_KEY = _multiSelect.default.ESCAPE_KEY,
      UP_KEY = _multiSelect.default.UP_KEY,
      DOWN_KEY = _multiSelect.default.DOWN_KEY,
      SPACE_KEY = _multiSelect.default.SPACE_KEY,
      ENTER_KEY = _multiSelect.default.ENTER_KEY,
      INPUT_TABINDEX = _multiSelect.default.INPUT_TABINDEX,
      SELECT_BTN_TABINDEX = _multiSelect.default.SELECT_BTN_TABINDEX,
      DESELECT_BTN_TABINDEX = _multiSelect.default.DESELECT_BTN_TABINDEX;
  exports.default = Ember.Component.extend(_rlDropdownComponent.default, _errorsHandler.default, {
    init: function init() {
      this._super.apply(this, arguments);

      this.allItems = [];
      this.selectedItems = [];
      this.currentSelectedItems = [];
      this.currentDeselectedItems = [];

      this.contentChanged();
      this.initSelections();
    },

    tagName: 'div',
    classNames: ['multi-select'],
    classNameBindings: ['dropdownExpanded:open', 'isInputDisabled:disabled'],
    searchText: '',
    dropdownExpanded: false,
    toggleOpen: function toggleOpen() {
      this.toggleProperty('dropdownExpanded');
    },


    /**
     * Enables the content into the dropdown to be a promise. If it isn't a promise
     * it resolves straight away. If it's a promise, the component will be in
     * a loading state until the promise resolves.
     * Content = initial list of all items
     */
    content: null,

    /**
     * Allow set a number of visble items in the list
     */
    viewLimit: null,

    /**
     * Variable bound to controller
     */
    selected: null,

    /**
     * When these are true, the component is disabled or in a loading state.
     */
    isDisabled: false,
    isLoading: false,

    /*
     * Update selectedItems when selected has init value
     * */
    selectedObserver: Ember.observer('selected', function () {
      this.initSelections();
    }),

    isInputDisabled: Ember.computed('isLoading', 'isDisabled', function () {
      return this.get('isLoading') || this.get('isDisabled');
    }),

    /**
     * When the dropdown is loaded for the first time, it should display a list
     * of items. Every other time it should display it according to the filter.
     * When the text filter is used for the first time, this variable will be set
     * to false, so that the next time the filter is cleared, only the selected
     * items will be visible.
     */
    firstTime: true,

    /**
     * Field name to use for display in dropdown box
     */
    displayName: 'name',

    /**
     * Submit selected list to controller action
     */
    submitOnClose: false,

    /**
     * Clear selection when the box closes
     */
    clearOnClose: false,

    filteredItems: Ember.computed('searchText', 'allItems', function () {
      var content = this.get('allItems'),
          filterText = this.get('searchText').toLowerCase(),
          displayName = this.get('displayName');

      if (filterText !== '') {
        // set first time to false the first time we use the search filter
        this.set('firstTime', false);
      }

      if (filterText !== '') {
        content = content.filter(function (row) {
          return row.get(displayName).toLowerCase().indexOf(filterText) > -1;
        });
      }

      if (this.get('viewLimit') && this.get('allItems.length') > this.get('viewLimit')) {
        content = content.slice(0, this.get('viewLimit'));
      }

      return content;
    }),

    noItems: Ember.computed('filteredItems.length', function () {
      return this.get('filteredItems.length') === 0;
    }),

    deselectedItems: Ember.computed.filter('allItems', function (item) {
      return !item.get('isSelected');
    }).property('allItems.@each.isSelected'),

    selectedItemsNum: Ember.computed('selectedItems', function () {
      return this.get('selectedItems.length');
    }),

    /**
     * Show the number of selected items in the dropdown when closed
     */
    showItemNum: Ember.computed('selectedItems.length', function () {
      return this.get('selectedItems.length') < 1;
    }),

    contentChangeObserver: Ember.observer('content', function () {
      this.contentChanged();
    }),
    /**
     * Runs at the init and after content has changed
     * */
    contentChanged: function contentChanged() {
      var _this = this;

      var getContent = this.get('content'),
          itemList = void 0;

      if (getContent === undefined || getContent === null) {
        return false;
      }
      if (getContent.then === undefined) {
        //no promise, lets load the data straight away
        itemList = this.createItemsList(getContent);
        this.set('allItems', itemList);
        return false;
      }

      this.set('isLoading', true);
      getContent.then(function (data) {
        Ember.run(function () {
          itemList = _this.createItemsList(data);

          _this.set('isLoading', false);
          _this.set('allItems', itemList);
        });
      }, function (error) {
        _this.handleErrors(error);
      });
    },
    createItemsList: function createItemsList(items) {
      if (!items) {
        return [];
      }

      var Item = Ember.Object.extend({ name: null, isSelected: false });

      return items.map(function (item) {
        if (item.hasOwnProperty('store')) {
          return Item.create(item.toJSON({ includeId: true }));
        }
        return Item.create(item);
      });
    },


    /**
     * Init and update selection list which is bound to the controller (for RT access)
     */

    initSelections: function initSelections() {
      var _selectedFilter = this.get('selected'),
          selections = void 0;

      if (!_selectedFilter) {
        selections = [];
      }

      if (typeof _selectedFilter === 'string') {
        selections = _selectedFilter.split(',');
      }

      if (!Array.isArray(selections)) {
        selections = [];
        // throw TypeError('Selected must be string or array!');
      }

      var allItems = this.get('allItems');

      if (!allItems) {
        return [];
      }

      var selected = allItems.filter(function (item) {
        var itemString = item.id.toString();
        if (selections.indexOf(itemString) > -1) {
          item.set('isSelected', true);
          return true;
        }
      });

      this.set('selectedItems', selected);
    },
    updateSelections: function updateSelections() {
      var selected = this.get('allItems').filter(function (item) {
        return item.isSelected === true;
      });
      this.set('selectedItems', selected);
      this.get('onChange')(this.get('selectedItems'));
    },


    selectedChanged: Ember.observer('selected', function () {
      if (this.get('selected') === null) {
        this.get('allItems').forEach(function (item) {
          return item.set('isSelected', false);
        });
        this.initSelections();
      }
    }),

    select: function select(items) {
      if (!items.length) {
        return;
      }
      items.forEach(function (item) {
        item.toggleProperty('isSelected');
      });
      this.get('currentSelectedItems').forEach(function (item) {
        item.set('isCurrent', false);
      });
      this.set('currentDeselectedItems', []);
      this.set('currentSelectedItems', items);
      this.updateSelections();
    },
    deselect: function deselect(items) {
      if (!items.length) {
        return;
      }
      items.forEach(function (item) {
        item.toggleProperty('isSelected');
      });
      this.get('currentDeselectedItems').forEach(function (item) {
        item.set('isCurrent', false);
      });
      this.set('currentSelectedItems', []);
      this.set('currentDeselectedItems', items);
      this.updateSelections();
    },
    hideDropDown: function hideDropDown() {
      this.set('dropdownExpanded', false);
    },
    onUpDownHandel: function onUpDownHandel(keyCode) {
      var newFocusIndex = void 0;
      var oldFocusIndex = parseInt(Ember.$(':focus').attr('tabindex'));

      if (keyCode === UP_KEY) {
        newFocusIndex = oldFocusIndex - 1;
      } else {
        newFocusIndex = oldFocusIndex + 1;
      }
      Ember.$('input[tabindex=' + newFocusIndex + '], button[tabindex=' + newFocusIndex + '], li[tabindex=' + newFocusIndex + ']').focus();
    },
    onTabHandel: function onTabHandel(currentTabIndex) {
      var tabIndex = void 0;

      switch (currentTabIndex) {
        case INPUT_TABINDEX:
          {
            tabIndex = SELECT_BTN_TABINDEX;
            break;
          }
        case SELECT_BTN_TABINDEX:
          {
            tabIndex = DESELECT_BTN_TABINDEX;
            break;
          }
        case DESELECT_BTN_TABINDEX:
          {
            tabIndex = INPUT_TABINDEX;
            break;
          }
        default:
          {
            tabIndex = INPUT_TABINDEX;
          }
      }

      Ember.$('input[tabindex=' + tabIndex + '], button[tabindex=' + tabIndex + '], li[tabindex=' + tabIndex + ']').focus();
    },
    onShiftTabHandler: function onShiftTabHandler(currentTabIndex) {
      var tabIndex = void 0;

      switch (currentTabIndex) {
        case INPUT_TABINDEX:
          {
            tabIndex = DESELECT_BTN_TABINDEX;
            break;
          }
        case SELECT_BTN_TABINDEX:
          {
            tabIndex = INPUT_TABINDEX;
            break;
          }
        case DESELECT_BTN_TABINDEX:
          {
            tabIndex = SELECT_BTN_TABINDEX;
            break;
          }
        default:
          {
            tabIndex = INPUT_TABINDEX;
          }
      }

      Ember.$('input[tabindex=' + tabIndex + '], button[tabindex=' + tabIndex + '], li[tabindex=' + tabIndex + ']').focus();
    },
    keyUp: function keyUp(event) {
      // close box on ESC key
      if (event.keyCode === ESCAPE_KEY) {
        this.hideDropDown();
      }
    },
    keyDown: function keyDown(event) {
      var code = event.keyCode;
      if (code === TAB_KEY) {
        event.preventDefault();
        var currentTabIndex = parseInt(Ember.$(':focus').attr('tabindex'));

        if (event.shiftKey) {
          this.onShiftTabHandler(currentTabIndex);
        } else {
          this.onTabHandel(currentTabIndex);
        }
      } else if (code === UP_KEY || code === DOWN_KEY) {
        event.preventDefault();

        this.onUpDownHandel(event.keyCode);
        this.addFocusClassToCheckbox();
      } else if (code === SPACE_KEY) {
        event.preventDefault();

        this.triggerCheckboxAction();
      } else if (code === ENTER_KEY) {
        if (Ember.$(':focus').is('input')) {
          event.preventDefault();
        } else if (Ember.$(':focus').is('li')) {
          event.preventDefault();
          this.hideDropDown();
        }
      }
    },

    /**
     * Runs some jobs when the list is closed.
     *  * Clears the list if `clearOnClose` is true
     *  * Submits the list if `submitOnClose` is true
     */
    processClose: Ember.observer('dropdownExpanded', function () {
      if (this.get('dropdownExpanded')) {
        return;
      }

      if (this.get('clearOnClose')) {
        this.get('selectedItems').forEach(function (item) {
          return item.set('isSelected', false);
        });
      }

      if (this.get('submitOnClose')) {
        if (Ember.isBlank(this.get('submit'))) {
          throw new Error("No submit action linked");
        }
        this.send('submit');
      }
    }),
    actions: {
      /**
       * Selects filtered items if text filter is set, else select everything
       */
      selectAll: function selectAll() {
        var items = this.get('filteredItems');
        items.forEach(function (item) {
          item.set('isSelected', true);
          item.set('isCurrent', false);
        });
        this.set('currentDeselectedItems', []);
        this.updateSelections();
      },
      deselectAll: function deselectAll() {
        var items = this.get('filteredItems');
        items.forEach(function (item) {
          item.set('isSelected', false);
          item.set('isCurrent', false);
        });
        this.set('currentSelectedItems', []);
        this.updateSelections();
      },
      select: function select(items) {
        this.select(items);
      },
      deselect: function deselect(items) {
        this.deselect(items);
      },
      toggleOpen: function toggleOpen() {
        if (!this.get('isInputDisabled')) {
          this.toggleOpen();
        }
      },
      addCurrentItem: function addCurrentItem(item, event) {
        if (item.get('isSelected')) {
          if (event.ctrlKey) {
            if (item.get('isCurrent')) {
              item.set('isCurrent', false);
              this.get('currentSelectedItems').removeObject(item);
            } else {
              item.set('isCurrent', true);
              this.get('currentSelectedItems').addObject(item);
            }
          } else {
            this.get('currentSelectedItems').forEach(function (item) {
              item.set('isCurrent', false);
            });
            this.set('currentSelectedItems', []);
            item.set('isCurrent', true);
            this.get('currentSelectedItems').addObject(item);
          }
        } else {
          if (event.ctrlKey) {
            item.set('isCurrent', !item.get('isCurrent'));
            this.get('currentDeselectedItems').addObject(item);
          } else {
            this.get('currentDeselectedItems').forEach(function (item) {
              item.set('isCurrent', false);
            });
            this.set('currentDeselectedItems', []);
            item.set('isCurrent', true);
            this.get('currentDeselectedItems').addObject(item);
          }
        }
      },
      addAndSelect: function addAndSelect(item) {
        if (item.get('isSelected')) {
          this.deselect([item]);
        } else {
          this.select([item]);
        }

        this.updateSelections();
      },


      /**
       * Use if you want to add Cancel and Submit buttons for component
       * */
      submit: function submit() {
        this.get('submit')(this.get('selectedItems'));
        this.toggleOpen();
      },
      cancel: function cancel() {
        this.get('filteredItems').forEach(function (item) {
          return item.set('isSelected', false);
        });
        this.toggleOpen();
      }
    }
  });
});