define('backoffice/routes/admin/dashboard', ['exports', 'backoffice/mixins/admin-route-mixin', 'ember-simple-auth/mixins/authenticated-route-mixin', 'backoffice/mixins/errors-handler', 'backoffice/config/environment'], function (exports, _adminRouteMixin, _authenticatedRouteMixin, _errorsHandler, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_adminRouteMixin.default, _authenticatedRouteMixin.default, _errorsHandler.default, {
    title: 'Dashboard',
    model: function model() {
      var _this = this;

      var token = this.get('session').get('data.authenticated.token.access_token');

      var queryParams = {
        url: _environment.default.APP.apiHost + '/api/helper/dashboard/lastUpdate',
        async: true,
        headers: {
          Authorization: 'Bearer ' + token
        },
        type: 'GET',
        contentType: 'application/x-www-form-urlencoded',
        dataType: 'json'
      };

      return new Promise(function (resolve) {
        Ember.$.ajax(queryParams).then(function (response) {
          var lastUpdateData = response;
          return resolve(Ember.RSVP.hash({
            merchantPerformances: _this.store.query('merchant-performance', { period: 'day' }),
            countryPerformances: _this.store.query('country-performance', { period: 'day', topCountriesCount: 5 }),
            processingCompanies: _this.store.query('processing-company', { customerId: _this.get('accountStorage').get('customer').id }),
            lastUpdate: lastUpdateData
          }));
        }).catch(function () {
          return resolve(Ember.RSVP.hash({
            merchantPerformances: _this.store.query('merchant-performance', { period: 'day' }),
            countryPerformances: _this.store.query('country-performance', { period: 'day', topCountriesCount: 5 }),
            processingCompanies: _this.store.query('processing-company', { customerId: _this.get('accountStorage').get('customer').id }),
            lastUpdate: {}
          }));
        });
      });
    }
  });
});