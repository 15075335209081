define('backoffice/validators/translation-text', ['exports', 'ember-cp-validations/validators/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var TranslationText = _base.default.extend({
    validate: function validate(value, options /*, model, attribute*/) {
      if (value.translation) {
        if (value.translation.length < options.get('min') || value.translation.length > options.get('max')) {
          return options.get('message');
        } else {
          return true;
        }
      }
    }
  });

  TranslationText.reopenClass({
    getDependentsFor: function getDependentsFor() /* attribute, options */{
      return [];
    }
  });

  exports.default = TranslationText;
});