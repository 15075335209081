define("backoffice/constants/websites", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    WEBSITE_STATUS_PENDING: 0,
    WEBSITE_STATUS_ACCEPTED: 1,
    WEBSITE_STATUS_DENIED: 2,
    WEBSITE_STATUS_BLOCKED: 4
  };
});