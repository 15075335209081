define('backoffice/serializers/website', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      packages: { embedded: 'always' }
    },
    normalize: function normalize() /*modelClass, resourceHash, prop*/{
      return this._super.apply(this, arguments);
    }
  });
});