define('backoffice/helpers/date-from-string', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.dateFromString = dateFromString;
  function dateFromString(params /*, hash*/) {
    return new Date(params[0]);
  }

  exports.default = Ember.Helper.helper(dateFromString);
});