define('backoffice/pods/components/forecast-search-form/component', ['exports', 'backoffice/mixins/errors-handler', 'moment'], function (exports, _errorsHandler, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var rowSettings = {
    component: 'table-row'
  };

  var csvFieldsConvert = {
    'date': function date(value) {
      return (0, _moment.default)(value).format('DD.MM.YYYY');
    }
  };

  exports.default = Ember.Component.extend(_errorsHandler.default, {
    init: function init() {
      this._super.apply(this, arguments);

      this.searchParams = {
        dateFrom: (0, _moment.default)().format('YYYY-MM-DD'),
        dateTo: (0, _moment.default)().format('YYYY-MM-DD'),
        groupBy: 'Acquirer'
      };
      this.rowSettings = rowSettings;
    },

    actions: {
      exportAction: function exportAction(items, columns, ReportTitle) {
        if (items.length < 1) {
          return;
        }
        var rows = [];
        // Title row
        rows.push(Object.values(columns).map(function (col) {
          return col.title;
        }).join(","));
        // Data rows
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = items[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var row = _step.value;

            var data = Object.entries(row).map(function (_ref) {
              var _ref2 = _slicedToArray(_ref, 2),
                  key = _ref2[0],
                  value = _ref2[1];

              if (csvFieldsConvert[key]) {
                return csvFieldsConvert[key](value);
              }
              if (!Number.isNaN(parseFloat(value))) {
                return parseFloat(value).toFixed(2);
              } else {
                return value;
              }
            });
            var rowData = data.join(",");
            rows.push(rowData);
          }
          // Final CSV code
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }

        var csv = rows.join("\r\n");

        var fileName = "MyReport_" + ReportTitle.replace(/ /g, "_");
        var uri = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);

        var link = document.createElement("a");
        link.href = uri;
        link.style = "visibility:hidden";
        link.download = fileName + ".csv";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
      searchAction: function searchAction() {
        var params = this.get('searchParams');
        this.get('searchAction')(params);
      },
      setDateRange: function setDateRange(from, to) {
        this.set('searchParams.dateFrom', from === '' ? null : from);
        this.set('searchParams.dateTo', to === '' ? null : to);
      },
      cancelDatePicker: function cancelDatePicker() {},
      showErrors: function showErrors(param) {
        this.set(param, true);
      },
      selectGroupBy: function selectGroupBy(value) {
        this.set('searchParams.groupBy', value === '' ? null : value);
      },
      showSelectedSummaryColumns: function showSelectedSummaryColumns(items) {
        this.get('showSelectedColumns')({ items: items, type: 'summary' });
      },
      showSelectedDetailColumns: function showSelectedDetailColumns(items) {
        this.get('showSelectedColumns')({ items: items, type: 'detail' });
      }
    }
  });
});