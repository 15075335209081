define('backoffice/pods/components/role-form/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.sortDefinition = ['order'];
    },

    classNames: ['row'],
    customHeader: false,
    isEditable: true,
    isEditMode: false,
    activeRole: null,
    isFormDisabled: false,
    isRoleErrorShown: false,
    resources: null,
    sortedResources: Ember.computed.sort('resources', 'sortDefinition'),
    columnsAtRow: 3,
    actions: {
      setValidationState: function setValidationState(isFormValid) {
        this.set('isFormDisabled', !isFormValid);
      }
    }
  });
});