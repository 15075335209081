define('backoffice/pods/components/table-cell-package-status/component', ['exports', 'backoffice/constants/packages'], function (exports, _packages) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var PACKAGE_EDITABLE_STATUS_DESCRIPTION = _packages.default.PACKAGE_EDITABLE_STATUS_DESCRIPTION,
      PACKAGE_ACTIVE_STATUS_DESCRIPTION = _packages.default.PACKAGE_ACTIVE_STATUS_DESCRIPTION;
  exports.default = Ember.Component.extend({
    tagName: 'td',
    index: null,
    data: null,
    row: null,
    collapsed: null,
    hasActions: Ember.computed('row.websitesAttached', function () {
      return this.get('row.websitesAttached') === 0;
    }),
    statusDescription: Ember.computed('hasActions', function () {
      return this.get('hasActions') ? PACKAGE_EDITABLE_STATUS_DESCRIPTION : PACKAGE_ACTIVE_STATUS_DESCRIPTION;
    })
  });
});