define('backoffice/pods/components/table-cell-radiobutton/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'td',
    index: null,
    data: null,
    row: null,
    collapsed: null,
    groupValue: null,
    isDefaultDisabled: null,
    currentItem: Ember.computed('row', function () {
      return this.get('row.id').toString();
    }),
    actions: {
      select: function select(item) {
        this.get('onSelect')(item);
      }
    }
  });
});