define('backoffice/pods/components/table-row-payout/component', ['exports', 'backoffice/pods/components/table-row/component', 'backoffice/constants/permissions', 'backoffice/constants/payouts'], function (exports, _component, _permissions, _payouts) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var PAYOUTS_REPORT_KEY = _permissions.default.PAYOUTS_REPORT_KEY,
      PAYOUTS_PAY_KEY = _permissions.default.PAYOUTS_PAY_KEY,
      PAYOUTS_VIEW_KEY = _permissions.default.PAYOUTS_VIEW_KEY;
  var STATUS_PAYOUT_PENDING = _payouts.default.STATUS_PAYOUT_PENDING,
      STATUS_PAYOUT_CHECKED = _payouts.default.STATUS_PAYOUT_CHECKED,
      STATUS_PAYOUT_DONE = _payouts.default.STATUS_PAYOUT_DONE;
  exports.default = _component.default.extend({
    permissionsManager: Ember.inject.service(),
    isCancelOperationShown: Ember.computed(function () {
      return this.get('permissionsManager').hasPermission([PAYOUTS_PAY_KEY, PAYOUTS_VIEW_KEY]);
    }),
    isReportOperationShown: Ember.computed(function () {
      return this.get('permissionsManager').hasPermission([PAYOUTS_VIEW_KEY, PAYOUTS_REPORT_KEY]);
    }),
    isSendOperationShown: Ember.computed(function () {
      return this.get('permissionsManager').hasPermission([PAYOUTS_VIEW_KEY, PAYOUTS_PAY_KEY]);
    }),
    isCancelAction: Ember.computed('data.statusId', function () {
      return this.get('data.statusId') === STATUS_PAYOUT_PENDING || this.get('data.statusId') === STATUS_PAYOUT_CHECKED;
    }),
    isReportAction: Ember.computed('data.statusId', function () {
      return this.get('data.statusId') === STATUS_PAYOUT_CHECKED || this.get('data.statusId') === STATUS_PAYOUT_DONE;
    }),
    isSendAction: Ember.computed('data.statusId', function () {
      return this.get('data.statusId') === STATUS_PAYOUT_CHECKED;
    }),
    linesProcessed: Ember.computed('data.linesProcessed', function () {
      return this.get('data.linesProcessed') ? this.get('data.linesProcessed') : 0;
    }),
    actions: {
      ping: function ping(url) {
        this.get('ping')(url);
      }
    }
  });
});