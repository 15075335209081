define('backoffice/instance-initializers/session-events', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(applicationInstance) {
    var applicationRoute = applicationInstance.lookup('route:application');
    var session = applicationInstance.lookup('service:session');

    session.on('authenticationSucceeded', function () {
      // applicationRoute.get('permissions').clear();
      // applicationRoute.get('accountStorage').clear();
    });

    session.on('invalidationSucceeded', function () {
      var currentRoute = applicationRoute.get('router.url').replace(/\//g, ".");

      if (currentRoute.length && currentRoute[0] === '.') {
        currentRoute = currentRoute.substr(1);
      }

      if (currentRoute.length && currentRoute[currentRoute.length - 1] === '.') {
        currentRoute = currentRoute.substr(0, currentRoute.length - 1);
      }

      applicationRoute.get('settingsStorage').set('beforeInvalidationSucceeded', currentRoute);
      applicationRoute.get('accountStorage').remove('account');
      applicationRoute.get('accountStorage').remove('permissions');
    });
  }

  exports.default = {
    initialize: initialize,
    name: 'session-events',
    after: 'ember-simple-auth'
  };
});