define('backoffice/pods/components/table-cell-date/component', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'td',
    index: null,
    data: null,
    row: null,
    collapsed: null,
    date: Ember.computed('data', function () {
      return this.get('data') && (0, _moment.default)(this.get('data')).format('DD/MM/YYYY');
    })
  });
});