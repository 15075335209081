define('backoffice/pods/components/table-row/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'tr',
    data: null,
    index: null,
    columns: null,
    meta: null,
    collapsed: null,
    columnsCount: Ember.computed('columns', function () {
      return this.get('columns').length;
    }),
    actions: {
      toggleDetails: function toggleDetails(collapsed) {
        var param = {
          collapsed: collapsed.collapsed,
          columnsCount: this.get('columnsCount'),
          row: this
        };

        // if it's nesessary add catcher for this action in the template
        if (this.get('toggleDetails')) {
          this.get('toggleDetails')(param);
        }
      }
    }
  });
});