define('backoffice/pods/components/merchant-summary-chart/component', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    data: Ember.computed('merchantSummary', function () {
      var data = [];

      if (this.get('merchantSummary')) {
        this.get('merchantSummary').forEach(function (item) {
          data.push(+item.get('successRatio'));
        });
      }

      return data;
    }),
    categories: Ember.computed('merchantSummary', function () {
      var categories = [];

      if (this.get('merchantSummary')) {
        this.get('merchantSummary').forEach(function (item) {
          categories.push((0, _moment.default)(item.get('month'), 'YYYY-MM-DD').format("MMM'YY"));
        });
      }

      return categories;
    }),
    chartOptions: Ember.computed('merchantSummary', function () {
      return {
        chart: {
          type: 'column',
          height: 200
        },
        legend: {
          align: 'right',
          verticalAlign: 'top',
          symbolHeight: 10,
          symbolWidth: 12,
          symbolRadius: 0,
          title: {
            style: {
              fontWeight: 'normal'
            }
          }
        },
        title: {
          text: null,
          align: 'right',
          style: {
            color: '#4A4A4A',
            fontSize: '13px',
            fontWeight: 'normal',
            fill: '#4A4A4A'
          },
          useHTML: true
        },
        loading: {
          style: {
            color: '#000000'
          }
        },
        xAxis: {
          type: 'datetime',
          categories: this.get('categories')
        },
        yAxis: {
          labels: {
            enabled: false
          },
          title: {
            text: null
          }
        }
      };
    }),
    series: Ember.computed('merchantSummary', function () {
      return [{
        name: 'Success Ratio',
        color: 'rgb(0, 179, 119)',
        data: this.get('data'),
        dataLabels: {
          enabled: true,
          align: 'center',
          color: '#000000',
          format: '{y:.0f}%',
          style: {
            color: 'contrast',
            fontSize: '11px',
            fontWeight: 'bold',
            textOutline: '0px 0px contrast'
          },
          y: 25
        },
        tooltip: {
          headerFormat: '<span style="font-size: 12px; font-weight: bold;">{series.name}.</span><br/>',
          pointFormatter: function pointFormatter() {
            return this.category + ': ' + this.y + '%';
          }
        }
      }];
    })
  });
});