define('backoffice/pods/components/table-cell-other-amounts/component', ['exports', 'backoffice/constants/currencies'], function (exports, _currencies) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var ISO_USD = _currencies.default.ISO_USD,
      ISO_EUR = _currencies.default.ISO_EUR;
  exports.default = Ember.Component.extend({
    tagName: 'td',
    index: null,
    data: null,
    row: null,
    prices: Ember.computed.filter('data', function (price) {
      return price.get('iso') !== ISO_USD && price.get('iso') !== ISO_EUR;
    }),
    collapsed: false,
    click: function click() {
      if (this.get('isTruncated')) {
        this.toggleProperty('collapsed');
        if (this.get('toggleDetails')) {
          this.get('toggleDetails')({ collapsed: this.get('collapsed') });
        }
      }
    }
  });
});