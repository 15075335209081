define('backoffice/pods/components/table-cell-eur-amount/component', ['exports', 'backoffice/constants/currencies'], function (exports, _currencies) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var ISO_EUR = _currencies.default.ISO_EUR;
  exports.default = Ember.Component.extend({
    tagName: 'td',
    index: null,
    data: null,
    row: null,
    realSum: Ember.computed('data', function () {
      var eurPrice = this.get('data').findBy('iso', ISO_EUR);
      return eurPrice ? (parseInt(eurPrice.get('price')) / 100).toFixed(2) : '-';
    }),
    collapsed: false,
    click: function click() {
      if (this.get('isTruncated')) {
        this.toggleProperty('collapsed');
        if (this.get('toggleDetails')) {
          this.get('toggleDetails')({ collapsed: this.get('collapsed') });
        }
      }
    }
  });
});