define('backoffice/pods/components/table-cell-website-status/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'td',
    index: null,
    data: null,
    row: null,
    collapsed: null,
    status: Ember.computed('data', 'row.status', function () {
      if (this.get('data')) {
        return this.get('row.hasPendingChanges') ? 'websites.statuses.pending' : 'websites.statuses.live';
      }

      return 'websites.statuses.test';
    }),
    statusTooltip: Ember.computed('data', 'row.status', function () {
      if (this.get('data')) {
        return this.get('row.hasPendingChanges') ? 'websites.tooltips.status.pending' : 'websites.tooltips.status.live';
      }

      return 'websites.tooltips.status.test';
    }),
    isLiveStatus: Ember.computed('data', function () {
      return this.get('data');
    })
  });
});