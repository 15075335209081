define('backoffice/pods/components/reset-password-form/component', ['exports', 'backoffice/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    intl: Ember.inject.service(),
    identification: null,
    serverTokenEndpoint: _environment.default.APP.apiHost + '/api/security/restore',
    message: false,
    errorMessage: '',
    successMessage: '',
    isLoading: false,
    actions: {
      resetPassword: function resetPassword() {
        var _this = this;

        var serverTokenEndpoint = this.get('serverTokenEndpoint');
        this.set('isLoading', true);

        Ember.$.ajax({
          url: serverTokenEndpoint,
          data: JSON.stringify({
            login: '' + this.get('identification')
          }),
          type: 'POST',
          contentType: 'application/json', // Updated content type
          dataType: 'json'
        }).then(function () /*response*/{
          _this.set('message', _this.get('intl').t('password.resetMessage'));
          _this.set('errorMessage', false);
          _this.set('successMessage', true);
          _this.set('isLoading', false);
        }, function (error) {
          var errorText = void 0;

          switch (error.status) {
            case 0:
              errorText = error.statusText ? error.statusText : _this.get('intl').t('common.errors.serverNotAvailable');
              break;
            default:
              if (error.responseText) {
                errorText = _this.get('intl').t('common.errors.' + error.responseText, { default: 'common.errors.noErrorMessage' });
              } else if (error.statusText) {
                errorText = _this.get('intl').t('common.errors.' + error.statusText, { default: 'common.errors.noErrorMessage' });
              } else {
                errorText = _this.get('intl').t('common.errors.noErrorMessage');
              }
          }
          _this.set('message', errorText);
          _this.set('successMessage', false);
          _this.set('errorMessage', true);
          _this.set('isLoading', false);
        });
      }
    }
  });
});