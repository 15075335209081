define('backoffice/controllers/admin/profile', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    isDialogShown: false,
    isTzChanged: false,
    isTzEnabled: true
  });
});