define('backoffice/pods/components/offer-settings-dynamic-view/component', ['exports', 'backoffice/constants/offers'], function (exports, _offers) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var OFFER_ONESHOT_TYPEID = _offers.default.OFFER_ONESHOT_TYPEID,
      OFFER_UPSELL_TYPEID = _offers.default.OFFER_UPSELL_TYPEID;
  exports.default = Ember.Component.extend({
    tagName: '',
    isDynamicPricing: Ember.Object.create({
      isSelected: true,
      name: 'Dynamic pricing offer'
    }),
    isDynamicPricingShow: Ember.computed('newOffer.isDynamicPricing', function () {
      var id = parseInt(this.get('currentOfferType.id'));
      return this.get('newOffer.isDynamicPricing') && (id === OFFER_ONESHOT_TYPEID || id === OFFER_UPSELL_TYPEID);
    }),
    disabled: false
  });
});