define('backoffice/helpers/escape-css', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.helper(function (params /*, options*/) {
    var escaped = Ember.Handlebars.Utils.escapeExpression(params[0]);
    //console.log('Value: ' + value + '; Escaped: ' + escaped + '; Safe: ' + Ember.Handlebars.SafeString(escaped));
    return Ember.String.htmlSafe(escaped);
  });
});