define('backoffice/pods/components/location-ratio-chart/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    data: null,
    intl: Ember.inject.service(),
    createDataset: function createDataset(dataType, isNumber) {
      if (this.get('data')) {
        return this.get('data').map(function (item) {
          return isNumber ? parseFloat(item.get(dataType)) : item.get(dataType);
        });
      } else {
        return [];
      }
    },

    successRatio: Ember.computed('data', function () {
      return this.createDataset('current', true);
    }),
    successRatioRef: Ember.computed('data', function () {
      return this.createDataset('reference', true);
    }),
    categories: Ember.computed('data', function () {
      return this.createDataset('iso', false);
    }),
    chartOptions: Ember.computed('data', function () {
      return {
        chart: {
          type: 'column',
          height: 300
        },
        legend: {
          align: 'right',
          verticalAlign: 'top',
          symbolHeight: 10,
          symbolWidth: 12,
          symbolRadius: 0,
          title: {
            style: { fontWeight: 'normal' }
          }
        },
        title: {
          text: null,
          align: 'right',
          style: {
            color: '#4A4A4A',
            fontSize: '13px',
            fontWeight: 'normal',
            fill: '#4A4A4A'
          },
          useHTML: true
        },
        loading: {
          labelStyle: {
            color: '#000000',
            fontWeight: 'bold'
          }
        },
        fillOpacity: 0.3,
        xAxis: {
          labels: {
            enabled: true
          },
          title: {
            text: 'Location',
            style: {
              fontWeight: 'bold',
              color: '#000000'
            }
          },
          categories: this.get('categories')
        },
        yAxis: {
          labels: {
            enabled: true
          },
          title: {
            text: 'Ratio',
            style: {
              fontWeight: 'bold',
              color: '#000000'
            }
          },
          tickInterval: 0.25
        },
        plotOptions: {
          series: {
            groupPadding: 0.1
          }
        }
      };
    }),
    series: Ember.computed('data', function () {
      return [{
        name: 'Success Ratio (Reference Period)',
        color: '#CCCCCC',
        data: this.get('successRatioRef'),
        dataLabels: {
          enabled: true,
          allowOverlap: false,
          align: 'center',
          color: '#000000',
          style: {
            color: 'contrast',
            fontSize: '11px',
            fontWeight: 'normal',
            textOutline: '0px 0px contrast'
          }
        },
        tooltip: {
          headerFormat: '<span style="font-size: 12px; font-weight: bold;">{series.name}.</span><br/>',
          pointFormatter: function pointFormatter() {
            return this.category + ': ' + this.get('intl').formatNumber(this.y);
          }
        }
      }, {
        name: 'Success Ratio MTD',
        color: '#71B37C',
        data: this.get('successRatio'),
        dataLabels: {
          enabled: true,
          allowOverlap: false,
          align: 'center',
          color: '#000000',
          style: {
            color: 'contrast',
            fontSize: '11px',
            fontWeight: 'normal',
            textOutline: '0px 0px contrast'
          }
        },
        tooltip: {
          headerFormat: '<span style="font-size: 12px; font-weight: bold;">{series.name}.</span><br/>',
          pointFormatter: function pointFormatter() {
            return this.category + ': ' + this.get('intl').formatNumber(this.y);
          }
        }
      }];
    })
  });
});