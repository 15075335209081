define('backoffice/controllers/admin/support/tickets', ['exports', 'backoffice/constants/support'], function (exports, _support) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var FILTERS_LIST = _support.default.FILTERS_LIST,
      DEFAULT_STATUS_FILTER = _support.default.DEFAULT_STATUS_FILTER,
      TICKETS_ON_PAGE = _support.default.TICKETS_ON_PAGE;
  exports.default = Ember.Controller.extend({
    queryParams: ['page', 'size', 'filter'],
    page: null,
    size: TICKETS_ON_PAGE,
    filter: null,
    isNewButtonVisible: true,
    statusFilter: DEFAULT_STATUS_FILTER,
    subjectFilter: '',
    filterEntries: FILTERS_LIST,
    activeTicketId: null,
    actions: {
      pageChanged: function pageChanged(page) {
        this.replaceRoute('admin.support.tickets', { queryParams: { page: page } });
        return true;
      },
      filterByStatus: function filterByStatus(item) {
        this.set('statusFilter', item);
        this.replaceRoute('admin.support.tickets', { queryParams: { filter: item, page: null, subject: this.get('subjectFilter') } });
      },
      filterBySubject: function filterBySubject(item) {
        this.replaceRoute('admin.support.tickets', { queryParams: { filter: this.get('statusFilter'), page: null, subject: item } });
      }
    }
  });
});