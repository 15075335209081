define('backoffice/helpers/in-values', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.inValues = inValues;
  function inValues(params, _ref) {
    var needle = _ref.needle;

    return params.includes(needle);
  }

  exports.default = Ember.Helper.helper(inValues);
});