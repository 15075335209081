define('backoffice/pods/components/table-cell-settlement-status/component', ['exports', 'backoffice/constants/reports'], function (exports, _reports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var SETTLEMENT_APPROVED_STATUS = _reports.default.SETTLEMENT_APPROVED_STATUS,
      SETTLEMENT_APPROVED_DESCRIPTION = _reports.default.SETTLEMENT_APPROVED_DESCRIPTION,
      SETTLEMENT_PAID_STATUS = _reports.default.SETTLEMENT_PAID_STATUS,
      SETTLEMENT_PAID_DESCRIPTION = _reports.default.SETTLEMENT_PAID_DESCRIPTION,
      SETTLEMENT_SETTLED_STATUS = _reports.default.SETTLEMENT_SETTLED_STATUS,
      SETTLEMENT_SETTLED_DESCRIPTION = _reports.default.SETTLEMENT_SETTLED_DESCRIPTION,
      SETTLEMENT_REJECTED_STATUS = _reports.default.SETTLEMENT_REJECTED_STATUS,
      SETTLEMENT_REJECTED_DESCRIPTION = _reports.default.SETTLEMENT_REJECTED_DESCRIPTION,
      SETTLEMENT_RETURNED_STATUS = _reports.default.SETTLEMENT_RETURNED_STATUS,
      SETTLEMENT_RETURNED_DESCRIPTION = _reports.default.SETTLEMENT_RETURNED_DESCRIPTION;
  exports.default = Ember.Component.extend({
    tagName: 'td',
    index: null,
    data: null,
    row: null,
    collapsed: null,
    status: Ember.computed('data', function () {
      var status = '';

      switch (this.get('data')) {
        case SETTLEMENT_APPROVED_STATUS:
          status = SETTLEMENT_APPROVED_DESCRIPTION;
          break;
        case SETTLEMENT_PAID_STATUS:
          status = SETTLEMENT_PAID_DESCRIPTION;
          break;
        case SETTLEMENT_SETTLED_STATUS:
          status = SETTLEMENT_SETTLED_DESCRIPTION;
          break;
        case SETTLEMENT_REJECTED_STATUS:
          status = SETTLEMENT_REJECTED_DESCRIPTION;
          break;
        case SETTLEMENT_RETURNED_STATUS:
          status = SETTLEMENT_RETURNED_DESCRIPTION;
          break;
      }
      return status;
    })
  });
});