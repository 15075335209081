define('backoffice/storages/trx-viewer-columns', ['exports', 'ember-local-storage/local/object'], function (exports, _object) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Storage = _object.default.extend();

  Storage.reopenClass({
    initialState: function initialState() {
      // console.log('Storage columns init');

      return {
        settings: [{
          uniqueKey: 'counter',
          isShown: true
        }, {
          uniqueKey: 'originalTransactionType',
          isShown: true
        }, {
          uniqueKey: 'paymentMethod',
          isShown: true
        }, {
          uniqueKey: 'id',
          isShown: true
        }, {
          uniqueKey: 'status',
          isShown: true
        }, {
          uniqueKey: 'attributes',
          isShown: true
        }, {
          uniqueKey: 'transactionDate',
          isShown: true
        }, {
          uniqueKey: 'reason',
          isShown: true
        }, {
          uniqueKey: 'amount',
          isShown: true
        }, {
          uniqueKey: 'currency',
          isShown: true
        }, {
          uniqueKey: 'referedTransaction',
          isShown: true
        }, {
          uniqueKey: 'ip',
          isShown: true
        }, {
          uniqueKey: 'customerWebsiteId',
          isShown: true
        }, {
          uniqueKey: 'offerId',
          isShown: true
        }, {
          uniqueKey: 'provider',
          isShown: true
        }, {
          uniqueKey: 'providerId',
          isShown: true
        }, {
          uniqueKey: 'userName',
          isShown: true
        }, {
          uniqueKey: 'userPassword',
          isShown: true
        }, {
          uniqueKey: 'email',
          isShown: true
        }, {
          uniqueKey: 'country',
          isShown: true
        }, {
          uniqueKey: 'errorCode',
          isShown: true
        }, {
          uniqueKey: 'errorText',
          isShown: true
        }, {
          uniqueKey: 'action',
          isShown: true
        }, {
          uniqueKey: 'apiSource',
          isShown: true
        }, {
          uniqueKey: 'ccBrand',
          isShown: true
        }, {
          uniqueKey: 'cardHolder',
          isShown: true
        }, {
          uniqueKey: 'optionalParam1',
          isShown: true
        }, {
          uniqueKey: 'optionalParam2',
          isShown: true
        }, {
          uniqueKey: 'optionalParam3',
          isShown: true
        }, {
          uniqueKey: 'wlServiceUrl',
          isShown: true
        }, {
          uniqueKey: 'wlServiceName',
          isShown: true
        }, {
          uniqueKey: 'panMasked',
          isShown: true
        }, {
          uniqueKey: 'binCountry',
          isShown: true
        }, {
          uniqueKey: 'flowId',
          isShown: true
        }, {
          uniqueKey: 'payoutId',
          isShown: true
        }, {
          uniqueKey: 'paymentId',
          isShown: true
        }, {
          uniqueKey: 'refundId',
          isShown: true
        }, {
          uniqueKey: 'externalId',
          isShown: true
        }, {
          uniqueKey: 'orderId',
          isShown: true
        }]
      };
    }
  });

  exports.default = Storage;
});