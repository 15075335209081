define('backoffice/routes/admin', ['exports', 'backoffice/mixins/admin-route-mixin', 'backoffice/constants/permissions'], function (exports, _adminRouteMixin, _permissions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_adminRouteMixin.default, {
    session: Ember.inject.service('session'),
    permissionsManager: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      var routeName = transition.targetName;
      var hasPermissionForRoute = _permissions.ACCESSIBLE_PERMISSION_FOR_ROUTE[routeName];

      if (hasPermissionForRoute !== undefined && this.get('session.isAuthenticated') && !this.get('permissionsManager').hasPermission(hasPermissionForRoute, true)) {
        this.transitionTo('admin.no-access');
      }
    },
    model: function model() {
      return { user: this.get('accountStorage').get('account') };
    },

    actions: {
      toggleSideBar: function toggleSideBar() {
        var body = Ember.$('body');
        body.toggleClass('mini-navbar');
        this.controllerFor('admin').toggleProperty('isFullMenu');
        Ember.$('.navbar-minimalize i.fa').toggleClass('fa-chevron-left').toggleClass('fa-chevron-right');
        return false;
      }
    }
  });
});