define('backoffice/pods/components/table-cell-form-position/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'td',
    index: null,
    data: null,
    row: null,
    formPositions: null,
    position: Ember.computed('formPositions', function () {
      if (this.get('formPositions.length')) {
        var item = this.get('formPositions').findBy('offerId', this.get('row.id'));
        if (item) {
          return item.get('position');
        }
      }
      return null;
    }).property('formPositions.@each'),
    collapsed: false,
    click: function click() {
      if (this.get('isTruncated')) {
        this.toggleProperty('collapsed');
        if (this.get('toggleDetails')) {
          this.get('toggleDetails')({ collapsed: this.get('collapsed') });
        }
      }
    }
  });
});