define('backoffice/helpers/lt', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.lt = lt;
  function lt(params) {
    return params.reduce(function (a, b) {
      return a < b;
    });
  }

  exports.default = Ember.Helper.helper(lt);
});