define('backoffice/models/offer-type', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      Model = _emberData.default.Model;

  var Validations = (0, _emberCpValidations.buildValidations)({
    description: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      messageKey: 'offers.validations.required'
    })],
    transactionType: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      messageKey: 'offers.validations.required'
    })]
  });

  exports.default = Model.extend(Validations, {
    description: attr('string'),
    // offerTypeGroupId: attr(),
    transactionType: attr('number')
  });
});