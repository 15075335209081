define('backoffice/constants/currencies', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    ISO_USD: 'USD',
    ISO_EUR: 'EUR'
  };
});