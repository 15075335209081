define('backoffice/pods/components/i-radiobutton/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['i-checks'],
    labelText: null,
    withoutLabel: false,
    groupValue: null,
    groupName: null,
    item: null,
    isDisabled: null,
    isChecked: Ember.computed('groupValue', 'item', function () {
      return this.get('groupValue') === this.get('item');
    }),
    actions: {
      select: function select(item) {
        this.get('onSelect')(item);
      }
    }
  });
});