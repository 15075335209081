define('backoffice/pods/components/offer-settings-translations-input/component', ['exports', 'backoffice/constants/offers', 'ember-cp-validations'], function (exports, _offers, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var PATH_TO_FLAGS = _offers.default.PATH_TO_FLAGS;


  var Validations = (0, _emberCpValidations.buildValidations)({
    lang: [(0, _emberCpValidations.validator)('translation-text', {
      min: 6,
      max: 30,
      messageKey: 'offers.validations.translationText.length'
    })]
  });

  exports.default = Ember.Component.extend(Validations, {
    classNames: ['col-sm-7', 'col-xs-7', 'col-md-7', 'col-lg-7'],
    isTranslationErrorShown: false,
    path: PATH_TO_FLAGS,
    actions: {
      setValidationState: function setValidationState() {
        this.notifyPropertyChange('lang'); //need for validator
        this.set('isInputedTextValid', this.get('validations.attrs.lang.isValid') && this.get('lang.translation'));
      },
      errorHandler: function errorHandler() {
        this.set('isTranslationErrorShown', !this.get('validations.attrs.lang.isValid'));
        this.notifyPropertyChange('lang'); //need for validator
      }
    }
  });
});