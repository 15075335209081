define('backoffice/models/status-performance', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      Model = _emberData.default.Model;
  exports.default = Model.extend({
    month: attr('string'),
    refunded: attr('number'),
    represented: attr('number'),
    cgError: attr('number'),
    unknown: attr('number'),
    unpaid: attr('number'),
    chargedBack: attr('number'),
    paid: attr('number'),
    canceled: attr('number'),
    acquirerError: attr('number'),
    cancelled: attr('number'),
    summary: attr('number'),

    computedRefunded: Ember.computed('refunded', 'summary', function () {
      var value = parseFloat(this.get('refunded'), 10) / parseFloat(this.get('summary'), 10);

      return value * 100;
    }),
    computedRepresented: Ember.computed('represented', 'summary', function () {
      var value = parseFloat(this.get('represented'), 10) / parseFloat(this.get('summary'), 10);

      return value * 100;
    }),
    computedCgError: Ember.computed('cgError', 'summary', function () {
      var value = parseFloat(this.get('cgError'), 10) / parseFloat(this.get('summary'), 10);

      return value * 100;
    }),
    computedUnpaid: Ember.computed('unpaid', 'summary', function () {
      var value = parseFloat(this.get('unpaid'), 10) / parseFloat(this.get('summary'), 10);

      return value * 100;
    }),
    computedPaid: Ember.computed('paid', 'summary', function () {
      var value = parseFloat(this.get('paid'), 10) / parseFloat(this.get('summary'), 10);

      return value * 100;
    }),
    computedCancelled: Ember.computed('cancelled', 'summary', function () {
      var value = parseFloat(this.get('cancelled'), 10) / parseFloat(this.get('summary'), 10);

      return value * 100;
    }),
    computedAcquirerError: Ember.computed('acquirerError', 'summary', function () {
      var value = parseFloat(this.get('acquirerError'), 10) / parseFloat(this.get('summary'), 10);

      return value * 100;
    })
  });
});