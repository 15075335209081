define('backoffice/pods/components/i-switcher/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['onoffswitch'],
    checkedValueBool: Ember.computed('checkedValue', function () {
      return this.get('checkedValue');
    }),
    actions: {
      changeAction: function changeAction() {
        this.toggleProperty('checkedValue');
        this.get('changeAction')(this.get('checkedValue'));
      }
    }
  });
});