define('backoffice/services/util', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    enFormatNumber: function enFormatNumber(number, count) {
      return parseFloat(number).toLocaleString('en-en', { minimumFractionDigits: count });
    }
  });
});