define('backoffice/controllers/admin/transactions/search', ['exports', 'backoffice/config/environment', 'backoffice/constants/transactions', 'backoffice/constants/permissions', 'backoffice/constants/requests'], function (exports, _environment, _transactions, _permissions, _requests) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var TRANSACTIONS_ON_PAGE_LIST = _transactions.default.TRANSACTIONS_ON_PAGE_LIST,
      DEFAULT_TRANSACTIONS_ON_PAGE = _transactions.default.DEFAULT_TRANSACTIONS_ON_PAGE;
  var TRANSACTIONS_EXPORT_KEY = _permissions.default.TRANSACTIONS_EXPORT_KEY,
      FIELD_WHITELABEL_AT_TRANSACTIONS_KEY = _permissions.default.FIELD_WHITELABEL_AT_TRANSACTIONS_KEY,
      FIELD_PANMASK_AT_TRANSACTIONS_KEY = _permissions.default.FIELD_PANMASK_AT_TRANSACTIONS_KEY,
      TRANSACTIONS_CANCEL_KEY = _permissions.default.TRANSACTIONS_CANCEL_KEY;
  var SUCCESS_RESPONSE_STATUS = _requests.default.SUCCESS_RESPONSE_STATUS;


  var defaultSortedProperty = ['numberId:desc'];
  var rowSettings = {
    component: 'table-collapse-row'
  };
  var columnsSettings = [{
    uniqueKey: 'counter',
    component: 'table-cell-counter',
    title: '№',
    cls: 'counter-column counter-width text-center',
    key: null,
    isAllowHide: false,
    withTooltip: false,
    isTruncated: true
  }, {
    uniqueKey: 'originalTransactionType',
    component: 'table-cell-tx-type',
    title: 'Type',
    key: 'originalTransactionType',
    cls: 'originaltransactiontype-column icon-cell type-width text-center',
    type: 'icon-cell',
    isAllowHide: false,
    withTooltip: false,
    isTruncated: true
  }, {
    uniqueKey: 'paymentMethod',
    component: 'table-cell-text',
    title: 'Payment Method',
    key: 'paymentMethod',
    cls: 'paymentMethod-column optional-width nowrap-row',
    isAllowHide: true,
    withTooltip: false,
    isTruncated: true
  }, {
    uniqueKey: 'id',
    component: 'table-cell-text',
    title: 'ID',
    cls: 'transactionid-column id-width text-center',
    key: 'id',
    isAllowHide: false,
    withTooltip: false,
    isTruncated: true
  }, {
    uniqueKey: 'status',
    component: 'table-cell-tx-status',
    title: 'Status',
    key: 'status',
    cls: 'status-column icon-cell status-width text-center',
    type: 'icon-cell',
    isAllowHide: true,
    withTooltip: false,
    isTruncated: true
  }, {
    uniqueKey: 'attributes',
    component: 'table-cell-tx-attributes',
    title: 'Attributes',
    key: 'attributes',
    cls: 'attributes-column icon-cell status-width text-center',
    type: 'icon-cell',
    isAllowHide: true,
    withTooltip: false,
    isTruncated: true
  }, {
    uniqueKey: 'transactionDate',
    component: 'table-cell-date-time',
    title: 'Date',
    key: 'date',
    cls: 'transactiondate-column date-width nowrap-row text-center',
    isAllowHide: true,
    withTooltip: false,
    isTruncated: true
  }, {
    uniqueKey: 'reason',
    component: 'table-cell-text',
    title: 'Reason',
    key: 'reason',
    cls: 'reason-column collapse-width nowrap-row reason-width',
    isAllowHide: true,
    withTooltip: true,
    isTruncated: true
  }, {
    uniqueKey: 'amount',
    component: 'table-cell-amount',
    title: 'Amount',
    cls: 'amount-column amount-width text-right',
    key: 'amount',
    isAllowHide: true,
    withTooltip: false,
    isTruncated: true
  }, {
    uniqueKey: 'currency',
    component: 'table-cell-text',
    title: 'Currency',
    cls: 'currency-column currency-width text-center',
    key: 'currency',
    isAllowHide: true,
    withTooltip: false,
    isTruncated: true
  }, {
    uniqueKey: 'referedTransaction',
    component: 'table-cell-link',
    title: 'RefID',
    cls: 'refid-column refid-width text-center',
    key: 'referedTransaction',
    isAllowHide: true,
    withTooltip: false,
    isTruncated: false
  }, {
    uniqueKey: 'ip',
    component: 'table-cell-text',
    title: 'IP',
    cls: 'ip-column ip-width text-center',
    key: 'ip',
    isAllowHide: true,
    withTooltip: false,
    isTruncated: true
  }, {
    uniqueKey: 'customerWebsiteId',
    component: 'table-cell-text',
    title: 'WID',
    cls: 'wid-column wid-width text-center',
    key: 'customerWebsiteId',
    isAllowHide: true,
    withTooltip: false,
    isTruncated: true
  }, {
    uniqueKey: 'offerId',
    component: 'table-cell-text',
    title: 'OffID',
    cls: 'offid-column offid-width text-center',
    key: 'offerId',
    isAllowHide: true,
    withTooltip: false,
    isTruncated: true
  }, {
    uniqueKey: 'provider',
    component: 'table-cell-provider',
    title: 'Provider',
    cls: 'provider-column wid-width text-center nowrap-row',
    key: 'isTest',
    isAllowHide: false,
    withTooltip: false,
    isTruncated: true
  }, {
    uniqueKey: 'providerId',
    component: 'table-cell-text',
    title: 'Provider ID',
    cls: 'providerid-column wid-width text-center nowrap-row',
    key: 'providerId',
    isAllowHide: true,
    withTooltip: false,
    isTruncated: true
  }, {
    uniqueKey: 'userName',
    component: 'table-cell-text',
    title: 'Username',
    key: 'userName',
    cls: 'username-column username-width collapse-width nowrap-row',
    isAllowHide: true,
    withTooltip: true,
    isTruncated: true
  }, {
    uniqueKey: 'userPassword',
    component: 'table-cell-text',
    title: 'PWD',
    key: 'userPassword',
    cls: 'passwd-column passwd-width collapse-width nowrap-row',
    isAllowHide: true,
    withTooltip: false,
    isTruncated: true
  }, {
    uniqueKey: 'email',
    component: 'table-cell-text',
    title: 'Email',
    key: 'email',
    cls: 'email-column email-width',
    isAllowHide: true,
    withTooltip: false,
    isTruncated: true
  }, {
    uniqueKey: 'country',
    component: 'table-cell-text',
    title: 'IP Country',
    cls: 'country-column country-width text-center nowrap-row',
    key: 'ipCountryIso',
    isAllowHide: true,
    withTooltip: false,
    isTruncated: true
  }, {
    uniqueKey: 'errorCode',
    component: 'table-cell-text',
    title: 'ErCode',
    cls: 'errcode-column errcode-width text-center',
    key: 'errorCode',
    isAllowHide: true,
    withTooltip: false,
    isTruncated: true
  }, {
    uniqueKey: 'errorText',
    component: 'table-cell-text',
    title: 'ErText',
    key: 'errorText',
    cls: 'errtext-column errtext-width collapse-width nowrap-row',
    isAllowHide: true,
    withTooltip: true,
    isTruncated: true
  }, {
    uniqueKey: 'action',
    component: 'table-cell-tx-actions',
    title: 'Action',
    key: 'id',
    cls: 'action-column trx-action-width',
    isAllowHide: true,
    withTooltip: false,
    isTruncated: false
  }, {
    uniqueKey: 'apiSource',
    component: 'table-cell-source',
    title: 'Method',
    cls: 'source-column source-width text-center',
    key: 'apiSource',
    isAllowHide: true,
    withTooltip: false,
    isTruncated: true
  }, {
    uniqueKey: 'ccBrand',
    component: 'table-cell-text',
    title: 'Brand',
    cls: 'cardbrand-column brand-width text-center',
    key: 'ccBrand',
    isAllowHide: true,
    withTooltip: false,
    isTruncated: true
  }, {
    uniqueKey: 'cardHolder',
    component: 'table-cell-text',
    title: 'Card Holder',
    key: 'cardHolder',
    cls: 'cardholder-column cardholder-width collapse-width nowrap-row',
    isAllowHide: true,
    withTooltip: true,
    isTruncated: true
  }, {
    uniqueKey: 'optionalParam1',
    component: 'table-cell-text',
    title: 'OP1 param',
    key: 'optionalParam1',
    cls: 'optional1-column optional-width collapse-width nowrap-row',
    isAllowHide: true,
    withTooltip: false,
    isTruncated: true
  }, {
    uniqueKey: 'optionalParam2',
    component: 'table-cell-text',
    title: 'OP2 param',
    key: 'optionalParam2',
    cls: 'optional2-column optional-width collapse-width nowrap-row',
    isAllowHide: true,
    withTooltip: true,
    isTruncated: true
  }, {
    uniqueKey: 'optionalParam3',
    component: 'table-cell-text',
    title: 'OP3 param',
    key: 'optionalParam3',
    cls: 'optional3-column optional-width collapse-width nowrap-row',
    isAllowHide: true,
    withTooltip: false,
    isTruncated: true
  }, {
    uniqueKey: 'wlServiceUrl',
    component: 'table-cell-text',
    title: 'Service URL',
    key: 'wlServiceUrl',
    cls: 'wlserviceurl-column optional-width collapse-width nowrap-row',
    isAllowHide: true,
    withTooltip: false,
    isTruncated: true
  }, {
    uniqueKey: 'wlServiceName',
    component: 'table-cell-text',
    title: 'Service Name',
    key: 'wlServiceName',
    cls: 'wlservicename-column optional-width collapse-width nowrap-row',
    isAllowHide: true,
    withTooltip: false,
    isTruncated: true
  }, {
    uniqueKey: 'panMasked',
    component: 'table-cell-text',
    title: 'PAN Mask',
    key: 'panMasked',
    cls: 'panmasked-column optional-width collapse-width nowrap-row',
    isAllowHide: true,
    withTooltip: false,
    isTruncated: true
  }, {
    uniqueKey: 'binCountry',
    component: 'table-cell-text',
    title: 'BIN Country',
    cls: 'bincountry-column country-width text-center nowrap-row',
    key: 'binCountry',
    isAllowHide: true,
    withTooltip: false,
    isTruncated: true
  }, {
    uniqueKey: 'flowId',
    component: 'table-cell-text',
    title: 'Flow ID',
    key: 'flowId',
    cls: 'flowid-column optional-width collapse-width nowrap-row',
    isAllowHide: true,
    withTooltip: false,
    isTruncated: true
  }, {
    uniqueKey: 'payoutId',
    component: 'table-cell-text',
    title: 'Payout ID',
    key: 'payoutId',
    cls: 'payoutid-column optional-width nowrap-row',
    isAllowHide: true,
    withTooltip: false,
    isTruncated: true
  }, {
    uniqueKey: 'paymentId',
    component: 'table-cell-text',
    title: 'Payment ID',
    key: 'paymentId',
    cls: 'paymentid-column optional-width nowrap-row',
    isAllowHide: true,
    withTooltip: false,
    isTruncated: true
  }, {
    uniqueKey: 'refundId',
    component: 'table-cell-text',
    title: 'Refund ID',
    key: 'refundId',
    cls: 'refundid-column optional-width nowrap-row',
    isAllowHide: true,
    withTooltip: false,
    isTruncated: true
  }, {
    uniqueKey: 'externalId',
    component: 'table-cell-text',
    title: 'External ID',
    key: 'externalId',
    cls: 'externalid-column optional-width nowrap-row',
    isAllowHide: true,
    withTooltip: false,
    isTruncated: true
  }, {
    uniqueKey: 'orderId',
    component: 'table-cell-text',
    title: 'Order ID',
    key: 'orderId',
    cls: 'orderid-column optional-width nowrap-row',
    isAllowHide: true,
    withTooltip: false,
    isTruncated: true
  }, {
    uniqueKey: 'rrn',
    component: 'table-cell-text',
    title: 'RRN',
    key: 'rrn',
    cls: 'rrn-column optional-width nowrap-row text-center',
    isAllowHide: true,
    withTooltip: false,
    isTruncated: true
  }, {
    uniqueKey: 'op1',
    component: 'table-cell-text',
    title: 'op1',
    key: 'op1',
    cls: 'op1-column optional-width nowrap-row text-center',
    isAllowHide: true,
    withTooltip: false,
    isTruncated: true
  }, {
    uniqueKey: 'op2',
    component: 'table-cell-text',
    title: 'op2',
    key: 'op2',
    cls: 'op2-column optional-width nowrap-row text-center',
    isAllowHide: true,
    withTooltip: false,
    isTruncated: true
  }, {
    uniqueKey: 'op3',
    component: 'table-cell-text',
    title: 'op3',
    key: 'op3',
    cls: 'op3-column optional-width nowrap-row text-center',
    isAllowHide: true,
    withTooltip: false,
    isTruncated: true
  }];

  exports.default = Ember.Controller.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.defaultSortedProperty = defaultSortedProperty;
      this.rowSettings = rowSettings;
      this.columnsSettings = columnsSettings;
    },

    session: Ember.inject.service('session'),
    permissionsManager: Ember.inject.service(),
    queryParams: ['dateFrom', 'dateTo', 'status', 'transactionType', 'isoCountry', 'transactionId', 'referredId', 'provider', 'ip', 'websiteId', 'offer', 'source', 'ccNumber', 'ccLastSixDigits', 'cardHolder', 'cardBrand', 'email', 'userName', 'password', 'usernameType', 'page', 'ipParam', 'cardNumberParam', 'cardHolderParam', 'emailParam', 'usernameParam', 'passwordParam', 'size', 'payoutId', 'paymentId', 'externalId', 'orderId', 'rrn', 'op1', 'op2', 'op3', 'currency'],
    isDialogShown: false,
    isResendCallbackDialogShown: false,
    loadingState: false,
    dateFrom: null,
    dateTo: null,
    status: null,
    transactionType: null,
    isoCountry: null,
    transactionId: null,
    provider: null,
    referredId: null,
    ip: null,
    websiteId: null,
    offer: null,
    source: null,
    ccNumber: null,
    ccLastSixDigits: null,
    cardHolder: null,
    cardBrand: null,
    email: null,
    userName: null,
    password: null,
    usernameType: null,
    page: null,
    ipParam: null,
    payoutId: null,
    paymentId: null,
    externalId: null,
    orderId: null,
    cardNumberParam: null,
    cardHolderParam: null,
    emailParam: null,
    usernameParam: null,
    passwordParam: null,
    rrn: null,
    currency: null,
    op1: null,
    op2: null,
    op3: null,
    size: null,
    entriesOnPage: TRANSACTIONS_ON_PAGE_LIST,
    defaultEntriesOnPage: DEFAULT_TRANSACTIONS_ON_PAGE,
    isActionColumnShown: Ember.computed(function () {
      return this.get('permissionsManager').hasPermission(TRANSACTIONS_CANCEL_KEY);
    }),
    isWhitelistColumnsShown: Ember.computed(function () {
      return this.get('permissionsManager').hasPermission(FIELD_WHITELABEL_AT_TRANSACTIONS_KEY);
    }),
    isPanmaskColumnShown: Ember.computed(function () {
      return this.get('permissionsManager').hasPermission(FIELD_PANMASK_AT_TRANSACTIONS_KEY);
    }),
    isExportShow: Ember.computed(function () {
      return this.get('permissionsManager').hasPermission(TRANSACTIONS_EXPORT_KEY);
    }),
    chosenEntriesOnPage: Ember.computed('size', function () {
      return this.get('size') || this.get('defaultEntriesOnPage');
    }),
    tableId: 'transactions-table',
    filter: null,
    columnsStorageSettings: Ember.computed(function () {
      var settingsStorage = this.get('settingsStorage');
      var savedSettings = settingsStorage.get('trx-viewer-columns');

      if (savedSettings) {
        return savedSettings;
      }
      var defaultSettings = this.get('columnsSettings').map(function (item) {
        return {
          uniqueKey: item.uniqueKey,
          isShown: true
        };
      });

      settingsStorage.set('trx-viewer-columns', defaultSettings);
      return defaultSettings;
    }),
    columns: Ember.computed('columnsStorageSettings', 'columnsSettings', 'isActionColumnShown', 'isWhitelistColumnsShown', 'isPanmaskColumnShown', function () {
      var columnsSettings = this.get('columnsSettings');
      var columnsStorageSettings = this.get('columnsStorageSettings');
      var settings = void 0;

      if (!this.get('isActionColumnShown')) {
        columnsSettings = columnsSettings.filter(function (item) {
          return item.uniqueKey !== 'action';
        });
        columnsStorageSettings = columnsStorageSettings.filter(function (item) {
          return item.uniqueKey !== 'action';
        });
      }

      if (!this.get('isWhitelistColumnsShown')) {
        columnsSettings = columnsSettings.filter(function (item) {
          return item.uniqueKey !== 'wlServiceUrl' && item.uniqueKey !== 'wlServiceName';
        });
        columnsStorageSettings = columnsStorageSettings.filter(function (item) {
          return item.uniqueKey !== 'wlServiceUrl' && item.uniqueKey !== 'wlServiceName';
        });
      }

      if (!this.get('isPanmaskColumnShown')) {
        columnsSettings = columnsSettings.filter(function (item) {
          return item.uniqueKey !== 'panMasked';
        });
        columnsStorageSettings = columnsStorageSettings.filter(function (item) {
          return item.uniqueKey !== 'panMasked';
        });
      }

      settings = columnsSettings.map(function (cSEl) {
        var sameKeyElement = columnsStorageSettings.find(function (cSSEl) {
          return cSSEl.uniqueKey === cSEl.uniqueKey;
        });
        return Ember.merge(cSEl, sameKeyElement);
      });

      return settings;
    }),

    searchString: Ember.computed('model.transactions', function () {
      return window.location.search.replace('?', '');
    }),
    csvUrl: Ember.computed('searchString', function () {
      return _environment.default.APP.apiHost + '/api/transactions/export/csv?access_token=' + this.get('session').get('data.authenticated.token.access_token') + '&' + this.get('searchString');
    }),
    selectedTransaction: null,
    actions: {
      ping: function ping(url) {
        var self = this;
        Ember.$.ajax({
          url: _environment.default.APP.apiHost + '/api/ping',
          data: {},
          async: false,
          headers: {
            Authorization: 'Bearer ' + this.get('session').get('data.authenticated.token.access_token')
          },
          type: 'GET',
          contentType: 'application/x-www-form-urlencoded',
          dataType: 'json',
          complete: function complete(data) {
            if (data.status === SUCCESS_RESPONSE_STATUS) {
              window.open(url);
            } else {
              self.get('session').invalidate();
            }
          }
        });
        return false;
      },
      pageChanged: function pageChanged(page) {
        this.replaceRoute({ queryParams: { page: page } });
        return true;
      },
      showSelectedColumns: function showSelectedColumns(items) {
        if (!items || !items.length) {
          return;
        }

        var columns = this.get('columns'),
            settings = this.get('columnsStorageSettings');

        if (!settings) {
          return;
        }

        columns.forEach(function (col) {
          items.forEach(function (item) {
            if (item.uniqueKey === col.uniqueKey) {
              var colIndex = settings.map(function (el) {
                return el.uniqueKey;
              }).indexOf(col.uniqueKey),
                  updatedElement = settings.objectAt(colIndex);

              // if element exists at columnsStorageSettings.settings
              if (colIndex >= 0) {
                Ember.set(updatedElement, 'isShown', item.isSelected);
              } else {
                settings.addObject({
                  uniqueKey: col.uniqueKey,
                  isShown: item.isSelected
                });
              }

              Ember.set(col, 'isShown', item.isSelected);
            }
          });
        });

        this.get('columnsStorageSettings').set('settings', settings);
        var settingsStorage = this.get('settingsStorage');
        settingsStorage.set('trx-viewer-columns', settings);
      },
      chooseEntriesOnPage: function chooseEntriesOnPage(item) {
        this.set('chosenEntriesOnPage', item);
        this.replaceRoute({ queryParams: { size: item, page: null } });
      }
    }
  });
});