define('backoffice/pods/components/offer-settings-rebill/component', ['exports', 'backoffice/constants/offers'], function (exports, _offers) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var OFFER_MEMBERSHIP_TYPEID = _offers.default.OFFER_MEMBERSHIP_TYPEID;
  exports.default = Ember.Component.extend({
    classNames: ['row'],
    selectedRebill: null,
    isRebillErrorShown: false,
    disabled: false,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.get('offers').forEach(function (item) {
        var id = item.get('id'),
            name = item.get('name');

        if (_this.get('newOffer.rebill') === parseInt(id, 10)) {
          _this.set('selectedRebill', id + ' - ' + name);
        }
      });
    },

    rebillOffers: Ember.computed('offers', function () {
      var rebillOffers = this.get('offers'),
          array = [];

      rebillOffers.forEach(function (offer) {
        var id = offer.get('id'),
            name = offer.get('name');

        if (id && name && parseInt(offer.get('type.id'), 10) === OFFER_MEMBERSHIP_TYPEID) {
          array.push(id + ' - ' + name);
        }
      });

      return array;
    }),
    actions: {
      setRebill: function setRebill(rebill) {
        var id = parseInt(rebill, 10);
        this.set('selectedRebill', rebill);
        this.set('newOffer.rebill', id === this.get('newOffer.id') ? 0 : id);
      },
      showErrors: function showErrors(param) {
        this.set(param, true);
      }
    }
  });
});