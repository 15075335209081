define('backoffice/routes/admin/transactions', ['exports', 'backoffice/mixins/admin-route-mixin', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _adminRouteMixin, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_adminRouteMixin.default, _authenticatedRouteMixin.default, {
    init: function init() {
      this._super.apply(this, arguments);
      this.title = this.get('intl').t('transactions.title');
    },

    togglesInit: Ember.inject.service('toggles-init'),
    model: function model() {
      return Ember.RSVP.hash({
        websites: this.store.findAll('short-website'),
        countries: this.store.findAll('country'),
        statuses: this.store.findAll('status'),
        brands: this.store.findAll('brand')
      });
    },
    setupController: function setupController(ctrl, model) {
      this._super(ctrl, model);
      this.get('togglesInit').initToggles(ctrl);
    },

    actions: {
      refreshPage: function refreshPage() {
        this.refresh();
      }
    }
  });
});