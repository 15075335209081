define('backoffice/pods/components/main-header/component', ['exports', 'ember-rl-dropdown/mixins/rl-dropdown-component', 'backoffice/constants/permissions', 'backoffice/config/environment'], function (exports, _rlDropdownComponent, _permissions, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var DASHBOARD_MERCHANT_PERFORMANCE_KEY = _permissions.default.DASHBOARD_MERCHANT_PERFORMANCE_KEY,
      DASHBOARD_COUNTRY_PERFORMANCE_KEY = _permissions.default.DASHBOARD_COUNTRY_PERFORMANCE_KEY,
      DASHBOARD_TRANSACTION_PERFORMANCE_KEY = _permissions.default.DASHBOARD_TRANSACTION_PERFORMANCE_KEY,
      TRANSACTIONS_VIEW_KEY = _permissions.default.TRANSACTIONS_VIEW_KEY,
      PAYOUTS_VIEW_KEY = _permissions.default.PAYOUTS_VIEW_KEY,
      MEMBERSHIPS_VIEW_KEY = _permissions.default.MEMBERSHIPS_VIEW_KEY,
      WEBSITES_VIEW_KEY = _permissions.default.WEBSITES_VIEW_KEY,
      SUPPORT_ALL_KEY = _permissions.default.SUPPORT_ALL_KEY,
      PERMISSIONS_VIEW_KEY = _permissions.default.PERMISSIONS_VIEW_KEY;


  var isSideBarShown = [DASHBOARD_MERCHANT_PERFORMANCE_KEY, DASHBOARD_COUNTRY_PERFORMANCE_KEY, DASHBOARD_TRANSACTION_PERFORMANCE_KEY, TRANSACTIONS_VIEW_KEY, PAYOUTS_VIEW_KEY, MEMBERSHIPS_VIEW_KEY, WEBSITES_VIEW_KEY, SUPPORT_ALL_KEY, PERMISSIONS_VIEW_KEY];

  exports.default = Ember.Component.extend(_rlDropdownComponent.default, {
    init: function init() {
      this._super.apply(this, arguments);

      this.isSideBarShown = isSideBarShown;
    },

    isEnabledTranslation: _environment.default.APP.enableTranslations,
    session: Ember.inject.service('session'),
    tagName: 'nav',
    classNames: ['navbar', 'navbar-static-top', 'white-bg'],
    attributeBindings: ['role'],
    dropdownExpanded: false,
    actions: {
      logout: function logout() {
        this.toggleProperty('dropdownExpanded');
        this.get('session').invalidate();
      },
      toggleOpen: function toggleOpen() {
        this.toggleProperty('dropdownExpanded');
      }
    }
  });
});