define('backoffice/pods/components/change-user-role-dialog/component', ['exports', 'ember-intl'], function (exports, _emberIntl) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    isDialogShown: false,
    confirmBtnName: (0, _emberIntl.t)('common.save'),
    cancelBtnName: (0, _emberIntl.t)('common.cancel'),
    roleEntries: null,
    chosen: null,
    chosenRole: null,
    roles: Ember.computed('roleEntries', function () {
      return this.get('roleEntries').map(function (item) {
        return {
          id: item.get('id'),
          name: item.get('name')
        };
      });
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.set('chosen', this.get('chosenRole'));
    },

    actions: {
      confirm: function confirm() {
        this.get('confirm')(this.get('chosen.id'));
      },
      cancelOperation: function cancelOperation() {
        this.set('chosen', this.get('chosenRole'));
        this.get('reset')();
      }
    }
  });
});