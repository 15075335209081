define('backoffice/adapters/application', ['exports', 'ember-data', 'ember-simple-auth/mixins/data-adapter-mixin', 'backoffice/config/environment'], function (exports, _emberData, _dataAdapterMixin, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTAdapter.extend(_dataAdapterMixin.default, {
    session: Ember.inject.service(),
    authorize: function authorize(xhr) {
      var _get = this.get('session.data.authenticated'),
          token = _get.token;

      if (this.get('session.isAuthenticated') && token) {
        xhr.setRequestHeader('Authorization', 'Bearer ' + token.access_token);
      }
    },

    namespace: 'api',
    host: '' + _environment.default.APP.apiHost,
    handleResponse: function handleResponse(status, headers, payload) {
      if (payload.errors) {
        return new _emberData.default.AdapterError(payload.errors);
      }
      return this._super.apply(this, arguments);
    }
  });
});