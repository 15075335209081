define('backoffice/pods/components/table-cell-member-offerid/component', ['exports', 'ember-cli-tooltipster/components/tool-tipster'], function (exports, _toolTipster) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _toolTipster.default.extend({
    tagName: 'td',
    store: Ember.inject.service('store'),
    index: null,
    data: null,
    row: null,
    collapsed: null,
    content: Ember.computed('row', function () {
      var offer = this.get('store').peekRecord('shortOffer', parseInt(this.get('row.offerId'), 10));

      return offer ? offer.get('name') : 'No name';
    }),
    click: function click() {
      if (this.get('isTruncated')) {
        this.toggleProperty('collapsed');
        if (this.get('toggleDetails')) {
          this.get('toggleDetails')({ collapsed: this.get('collapsed') });
        }
      }
    }
  });
});