define('backoffice/models/type', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-cp-validations'], function (exports, _model, _attr, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    name: [(0, _emberCpValidations.validator)('presence', true)]
  });

  exports.default = _model.default.extend(Validations, {
    name: (0, _attr.default)('string')
  });
});