define('backoffice/pods/components/table-cell-provider/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'td',
    data: null,
    collapsed: false,
    providerStatus: Ember.computed(function () {
      switch (this.get('data')) {
        case null:
          return 'N/A';
        case 'true':
          return 'TEST';
        case 'false':
          return 'LIVE';
      }
    }),
    click: function click() {
      if (this.get('isTruncated')) {
        this.toggleProperty('collapsed');
        if (this.get('toggleDetails')) {
          this.get('toggleDetails')({ collapsed: this.get('collapsed') });
        }
      }
    }
  });
});