define('backoffice/models/settlement-note', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      Model = _emberData.default.Model;
  exports.default = Model.extend({
    clearingDate: attr('string'),
    paymentDate: attr('string'),
    settlementDate: attr('string'),
    date: attr('string'),
    externalId: attr('string'),
    currency: attr('string'),
    currentAmount: attr('number'),
    reserveAmount: attr('number'),
    totalAmount: attr('number'),
    status: attr('string')
  });
});