define('backoffice/services/permissions-manager', ['exports', 'backoffice/mixins/admin-route-mixin', 'ember-simple-auth/mixins/authenticated-route-mixin', 'backoffice/mixins/errors-handler', 'backoffice/config/environment', 'backoffice/constants/requests'], function (exports, _adminRouteMixin, _authenticatedRouteMixin, _errorsHandler, _environment, _requests) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var CLOSE_NOTIFY_DELAY = _requests.default.CLOSE_NOTIFY_DELAY;
  exports.default = Ember.Service.extend(_adminRouteMixin.default, _authenticatedRouteMixin.default, _errorsHandler.default, {
    serverTokenEndpoint: _environment.default.APP.apiHost + '/api/security/reset',
    session: Ember.inject.service('session'),
    notify: Ember.inject.service('notify'),
    store: Ember.inject.service(),
    intl: Ember.inject.service('intl'),
    _checkPermissions: function _checkPermissions(allPermissions, checkingPermissions, isAny) {
      var i = 0,
          len = checkingPermissions.length;

      allPermissions.forEach(function (permission) {
        checkingPermissions.forEach(function (string) {
          if (isAny) {
            if (string === permission) {
              i = len;
            }
          } else {
            if (string === permission) {
              ++i;
            }
          }
        });
      });

      return checkingPermissions.length === i;
    },
    _checkPermission: function _checkPermission(allPermissions, permission) {
      var hasPermission = false;
      allPermissions.forEach(function (obj) {
        if (obj === permission) {
          hasPermission = true;
        }
      });

      return hasPermission;
    },
    hasPermission: function hasPermission(permission, isAny) {
      var hasPermission = false;
      var allPermissions = this.get('accountStorage').get('permissions') || [];

      if (Array.isArray(permission)) {
        hasPermission = this._checkPermissions(allPermissions, permission, isAny);
      } else {
        hasPermission = this._checkPermission(allPermissions, permission);
      }

      return hasPermission;
    },
    createUser: function createUser(user, roleId) {
      var store = this.get('store'),
          role = store.peekRecord('role', roleId),
          newUser = store.createRecord('account', user);
      newUser.set('role', role);

      return {
        newUserRecord: newUser,
        newUserPromise: newUser.save()
      };
    },
    createRole: function createRole(role) {
      var store = this.get('store'),
          defaultRole = store.peekAll('role').findBy('isDefault', true);
      if (defaultRole) {
        return defaultRole.copy(true, { overwrite: { name: role.name, isDefault: false, accounts: [] } }).then(function (copy) {
          return copy.save();
        });
      }
      var newRole = store.createRecord('role', { name: role.name, isDefault: false, accounts: [], permissions: [] });

      return newRole.save();
    },
    changeRole: function changeRole(user, roleId) {
      var store = this.get('store'),
          role = store.peekRecord('role', roleId);

      user.set('role', role);
      return user.save();
    },
    changeStatus: function changeStatus(user) {
      var _this = this;

      user.set('enabled', !user.get('enabled'));
      user.save().catch(function (error) {
        _this.handleErrors(error);
      });
    },
    resetPass: function resetPass(user) {
      var _this2 = this;

      var notify = this.get('notify');
      Ember.$.ajax({
        url: this.get('serverTokenEndpoint'),
        headers: {
          Authorization: 'Bearer ' + this.get('session').get('data.authenticated.token.access_token')
        },
        data: JSON.stringify({ // convert object to JSON string
          login: user.get('login'),
          id: user.get('id')
        }),
        type: 'POST',
        contentType: 'application/json', // Updated content type
        dataType: 'json'
      }).then(function () /*response*/{
        notify.success(_this2.get('intl').t('users.resetPassword.successNotify', { name: user.get('name'), email: user.get('email') }), { closeAfter: CLOSE_NOTIFY_DELAY });
      }, function (error) {
        notify.error(JSON.parse(error.responseText).message, { closeAfter: CLOSE_NOTIFY_DELAY });
      });
    }
  });
});