define('backoffice/routes/admin/roles', ['exports', 'backoffice/mixins/admin-route-mixin', 'ember-simple-auth/mixins/authenticated-route-mixin', 'backoffice/mixins/errors-handler'], function (exports, _adminRouteMixin, _authenticatedRouteMixin, _errorsHandler) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_adminRouteMixin.default, _authenticatedRouteMixin.default, _errorsHandler.default, {
    init: function init() {
      this._super.apply(this, arguments);
      this.title = this.get('intl').t('roles.title');
    },

    permissionsManager: Ember.inject.service(),
    model: function model() {
      return Ember.RSVP.hash({
        permissions: this.store.findAll('permission'),
        resources: this.store.findAll('resource'),
        roles: this.store.findAll('role'),
        accounts: this.store.findAll('account')
      });
    },

    filteredAccounts: null,

    actions: {
      createUser: function createUser() {
        this.controller.set('isNewUserShowDialog', true);
      },
      createRole: function createRole() {
        this.controller.set('isNewRoleShowDialog', true);
      },
      saveNewUser: function saveNewUser(user, roleId) {
        var _this = this;

        var permissionsManager = this.get('permissionsManager'),
            newUser = permissionsManager.createUser(user, roleId);

        newUser.newUserPromise.then(function (data) {
          var newUserSaved = _this.store.peekRecord('account', data.get('id'));
          _this.controller.set('selectedUser', newUserSaved);

          _this.controller.set('successNewUserDialog', true);
          _this.controller.set('uniqueLoginError', null);
        }, function (error) {
          _this.store.unloadRecord(newUser.newUserRecord);
          if (error.errors.tag === 'LOGIN_NOT_UNIQUE') {
            _this.controller.set('uniqueLoginError', error.errors.msg);
            return;
          }
          _this.handleErrors(error);
        });
      },
      saveNewRole: function saveNewRole(role) {
        var _this2 = this;

        var permissionsManager = this.get('permissionsManager');
        permissionsManager.createRole(role).then(function (data) {
          _this2.controller.set('isNewRoleShowDialog', false);

          var newRole = _this2.store.peekRecord('role', data.get('id'));
          _this2.controller.set('activeRoleAtRoles', newRole);
          _this2.controller.set('isEditMode', true);
          _this2.controller.set('uniqueRoleNameError', null);
          _this2.controller.get('activeRoleAtRoles').startTrack();
        }, function (error) {
          if (error.errors.tag === 'NAME_NOT_UNIQUE') {
            _this2.controller.set('uniqueRoleNameError', error.errors.msg);
            return;
          }
          _this2.handleErrors(error);
        });
      },
      editRole: function editRole() {
        this.controller.set('isEditMode', true);
        this.controller.set('filterUsersAtRole', null);
        this.controller.set('isRoleChangesDialog', false);
      },
      cancelEditRole: function cancelEditRole() {
        this.controller.set('isEditMode', false);
        this.controller.set('uniqueRoleNameError', null);
        this.controller.get('activeRoleAtRoles').rollback();
      },
      saveRole: function saveRole() {
        var _this3 = this;

        this.controller.set('isActionInProgress', true);
        var trimmedName = this.controller.get('activeRoleAtRoles.name').trim();
        this.controller.set('activeRoleAtRoles.name', trimmedName);
        this.controller.get('activeRoleAtRoles').save().then(function () {
          _this3.controller.set('isEditMode', false);
          _this3.controller.set('uniqueRoleNameError', null);
        }, function (error) {
          if (error.errors.tag === 'NAME_NOT_UNIQUE') {
            _this3.controller.set('uniqueRoleNameError', error.errors.msg);
          }
          _this3.handleErrors(error);
        }).finally(function () {
          _this3.controller.set('isActionInProgress', false);
        });
      },
      changeRoleForUser: function changeRoleForUser(roleId) {
        var _this4 = this;

        var permissionsManager = this.get('permissionsManager');
        permissionsManager.changeRole(this.controller.get('selectedUser'), roleId).then(function () {
          _this4.controller.set('isDialogShown', false);
          _this4.controller.set('selectedUser', null);
          _this4.controller.set('operationType', null);
        }, function (error) {
          _this4.controller.set('isDialogShown', false);
          _this4.controller.set('selectedUser', null);
          _this4.controller.set('operationType', null);
          _this4.handleErrors(error);
        });
      },
      changeStatus: function changeStatus() {
        var permissionsManager = this.get('permissionsManager');
        permissionsManager.changeStatus(this.controller.get('selectedUser'));
        this.controller.set('isDialogShown', false);
        this.controller.set('selectedUser', null);
        this.controller.set('operationType', null);
      },
      resetAccountOTPStatus: function resetAccountOTPStatus() {
        this.controller.set('isResetOTPDialogShown', false);
        this.controller.set('selectedUser', null);
        this.controller.set('operationType', null);
      },
      resetPass: function resetPass() {
        var permissionsManager = this.get('permissionsManager');
        permissionsManager.resetPass(this.controller.get('selectedUser'));
        this.controller.set('isDialogShown', false);
        this.controller.set('selectedUser', null);
        this.controller.set('operationType', null);
      },
      openUserDialog: function openUserDialog(user, operation) {
        this.controller.set('selectedUser', user);
        this.controller.set('operationType', operation);
        this.controller.set('isDialogShown', true);
      },
      resetDialog: function resetDialog() {
        this.controller.set('isDialogShown', false);
        this.controller.set('selectedUser', null);
        this.controller.set('operationType', null);
      },
      openResetOTPConfirmationDialog: function openResetOTPConfirmationDialog(user, operation) {
        this.controller.set('selectedUser', user);
        this.controller.set('operationType', operation);
        this.controller.set('isResetOTPDialogShown', true);
      },
      hideOTPDialog: function hideOTPDialog() {
        this.controller.set('isResetOTPDialogShown', false);
        this.controller.set('selectedUser', null);
        this.controller.set('operationType', null);
      },
      openChangeAccount2FAStatus: function openChangeAccount2FAStatus(selectedItem) {
        this.controller.set('isChangeOTPStatusDialogShown', true);
        this.controller.set('selectedUser', selectedItem);
      },
      hideChangeAccount2FAStatusDialog: function hideChangeAccount2FAStatusDialog() {
        this.controller.toggleProperty('selectingOperationChecker');
        this.controller.set('isChangeOTPStatusDialogShown', false);
        this.controller.set('selectedUser', null);
      },
      changeAccount2FAStatus: function changeAccount2FAStatus() {
        this.controller.set('isChangeOTPStatusDialogShown', false);
        this.controller.set('selectedUser', null);
      },
      resetNewUserDialog: function resetNewUserDialog() {
        this.controller.set('uniqueLoginError', null);
        this.controller.set('successNewUserDialog', false);
        this.controller.set('isNewUserShowDialog', false);
      },
      resetNewRoleDialog: function resetNewRoleDialog() {
        this.controller.set('isNewRoleShowDialog', false);
        this.controller.set('uniqueRoleNameError', null);
      },
      showAssignedUsers: function showAssignedUsers(roleId) {
        this.controller.set('filterUsersAtRole', roleId);
        this.controller.set('isRoleChangesDialog', false);
        this.controller.set('isRoleAssignmentDialog', true);
      },
      showRoleChangesDialog: function showRoleChangesDialog(roleId) {
        var counter = 0;

        this.controller.get('model.accounts').forEach(function (record) {
          if (record.get('role.id') === roleId) {
            counter++;
          }
        });
        if (!counter) {
          this.controller.set('isEditMode', true);
          return;
        }

        this.controller.set('filterUsersAtRole', roleId);
        this.controller.set('isRoleChangesDialog', true);
      },
      resetDetailedRoleDialog: function resetDetailedRoleDialog() {
        this.controller.set('filterUsersAtRole', null);
        this.controller.set('isRoleAssignmentDialog', false);
        this.controller.set('isRoleChangesDialog', false);
      },
      voidUniqueNameError: function voidUniqueNameError() {
        this.controller.set('uniqueRoleNameError', null);
      },
      voidUniqueLoginError: function voidUniqueLoginError() {
        this.controller.set('uniqueLoginError', null);
      },
      updatePermissionsList: function updatePermissionsList(item) {
        var _this5 = this;

        if (item.get('isSelected')) {
          var el = this.controller.get('model.permissions').findBy('id', item.get('id'));
          if (!el) {
            return;
          }
          this.controller.get('activeRoleAtRoles.permissions').pushObject(el);

          if (item.get('parentId') !== null) {
            var parentEl = this.controller.get('model.permissions').findBy('id', item.get('parentId'));

            if (this.controller.get('activeRoleAtRoles.permissions').indexOf(parentEl) === -1) {
              this.controller.get('activeRoleAtRoles.permissions').pushObject(parentEl);
            }
          }
        } else {
          var _el = this.controller.get('activeRoleAtRoles.permissions').findBy('id', item.get('id'));

          if (!_el) {
            return;
          }

          this.controller.get('activeRoleAtRoles.permissions').removeObject(_el);

          if (item.get('parentId') === null) {
            var childrenElements = this.controller.get('model.resources').findBy('id', item.get('resourceId'));

            childrenElements.get('permissions').forEach(function (childEl) {
              var el = _this5.controller.get('activeRoleAtRoles.permissions').findBy('id', childEl.get('id'));

              if (el && el.get('parentId') !== null) {
                _this5.controller.get('activeRoleAtRoles.permissions').removeObject(el);
              }
            });
          }
        }
      }
    }
  });
});