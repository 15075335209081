define('backoffice/authenticators/token', ['exports', 'backoffice/authenticators/oauth2'], function (exports, _oauth) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _oauth.default.extend({
    authenticate: function authenticate(token) {
      return new Promise(function (resolve) {
        return resolve({ token: token });
      });
    }
  });
});