define('backoffice/controllers/admin/packages/index', ['exports', 'backoffice/constants/permissions'], function (exports, _permissions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var WEBSITES_VIEW_KEY = _permissions.default.WEBSITES_VIEW_KEY,
      WEBSITES_EDIT_KEY = _permissions.default.WEBSITES_EDIT_KEY;

  var rowSettings = {
    component: 'table-row-packages'
  };

  exports.default = Ember.Controller.extend({
    permissionsManager: Ember.inject.service(),
    intl: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.rowSettings = rowSettings;
    },

    columnsSettingsPackages: Ember.computed('intl.primaryLocale', function () {
      return [{
        uniqueKey: 'id',
        component: 'table-cell-text',
        title: this.get('intl').t('packages.fields.packageId'),
        key: 'id',
        cls: 'text-center',
        isTruncated: true
      }, {
        uniqueKey: 'name',
        component: 'table-cell-text',
        title: this.get('intl').t('packages.fields.name'),
        key: 'name',
        cls: '',
        isTruncated: true
      }, {
        uniqueKey: 'websitesCounter',
        component: 'table-cell-tooltipstered-websites-counter',
        title: this.get('intl').t('packages.fields.websitesCounter'),
        key: 'websitesAttached',
        cls: 'text-center',
        isTruncated: true
      }, {
        uniqueKey: 'offersCounter',
        component: 'table-cell-tooltipstered-offers-counter',
        title: this.get('intl').t('packages.fields.offersCounter'),
        key: 'offersAttached',
        cls: 'text-center',
        isTruncated: true
      }, {
        uniqueKey: 'creationDate',
        component: 'table-cell-date',
        title: this.get('intl').t('packages.fields.creationDate'),
        key: 'creationDate',
        cls: 'text-center date-cell',
        isTruncated: true
      }, {
        uniqueKey: 'status',
        component: 'table-cell-package-status',
        title: this.get('intl').t('packages.fields.status'),
        key: 'status',
        cls: 'text-center',
        isTruncated: true
      }, {
        uniqueKey: 'actions',
        component: 'table-cell-package-actions',
        title: this.get('intl').t('common.actions'),
        key: 'id',
        cls: 'text-center',
        isTruncated: true
      }];
    }),
    tableId: 'package-table',
    filter: null,
    isRemoveDialogShown: false,
    removedId: null,
    hasPermissionNewBtn: Ember.computed(function () {
      return this.get('permissionsManager').hasPermission([WEBSITES_VIEW_KEY, WEBSITES_EDIT_KEY]);
    }),
    isActionColumnShown: Ember.computed(function () {
      return this.get('permissionsManager').hasPermission([WEBSITES_VIEW_KEY, WEBSITES_EDIT_KEY]);
    }),
    columns: Ember.computed('columnsSettingsPackages', 'isActionColumnShown', function () {
      var columnsSettings = void 0;

      if (this.get('isActionColumnShown')) {
        columnsSettings = this.get('columnsSettingsPackages');
      } else {
        columnsSettings = this.get('columnsSettingsPackages').filter(function (item) {
          return item.uniqueKey !== 'actions';
        });
      }

      return columnsSettings;
    })
  });
});