define('backoffice/constants/reports', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    INVOICES_ON_PAGE_LIST: ['50', '100'],
    DEFAULT_INVOICES_ON_PAGE: '50',
    SETTLEMENT_NOTES_ON_PAGE_LIST: ['50', '100'],
    DEFAULT_SETTLEMENT_NOTES_ON_PAGE: '50',
    INVOICE_APPROVED_STATUS: 'approved',
    INVOICE_APPROVED_DESCRIPTION: 'Approved',
    INVOICE_APPROVED_CLEARED_STATUS: 'approved_s',
    INVOICE_APPROVED_CLEARED_DESCRIPTION: 'Approved Cleared',
    INVOICE_DUE_STATUS: 'due_to_pay',
    INVOICE_DUE_DESCRIPTION: 'Due to Pay',
    INVOICE_SETTLED_STATUS: 'settled',
    INVOICE_SETTLED_DESCRIPTION: 'Settled',
    INVOICE_SUSPENDED_STATUS: 'suspended',
    INVOICE_SUSPENDED_DESCRIPTION: 'Suspended',
    INVOICE_RETURNED_STATUS: 'returned',
    INVOICE_RETURNED_DESCRIPTION: 'Returned',
    SETTLEMENT_APPROVED_STATUS: 'approved',
    SETTLEMENT_APPROVED_DESCRIPTION: 'Approved',
    SETTLEMENT_PAID_STATUS: 'paid',
    SETTLEMENT_PAID_DESCRIPTION: 'Paid',
    SETTLEMENT_SETTLED_STATUS: 'settled',
    SETTLEMENT_SETTLED_DESCRIPTION: 'Settled',
    SETTLEMENT_REJECTED_STATUS: 'rejected',
    SETTLEMENT_REJECTED_DESCRIPTION: 'Rejected',
    SETTLEMENT_RETURNED_STATUS: 'returned',
    SETTLEMENT_RETURNED_DESCRIPTION: 'Returned'
  };
});