define('backoffice/models/website', ['exports', 'ember-data', 'ember-cp-validations', 'ember-data-copyable'], function (exports, _emberData, _emberCpValidations, _emberDataCopyable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      Model = _emberData.default.Model,
      hasMany = _emberData.default.hasMany;


  var urlRegexp = /^https?:\/\/(((www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,12}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*))|((?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)))/;
  var urlWithLocalhostRegexp = /^https?:\/\/(?:(((www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,12}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*))|((?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)))|localhost)/;

  var Validations = (0, _emberCpValidations.buildValidations)({
    name: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        messageKey: 'websites.validations.required'
      }), (0, _emberCpValidations.validator)('length', {
        min: 6,
        max: 30,
        messageKey: 'websites.validations.name.length'
      })]
    },
    packages: {
      validators: [(0, _emberCpValidations.validator)('length', {
        min: 1,
        messageKey: 'websites.validations.package.length'
      })]
    },
    defaultPackageId: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        messageKey: 'websites.validations.defaultPackageId.presence'
      })]
    },
    publicUrl: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        messageKey: 'websites.validations.required'
      }), (0, _emberCpValidations.validator)('format', {
        regex: urlRegexp,
        messageKey: 'websites.validations.validUrl'
      })]
    },
    memberUrl: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        messageKey: 'websites.validations.required'
      }), (0, _emberCpValidations.validator)('format', {
        regex: urlRegexp,
        messageKey: 'websites.validations.validUrl'
      })]
    },
    callbackUrl: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        messageKey: 'websites.validations.required'
      }), (0, _emberCpValidations.validator)('format', {
        regex: urlWithLocalhostRegexp,
        messageKey: 'websites.validations.validUrl'
      })]
    },
    callbackMethod: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        messageKey: 'websites.validations.callbackMethod.presence'
      })]
    },
    alternateBilling: {
      validators: [(0, _emberCpValidations.validator)('format', {
        regex: urlRegexp,
        messageKey: 'websites.validations.validUrl',
        disabled: Ember.computed.not('model.alternateBilling')
      })]
    }
  });

  exports.default = Model.extend(Validations, _emberDataCopyable.default, {
    name: attr('string'),
    packages: hasMany('package', { async: false }),
    publicUrl: attr('string'),
    callbackUrl: attr('string'),
    callbackMethod: attr('string'),
    creationDate: attr('string'),
    defaultPackageId: attr('string'),
    status: attr('number'),
    liveToggle: attr('boolean'),
    hasPendingChanges: attr('boolean'),
    memberUrl: attr('string'),
    alternateBilling: attr('non-required-urls'),
    testuser: attr('string'),
    testpassword: attr('string'),
    callbackType: attr('string')
  });
});