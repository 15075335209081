define('backoffice/constants/packages', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    PACKAGE_EDITABLE_STATUS_DESCRIPTION: 'Editable',
    PACKAGE_ACTIVE_STATUS_DESCRIPTION: 'Active'
  };
});