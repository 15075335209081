define('backoffice/models/type-performance', ['exports', 'ember-data', 'ember-data/attr'], function (exports, _emberData, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    month: (0, _attr.default)('string'),
    enroll: (0, _attr.default)('number'),
    directSale: (0, _attr.default)('number'),
    directUpsell: (0, _attr.default)('number'),
    paRebill: (0, _attr.default)('number'),
    paSale: (0, _attr.default)('number'),
    paUpsell: (0, _attr.default)('number'),
    rebill: (0, _attr.default)('number'),
    summary: (0, _attr.default)('number'),
    computedEnroll: Ember.computed('enroll', 'summary', function () {
      var value = parseFloat(this.get('enroll'), 10) / parseFloat(this.get('summary'), 10);
      this.set('enroll', value);
      return value * 100;
    }),
    computedDirectSale: Ember.computed('directSale', 'summary', function () {
      var value = parseFloat(this.get('directSale'), 10) / parseFloat(this.get('summary'), 10);

      return value * 100;
    }),
    computedDirectUpsell: Ember.computed('directUpsell', 'summary', function () {
      var value = parseFloat(this.get('directUpsell'), 10) / parseFloat(this.get('summary'), 10);

      return value * 100;
    }),
    computedPaRebill: Ember.computed('paRebill', 'summary', function () {
      var value = parseFloat(this.get('paRebill'), 10) / parseFloat(this.get('summary'), 10);

      return value * 100;
    }),
    computedPaSale: Ember.computed('paSale', 'summary', function () {
      var value = parseFloat(this.get('paSale'), 10) / parseFloat(this.get('summary'), 10);

      return value * 100;
    }),
    computedPaUpsell: Ember.computed('paUpsell', 'summary', function () {
      var value = parseFloat(this.get('paUpsell'), 10) / parseFloat(this.get('summary'), 10);

      return value * 100;
    }),
    computedRebill: Ember.computed('rebill', 'summary', function () {
      var value = parseFloat(this.get('rebill'), 10) / parseFloat(this.get('summary'), 10);

      return value * 100;
    })
  });
});