define('backoffice/helpers/conv-cents-to', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toArray(arr) {
    return Array.isArray(arr) ? arr : Array.from(arr);
  }

  exports.default = Ember.Helper.extend({
    util: Ember.inject.service(),
    compute: function compute(_ref, hash) {
      var _ref2 = _toArray(_ref),
          value = _ref2[0],
          rest = _ref2.slice(1);

      return this.get('util').enFormatNumber(value, 2);
    }
  });
});