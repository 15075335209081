define('backoffice/routes/token', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    session: Ember.inject.service('session'),
    activate: function activate() {
      Ember.$('body').addClass('gray-bg fixed-footer');
    },
    deactivate: function deactivate() {
      Ember.$('body').removeClass('gray-bg fixed-footer');
    },
    beforeModel: function beforeModel(params) {
      if (params.queryParams && params.queryParams.username && params.queryParams.password) {
        this.set('identification', params.queryParams.username);
        this.set('password', params.queryParams.password);
        var setupNewPassword = true;
        this.controllerFor('login').authenticate(this.get('identification'), this.get('password'), setupNewPassword);
        return;
      }

      if (this.get('session').get('isAuthenticated')) {
        this.transitionTo('admin.dashboard');
      }
    }
  });
});