define('backoffice/services/two-factor-authentication', ['exports', 'backoffice/config/environment', 'backoffice/constants/auth'], function (exports, _environment, _auth) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    serviceURL: _environment.default.APP.apiHost + '/api/v1/2fa/' + _auth.default.AUTH_2FA_PLACEMENT_MBO,
    intl: Ember.inject.service(),
    session: Ember.inject.service('session'),

    reset2FA: function reset2FA(accountId, tokenOrRecoveryCode) {
      return this.makeRequest(this.get('serviceURL') + '/' + accountId + '/reset', 'POST', { otp_token: tokenOrRecoveryCode });
    },
    hardReset2FA: function hardReset2FA(accountId) {
      var token = this.get('session').get('data.authenticated.token.access_token');
      if (!token) {
        return Promise.reject({ status: 400, message: 'Access token is not set.' });
      }

      return this.makeRequest(this.get('serviceURL') + '/' + accountId + '/hard-reset', 'POST', {}, {
        headers: {
          Authorization: 'Bearer ' + token
        }
      });
    },
    verify2FA: function verify2FA(accountId, token) {
      return this.makeRequest(this.get('serviceURL') + '/' + accountId + '/verify', 'POST', { otp_token: token });
    },
    change2FAStatus: function change2FAStatus(accountId, enabled) {
      var token = this.get('session').get('data.authenticated.token.access_token');
      if (!token) {
        return Promise.reject({ status: 400, message: 'Access token is not set.' });
      }

      return this.makeRequest(this.get('serviceURL') + '/' + accountId + '/status', 'patch', { enabled: enabled }, {
        headers: {
          Authorization: 'Bearer ' + token,
          "Access-Control": "*",
          "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept, Z-Key",
          "Access-Control-Allow-Methods": "GET, HEAD, POST, PATCH, PUT, DELETE, OPTIONS"
        },
        processData: false
      });
    },
    generateQRCode: function generateQRCode(accountId) {
      return this.makeRequest(this.get('serviceURL') + '/' + accountId + '/qr-code', 'GET', null);
    },
    register2FA: function register2FA(accountId, token) {
      return this.makeRequest(this.get('serviceURL') + '/' + accountId + '/register', 'POST', { otp_token: token });
    },
    makeRequest: function makeRequest(url) {
      var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'POST';

      var _this = this;

      var data = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
      var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};

      var params = Object.assign({}, {
        url: url,
        type: type,
        dataType: "json",
        data: data ? JSON.stringify(data) : undefined,
        contentType: "application/json; charset=utf-8"
      }, options || {});

      return new Promise(function (resolve, reject) {
        Ember.$.ajax(params).then(function (response) {
          return resolve(response);
        }).catch(function (error) {
          return reject(_this.formatError(error));
        });
      });
    },
    formatError: function formatError(error) {
      var errorObject = error.responseJSON || {};
      var errorText = error.statusText ? error.statusText : this.get('intl').t('common.errors.noErrorMessage');

      switch (error.status) {
        case 400:
        case 500:
          if (errorObject.errMessage) {
            errorText = errorObject.errMessage;
          } else if (errorObject.errors && errorObject.errors.msg) {
            errorText = errorObject.errors.msg;
          } else {
            errorText = this.get('intl').t('common.errors.badRequestError');
          }
          break;
      }

      return {
        message: errorText,
        status: error.status,
        original: error
      };
    }
  });
});