define('backoffice/constants/transactions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    TRANSACTIONS_ON_PAGE_LIST: ['200', '400'],
    DEFAULT_TRANSACTIONS_ON_PAGE: '200',
    SOURCE_API_CODE: 'Y',
    SOURCE_PAYMENTFORM_CODE: 'N',
    SOURCE_API_DESCRIPTION: 'API',
    SOURCE_PAYMENTFORM_DESCRIPTION: 'Payment Form',
    ORIGINAL_TRANSACTION_TYPE_REFUND: 'refund',
    ORIGINAL_TRANSACTION_TYPE_OCTPT: 'octpt',
    ORIGINAL_TRANSACTION_TYPE_CHARGEBACK: 'chargeback',
    ORIGINAL_TRANSACTION_TYPE_UPSELL: 'upsell',
    ORIGINAL_TRANSACTION_TYPE_SALE: 'sale',
    ORIGINAL_TRANSACTION_TYPE_REBILL: 'rebill',
    ORIGINAL_TRANSACTION_TYPE_ALTPAYMENT: 'altpayment'
  };
});