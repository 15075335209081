define("backoffice/constants/multi-select", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    TAB_KEY: 9,
    ESCAPE_KEY: 27,
    UP_KEY: 38,
    DOWN_KEY: 40,
    SPACE_KEY: 32,
    ENTER_KEY: 13,
    INPUT_TABINDEX: 1,
    SELECT_BTN_TABINDEX: 2,
    DESELECT_BTN_TABINDEX: 3
  };
});