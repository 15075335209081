define('backoffice/pods/components/data-table-info/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['row', 'table-info', 'm-b-sm'],
    attributeBindings: ['role', 'aria-live'],
    info: null,
    showingFrom: Ember.computed('info.{number,size}', function () {
      return parseInt(this.get('info.number')) * parseInt(this.get('info.size')) + 1;
    }),
    showingTo: Ember.computed('info.{number,size}', function () {
      return parseInt(this.get('info.number')) * parseInt(this.get('info.size')) + parseInt(this.get('info.numberOfElements'));
    }),
    totalElements: Ember.computed('info.totalElements', function () {
      return !!parseInt(this.get('info.totalElements'));
    })
  });
});