define('backoffice/controllers/admin/websites/edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var rowSettingsPackage = {
    component: 'table-row-package'
  };
  var rowSettingsPackages = {
    component: 'table-row-packages'
  };

  var EditWebsiteController = Ember.Controller.extend({
    intl: Ember.inject.service('intl'),
    init: function init() {
      this._super.apply(this, arguments);

      this.rowSettingsPackage = rowSettingsPackage;
      this.rowSettingsPackages = rowSettingsPackages;
    },

    columnsSettingsPackage: Ember.computed('intl.primaryLocale', function () {
      return [{
        uniqueKey: 'formPosition',
        component: 'table-cell-form-position',
        title: this.get('intl').t('offers.fields.formPosition'),
        key: 'formPosition',
        cls: 'offer-position-cell text-center',
        isTruncated: true
      }, {
        uniqueKey: 'id',
        component: 'table-cell-text',
        title: this.get('intl').t('offers.fields.offId'),
        key: 'id',
        cls: 'text-center',
        isTruncated: true
      }, {
        uniqueKey: 'description',
        component: 'table-cell-text',
        title: this.get('intl').t('offers.fields.description'),
        key: 'name',
        cls: '',
        isTruncated: true
      }, {
        uniqueKey: 'type',
        component: 'table-cell-offer-type',
        title: this.get('intl').t('offers.fields.type'),
        key: 'type',
        cls: 'text-center',
        isTruncated: true
      }, {
        uniqueKey: 'duration',
        component: 'table-cell-text',
        title: this.get('intl').t('offers.fields.duration'),
        key: 'duration',
        cls: '',
        isTruncated: true
      }, {
        uniqueKey: 'creationDate',
        component: 'table-cell-date',
        title: this.get('intl').t('offers.fields.creationDate'),
        key: 'creationDate',
        cls: 'text-center',
        isTruncated: true
      }, {
        uniqueKey: 'rebillsOverOffer',
        component: 'table-cell-offer-rebill',
        title: this.get('intl').t('offers.fields.rebillsOverOffer'),
        key: 'rebill',
        cls: 'text-center',
        isTruncated: true
      }, {
        uniqueKey: 'packagesCounter',
        component: 'table-cell-tooltipstered-packages-counter',
        title: this.get('intl').t('offers.fields.packagesCounter'),
        key: 'packagesAttached',
        cls: 'text-center',
        isTruncated: true
      }, {
        uniqueKey: 'amountEuro',
        component: 'table-cell-eur-amount',
        title: this.get('intl').t('offers.fields.amountEuro'),
        key: 'prices',
        cls: 'text-right',
        isTruncated: true
      }, {
        uniqueKey: 'amountUSD',
        component: 'table-cell-usd-amount',
        title: this.get('intl').t('offers.fields.amountUSD'),
        key: 'prices',
        cls: 'text-right',
        isTruncated: true
      }];
    }),
    columnsSettingsPackages: Ember.computed('intl.primaryLocale', function () {
      return [{
        uniqueKey: 'isPackageChecked',
        component: 'table-cell-checkbox',
        title: this.get('intl').t('packages.fields.linkWebsite'),
        key: 'id',
        cls: 'text-center',
        isTruncated: false
      }, {
        uniqueKey: 'id',
        component: 'table-cell-text',
        title: this.get('intl').t('packages.fields.packageId'),
        key: 'id',
        cls: 'text-center',
        isTruncated: true
      }, {
        uniqueKey: 'name',
        component: 'table-cell-text',
        title: this.get('intl').t('packages.fields.name'),
        key: 'name',
        cls: '',
        isTruncated: true
      }, {
        uniqueKey: 'websitesCounter',
        component: 'table-cell-tooltipstered-websites-counter',
        title: this.get('intl').t('packages.fields.websitesCounter'),
        key: 'websitesAttached',
        cls: 'counter-cell-width text-center',
        isTruncated: true
      }, {
        uniqueKey: 'offersCounter',
        component: 'table-cell-tooltipstered-offers-counter',
        title: this.get('intl').t('packages.fields.offersCounter'),
        key: 'offersAttached',
        cls: 'counter-cell-width text-center',
        isTruncated: true
      }, {
        uniqueKey: 'creationDate',
        component: 'table-cell-date',
        title: this.get('intl').t('packages.fields.creationDate'),
        key: 'creationDate',
        cls: 'text-center',
        isTruncated: true
      }, {
        uniqueKey: 'isDefault',
        component: 'table-cell-radiobutton',
        title: this.get('intl').t('packages.fields.isDefault'),
        key: 'isDefault',
        cls: 'text-center',
        isTruncated: true
      }];
    }),
    tableIdPackages: 'website-packages-table',
    tableIdPackage: 'website-package-table',
    filter: null,
    selectedWebsite: null,
    selectedPackageId: null,
    selectedPackage: null,
    actions: {}
  });

  exports.default = EditWebsiteController;
});