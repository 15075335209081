define('backoffice/pods/components/offer-settings-translations-remove/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    classNames: ['col-sm-2', 'col-xs-2', 'col-md-2', 'col-lg-2'],
    actions: {
      removeTranslation: function removeTranslation(translation) {
        this.get('updateTranslationList')(translation.get('id'));
        this.set('lang.translation', '');
      }
    }
  });
});