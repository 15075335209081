define('backoffice/pods/components/widgets-connect-chargebacks/component', ['exports', 'backoffice/constants/general'], function (exports, _general) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    interval: null,
    counter: 0,
    didInsertElement: function didInsertElement() {
      var _this = this;

      this.$().prop('id', 'ChargebackManager');
      this.interval = window.setInterval(function () {
        _this.counter++;
        if (_this.counter >= _general.MAX_ATTEMPTS_INIT_CGWIDGETS) {
          return window.clearInterval(_this.interval);
        }
        var settingsStorage = _this.get('settingsStorage');
        if (window.CGWIDGETS && window.CGWIDGETS.ChargebackManager && window.CGWIDGETS.ChargebackManager.isReady) {
          window.CGWIDGETS.ChargebackManager.initializeWidget('ChargebackManager', settingsStorage.get('user-locale'));
          _this.widgetReady();
          window.clearInterval(_this.interval);
        }
      }, 500);
    }
  });
});