define('backoffice/pods/components/collapse-container/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['row'],
    collapsed: false,
    actions: {
      collapse: function collapse() {
        this.toggleProperty('collapsed');
      }
    }
  });
});