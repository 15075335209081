define('backoffice/pods/components/dynamic-high-charts/component', ['exports', 'ember-highcharts/components/high-charts'], function (exports, _highCharts) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _highCharts.default.extend({
    isLoading: false,
    contentDidChange: Ember.observer('content.@each.isLoaded', function () {
      var chart = this.get('chart'),
          seriesName = this.get('content')[0].name,
          seriesData = this.get('content')[0].data,
          xAxis = this.get('chart.axes')[0];

      chart.series[0].update({
        name: seriesName,
        data: seriesData
      }, false);
      xAxis.setCategories(this.get('chartOptions.xAxis.categories'), false);
    }),
    showLoading: Ember.observer('isLoading', function () {
      var chart = this.get('chart');
      this.get('isLoading') ? chart.showLoading() : chart.hideLoading();
    })
  });
});