define('backoffice/transforms/non-required-urls', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      return serialized === 'null' ? null : serialized;
    },
    serialize: function serialize(deserialized) {
      return deserialized === '' || deserialized === 'null' ? null : deserialized;
    }
  });
});