define('backoffice/routes/admin/websites/new', ['exports', 'backoffice/mixins/admin-route-mixin', 'ember-simple-auth/mixins/authenticated-route-mixin', 'backoffice/mixins/errors-handler', 'backoffice/mixins/reset-scroll-position'], function (exports, _adminRouteMixin, _authenticatedRouteMixin, _errorsHandler, _resetScrollPosition) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_adminRouteMixin.default, _authenticatedRouteMixin.default, _errorsHandler.default, _resetScrollPosition.default, {
    init: function init() {
      this._super.apply(this, arguments);
      this.title = this.get('intl').t('websites.newWebsite');
    },

    queryParams: {
      copiedId: { refreshModel: true }
    },
    model: function model(params) {
      var _this = this;

      if (params && params.copiedId) {
        var copied = this.store.findRecord('website', params.copiedId).then(function (el) {
          return el.copy();
        });

        return Ember.RSVP.hash({
          website: copied,
          packagesList: this.store.query('package', {})
        });
      } else {
        var websitePromise = new Ember.RSVP.Promise(function (resolve /*, reject*/) {
          resolve(_this.store.createRecord('website'));
        });

        return Ember.RSVP.hash({
          website: websitePromise,
          packagesList: this.store.query('package', {})
        });
      }
    },

    actions: {
      cancel: function cancel() {
        var model = this.controller.get('model.website'),
            isChanged = model.get('hasDirtyAttributes');
        if (isChanged) {
          model.rollbackAttributes();
        }
        this.transitionTo('admin.websites.index');
      },
      save: function save(website) {
        var _this2 = this;

        website.set('creationDate', null);
        website.save().then(function () {
          return _this2.transitionTo('admin.websites.index');
        }).catch(function (error) {
          _this2.handleErrors(error);
        });
      },
      toggleDetailsTable: function toggleDetailsTable(pack) {
        var selectedPackage = this.store.peekRecord('package', pack.get('id'));
        this.controller.set('selectedPackageId', pack.get('id'));
        this.controller.set('selectedPackage', selectedPackage);

        setTimeout(function () {
          if (!Ember.$('#selected-window').dialog('isOpen')) {
            Ember.$('#selected-window').dialog('open');
          }
        }, 0);
      },
      deselect: function deselect() {
        this.controller.set('selectedPackageId', null);
        this.controller.set('selectedPackage', null);
      },
      willTransition: function willTransition() {
        var _this3 = this;

        var queryParams = this.controller.get('queryParams');

        // this close modal for selected Package
        this.controller.set('selectedPackage', null);

        queryParams.forEach(function (param) {
          _this3.controller.set(param, null);
        });

        var model = this.controller.get('model.website');
        if (model.get('hasDirtyAttributes')) {
          model.rollbackAttributes();
        }
      }
    }
  });
});