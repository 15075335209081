define('backoffice/pods/components/transaction-performance/component', ['exports', 'backoffice/mixins/errors-handler', 'backoffice/constants/dashboard'], function (exports, _errorsHandler, _dashboard) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var CONVERSION_SUMMARY_TABLE_CONFIG = _dashboard.default.CONVERSION_SUMMARY_TABLE_CONFIG,
      TRX_TYPES_SUMMARY_TABLE_CONFIG = _dashboard.default.TRX_TYPES_SUMMARY_TABLE_CONFIG;


  var rowSettings = {
    component: 'table-row',
    shareModel: false
  };

  exports.default = Ember.Component.extend(_errorsHandler.default, {
    init: function init() {
      this._super.apply(this, arguments);

      this.rowSettings = rowSettings;
      this.sortProperties = ['intervalNumber:asc'];
    },

    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    dashboardService: Ember.inject.service('dashboard'),
    clientSettings: Ember.inject.service(),
    lastUpdate: Ember.computed('model.lastUpdate', 'dataInProgress', function () {
      var result = this.get('model.lastUpdate');
      var dataInProgress = this.get('dataInProgress');
      return !dataInProgress && result ? result.lastUpdate : null;
    }),
    timeZone: Ember.computed('data', function () {
      var data = this.get('data');
      var firstObject = data.objectAt(0);
      return firstObject && firstObject.get('timeZone') ? firstObject.get('timeZone').slice(0, -3) : null;
    }),
    dataInProgress: Ember.computed('data', function () {
      var data = this.get('data');
      return data.meta && data.meta.status === "processing";
    }),
    columnsSettings: CONVERSION_SUMMARY_TABLE_CONFIG,
    columnsSettings2: TRX_TYPES_SUMMARY_TABLE_CONFIG,
    formattedData: Ember.computed('data', function () {
      var _this = this;

      var data = this.get('data'),
          formatPercent = this.get('dashboardService').formatPercent,
          formatDate = this.get('dashboardService').formatDate,
          round = this.get('dashboardService').round;

      return data.map(function (row, index) {
        return Ember.Object.extend({}).create({
          'period': row.get('intervalName') === 'Total' ? row.get('intervalName') : formatDate(row.get('period'), _this.get('period')),
          'intervalNumber': row.get('intervalNumber') || index,
          'processedTransactions': _this.get('intl').formatNumber(round(parseFloat(row.get('processedTransactions')))),
          'successRatio': formatPercent(round(parseFloat(row.get('successRatio')) * 100)),
          'techErrorsRatio': formatPercent(round(parseFloat(row.get('techErrorsRatio')) * 100)),
          'riskErrorsRatio': formatPercent(round(parseFloat(row.get('riskErrorsRatio')) * 100)),
          'bankErrorsRatio': formatPercent(round(parseFloat(row.get('bankErrorsRatio')) * 100))
        });
      });
    }),
    formattedData2: Ember.computed('data', function () {
      var _this2 = this;

      var data = this.get('data'),
          formatPercent = this.get('dashboardService').formatPercent,
          formatDate = this.get('dashboardService').formatDate,
          round = this.get('dashboardService').round;

      return data.map(function (row, index) {
        return Ember.Object.extend({}).create({
          'period': row.get('intervalName') === 'Total' ? row.get('intervalName') : formatDate(row.get('period'), _this2.get('period')),
          'intervalNumber': row.get('intervalNumber') || index,
          'successfulSales': _this2.get('intl').formatNumber(round(parseFloat(row.get('successfulSales')))),
          'rateSales': formatPercent(round(parseFloat(row.get('rateSales')) * 100)),
          'successfulRebills': _this2.get('intl').formatNumber(round(parseFloat(row.get('successfulRebills')))),
          'rateRebill': formatPercent(round(parseFloat(row.get('rateRebill')) * 100))
        });
      });
    })
  });
});