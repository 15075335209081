define("backoffice/constants/payouts", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    PAYOUT_POLLING_TIMEOUT: 5000,
    PAYOUT_UPLOAD_NOTIFICATION_TIME: 8000,
    STATUS_PAYOUT_PENDING: 1,
    STATUS_PAYOUT_CHECKED: 4,
    STATUS_PAYOUT_IN_PROGRESS: 5,
    STATUS_PAYOUT_DONE: 6
  };
});