define('backoffice/pods/components/permission-checkbox/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    permission: null,
    isEditable: true,
    permissionItem: Ember.computed('permission.id', 'activeRole.permissions.@each', function () {
      var _this = this;

      var isSelected = false;

      if (this.get('activeRole.permissions.length')) {
        this.get('activeRole.permissions').forEach(function (itemInPermissions) {
          if (_this.get('permission.id') === itemInPermissions.get('id')) {
            isSelected = true;
          }
        });
      }

      var newItem = Ember.Object.create();
      newItem.set('id', this.get('permission.id'));
      newItem.set('name', this.get('permission.name'));
      newItem.set('tip', this.get('permission.description'));
      newItem.set('parentId', this.get('permission.parentId'));
      newItem.set('resourceId', this.get('permission.resourceId'));
      newItem.set('isSelected', isSelected);

      return newItem;
    }),
    actions: {
      select: function select(item) {
        item.toggleProperty('isSelected');
        this.get('updateList')(item);
      }
    }
  });
});