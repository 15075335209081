define('backoffice/routes/admin/callback-settings', ['exports', 'backoffice/mixins/admin-route-mixin', 'ember-simple-auth/mixins/authenticated-route-mixin', 'backoffice/mixins/errors-handler', 'backoffice/constants/requests'], function (exports, _adminRouteMixin, _authenticatedRouteMixin, _errorsHandler, _requests) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_adminRouteMixin.default, _authenticatedRouteMixin.default, _errorsHandler.default, {
    model: function model() {
      return this.store.find('customer-callback-setting', this.get('clientSettings').getCustomer().id);
    },

    clientSettings: Ember.inject.service(),
    actions: {
      toggleSwitch: function toggleSwitch() {
        this.controller.get('model').toggleProperty('isCallbackRetriesEnabled');
      },
      save: function save() {
        var _this = this;

        var model = this.controller.get('model');

        model.save().then(function () {
          _this.get('notify').success("Saved", { closeAfter: _requests.CLOSE_NOTIFY_DELAY });
        }, function (error) {
          _this.handleErrors(error);
        });
      }
    }
  });
});