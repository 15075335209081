define('backoffice/pods/components/country-performance/component', ['exports', 'backoffice/mixins/errors-handler', 'backoffice/constants/dashboard'], function (exports, _errorsHandler, _dashboard) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var COUNTRY_TABLE_CONFIG = _dashboard.default.COUNTRY_TABLE_CONFIG;

  var topsCountries = ['Top 5', 'Top 10', 'Top 20', 'Top 30'];
  var rowSettings = {
    component: 'table-row',
    shareModel: false
  };

  exports.default = Ember.Component.extend(_errorsHandler.default, {
    init: function init() {
      this._super.apply(this, arguments);

      this.topsCountries = topsCountries;
      this.rowSettings = rowSettings;
    },

    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    dashboardService: Ember.inject.service('dashboard'),
    clientSettings: Ember.inject.service(),
    lastUpdate: Ember.computed('model.lastUpdate', 'dataInProgress', function () {
      var result = this.get('model.lastUpdate');
      var dataInProgress = this.get('dataInProgress');
      return !dataInProgress && result ? result.lastUpdate : null;
    }),
    timeZone: Ember.computed('data', function () {
      var data = this.get('data');
      var firstObject = data.objectAt(0);
      return firstObject && firstObject.get('timeZone') ? firstObject.get('timeZone').slice(0, -3) : null;
    }),
    dataInProgress: Ember.computed('data', function () {
      var data = this.get('data');
      return data.meta && data.meta.status === "processing";
    }),
    columnsSettings: COUNTRY_TABLE_CONFIG,
    formattedData: Ember.computed('data.@each.id', function () {
      var _this = this;

      var data = this.get('data'),
          formatPercent = this.get('dashboardService').formatPercent,
          round = this.get('dashboardService').round;

      return data.map(function (row) {
        return Ember.Object.extend({}).create({
          'nameCountry': row.get('nameCountry'),
          'codeCountry': row.get('nameCountry') === 'Total' ? '' : row.get('codeCountry'),
          'hitsCurrent': _this.get('intl').formatNumber(round(parseFloat(row.get('hitsCurrent')))),
          'hitsCurrentRatio': formatPercent(round(parseFloat(row.get('hitsCurrentRatio')) * 100)),
          'currentSuccessRatio': formatPercent(round(parseFloat(row.get('currentSuccessRatio')) * 100)),
          'hitsPrevRatio': formatPercent(round(parseFloat(row.get('hitsPrevRatio')) * 100)),
          'hitsPrev': _this.get('intl').formatNumber(round(parseFloat(row.get('hitsPrev')))),
          'previousSuccessRatio': formatPercent(round(parseFloat(row.get('previousSuccessRatio')) * 100))
        });
      });
    }),
    enableLoadingObserver: Ember.observer('isCountriesLoading', function () {
      this.set('isLoading', !!this.get('isCountriesLoading'));
    }),
    selectedTopCountries: Ember.computed('topCountries', function () {
      return this._getCountriesText(this.get('topCountries'));
    }),
    actions: {
      onTopCountiesChange: function onTopCountiesChange(topCountries) {
        this.set('topCountries', this._getCountriesCount(topCountries));
        this.get('changeCountriesCount')();
      }
    },
    _getCountriesCount: function _getCountriesCount(text) {
      switch (text) {
        case 'Top 5':
          {
            return 5;
          }
        case 'Top 10':
          {
            return 10;
          }
        case 'Top 20':
          {
            return 20;
          }
        case 'Top 30':
          {
            return 30;
          }
        default:
          {
            return 5;
          }
      }
    },
    _getCountriesText: function _getCountriesText(count) {
      switch (count) {
        case 5:
          {
            return 'Top 5';
          }
        case 10:
          {
            return 'Top 10';
          }
        case 20:
          {
            return 'Top 20';
          }
        case 30:
          {
            return 'Top 30';
          }
        default:
          {
            return 'Top 5';
          }
      }
    }
  });
});