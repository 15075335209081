define('backoffice/pods/components/trx-cancel/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    isConfirmShown: false,
    reason: null,
    intl: Ember.inject.service(),
    title: Ember.computed('isConfirmShown', function () {
      var text = void 0;
      if (this.get('isConfirmShown')) {
        text = this.get('intl').t('transactions.cancelConfirmTitle') + ': ' + this.get('transactionId') + '?';
      } else {
        text = this.get('intl').t('transactions.cancelConfirmReason') + ':';
      }

      return text;
    }),
    actions: {
      cancelOperation: function cancelOperation() {
        this.set('isConfirmShown', false);
        this.set('reason', null);
        this.get('closeDialog')();
      },
      accept: function accept() {
        if (this.get('reason.id') && this.get('reason.name')) {
          this.set('isConfirmShown', true);
        } else {
          this.set('isConfirmShown', false);
        }
      },
      confirm: function confirm() {
        this.get('cancelTrx')(this.get('transactionId'), this.get('reason'));

        this.set('isConfirmShown', false);
        this.set('reason', null);
      }
    }
  });
});