define('backoffice/pods/components/trx-callback/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    intl: Ember.inject.service('intl'),
    title: Ember.computed('isConfirmShown', function () {
      return this.get('intl').t('transactions.resendCallbackConfirmTitle') + ': ' + this.get('transactionId') + '?';
    }),
    actions: {
      cancelOperation: function cancelOperation() {
        this.get('closeDialog')();
      },
      confirm: function confirm() {
        this.get('resendCallback')(this.get('transactionId'));
      }
    }
  });
});