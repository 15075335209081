define('backoffice/pods/components/table-row-members/component', ['exports', 'backoffice/pods/components/table-row/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    classNameBindings: ['isActive:active', 'isTest:test-subscription'],
    isTest: Ember.computed('data', function () {
      return !!this.get('data.isTest');
    })
  });
});