define('backoffice/pods/components/offer-settings-translations/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var langs = [{ name: 'French', translation: '', langcode: 'FR' }, { name: 'Spanish', translation: '', langcode: 'ES' }, { name: 'Russian', translation: '', langcode: 'RU' }];

  exports.default = Ember.Component.extend({
    tagName: '',
    classNames: ['row'],
    collapsed: false,
    isTranslationErrorShown: false,
    lang: null,
    isInputedTextValid: false,
    disabled: false,
    init: function init() {
      this._super.apply(this, arguments);

      this.langs = langs;
      this.set('lang', this.get('langs').objectAt(0));
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.set('lang.translation', '');
    }
  });
});