define('backoffice/routes/admin/ip-restrictions', ['exports', 'backoffice/mixins/admin-route-mixin', 'ember-simple-auth/mixins/authenticated-route-mixin', 'backoffice/mixins/errors-handler', 'backoffice/constants/requests'], function (exports, _adminRouteMixin, _authenticatedRouteMixin, _errorsHandler, _requests) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var IP_VALIDITY_REGEX = /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/;

  exports.default = Ember.Route.extend(_adminRouteMixin.default, _authenticatedRouteMixin.default, _errorsHandler.default, {
    model: function model() {
      return this.store.find('customer-ip-restriction', this.get('clientSettings').getCustomer().id);
    },

    clientSettings: Ember.inject.service(),
    validateIp: function validateIp(list) {
      var result = [];
      list.forEach(function (item) {
        return !IP_VALIDITY_REGEX.test(item) && result.push(item);
      });
      return result;
    },

    actions: {
      toggleSwitch: function toggleSwitch() {
        this.controller.get('model').toggleProperty('ipRestriction');
      },
      addTag: function addTag(item, list) {
        item.split(/\\n|\s/g).forEach(function (i) {
          return i && list.addObject(i);
        });
        this.controller.get('model').set('ipWhitelist', list);
      },
      removeTag: function removeTag(item, list) {
        list.removeObject(item);
        this.controller.get('model').set('ipWhitelist', list);
      },
      save: function save() {
        var _this = this;

        this.controller.set('ipErrors', []);
        var model = this.controller.get('model');
        var ipErrors = this.get('validateIp')(model.get('ipWhitelist'));
        if (ipErrors.length) {
          this.controller.set('ipErrors', ipErrors);
          return;
        }

        model.save().then(function () {
          _this.get('notify').success("IP Restrictions Settings saved", { closeAfter: _requests.CLOSE_NOTIFY_DELAY });
        }, function (error) {
          _this.handleErrors(error);
        });
      }
    }
  });
});