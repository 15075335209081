define('backoffice/pods/components/offer-settings-translations-add/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    classNames: ['col-sm-2', 'col-xs-2', 'col-md-2', 'col-lg-2'],
    removeNotSavedTranslationRecord: function removeNotSavedTranslationRecord(record) {
      var existentNewTranslationRecord = this.get('store').peekRecord('offerTranslation', record.id);

      if (existentNewTranslationRecord) {
        this.get('store').unloadRecord(existentNewTranslationRecord);
      }
    },

    actions: {
      addTranslation: function addTranslation() {
        var _this = this;

        if (this.get('isInputedTextValid')) {
          var offerRecord = this.get('newOffer.id') ? this.get('store').peekRecord('offer', this.get('newOffer.id')) : null;
          var isPresent = false;

          if (offerRecord) {
            offerRecord.get('translations').forEach(function (translation) {
              if (translation.get('langcode') === _this.get('lang').langcode) {
                translation.set('translation', _this.get('lang').translation);
                isPresent = true;
              }
            });

            if (!isPresent) {
              var obj = {
                id: '' + this.get('newOffer.id') + this.get('lang.langcode'),
                idOffer: this.get('newOffer.idOffer') ? this.get('newOffer.idOffer') : 0,
                langcode: this.get('lang.langcode'),
                translation: '' + this.get('lang.translation')
              };

              this.removeNotSavedTranslationRecord(obj);

              offerRecord.get('translations').pushObject(this.get('store').createRecord('offerTranslation', obj));
            }
          } else {
            this.get('newOffer.translations').forEach(function (translation) {
              if (translation.get('langcode') === _this.get('lang').langcode) {
                translation.set('translation', _this.get('lang').translation);
                isPresent = true;
              }
            });

            if (!isPresent) {
              var _obj = {
                id: '' + this.get('newOffer.id') + this.get('lang.langcode'),
                idOffer: this.get('newOffer.idOffer') ? this.get('newOffer.idOffer') : 0,
                langcode: this.get('lang.langcode'),
                translation: '' + this.get('lang.translation')
              };

              this.removeNotSavedTranslationRecord(_obj);

              this.get('newOffer.translations').pushObject(this.get('store').createRecord('offerTranslation', _obj));
            }
          }

          this.set('lang.translation', null);
        }
      }
    }
  });
});