define('backoffice/pods/components/table-cell-users-assigned/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'td',
    index: null,
    data: null,
    row: null,
    collapsed: null,
    usersCounter: Ember.computed('sharedModel', function () {
      var _this = this;

      var counter = 0;
      this.get('sharedModel').forEach(function (record) {
        if (record.get('role.id') === _this.get('data')) {
          counter++;
        }
      });
      return counter;
    }).property('sharedModel.@each.role')
  });
});