define('backoffice/models/package', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany,
      Model = _emberData.default.Model;


  var Validations = (0, _emberCpValidations.buildValidations)({
    name: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        messageKey: 'packages.validations.required'
      }), (0, _emberCpValidations.validator)('length', {
        min: 6,
        max: 30,
        messageKey: 'packages.validations.name.length'
      })]
    },
    offers: {
      validators: [(0, _emberCpValidations.validator)('length', {
        min: 1,
        messageKey: 'packages.validations.offer.length'
      })]
    }
  });

  exports.default = Model.extend(Validations, {
    name: attr('string'),
    status: attr('string'),
    creationDate: attr('string'),
    websitesAttached: attr('number'),
    offersAttached: attr('number'),
    websitesTitles: hasMany('website-title'),
    offers: hasMany('offer'),
    formPositions: hasMany('form-position')
  });
});