define('backoffice/services/toggles-init', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    initToggles: function initToggles(ctrl) {
      var toggles = ctrl.get('toggles');

      toggles.forEach(function (toggle) {
        var defaultVal = ctrl.get('switchParams.' + toggle + '.isChecked'),
            val = ctrl.get('searchParams.' + toggle),
            resultValue = void 0;

        if (val === null) {
          resultValue = defaultVal;
        } else if (val === 'false') {
          resultValue = false;
        } else if (val === 'true') {
          resultValue = true;
        } else {
          resultValue = val;
        }

        ctrl.set('searchParams.' + toggle, resultValue);

        return resultValue;
      });
    }
  });
});