define('backoffice/pods/components/table-cell-settlement-actions/component', ['exports', 'backoffice/config/environment', 'backoffice/constants/permissions', 'backoffice/constants/requests'], function (exports, _environment, _permissions, _requests) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var FINANCE_EXPORT_KEY = _permissions.default.FINANCE_EXPORT_KEY;
  var SUCCESS_RESPONSE_STATUS = _requests.default.SUCCESS_RESPONSE_STATUS,
      FAILED_RESPONSE_STATUS = _requests.default.FAILED_RESPONSE_STATUS,
      CLOSE_NOTIFY_DELAY = _requests.default.CLOSE_NOTIFY_DELAY;
  exports.default = Ember.Component.extend({
    tagName: 'td',
    permissionsManager: Ember.inject.service(),
    session: Ember.inject.service(),
    notify: Ember.inject.service(),
    settlementEndpoint: _environment.default.APP.apiHost + '/api/settlementNotes/pdf',
    pingEndpoint: _environment.default.APP.apiHost + '/api/ping',
    hasPermissionDataExport: Ember.computed(function () {
      return this.get('permissionsManager').hasPermission(FINANCE_EXPORT_KEY);
    }),
    hasActions: Ember.computed('row', function () {
      return true;
    }),
    isView: Ember.computed('row', function () {
      return true;
    }),
    isSave: Ember.computed('row', function () {
      return true;
    }),
    _getPDFFile: function _getPDFFile(id, isView) {
      var settlementEndpoint = this.get('settlementEndpoint'),
          session = this.get('session'),
          token = session.get('data.authenticated.token.access_token');

      var queryParams = {
        url: '' + this.get('pingEndpoint'),
        async: false,
        headers: {
          Authorization: 'Bearer ' + token
        },
        type: 'GET',
        contentType: 'application/x-www-form-urlencoded',
        dataType: 'json',
        complete: function complete(data) {
          if (data.status === SUCCESS_RESPONSE_STATUS) {
            var url = settlementEndpoint + '/' + id + '?access_token=' + token + '&view=' + isView;
            window.open(url, '_blank');
          } else if (data.status === FAILED_RESPONSE_STATUS) {
            session.invalidate();
          } else {
            this.get('notify').error('Error with status ' + data.status + '.', {
              closeAfter: CLOSE_NOTIFY_DELAY
            });
          }
        }
      };

      Ember.$.ajax(queryParams);
    },

    actions: {
      viewSettlement: function viewSettlement(id) {
        this._getPDFFile(id, true);
      },
      saveSettlement: function saveSettlement(id) {
        this._getPDFFile(id, false);
      }
    }
  });
});