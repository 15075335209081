define('backoffice/pods/components/2fa-recovery-form/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    intl: Ember.inject.service(),
    identification: null,
    message: null,
    securityCode: '',
    errorMessage: '',
    successMessage: '',
    isLoading: false,
    isConfirmButtonShown: true,
    init: function init() {
      this._super.apply(this, arguments);
    },

    actions: {
      confirm: function confirm() {
        this.get('onFormSubmit')();
      },
      copy: function copy() {
        var _this = this;

        var codes = this.get('codes');

        if (!Array.isArray(codes) || codes.length === 0) {
          this.set('errorMessage', true);
          return this.set('message', this.get('intl').t('login.otp.badRestoreCodes'));
        }

        navigator.clipboard.writeText(codes.join(',')).then(function () {
          _this.set('successMessage', true);
          _this.set('message', _this.get('intl').t('login.otp.restoreCodesCopied'));
          var callback = _this.get('onCopyButtonClick');
          if (callback && typeof callback === "function") {
            Ember.run(function () {
              return callback();
            });
          }
        });
      }
    }
  });
});