define('backoffice/initializers/client-storage', ['exports', 'npm:store/src/store-engine', 'npm:store/storages/localStorage', 'npm:store/storages/oldFF-globalStorage', 'npm:store/storages/oldIE-userDataStorage', 'npm:store/storages/cookieStorage', 'npm:store/storages/sessionStorage', 'npm:store/storages/memoryStorage', 'npm:store/plugins/defaults', 'npm:store/plugins/expire', 'npm:store/plugins/dump'], function (exports, _storeEngine, _localStorage, _oldFFGlobalStorage, _oldIEUserDataStorage, _cookieStorage, _sessionStorage, _memoryStorage, _defaults, _expire, _dump) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {
    var storageList = [_localStorage.default, _oldFFGlobalStorage.default, _oldIEUserDataStorage.default, _cookieStorage.default, _sessionStorage.default, _memoryStorage.default];
    var plugins = [_defaults.default, _expire.default, _dump.default];
    var storage = _storeEngine.default.createStore(storageList, plugins);
    application.register('client-storage:main', storage, { instantiate: false });
    application.register('client-storage:account', storage.namespace('account'), { instantiate: false });
    application.register('client-storage:settings', storage.namespace('settings'), { instantiate: false });
    application.register('client-storage:version', storage.namespace('version'), { instantiate: false });
    application.inject('route', 'settingsStorage', 'client-storage:settings');
    application.inject('route', 'accountStorage', 'client-storage:account');
    application.inject('route', 'settingsVersion', 'client-storage:version');
    application.inject('authenticator:oauth2', 'accountStorage', 'client-storage:account');
    application.inject('service:permissions-manager', 'accountStorage', 'client-storage:account');
    application.inject('service:client-settings', 'accountStorage', 'client-storage:account');
    application.inject('controller:2fa.recovery', 'accountStorage', 'client-storage:account');
    application.inject('controller:login', 'settingsStorage', 'client-storage:settings');
    application.inject('controller:admin.transactions.search', 'settingsStorage', 'client-storage:settings');
    application.inject('component:forecast-wrapper', 'settingsStorage', 'client-storage:settings');
    application.inject('component:locale-switcher', 'settingsStorage', 'client-storage:settings');
    application.inject('component:widgets-connect-landing', 'settingsStorage', 'client-storage:settings');
    application.inject('component:widgets-connect-btv', 'settingsStorage', 'client-storage:settings');
    application.inject('component:widgets-connect-reports', 'settingsStorage', 'client-storage:settings');
    application.inject('component:widgets-connect-subscriptions', 'settingsStorage', 'client-storage:settings');
    application.inject('component:widgets-connect-chargebacks', 'settingsStorage', 'client-storage:settings');
    application.inject('controller:admin.reports.invoices.search', 'settingsStorage', 'client-storage:settings');
    application.inject('controller:admin.reports.settlements.search', 'settingsStorage', 'client-storage:settings');
  }

  exports.default = {
    name: 'client-storage',
    initialize: initialize
  };
});