define('backoffice/routes/admin/reports/invoices/search', ['exports', 'backoffice/mixins/admin-route-mixin', 'ember-simple-auth/mixins/authenticated-route-mixin', 'backoffice/mixins/errors-handler', 'moment'], function (exports, _adminRouteMixin, _authenticatedRouteMixin, _errorsHandler, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_adminRouteMixin.default, _authenticatedRouteMixin.default, _errorsHandler.default, {
    init: function init() {
      this._super.apply(this, arguments);
      this.title = this.get('intl').t('reports.title');
    },

    store: Ember.inject.service(),
    queryParams: {
      dateFrom: { refreshModel: true },
      dateTo: { refreshModel: true },
      status: { refreshModel: true },
      currency: { refreshModel: true },
      invoiceNumbers: { refreshModel: true },
      page: { refreshModel: true },
      size: { refreshModel: true }
    },
    model: function model(params) {
      var populatedParams = {};

      for (var key in params) {
        if (params.hasOwnProperty(key) && params[key] != null && params[key] !== '') {
          if (key === 'dateFrom') {
            populatedParams.dateFrom = params.dateFrom || this.controllerFor('admin.reports.invoices').get('searchParams.dateFrom');
          } else if (key === 'dateTo') {
            populatedParams.dateTo = params.dateTo || this.controllerFor('admin.reports.invoices').get('searchParams.dateTo');
          } else {
            populatedParams[key] = params[key];
          }
        }
      }

      this.controllerFor('admin.reports.invoices').set('searchParams', this.paramsFor('admin.reports.invoices.search'));

      return this.store.query('invoice', populatedParams);
    },
    resetController: function resetController(controller) {
      var queryParams = controller.get('queryParams');

      queryParams.forEach(function (param) {
        if (param === 'dateFrom') {
          controller.set('dateFrom', _moment.default.utc().startOf('month').format('YYYY-MM-DDTHH:mm:ss'));
        } else if (param === 'dateTo') {
          controller.set('dateTo', (0, _moment.default)().format('YYYY-MM-DDTHH:mm:ss'));
        } else {
          controller.set(param, null);
        }
      });
    }
  });
});