define('backoffice/models/kpi', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    month: (0, _attr.default)('string', { defaultValue: '' }),
    succeeded: (0, _attr.default)('number', { defaultValue: 0 }),
    amount: (0, _attr.default)('number', { defaultValue: 0 }), // in euro
    successRatio: (0, _attr.default)('number', { defaultValue: 0 }),
    visaCHB: (0, _attr.default)('number', { defaultValue: 0 }),
    mcCHB: (0, _attr.default)('number', { defaultValue: 0 }),
    visaSucceeded: (0, _attr.default)('number', { defaultValue: 0 }),
    mcmaSucceded: (0, _attr.default)('number', { defaultValue: 0 }),
    visaCHBRatio: (0, _attr.default)('number', { defaultValue: 0 }),
    visaCHBAmount: (0, _attr.default)('number', { defaultValue: 0 }),
    mcCHBRatio: (0, _attr.default)('number', { defaultValue: 0 }),
    mcCHBAmount: (0, _attr.default)('number', { defaultValue: 0 }), // in cents
    countProgress: (0, _attr.default)('number', { defaultValue: 0 }),
    amountProgress: (0, _attr.default)('number', { defaultValue: 0 })
  });
});