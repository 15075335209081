define('backoffice/helpers/contains', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.contains = contains;
  function contains(object, options) {
    // string check
    if (typeof options.collection === 'string') {
      return options.collection.search(options.item) >= 0;
    }
    // "collection" check (objects & arrays)
    for (var prop in options.collection) {
      if (options.collection.hasOwnProperty(prop)) {
        if (options.collection[prop] === options.item) {
          return true;
        }
      }
    }
    return false;
  }

  exports.default = Ember.Helper.helper(contains);
});