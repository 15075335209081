define('backoffice/pods/components/table-cell-period/component', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'td',
    index: null,
    data: null,
    row: null,
    collapsed: null,
    period: Ember.computed('row', function () {
      return (0, _moment.default)(this.get('row.periodFrom')).format('DD/MM/YYYY') + '-' + (0, _moment.default)(this.get('row.periodTo')).format('DD/MM/YYYY');
    }),
    click: function click() {
      if (this.get('isTruncated')) {
        this.toggleProperty('collapsed');
        if (this.get('toggleDetails')) {
          this.get('toggleDetails')({ collapsed: this.get('collapsed') });
        }
      }
    }
  });
});