define('backoffice/constants/support', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    FILTERS_LIST: ['All', 'Open', 'Closed'],
    DEFAULT_STATUS_FILTER: 'All',
    CATEGORIES_LIST: ['General query', 'IT support query', 'Financial&Payment query', 'Risk&Compliance query'],
    SEVERITY_LIST: ['Critical', 'Issue', 'Improvement'],
    CATEGORIES_MAP: {
      45: 'General query',
      46: 'IT support query',
      47: 'Financial&Payment query',
      48: 'Risk&Compliance query'
    },
    SEVERITIES_MAP: {
      78: 'Critical',
      79: 'Issue',
      80: 'Improvement'
    },
    MESSAGES_ON_PAGE: 10,
    TICKETS_ON_PAGE: 10,
    CRITICAL_HINT: 'Transaction processing affected, probable financial losses.',
    ISSUE_HINT: 'Technical issue persists, but it neither affects transaction processing nor incurs financial losses.',
    IMPROVEMENT_HINT: 'Provide your feedback as for improvement of current functionality or submit a new feature request.'
  };
});