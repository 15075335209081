define('backoffice/pods/components/table-cell-user-actions/component', ['exports', 'backoffice/constants/permissions'], function (exports, _permissions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var resetPassHint = 'An email will be sent to the associated email with the link to reset password.';
  var adminUserHint = 'No actions can be performed over the admin user.';
  var disabledUserHint = 'Enable user first.';

  exports.default = Ember.Component.extend({
    tagName: 'td',
    index: null,
    data: null,
    row: null,
    collapsed: null,
    manage2FAPermission: _permissions.default.TWO_FACTOR_AUTH_MANAGE_KEY,
    resetPassHint: resetPassHint,
    adminUserHint: adminUserHint,
    disabledUserHint: disabledUserHint
  });
});