define('backoffice/pods/components/modal-dialog/component', ['exports', 'ember-modal-dialog/components/modal-dialog', 'ember-keyboard'], function (exports, _modalDialog, _emberKeyboard) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _modalDialog.default.extend(_emberKeyboard.EKMixin, {
    init: function init() {
      this._super.apply(this, arguments);
      this.set('keyboardActivated', true);
    },

    closeOnEsc: Ember.on((0, _emberKeyboard.keyDown)('Escape'), function () {
      this.get('reset')();
    })
  });
});