define('backoffice/pods/components/offer-settings-checkboxes/component', ['exports', 'backoffice/constants/offers'], function (exports, _offers) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var OFFER_ONESHOT_TYPEID = _offers.default.OFFER_ONESHOT_TYPEID,
      OFFER_UPSELL_TYPEID = _offers.default.OFFER_UPSELL_TYPEID;
  exports.default = Ember.Component.extend({
    tagName: '',
    isCloneAsUpsellFlag: false,
    isDynamicPricing: Ember.Object.create({
      isSelected: false,
      name: 'Dynamic pricing offer'
    }),
    isCloneAsUpsell: Ember.Object.create({
      isSelected: false,
      name: 'Clone as Upsell'
    }),
    isOneShot: Ember.computed('currentOfferType.id', function () {
      return parseInt(this.get('currentOfferType.id')) === OFFER_ONESHOT_TYPEID && !this.get('newOffer.hasUpsell');
    }),
    isDynamicPricingShow: Ember.computed('currentOfferType', function () {
      var id = parseInt(this.get('currentOfferType.id'));
      return this.get('newOffer.isNew') && (id === OFFER_ONESHOT_TYPEID || id === OFFER_UPSELL_TYPEID);
    }),
    disabled: false,
    init: function init() {
      this._super.apply(this, arguments);
      var isNewDynamicPricing = this.get('newOffer.isNew') && this.get('isOneShot');

      if (isNewDynamicPricing) {
        this.set('newOffer.isDynamicPricing', true);
        this.set('isDynamicPricing.isSelected', true);
      } else {
        this.set('isDynamicPricing.isSelected', this.get('newOffer.isDynamicPricing'));
      }
      this.set('isCloneAsUpsell.isSelected', this.get('newOffer.hasUpsell'));
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.set('isCloneAsUpsellFlag', false);
    },

    actions: {
      dynamicPriceClick: function dynamicPriceClick(item) {
        item.toggleProperty('isSelected');
        this.set('newOffer.isDynamicPricing', this.get('isDynamicPricing.isSelected'));
      },
      cloneAsUpsellClick: function cloneAsUpsellClick(item) {
        item.toggleProperty('isSelected');
        this.set('isCloneAsUpsellFlag', this.get('isCloneAsUpsell.isSelected'));
      },
      showErrors: function showErrors(param) {
        this.set(param, true);
      }
    }
  });
});