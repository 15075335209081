define('backoffice/pods/components/create-user-dialog/component', ['exports', 'ember-intl', 'ember-cp-validations'], function (exports, _emberIntl, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var loginFormat = /^[a-zA-Z0-9\-_]+$/i;
  var emailFormat = /^[^<>()\[\]\\.,;:\s@"]+(?:[+.][^<>()\[\]\\.,;:\s@"]+)*@(?:[a-z\-\d]+\.)+[a-z]{2,}$/i;
  var loginTip = 'User ID consists of part common for all users and unique part which you can set up here.';
  var Validations = (0, _emberCpValidations.buildValidations)({
    newLogin: [(0, _emberCpValidations.validator)('length', {
      min: 1,
      max: 20,
      messageKey: 'users.validations.newLogin.length'
    }), (0, _emberCpValidations.validator)('format', {
      regex: loginFormat,
      messageKey: 'users.validations.newLogin.format'
    })],
    newEmail: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      messageKey: 'users.validations.newEmail.presence'
    }), (0, _emberCpValidations.validator)('format', {
      regex: emailFormat,
      messageKey: 'users.validations.newEmail.format'
    })]
  });

  exports.default = Ember.Component.extend(Validations, {
    clientSettings: Ember.inject.service('clientSettings'),
    session: Ember.inject.service(),
    cid: Ember.computed(function () {
      return this.get('clientSettings').getCustomer().id + '_';
    }),
    confirmBtnName: (0, _emberIntl.t)('common.create'),
    cancelBtnName: (0, _emberIntl.t)('common.cancel'),
    loginTip: loginTip,
    roleEntries: null,
    newLogin: null,
    newName: null,
    newEmail: null,
    chosen: null,
    isFormDisabled: true,
    roles: Ember.computed('roleEntries', function () {
      return this.get('roleEntries').map(function (item) {
        return {
          id: item.get('id'),
          name: item.get('name')
        };
      });
    }),
    hasUniqueLoginError: Ember.computed('uniqueNameError', function () {
      return !!this.get('uniqueNameError');
    }),
    isLoginErrorShown: false,
    isEmailErrorShown: false,
    willDestroy: function willDestroy() {
      this.set('newName', null);
      this.set('newEmail', null);
      this.set('newLogin', null);
      this.set('chosen', null);
    },

    actions: {
      confirm: function confirm() {
        var newName = this.get('newName') ? this.get('newName').trim() : null,
            user = {
          login: this.get('newLogin'),
          name: newName,
          email: this.get('newEmail')
        };

        this.get('confirm')(user, this.get('chosen.id'));
      },
      confirmNotify: function confirmNotify() {
        this.get('reset')();
      },
      cancelOperation: function cancelOperation() {
        this.get('reset')();
      },
      setValidationState: function setValidationState(inputField) {
        var isFormValid = !!(this.get('validations.attrs.newLogin.isValid') && this.get('validations.attrs.newEmail.isValid') && this.get('chosen.id'));
        this.set('isFormDisabled', !isFormValid);
        if (inputField === 'Login') {
          this.get('voidUniqueLoginError')();
        }
      },
      errorHandler: function errorHandler(inputField) {
        this.set('is' + inputField + 'ErrorShown', !this.get('validations.attrs.new' + inputField + '.isValid'));
      }
    }
  });
});