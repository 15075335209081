define('backoffice/routes/admin/reports/invoices', ['exports', 'backoffice/mixins/admin-route-mixin', 'ember-simple-auth/mixins/authenticated-route-mixin', 'backoffice/mixins/errors-handler'], function (exports, _adminRouteMixin, _authenticatedRouteMixin, _errorsHandler) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_adminRouteMixin.default, _authenticatedRouteMixin.default, _errorsHandler.default, {
    init: function init() {
      this._super.apply(this, arguments);
      this.title = this.get('intl').t('reports.invoices');
    },

    actions: {
      refreshPage: function refreshPage() {
        this.refresh();
      }
    },
    model: function model() {
      return Ember.RSVP.hash({
        allCurrencies: this.store.findAll('allCurrency')
      });
    }
  });
});