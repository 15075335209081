define('backoffice/pods/components/table-row-websites/component', ['exports', 'backoffice/pods/components/table-row/component', 'backoffice/constants/websites'], function (exports, _component, _websites) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var WEBSITE_STATUS_ACCEPTED = _websites.default.WEBSITE_STATUS_ACCEPTED,
      WEBSITE_STATUS_PENDING = _websites.default.WEBSITE_STATUS_PENDING;
  exports.default = _component.default.extend({
    classNameBindings: ['isAccepted:active-row', 'isPending:pending-row'],
    isAccepted: Ember.computed('data', function () {
      return this.get('data.status') === WEBSITE_STATUS_ACCEPTED;
    }),
    isPending: Ember.computed('data', function () {
      return this.get('data.status') === WEBSITE_STATUS_PENDING;
    })
  });
});