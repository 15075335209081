define('backoffice/constants/permissions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var PERMISSION_KEYS = {
    TRANSACTIONS_VIEW_KEY: 'transactions_view',
    BTV_VIEW_KEY: 'btv_view',
    BTV_REPORTS_VIEW_KEY: 'btv_reports',
    CHB_VIEW_KEY: 'chb_view',
    CHB_REPORTS_VIEW_KEY: 'chb_reports',
    PAYOUTS_PAY_KEY: 'payouts_pay',
    TRANSACTIONS_EXPORT_KEY: 'transactions_export',
    TRANSACTIONS_CANCEL_KEY: 'transactions_cancel',
    PERMISSIONS_VIEW_KEY: 'permissions_view',
    PERMISSIONS_ROLES_ASSIGN_KEY: 'permissions_roles_assign',
    PERMISSIONS_ROLES_EDIT_KEY: 'permissions_roles_edit',
    PERMISSIONS_USERS_EDIT_KEY: 'permissions_users_edit',
    PAYOUTS_REPORT_KEY: 'payouts_report',
    DASHBOARD_MERCHANT_PERFORMANCE_KEY: 'dashboard_merchant_performance',
    DASHBOARD_COUNTRY_PERFORMANCE_KEY: 'dashboard_country_performance',
    DASHBOARD_TRANSACTION_PERFORMANCE_KEY: 'dashboard_transaction_performance',
    MEMBERSHIPS_VIEW_KEY: 'memberships_view',
    MEMBERSHIPS_CANCEL_KEY: 'memberships_cancel',
    WEBSITES_VIEW_KEY: 'websites_view',
    WEBSITES_EDIT_KEY: 'websites_edit',
    SUPPORT_ALL_KEY: 'support_all',
    PAYOUTS_VIEW_KEY: 'payouts_view',
    PAYOUTS_GET_BALANCE_KEY: 'payouts_balance',
    FINANCE_VIEW_KEY: 'finance_view',
    FINANCE_EXPORT_KEY: 'finance_export',
    FIELD_WHITELABEL_AT_TRANSACTIONS_KEY: 'field_white_label',
    FIELD_PANMASK_AT_TRANSACTIONS_KEY: 'field_pan_mask',
    IP_RESTRICTIONS_VIEW_KEY: 'ip_restrictions_view',
    IP_RESTRICTIONS_EDIT_KEY: 'ip_restrictions_edit',
    CALLBACK_CONFIG_KEY: 'settings_callback_config',
    API_DOCS_VIEW_KEY: 'api_docs',
    TWO_FACTOR_AUTH_VIEW_KEY: '2fa_view',
    TWO_FACTOR_AUTH_MANAGE_KEY: '2fa_manage'
  };

  var ACCESSIBLE_PERMISSION_FOR_ROUTE = exports.ACCESSIBLE_PERMISSION_FOR_ROUTE = {
    'admin.no-access': [],
    'admin.error': [],
    'admin.dashboard': ['dashboard_merchant_performance', 'dashboard_country_performance', 'dashboard_transaction_performance'],
    'admin.transactions.index': 'transactions_view',
    'admin.transactions.search': 'transactions_view',
    'admin.members.index': 'memberships_view',
    'admin.members.search': 'memberships_view',
    'admin.subscriptions.index': 'memberships_view',
    'admin.subscriptions.search': 'memberships_view',
    'admin.websites.index': 'websites_view',
    'admin.websites.new': 'websites_view',
    'admin.websites.edit': 'websites_view',
    'admin.packages.index': 'websites_view',
    'admin.packages.new': 'websites_view',
    'admin.packages.edit': 'websites_view',
    'admin.offers.index': 'websites_view',
    'admin.offers.new': 'websites_view',
    'admin.offers.edit': 'websites_view',
    'admin.payouts': 'payouts_view',
    'admin.support.index': 'support_all',
    'admin.support.tickets.index': 'support_all',
    'admin.support.tickets.new': 'support_all',
    'admin.support.tickets.edit': 'support_all',
    'admin.roles': 'permissions_view',
    'admin.forecast.index': 'finance_view',
    'admin.forecast.search': 'finance_view',
    'admin.reports.index': 'finance_view',
    'admin.reports.invoices.index': 'finance_view',
    'admin.reports.invoices.search': 'finance_view',
    'admin.reports.settlements.index': 'finance_view',
    'admin.reports.settlements.search': 'finance_view',
    'admin.ipSettings': 'ip_restrictions_view',
    'admin.callbackSettings': 'settings_callback_config',
    'admin.2fa.manage': PERMISSION_KEYS.TWO_FACTOR_AUTH_MANAGE_KEY,
    'admin.2faSettings': [PERMISSION_KEYS.TWO_FACTOR_AUTH_VIEW_KEY, PERMISSION_KEYS.TWO_FACTOR_AUTH_MANAGE_KEY],
    'admin.profile': [],
    'docs.reference': [] //'api_docs',
  };

  exports.default = PERMISSION_KEYS;
});