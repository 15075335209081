define('backoffice/pods/components/dashboard-ref-chb-chart/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    dashboardService: Ember.inject.service('dashboard'),
    intl: Ember.inject.service(),
    countData: Ember.computed('data', function () {
      return this._getData(this.get('countField'), this.get('data'));
    }),
    ratioData: Ember.computed('data', function () {
      var _this = this;

      return this._getData(this.get('ratioField'), this.get('data')).map(function (value) {
        return _this.get('dashboardService').round(value * 100);
      });
    }),
    categories: Ember.computed('data', function () {
      if (this.get('data')) {
        return this.get('data').map(function (item) {
          var period = item.get('period');
          var formattedPeriod = typeof period === 'string' ? period.replace(/-/g, "/") : period;
          return new Date(formattedPeriod);
        });
      } else {
        return [];
      }
    }),
    _getData: function _getData(dataType) {
      var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];

      var filtered = data.filter(function (item) {
        return item.get('intervalName') !== 'Total';
      });
      return filtered.map(function (item) {
        var val = item.get(dataType);
        return val === 0 ? 0 : val;
      });
    },

    chartOptions: Ember.computed('data', function () {
      var that = this;
      return {
        chart: {
          height: 300
        },
        legend: {
          align: 'right',
          verticalAlign: 'top',
          title: {
            style: {
              fontWeight: 'normal'
            }
          }
        },
        title: {
          text: null
        },
        loading: {
          labelStyle: {
            color: '#000000',
            fontWeight: 'bold'
          }
        },
        xAxis: {
          labels: {
            type: 'datetime',
            formatter: function formatter() {
              return this.x === 0 ? '' : that.get('dashboardService').formatDate(this.value, that.get('period'));
            }
          },
          categories: this.get('categories')
        },
        yAxis: [{
          title: null,
          labels: {
            enabled: false
          }
        }, {
          title: null,
          labels: {
            enabled: false
          }
        }],
        plotOptions: {
          series: {
            stacking: 'normal',
            groupPadding: 0
          }
        }
      };
    }),
    series: Ember.computed('data', function () {
      var configure = function configure(cardBrand, type) {
        var obj = {
          countName: '',
          ratioName: '',
          countColor: '',
          ratioColor: ''
        };

        if (cardBrand === 'visa') {
          obj.countColor = '#c5f3d2';
          obj.ratioColor = '#cee237';

          if (type === 'ref') {
            obj.countName = 'Visa REFs';
            obj.ratioName = 'Visa REFs %';
          } else {
            obj.countName = 'Visa CHBs';
            obj.ratioName = 'Visa CHBs %';
          }
        } else {
          obj.countColor = '#ffcf68';
          obj.ratioColor = '#efd140';

          if (type === 'ref') {
            obj.countName = 'MC REFs';
            obj.ratioName = 'MC REFs %';
          } else {
            obj.countName = 'MC CHBs';
            obj.ratioName = 'MC CHBs %';
          }
        }

        return obj;
      };

      var _configure = configure(this.get('cardBrand'), this.get('type')),
          countName = _configure.countName,
          ratioName = _configure.ratioName,
          countColor = _configure.countColor,
          ratioColor = _configure.ratioColor;

      var that = this;
      return [{
        name: countName,
        type: 'column',
        data: this.get('countData'),
        color: countColor,
        yAxis: 0,
        dataLabels: {
          enabled: false,
          allowOverlap: true,
          align: 'center',
          color: '#000000',
          style: {
            fontSize: '11px',
            fontWeight: 'bold',
            boxShadow: '0px 0px 15px 10px rgba(255,255,255,1)'
          }
        },
        tooltip: {
          headerFormat: '<span style="font-size: 12px; font-weight: bold;">{series.name}.</span>',
          pointFormatter: function pointFormatter() {
            return '<br>' + that.get('dashboardService').formatDate(this.category, that.get('period')) + ': ' + that.get('intl').formatNumber(this.y);
          }
        }
      }, {
        name: ratioName,
        type: 'spline',
        data: this.get('ratioData'),
        color: ratioColor,
        yAxis: 1,
        dataLabels: {
          enabled: true,
          allowOverlap: true,
          align: 'center',
          color: '#000000',
          style: {
            fontSize: '11px',
            fontWeight: 'bold',
            boxShadow: '0px 0px 15px 10px rgba(255,255,255,1)'
          },
          formatter: function formatter() {
            return this.y === 0 ? '' : that.get('dashboardService').round(this.y) + '%';
          }
        },
        tooltip: {
          headerFormat: '<span style="font-size: 12px; font-weight: bold;">{series.name}.</span>',
          pointFormatter: function pointFormatter() {
            return '<br>' + that.get('dashboardService').formatDate(this.category, that.get('period')) + ': ' + that.get('intl').formatNumber(this.y) + '%';
          }
        }
      }];
    })
  });
});