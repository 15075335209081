define('backoffice/models/message', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      Model = _emberData.default.Model,
      hasMany = _emberData.default.hasMany;
  exports.default = Model.extend({
    attachments: hasMany('attachment', { async: false }),
    author: attr('string'),
    authorUrl: attr('string'),
    change: attr(),
    content: attr('string'),
    created: attr('string'),
    type: attr('string')
  });
});