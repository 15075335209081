define('backoffice/pods/components/table-cell-offer-translations/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'td',
    locale: Ember.computed('data', function () {
      var translations = this.get('data'),
          locale = [];

      if (translations.length) {
        translations.forEach(function (item) {
          locale.pushObject({
            iso: item.langcode.toLowerCase(),
            tooltip: item.translation
          });
        });
      }

      return locale;
    })
  });
});