define('backoffice/pods/components/offer-settings-translations-board/component', ['exports', 'backoffice/constants/offers'], function (exports, _offers) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var PATH_TO_FLAGS = _offers.default.PATH_TO_FLAGS;
  exports.default = Ember.Component.extend({
    tagName: '',
    path: PATH_TO_FLAGS,
    disabled: false,
    actions: {
      edit: function edit(translation) {
        var _this = this;

        this.get('langs').forEach(function (item) {
          if (translation.get('langcode') === item.langcode) {
            _this.set('lang', item);
            _this.set('lang.translation', translation.get('translation'));
          }
        });
      },
      removeTranslation: function removeTranslation(translationId) {
        var objRemove = this.get('newOffer.translations').findBy('id', translationId);
        this.get('newOffer.translations').removeObject(objRemove);
      }
    }
  });
});