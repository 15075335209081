define('backoffice/serializers/customer-ip-restriction', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTSerializer.extend({
    normalize: function normalize(modelClass, hash) {
      hash.id = hash.customerId;
      return this._super.apply(this, arguments);
    }
  });
});