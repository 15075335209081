define('backoffice/routes/admin/packages/index', ['exports', 'backoffice/mixins/admin-route-mixin', 'ember-simple-auth/mixins/authenticated-route-mixin', 'backoffice/mixins/errors-handler', 'backoffice/mixins/reset-scroll-position'], function (exports, _adminRouteMixin, _authenticatedRouteMixin, _errorsHandler, _resetScrollPosition) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_adminRouteMixin.default, _authenticatedRouteMixin.default, _errorsHandler.default, _resetScrollPosition.default, {
    init: function init() {
      this._super.apply(this, arguments);
      this.title = this.get('intl').t('packages.title');
    },
    model: function model() {
      return this.store.query('package', {});
    },

    actions: {
      create: function create() {
        this.transitionTo('admin.packages.new');
      },
      edit: function edit(id) {
        if (!id) {
          return;
        }

        this.transitionTo('admin.packages.edit', id);
      },
      showDialog: function showDialog(id) {
        this.controller.set('removedId', id);
        this.controller.set('isRemoveDialogShown', true);
      },
      resetRemove: function resetRemove() {
        this.controller.set('isRemoveDialogShown', false);
        this.controller.set('removedId', null);
      },
      remove: function remove() {
        var _this = this;

        this.controller.set('isRemoveDialogShown', false);
        var id = this.controller.get('removedId');
        if (!id) {
          return;
        }

        this.store.findRecord('package', id, { backgroundReload: false }).then(function (pack) {
          pack.destroyRecord().then(function () {
            _this.refresh();
          }).catch(function (error) {
            _this.handleErrors(error);
          });
        });
      }
    }
  });
});